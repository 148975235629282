export const months = {
	January: 0,
	February: 1,
	March: 2,
	April: 3,
	May: 4,
	June: 5,
	July: 6,
	August: 7,
	September: 8,
	October: 9,
	November: 10,
	December: 11,
};

const isYearLeap = (year) => {
	if (!(year instanceof Date) && typeof year !== 'number') {
		throw new TypeError(`Expected \`year\` to be of type \`Date\` or \`number\`, got \`${typeof year}\``);
	}

	year = year instanceof Date ? year.getFullYear() : year;
	return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const days = (month, year = new Date().getFullYear()) => {
	let totalDays = 31;
	const curMonth = parseInt(month, 10);
	const isLeap = isYearLeap(parseInt(year, 10));
	const daysList = [];
	if ([3, 5, 7, 8, 10].includes(curMonth)) totalDays = 30;
	else if (curMonth === 1 && isLeap) totalDays = 29;
	else if (curMonth === 1 && !isLeap) totalDays = 28;
	for (let i = 1; i <= totalDays; i += 1) {
		daysList.push(i);
	}
	return daysList;
};

export const years = () => {
	const currentYear = new Date().getFullYear();
	const yearsList = [];
	for (let i = 1900; i <= currentYear; i += 1) {
		yearsList.push(i);
	}
	yearsList.reverse();
	return yearsList;
};

export const getWeekLimits = (date) => {
	const currentDate = new Date(date);
	const startOfTheWeekDate = currentDate.getDate() - currentDate.getDay();

	return {
		startDate: new Date(new Date(currentDate.setDate(startOfTheWeekDate)).setHours(0, 0, 0)),
		endDate: new Date(new Date(currentDate.setDate(startOfTheWeekDate + 7)).setHours(0, 0, 0)),
	};
};

export const getMinutesDifference = (start, end) => (new Date(end) - new Date(start)) / 60000;

export const getDayDifference = (startDate, endDate) => {
	const timeBetweenDates = Math.abs(endDate - startDate);
	return Math.floor(timeBetweenDates / (1000 * 60 * 60 * 24));
};
