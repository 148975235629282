import PropTypes from 'prop-types';
import React, { lazy, useState } from 'react';
import { Button } from 'reactstrap';
import { FaArrowLeft } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import { ModalScreen } from '../../Modal/Screen/Screen';
import { ModalScreenHeader } from '../../Modal/Screen/Header';
import { StudioScheduleManagerTab, useStudioSchedule } from '../Context';
import { ModalLazyContent } from '../../Modal/LazyContent';

const StudioScheduleManagerEditModalContent = lazy(() => import('./EditModalContent'));

export const StudioScheduleManagerEditModal = ({
	isOpen,
	options,
	studioOwnerId,
}) => {
	const { t } = useTranslation();
	const { closeModal } = useStudioSchedule();
	const [tab, setTab] = useState(StudioScheduleManagerTab.EDIT);

	return (
		<ModalScreen
			contentClassName="bg-gray"
			bodyClassName="pt-0"
			header={(
				<ModalScreenHeader
					noBorder
					onClose={tab === StudioScheduleManagerTab.EDIT_BANNER ? undefined : closeModal}
				>
					{tab === StudioScheduleManagerTab.EDIT_BANNER && (
						<header className="d-flex align-items-center">
							<Button
								className="btn-pill d-40 p-0"
								color="neutral-secondary"
								onClick={() => { setTab(StudioScheduleManagerTab.EDIT); }}
								title={t('EditModal.back')}
							>
								<span className="btn-wrapper--icon">
									<FaArrowLeft />
								</span>
							</Button>
							<h2 className="ml-2 h4 mb-0">{t('EditModal.bannerAndDesc')}</h2>
						</header>
					)}
				</ModalScreenHeader>
			)}
			isOpen={isOpen}
			onClose={tab === StudioScheduleManagerTab.EDIT_BANNER ? undefined : closeModal}
		>
			<ModalLazyContent>
				<StudioScheduleManagerEditModalContent
					options={options}
					setTab={setTab}
					studioOwnerId={studioOwnerId}
					tab={tab}
				/>
			</ModalLazyContent>
		</ModalScreen>
	);
};

StudioScheduleManagerEditModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	options: PropTypes.shape({
		forceStartImmediately: PropTypes.bool,
	}),
	studioOwnerId: PropTypes.string,
};

StudioScheduleManagerEditModal.defaultProps = {
	options: {},
	studioOwnerId: undefined,
};
