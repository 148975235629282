import { createSelector } from 'reselect';

import {
	selectChannelLiveStream,
	selectChannelParticipantsStreams,
} from './channelStreams';
import { selectTracks } from './tracks';
import MediaStreamCache from '../lib/MediastreamCache';

const msc = new MediaStreamCache();

export const selectStreams = createSelector(
	selectChannelParticipantsStreams,
	selectChannelLiveStream,
	(
		channelParticipantsStreams,
		channelLiveStream,
	) => [
		...channelParticipantsStreams,
		channelLiveStream,
	].filter(Boolean),
);

export const select = createSelector(
	selectStreams,
	selectTracks,
	(streams, tracks) => msc.getMediastreams(streams, tracks),
);

export const selectLiveMediaStream = createSelector(
	select,
	selectChannelLiveStream,
	(streams = [], liveStream = {}) => {
		const liveMediaStream = streams.find(({ stream: { id } }) => id === liveStream.id);
		return liveMediaStream;
	},
);

export const selectParticipantsMediaStreams = createSelector(
	select,
	selectChannelParticipantsStreams,
	(streams = [], participantsStreams) => (
		participantsStreams.map(({ id }) => streams.find(({ stream }) => stream.id === id))
	),
);
