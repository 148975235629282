import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { FaAngleRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Alert, Button, ListGroup, ListGroupItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { toPercentage } from '../../../lib/number';
import ReallyPerfectScrollbar from '../../ReallyPerfectScrollbar/ReallyPerfectScrollbar';
import { useStudio } from '../../Studio/Provider';
import { useVote } from '../Provider';
import { useAsyncErrorLog } from '../../../lib/hooks';
import { useListSorting, ListSortingType } from '../../ListSorting/Provider';
import { ListSortingSelect } from '../../ListSorting/Select';

export const VoteHistory = ({ openReportModal, onVotePreviewClick, onCreateVoteClick }) => {
	const { t } = useTranslation();
	const { studio } = useStudio();
	const { votes, fetchVotes, sendReportThroughMail } = useVote();
	const { currentSort } = useListSorting();

	const sendReportThroughMailAsync = useAsyncCallback(sendReportThroughMail);
	useAsyncErrorLog(sendReportThroughMailAsync);

	const handleSendReportThroughEmail = () => {
		if (studio) {
			sendReportThroughMailAsync.execute(studio._id);
		}
	};

	useEffect(() => { fetchVotes(); }, [fetchVotes]);

	const votesSorted = useMemo(() => (votes || []).toSorted(
		(a, b) => (currentSort === ListSortingType.OLDEST_TO_NEWEST
			? new Date(a.endedAt) - new Date(b.endedAt)
			: new Date(b.endedAt) - new Date(a.endedAt)),
	), [votes, currentSort]);

	return (
		<>
			<section className="w-100 h-75 flex-grow-1">
				<ReallyPerfectScrollbar>
					{votesSorted?.length ? (
						<>
							<div className="w-100 text-center py-2 px-1">
								<Button
									color="neutral-secondary"
									className="btn-pill w-100 mb-2"
									disabled={sendReportThroughMailAsync.loading}
									onClick={handleSendReportThroughEmail}
								>{t('Votes.History.sendByEmail')}
								</Button>
								<Link to="#" onClick={openReportModal}>{t('Votes.History.seeSessionVoteReport')}</Link>
							</div>
							<ListSortingSelect className="mb-2" color="dark" />
							<ListGroup>
								{votesSorted.map((entry) => (
									<ListGroupItem
										key={entry._id}
										className="bg-dark content-dark rounded-0 d-flex justyfiy-content-between align-items-center py-2 px-3 mb-2"
									>
										<div className="w-100">
											<small className="content-dark-50">{entry.title}</small><br />
											<span className="content-dark-50">{entry.highestAnswer.label} - {toPercentage(entry.highestAnswer.percentage)}</span>
										</div>
										<div>
											<Button className="btn-pill d-40 p-0" color="neutral-secondary" onClick={() => onVotePreviewClick(entry)}>
												<span className="btn-wrapper--icon">
													<FaAngleRight size={20} className="content-dark-50" />
												</span>
											</Button>
										</div>
									</ListGroupItem>
								))}
							</ListGroup>
							{sendReportThroughMailAsync.status === 'success' && (
								<Alert color="success" className="mt-4">
									{t('Votes.History.reportSentByEmail')}
								</Alert>
							)}
							{sendReportThroughMailAsync.error && (
								<Alert color="danger" className="mt-4">
									{t('Global.error')}
								</Alert>
							)}
						</>
					) : (
						<p className="text-center">{t('Votes.History.noVotes')}</p>
					)}
				</ReallyPerfectScrollbar>
			</section>
			<footer className="w-100 mt-2">
				<Button color="primary" className="btn-pill w-100" onClick={onCreateVoteClick}>
					{t('Votes.History.startNewVote')}
				</Button>
			</footer>
		</>
	);
};

VoteHistory.propTypes = {
	openReportModal: PropTypes.func.isRequired,
	onVotePreviewClick: PropTypes.func.isRequired,
	onCreateVoteClick: PropTypes.func.isRequired,
};
