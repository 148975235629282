export const requestFullscreen = (videoElement) => {
	try {
		if (videoElement.requestFullscreen) return videoElement.requestFullscreen();
		if (videoElement.webkitRequestFullScreen) return videoElement.webkitRequestFullScreen();
		throw new Error('Fullscreen API is not supported');
	} catch (error) {
		console.error(error);
	}
	return false;
};

export const exitFullscreen = () => {
	if (
		document.fullscreenElement /* Standard syntax */
		|| document.webkitFullscreenElement /* Safari and Opera syntax */
		|| document.msFullscreenElement /* IE11 syntax */
	) {
		try {
			if (document.exitFullscreen) return document.exitFullscreen();
			if (document.webkitExitFullscreen) return document.webkitExitFullscreen();
			throw new Error('Fullscreen API is not supported');
		} catch (error) {
			console.error(error);
		}
	}
	return false;
};
