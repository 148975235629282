import React, { lazy, Suspense } from 'react';
import ReactGA from 'react-ga';
import {
	Switch,
	Route,
	useLocation,
	Redirect,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { LeftSidebar, NoSidebar } from './layout-blueprints';

import NavCount from './components/NavCount/NavCount';
import RouteIfAuthenticated from './components/Authentication/RouteIfAuthenticated';
import { postPageViewData } from './api/pageview/pageview';
import RouteIfRoleMatch from './components/Authentication/RouteIfRoleMatch';
import { Role } from './components/Authentication/Authentication';
import { BlankPage } from './layout-blueprints/BlankPage';
import { SlackConnect } from './components/Slack/Connect';
import ViewHome from './views/Home/Home';
import { AdminPath, BytvPathPrefix, DevPath, getRedirectToLink, Path, RedirectsFromPath } from './RoutePath';

const ViewChannel = lazy(() => import('./views/Channel/Channel'));
const ViewChannelFrame = lazy(() => import('./views/Channel/Frame'));
const ViewChannelFrameTest = lazy(() => import('./views/Channel/FrameTest'));
const ViewCookiePolicy = lazy(() => import('./views/LegalNotice/CookiePolicy'));
const ViewSearch = lazy(() => import('./views/Search/Search'));
const ViewTermsAndPrivacy = lazy(() => import('./views/LegalNotice/TermsAndPrivacy'));
const ViewVod = lazy(() => import('./views/VodView/VodView'));
const Checkout = lazy(() => import('./views/Checkout/Checkout'));
const Donate = lazy(() => import('./views/Donate/Donate'));
const ViewStudio = lazy(() => import('./views/Studio/Studio'));
const ViewStudioElements = lazy(() => import('./views/Studio/StudioElementsWrapper'));
const ViewStudioActivity = lazy(() => import('./views/Studio/StudioActivity'));
const ViewStudioChat = lazy(() => import('./views/Studio/StudioChatWrapper'));
const ViewStudioParticipants = lazy(() => import('./views/Studio/StudioParticipantsWrapper'));
const ViewStudioTools = lazy(() => import('./views/Studio/StudioToolsWrapper'));
const ViewStudioTemplates = lazy(() => import('./views/Studio/StudioTemplatesWrapper'));
const ViewStudioInactive = lazy(() => import('./views/Studio/Inactive'));
const ViewParticipant = lazy(() => import('./views/Participant/Participant'));
const ViewOperator = lazy(() => import('./views/Operator/Operator'));
const ViewLive = lazy(() => import('./views/Live/Live'));
const ViewLiveFrame = lazy(() => import('./views/Live/Frame'));
const ViewStudioDevReactVideo = lazy(() => import('./views/Studio/DevReactVideo'));
const ViewParticipantDevReactVideo = lazy(() => import('./views/Participant/DevReactVideo'));
const ViewMediaTest = lazy(() => import('./views/MediaTest/MediaTest'));
const ViewMediaTestWebrtc = lazy(() => import('./views/MediaTest/Webrtc'));
const ViewHelpCenter = lazy(() => import('./views/HelpCenter/HelpCenter'));
const ViewUserGuide = lazy(() => import('./views/UserGuide/UserGuide'));
const ViewSurvey = lazy(() => import('./views/Survey/Survey'));
const ViewStudioAttendanceSurvey = lazy(() => import('./components/Survey/Attendance/Attendance'));
const ViewPost = lazy(() => import('./views/PostPageView/PostPageView'));
const RoutesAdmin = lazy(() => import('./RoutesAdmin'));
const ViewWatchVideos = lazy(() => import('./views/WatchVideos/WatchVideos'));
const ViewMeetTheBees = lazy(() => import('./views/Meet/MeetTheBees'));
// const RoutesAdminAnalytics = lazy(() => import('./RoutesAdminAnalytics'));

function usePageViews() {
	const location = useLocation();
	const page = location.pathname;
	React.useEffect(() => {
		const timeout = setTimeout(() => {
			ReactGA.set({ page });
			ReactGA.pageview(page);
			postPageViewData(page);
		}, 500);
		return () => { clearTimeout(timeout); };
	}, [page]);
}

const RedirectRoutes = () => (
	<Switch>
		{RedirectsFromPath.map((path) => (
			<Route
				key={path}
				path={path}
				render={(props) => (
					// eslint-disable-next-line react/prop-types
					<Redirect to={getRedirectToLink(path, props.match.params)} />
				)}
			/>
		))}
	</Switch>
);

const Routes = () => {
	const { t } = useTranslation();
	usePageViews();

	return (
		<NavCount>
			<Suspense
				fallback={(
					<div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
						<div className="w-50 mx-auto">
							{t('Src.Routes.loading')}
						</div>
					</div>
				)}
			>
				<Switch>
					<Route path={RedirectsFromPath}>
						<RedirectRoutes />
					</Route>
					<RouteIfRoleMatch
						component={RoutesAdmin}
						path={AdminPath.ADMIN_ROOT}
						role={Role.ADMIN}
					/>
					<Route path={BytvPathPrefix}>
						<Switch>
							<Route
								path={[
									Path.MEDIATEST,
									Path.WEBRTCTEST,
									Path.LEGAL_COOKIES,
									Path.LEGAL_TERMS,
									Path.SEARCH,
									Path.HELP_USERGUIDE,
									Path.HELP_ANCHOR,
									Path.HELP,
									Path.HOME_WATCH_VIDEOS,
									Path.HOME_MEET_BEES,
								]}
							>
								<LeftSidebar>
									<Switch>
										<Route path={Path.HOME_WATCH_VIDEOS} component={ViewWatchVideos} />
										<Route path={Path.HOME_MEET_BEES} component={ViewMeetTheBees} />
										<Route path={Path.MEDIATEST} component={ViewMediaTest} />
										<Route path={Path.WEBRTCTEST} component={ViewMediaTestWebrtc} />
										<Route path={Path.LEGAL_COOKIES} component={ViewCookiePolicy} />
										<Route path={Path.LEGAL_TERMS} component={ViewTermsAndPrivacy} />
										<Route path={Path.SEARCH} component={ViewSearch} />
										<Route exact path={Path.HELP_USERGUIDE} component={ViewUserGuide} />
										<Route path={Path.HELP_ANCHOR} component={ViewHelpCenter} />
										<Route exact path={Path.HELP} component={ViewHelpCenter} />
									</Switch>
								</LeftSidebar>
							</Route>
							<Route
								path={[
									Path.CHECKOUT,
									Path.DONATE,
									Path.SLACK,
									'/pi-success', // TODO REMOVE ?
								]}
							>
								<NoSidebar>
									<Switch>
										<RouteIfAuthenticated path={Path.CHECKOUT} component={Checkout} />
										<RouteIfAuthenticated path={Path.DONATE} component={Donate} />
										<Route path={Path.SLACK_CONNECT} component={SlackConnect} />
									</Switch>
								</NoSidebar>
							</Route>
							<Route
								path={[
									Path.SURVEY,
									Path.SURVEY_STUDIO_ATTENDANCE,
								]}
							>
								<BlankPage>
									<Switch>
										<Route
											path={Path.SURVEY_STUDIO_ATTENDANCE}
											component={ViewStudioAttendanceSurvey}
										/>
										<RouteIfAuthenticated path={Path.SURVEY} component={ViewSurvey} />
									</Switch>
								</BlankPage>
							</Route>
							<Route
								path={[
									Path.STUDIO,
								]}
							>
								<LeftSidebar isMobileLayout over isStudioRoute noSearch>
									<Switch>
										<RouteIfAuthenticated path={Path.STUDIO} component={ViewStudioInactive} />
									</Switch>
								</LeftSidebar>
							</Route>
							{/* last one /bytv/:anchor to avoid conflicts */}
							<Route
								path={[
									Path.HOME,
								]}
							>
								<LeftSidebar>
									<Switch>
										<Route path={Path.HOME} component={ViewHome} />
									</Switch>
								</LeftSidebar>
							</Route>
						</Switch>
					</Route>
					<Route
						path={[
							DevPath.DEV_STUDIO,
							DevPath.DEV_STUDIO_PARTICIPANT,
						]}
					>
						<NoSidebar>
							<Switch>
								<RouteIfAuthenticated
									path={DevPath.DEV_STUDIO}
									component={ViewStudioDevReactVideo}
								/>
								<RouteIfAuthenticated
									path={DevPath.DEV_STUDIO_PARTICIPANT}
									component={ViewParticipantDevReactVideo}
								/>
							</Switch>
						</NoSidebar>
					</Route>
					<Route
						path={[
							'/:hashtag/v/:code/frame',
						]}
					>
						<BlankPage>
							<ViewLiveFrame />
						</BlankPage>
					</Route>
					<Route
						path={[
							Path.STUDIO_HOST_ELEMENTS,
							Path.STUDIO_ACTIVITY,
							Path.STUDIO_CHAT,
							Path.STUDIO_PARTICIPANTS,
							Path.STUDIO_TEMPLATES,
							Path.STUDIO_TOOLS,
						]}
					>
						<RouteIfAuthenticated
							path={Path.STUDIO_HOST_ELEMENTS}
							component={ViewStudioElements}
						/>
						<RouteIfAuthenticated
							path={Path.STUDIO_ACTIVITY}
							component={ViewStudioActivity}
						/>
						<RouteIfAuthenticated
							path={Path.STUDIO_CHAT}
							component={ViewStudioChat}
						/>
						<RouteIfAuthenticated
							path={Path.STUDIO_PARTICIPANTS}
							component={ViewStudioParticipants}
						/>
						<RouteIfAuthenticated
							path={Path.STUDIO_TEMPLATES}
							component={ViewStudioTemplates}
						/>
						<RouteIfAuthenticated
							path={Path.STUDIO_TOOLS}
							component={ViewStudioTools}
						/>
					</Route>
					<Route
						path={[
							Path.STUDIO_HOST,
							Path.STUDIO_PARTICIPANT,
							Path.STUDIO_OPERATOR,
							Path.STUDIO_VIEWER,
							Path.STUDIO_HOST_ELEMENTS,
						]}
					>
						<LeftSidebar isMobileLayout over isStudioRoute noSearch>
							<Switch>
								<RouteIfAuthenticated path={Path.STUDIO_PARTICIPANT} component={ViewParticipant} />
								<RouteIfAuthenticated path={Path.STUDIO_HOST} component={ViewStudio} />
								<RouteIfAuthenticated path={Path.STUDIO_OPERATOR} component={ViewOperator} />
								<Route path={Path.STUDIO_VIEWER} component={ViewLive} />
							</Switch>
						</LeftSidebar>
					</Route>
					<Route
						path={[
							'/:hashtag/frame',
						]}
					>
						<BlankPage>
							<ViewChannelFrame />
						</BlankPage>
					</Route>
					<Route
						path={[
							'/:hashtag/frametest',
						]}
					>
						<LeftSidebar>
							<ViewChannelFrameTest />
						</LeftSidebar>
					</Route>
					{/* Because channel pages can override defaults routes,
					keep them after everything else */}
					<Route
						path={[
							Path.CHANNEL,
							Path.POST,
							Path.VOD,
						]}
					>
						<LeftSidebar isMobileLayout over>
							<Switch>
								<Route path={Path.VOD} component={ViewVod} />
								<Route path={Path.POST} component={ViewPost} />
								<Route path={Path.CHANNEL} component={ViewChannel} />
							</Switch>
						</LeftSidebar>
					</Route>
					{/* ROOT last one to avoid "exact" */}
					<Route path={Path.ROOT}>
						<LeftSidebar>
							<Route path={Path.ROOT} component={ViewHome} />
						</LeftSidebar>
					</Route>
				</Switch>
			</Suspense>
		</NavCount>
	);
};

export default Routes;
