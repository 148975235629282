import {
	soupSession,
} from '../../api/soup';

const ACTION_KEY = 'publications';

export const ADD_STREAM_PUBLICATION = `${ACTION_KEY}/addStreamPublication`;
export const CLEAR_STREAM_PUBLICATION = `${ACTION_KEY}/clearStreamPublication`;
export const REMOVE_STREAM_PUBLICATION = `${ACTION_KEY}/removeStreamPublication`;
export const SUBSCRIBE_PUBLICATIONS = `${ACTION_KEY}/subscribePublications`;
export const UNSUBSCRIBE_PUBLICATIONS = `${ACTION_KEY}/unsubscribePublications`;

export const addStreamPublicationAction = (publication) => ({
	type: ADD_STREAM_PUBLICATION,
	payload: publication,
});

export const clearStreamPublicationAction = () => ({
	type: CLEAR_STREAM_PUBLICATION,
});

export const removeStreamPublicationAction = (publication) => ({
	type: REMOVE_STREAM_PUBLICATION,
	payload: publication,
});

export const subscribePublicationsAction = (publications, preferredLayers) => ({
	type: SUBSCRIBE_PUBLICATIONS,
	payload: async () => {
		const soup = soupSession();
		if (!soup) throw new Error('No soup session');
		return Promise.all(
			publications.map((publication) => soup.subscribe(publication, preferredLayers)),
		);
	},
});

export const unsubscribePublicationsAction = (publications) => ({
	type: UNSUBSCRIBE_PUBLICATIONS,
	payload: async () => {
		const soup = soupSession();
		if (!soup) throw new Error('No soup session');
		return Promise.all(publications.map((publication) => soup.unsubscribe(publication)));
	},
});
