import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { Form as SchemaForm, Field, FieldError } from 'react-jsonschema-form-validation';
import PhoneInput from 'react-phone-number-input';
import {
	Alert,
	Button,
	Container,
	FormGroup,
	Input,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FormLabel } from '../Form/Label';

import FormSubmit from '../Form/Submit';
import { PhoneInputInput } from '../Phone/PhoneInputInput';
import { IdentificationField, IdentificationFieldLabel } from '../Signup/signup.schema';
import { schema } from './stepForgottenPassword.schema';

const initFormData = (initialState) => ({
	identificationField: initialState.identificationField || IdentificationField.EMAIL,
	email: initialState.email || '',
	phoneNumber: initialState.phoneNumber || '',
});

const LoginStepForgottenPassword = ({
	initialState,
	onBack,
	onSubmit,
}) => {
	const { t } = useTranslation();
	const [formData, setFormData] = useState(initFormData(initialState));

	const handleSubmitAsync = useAsyncCallback(async () => {
		await onSubmit(formData);
	});

	const { error } = handleSubmitAsync;
	if (error) console.error(error);
	const errCode = error?.response?.status;

	const resetRef = useRef(handleSubmitAsync.reset);
	useEffect(() => { resetRef.current = handleSubmitAsync.reset; });

	const handleChange = useCallback((data) => {
		resetRef.current();
		setFormData((state) => ({ ...state, ...data }));
	}, []);

	useEffect(() => {
		setFormData((state) => ({ ...state, ...initFormData(initialState) }));
	}, [initialState]);

	return (
		<Container fluid>
			<SchemaForm
				className="h-100 d-flex flex-column mt-4 mx-0 form-light"
				data={formData}
				onChange={handleChange}
				onSubmit={handleSubmitAsync.execute}
				schema={schema}
			>
				<FormGroup>
					<FormLabel>
						Login with
					</FormLabel>
					<Field
						component={Input}
						name="identificationField"
						type="select"
						value={formData.identificationField}
					>
						{Object.keys(IdentificationField).map((key) => (
							<option
								key={`account-type-${IdentificationField[key]}`}
								value={IdentificationField[key]}
							>
								{IdentificationFieldLabel[key]}
							</option>
						))}
					</Field>
					<FieldError name="identificationField" />
				</FormGroup>
				{formData.identificationField === IdentificationField.EMAIL ? (
					<FormGroup>
						<FormLabel>{t('Login.StepForgottenPassword.email')}</FormLabel>
						<Field
							component={Input}
							placeholder={t('Login.StepForgottenPassword.enterEmail')}
							name="email"
							type="email"
							value={formData.email}
						/>
						<FieldError name="email" />
					</FormGroup>
				) : (
					<FormGroup>
						<FormLabel>
							{t('Login.StepForgottenPassword.Phone')}
						</FormLabel>
						<Field
							component={PhoneInput}
							inputComponent={PhoneInputInput}
							defaultCountry="US"
							international
							name="phoneNumber"
							placeholder={t('Login.StepForgottenPassword.enterPhoneNumber')}
							type="text"
							value={formData.phoneNumber}
							onChange={(value) => handleChange({ phoneNumber: value })}
						/>
						<FieldError name="phoneNumber" />
					</FormGroup>
				)}
				<p>
					<Button
						className="p-0"
						color="link"
						disabled={handleSubmitAsync.loading}
						onClick={() => onBack('stepLogin', formData)}
					>
						{t('Login.StepForgottenPassword.rememberPassword')}
					</Button>
				</p>
				<footer className="mt-auto">
					{(error && errCode === 403) && (
						<Alert color="danger">
							{t('Login.StepForgottenPassword.error.emailNotFound')}
						</Alert>
					)}
					{(error && errCode !== 403) && (
						<Alert color="danger">
							{t('Global.error')}
						</Alert>
					)}
					<div className="d-flex">
						<FormSubmit
							className="ml-auto px-5"
							loading={handleSubmitAsync.loading}
						>
							{t('Login.StepForgottenPassword.next')}
						</FormSubmit>
					</div>
				</footer>
			</SchemaForm>
		</Container>
	);
};

LoginStepForgottenPassword.propTypes = {
	initialState: PropTypes.shape({}),
	onBack: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
};

LoginStepForgottenPassword.defaultProps = {
	initialState: {},
};

export default LoginStepForgottenPassword;
