import React from 'react';
import PropTypes from 'prop-types';
import { getFileUrl, MEDIA_UPLOAD_LIST_THUMBNAIL_SIZE } from '../../lib/file';
import PlayerImage from '../Player/Image';
import PlayerVideo from '../Player/Video';
import { PostAssetType } from './Input';

export const MediaUploadList = ({
	uploadedAssets,
	onRemoveImage,
	onRemoveVideo,
}) => (
	<div className="d-flex mt-2">
		{uploadedAssets?.map((uploadedAsset) => (
			uploadedAsset.assetType === PostAssetType.IMAGE
				? (
					<PlayerImage
						key={uploadedAsset.filename}
						className="rounded-sm overflow-hidden size-sm mr-1"
						src={getFileUrl(
							{ name: uploadedAsset.filename },
							MEDIA_UPLOAD_LIST_THUMBNAIL_SIZE.width,
							MEDIA_UPLOAD_LIST_THUMBNAIL_SIZE.height,
						)}
						imageId={uploadedAsset.image}
						onRemove={onRemoveImage}
					/>
				)
				: (
					<PlayerVideo
						key={uploadedAsset.filename}
						className="rounded-sm overflow-hidden size-sm mr-1"
						src={getFileUrl({ name: uploadedAsset.filename })}
						videoId={uploadedAsset.video}
						onRemove={onRemoveVideo}
					/>
				)
		))}
	</div>
);

MediaUploadList.propTypes = {
	uploadedAssets: PropTypes.arrayOf(PropTypes.shape({})),
	onRemoveImage: PropTypes.func,
	onRemoveVideo: PropTypes.func,
};

MediaUploadList.defaultProps = {
	uploadedAssets: [],
	onRemoveImage: undefined,
	onRemoveVideo: undefined,
};
