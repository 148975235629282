import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { isSafari } from '../../lib/userAgent';

import './ReallyPerfectScrollbar.scss';

const ReallyPerfectScrollbar = ({
	children,
	className,
	forwardedRef,
	orientation,
	...props
}) => (
	<div
		className={clsx('ReallyPerfectScrollbar', className, { isSafari }, orientation)}
		ref={forwardedRef}
		{...props}
	>
		{children}
	</div>
);

ReallyPerfectScrollbar.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	forwardedRef: PropTypes.shape({}),
	orientation: PropTypes.oneOf(['horizontal', 'vertical']),
};

ReallyPerfectScrollbar.defaultProps = {
	children: undefined,
	className: undefined,
	forwardedRef: null,
	orientation: 'vertical',
};

export default React.forwardRef(
	(props, ref) => <ReallyPerfectScrollbar {...props} forwardedRef={ref} />,
);
