import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { getLink, Path } from '../../RoutePath';
import { ButtonPill, ButtonPillOutline } from '../Button';
import { ResourceAccessCard } from './Card';

export const ResourceAccessForceJoin = ({ onForceJoin }) => (
	<ResourceAccessCard>
		<h3>
			You already have an open connection to this meeting
		</h3>
		<p>
			You already have an open connection to this same meeting.
			If you want to open it anyway in this window click on &quot;Force join&quot;
		</p>
		<ButtonPillOutline
			className="mr-3"
			color="secondary"
			tag={Link}
			to={getLink(Path.ROOT)}
		>
			Exit
		</ButtonPillOutline>
		<ButtonPill onClick={onForceJoin}>Force Join</ButtonPill>
	</ResourceAccessCard>
);

ResourceAccessForceJoin.propTypes = {
	onForceJoin: PropTypes.func.isRequired,
};
