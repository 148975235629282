import { authenticatedApi } from '../api';

const API_SLACK_PATH = '/slack';

export const fetchSlackChannels = async () => authenticatedApi.get(
	`${API_SLACK_PATH}/channels`,
);

export const fetchSlackOAuthUrl = async () => authenticatedApi.get(
	`${API_SLACK_PATH}/oauth-url`,
);

export const fetchSlackChannel = async (slackChannelId) => authenticatedApi.get(
	`${API_SLACK_PATH}/channels/${slackChannelId}`,
);

export const importSlackChannel = (slackChannelId) => authenticatedApi.post(
	`${API_SLACK_PATH}/channels/${slackChannelId}/import`,
);

export const connectSlackChannel = (code) => authenticatedApi.post(
	`${API_SLACK_PATH}/channels/connect`,
	{ code },
);

export const deleteSlackChannel = (slackChannelId) => authenticatedApi.delete(
	`${API_SLACK_PATH}/channels/${slackChannelId}`,
);
