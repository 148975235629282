import {
	ADD_TRACK,
	CLEAR_TRACKS,
	REMOVE_TRACK,
} from '../actions/tracks';

export { STATE_KEY } from '../selectors/tracks';

/**
 * reducers
 */
export default (state = [], action) => {
	switch (action.type) {
	case ADD_TRACK: {
		const trackIndex = state.findIndex(({ id }) => (id !== action.track.id)) || state.length;
		return [
			...state.slice(0, trackIndex),
			action.track,
			...state.slice(trackIndex),
		];
	}
	case REMOVE_TRACK: {
		state = state.filter((track) => track.id !== action.track.id);
		return state;
	}
	case CLEAR_TRACKS: {
		return [];
	}
	default:
		return state;
	}
};
