import React, { useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import { useQueryClient } from 'react-query';
import Identity, { IdentityError } from '../../lib/Identity';

const { VITE_API_IDENTITY_AUTHENTICATION_ENDPOINT } = import.meta.env;

const BEEYOU_SESSION_LOCALSTORAGE_KEY = 'beeyou.session';

export const Role = {
	ADMIN: 'ROLE_ADMIN',
	USER: 'ROLE_USER',
};

/**
 * Simplified authentication mechanism using a single token (id_token)
 */
class AuthenticationIdentity extends Identity {
	baseURL = VITE_API_IDENTITY_AUTHENTICATION_ENDPOINT;

	localstorageSessionKey = BEEYOU_SESSION_LOCALSTORAGE_KEY;

	isSessionExpired() {
		return (
			!this.session.id_token
			|| this.session.id_token.expiresAt <= Date.now()
		);
	}

	getIdTokenSync() {
		const { id_token } = this.session;
		if (!id_token) throw new IdentityError('id_token_not_found', 'ID Token not found.');
		if (!this.isTokenValid(id_token)) {
			this.logout(); // this is an weird case, a logout is preferred
			throw new IdentityError('id_token_invalid', 'ID Token is invalid.');
		}
		return id_token;
	}

	getUserSync() {
		const { payload } = this.getIdTokenSync();
		return payload;
	}

	async requestToken(accessToken) {
		const { data: tokens } = await this.api.post(
			'/token',
			null,
			{
				headers: { authorization: `Bearer ${accessToken}` },
			},
		);
		return tokens;
	}

	async getRefreshToken() {
		return this.getAccessToken();
	}
}

export const identity = new AuthenticationIdentity().initialize();

export const AuthenticationContext = React.createContext();

export const useAuthentication = () => useContext(AuthenticationContext);

const reducer = (state, action) => {
	switch (action.type) {
	case 'AUTH_PENDING': {
		return { isPending: true };
	}
	case 'LOGIN': {
		return {
			isLoggedIn: true,
			user: action.user,
		};
	}
	case 'AUTH_ERROR':
	case 'LOGOUT': {
		return { isLoggedOut: true };
	}
	default:
		return state;
	}
};

const initAuthentication = () => {
	try {
		const user = identity.getUserSync();
		ReactGA.set({ userId: user.sub });
		return {
			isLoggedIn: true,
			user,
		};
	} catch (error) {
		if (!(error instanceof IdentityError)) {
			throw error;
		}
		return {};
	}
};

export const AuthenticationProvider = ({ children }) => {
	const [authentication, dispatch] = useReducer(reducer, {}, initAuthentication);
	const queryClient = useQueryClient();

	const login = useCallback(async (...args) => {
		dispatch({ type: 'AUTH_PENDING' });
		try {
			await identity.login(...args);
		} catch (error) {
			dispatch({ type: 'AUTH_ERROR' });
			throw error;
		}
	}, []);

	const logout = useCallback((...args) => {
		dispatch({ type: 'AUTH_PENDING' });
		try {
			identity.logout(...args);
		} catch (error) {
			dispatch({ type: 'AUTH_ERROR' });
			throw error;
		}
	}, []);

	const contextValue = useMemo(() => ({
		...authentication,
		login,
		logout,
	}), [authentication, login, logout]);

	useEffect(() => {
		const onLogin = async () => {
			try {
				const user = await identity.getUser();
				ReactGA.set({ userId: user.sub });
				dispatch({
					type: 'LOGIN',
					user,
				});
			} catch (error) {
				console.error(error);
			}
		};

		const onLogout = async () => {
			dispatch({ type: 'LOGOUT' });
		};

		identity.on('login', onLogin);
		identity.on('logout', onLogout);
		identity.on('refresh', onLogin);

		return () => {
			identity.off('login', onLogin);
			identity.off('logout', onLogout);
			identity.off('refresh', onLogin);
		};
	}, []);

	useEffect(() => {
		if (authentication.isLoggedOut) {
			queryClient.invalidateQueries();
		}
	}, [authentication.isLoggedOut, queryClient]);

	return (
		<AuthenticationContext.Provider value={contextValue}>
			{children}
		</AuthenticationContext.Provider>
	);
};

AuthenticationProvider.propTypes = {
	children: PropTypes.node,
};

AuthenticationProvider.defaultProps = {
	children: undefined,
};
