import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

import { ChannelButtonsPanelForVideo } from './ForVideo';
import { ChannelButtonsPanelForStudio } from './ForStudio';
import './ChannelButtonsPanel.scss';

export const ChannelButtonsPanel = ({
	centerChildren,
	channelDescription,
	className,
	handleChangeVolume,
	handleClickFullScreen,
	handleToggleCropVideo,
	handleTogglePlaying,
	handleToggleSettings,
	handleToggleSound,
	hashtag,
	isController,
	isFullscreen,
	isHandRaised,
	isMobilePortrait,
	isPlaying,
	isSoundMuted,
	isViewer,
	leftChildren,
	onClickRaiseHand,
	onKeySelectBackground,
	rightChildren,
	setAllMuted,
	volume,
	shareVideoButton,
	source,
	miniPlayer,
}) => (
	<div className={clsx('ChannelButtonsPanel position-relative p-1 d-flex align-items-center justify-content-between', className)}>
		{!isViewer ? (
			<ChannelButtonsPanelForStudio
				centerChildren={centerChildren}
				channelDescription={channelDescription}
				handleClickFullScreen={handleClickFullScreen}
				handleToggleCropVideo={handleToggleCropVideo}
				handleToggleSettings={handleToggleSettings}
				hashtag={hashtag}
				isController={isController}
				isFullscreen={isFullscreen}
				isHandRaised={isHandRaised}
				isMobilePortrait={isMobilePortrait}
				leftChildren={leftChildren}
				onClickRaiseHand={onClickRaiseHand}
				onKeySelectBackground={onKeySelectBackground}
				rightChildren={rightChildren}
				setAllMuted={setAllMuted}
			/>
		) : (
			<ChannelButtonsPanelForVideo
				centerChildren={centerChildren}
				channelDescription={channelDescription}
				handleChangeVolume={handleChangeVolume}
				handleClickFullScreen={handleClickFullScreen}
				handleTogglePlaying={handleTogglePlaying}
				handleToggleSound={handleToggleSound}
				isFullscreen={isFullscreen}
				isPlaying={isPlaying}
				isSoundMuted={isSoundMuted}
				leftChildren={leftChildren}
				rightChildren={rightChildren}
				volume={volume}
				shareVideoButton={shareVideoButton}
				source={source}
				miniPlayer={miniPlayer}
			/>
		)}
	</div>
);

ChannelButtonsPanel.propTypes = {
	centerChildren: PropTypes.node,
	channelDescription: PropTypes.node,
	className: PropTypes.string,
	handleChangeVolume: PropTypes.func,
	handleClickFullScreen: PropTypes.func,
	handleToggleCropVideo: PropTypes.func,
	handleTogglePlaying: PropTypes.func,
	handleToggleSettings: PropTypes.func,
	handleToggleSound: PropTypes.func,
	hashtag: PropTypes.string,
	isController: PropTypes.bool,
	isFullscreen: PropTypes.bool,
	isHandRaised: PropTypes.bool,
	isMobilePortrait: PropTypes.bool,
	isPlaying: PropTypes.bool,
	isSoundMuted: PropTypes.bool,
	isViewer: PropTypes.bool,
	leftChildren: PropTypes.node,
	onClickRaiseHand: PropTypes.func,
	onKeySelectBackground: PropTypes.func,
	rightChildren: PropTypes.node,
	setAllMuted: PropTypes.func,
	volume: PropTypes.number,
	shareVideoButton: PropTypes.node,
	miniPlayer: PropTypes.bool,
};

ChannelButtonsPanel.defaultProps = {
	centerChildren: null,
	channelDescription: null,
	className: '',
	handleChangeVolume: undefined,
	handleClickFullScreen: undefined,
	handleToggleCropVideo: undefined,
	handleTogglePlaying: undefined,
	handleToggleSettings: undefined,
	handleToggleSound: undefined,
	hashtag: null,
	isController: false,
	isFullscreen: false,
	isHandRaised: false,
	isMobilePortrait: false,
	isPlaying: false,
	isSoundMuted: false,
	isViewer: false,
	leftChildren: null,
	onClickRaiseHand: undefined,
	onKeySelectBackground: undefined,
	rightChildren: null,
	setAllMuted: undefined,
	volume: 50,
	shareVideoButton: null,
	miniPlayer: false,
};
