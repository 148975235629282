import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

import './MenuCollapse.scss';

export const MenuCollapse = ({ parentItem, childItems, hideSidebarMenu }) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleToggleCollapse = useCallback(() => {
		setIsOpen((prevIsOpen) => !prevIsOpen);
	}, []);

	return (
		<>
			<li>
				<div className={clsx('sidebar-navigation-item mx-2 cursor-pointer', { MenuItemsOpen: isOpen, 'text-primary active': isOpen, 'font-weight-medium': isOpen })} onClick={handleToggleCollapse}>
					<i className="sidebar-icon">
						{parentItem.icon && (
							parentItem.icon
						)}
					</i>
					<span className="d-flex align-items-center justify-content-between w-100">
						{parentItem.label}
						{isOpen ? <FaChevronUp /> : <FaChevronDown />}
					</span>
				</div>
			</li>

			<Collapse isOpen={isOpen}>
				{childItems.map((menuItem) => menuItem && (
					<li key={menuItem.label}>
						<Link
							to={menuItem.to}
							className={clsx('sidebar-navigation-item pl-3 mx-2 mt-1')}
							onClick={() => {
								if (menuItem.onClick) {
									menuItem.onClick();
								}
								if (menuItem.hideSidebarMenu) {
									hideSidebarMenu();
								}
								handleToggleCollapse();
							}}
						>
							{menuItem.badge}
							<i className="sidebar-icon">
								{menuItem.icon && (
									menuItem.icon
								)}
							</i>
							{menuItem.label}
						</Link>

					</li>
				))}
			</Collapse>
		</>
	);
};

MenuCollapse.propTypes = {
	parentItem: PropTypes.shape({
		label: PropTypes.string,
		icon: PropTypes.string,
	}).isRequired,
	hideSidebarMenu: PropTypes.func,
	childItems: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string,
		icon: PropTypes.node,
		to: PropTypes.string,
		toggleSidebarOnClick: PropTypes.bool,
	})).isRequired,
};

MenuCollapse.defaultProps = {
	hideSidebarMenu: undefined,
};
