import { authenticatedApi, publicApi } from './api';

export const API_SEARCH_PATH = '/search';

export const fetchSearchResults = async (searchTerm, opts) => publicApi.get(
	`${API_SEARCH_PATH}/q/?q=${searchTerm}`,
	{ params: opts },
);

export const fetchSearchSuggestions = async (searchTerm) => publicApi.get(
	`${API_SEARCH_PATH}/s/?q=${searchTerm}`,
);

export const fetchUserSuggestions = async (searchTerm, opts) => authenticatedApi.get(
	`${API_SEARCH_PATH}/u/?q=${searchTerm}`,
	{ params: opts },
);
