import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Resolution, stopTrack } from './utils';
import { useMediaScreenshare } from './Screenshare';

const MAX_FPS = 30;

const Perf = performance || Date;

export const MediaVideoshareContext = createContext();

export const useMediaVideoshare = () => useContext(MediaVideoshareContext);

export const contain = (sourceWidth, sourceHeight, destinationWidth, destinationHeight) => {
	const ratio = (sourceHeight && sourceWidth)
		? Math.min(destinationHeight / sourceHeight, destinationWidth / sourceWidth)
		: 0;

	const newHeight = Math.floor(sourceHeight * ratio);
	const newWidth = Math.floor(sourceWidth * ratio);

	const newX = Math.floor((destinationWidth - newWidth) / 2);
	const newY = Math.floor((destinationHeight - newHeight) / 2);

	return {
		height: newHeight,
		width: newWidth,
		x: newX,
		y: newY,
	};
};

const getCanvasSize = (res) => ({
	height: res,
	width: Math.floor(res * (16 / 9)),
});

export const MediaVideoshare = ({
	children,
	disabled,
	isHost,
	resolution,
}) => {
	const { screenshareActive } = useMediaScreenshare();

	// const [videoshareFile, setVideoshareFile] = useState();
	const [videoshareData, setVideoshareData] = useState();
	const [videoShareTimeCodes, setVideoShareTimeCodes] = useState(
		{
			startTime: 0,
			endTime: null,
		},
	);
	const [isVideoPaused, setIsVideoPaused] = useState(false);

	const videoRef = useRef();

	const [videoshareActiveTracks, setVideoshareActiveTracks] = useState([]);
	const [videoshareRequestError, setVideoshareRequestError] = useState();

	const videoshareActive = videoshareActiveTracks.length > 0;

	const videoshareMediastream = useMemo(() => {
		if (videoshareActiveTracks.length > 0) {
			const mediastream = new MediaStream(videoshareActiveTracks);
			// Refresh mediastream when tracks change to avoid player image stuck
			return mediastream;
		}
		return undefined;
	}, [videoshareActiveTracks]);

	const isAllowed = (isHost || !screenshareActive);
	// const enabledVideoshareFile = !disabled && isAllowed && videoshareFile;

	const resolutionRef = useRef(resolution);
	useEffect(() => { resolutionRef.current = resolution; }, [resolution]);
	const handleLoopVideo = useCallback(() => {
		if (videoRef.current
			&& videoRef.current.currentTime >= videoShareTimeCodes.endTime) {
			videoRef.current.currentTime = videoShareTimeCodes.startTime;
			videoRef.current.play();
			if (videoShareTimeCodes.endTime) {
				videoRef.current.addEventListener('timeupdate', handleLoopVideo);
			}
			setIsVideoPaused(false);
		}
	}, [videoShareTimeCodes.endTime, videoShareTimeCodes.startTime]);

	const playVideo = useCallback(() => {
		videoRef.current.play();
		if (videoShareTimeCodes.endTime) {
			videoRef.current.addEventListener('timeupdate', handleLoopVideo);
		}
		setIsVideoPaused(false);
	}, [handleLoopVideo, videoShareTimeCodes.endTime]);

	useEffect(() => {
		if (videoRef.current && videoShareTimeCodes.endTime) {
			videoRef.current.removeEventListener('timeupdate', handleLoopVideo);
			videoRef.current.addEventListener('timeupdate', handleLoopVideo);
		}
		return () => {
			if (videoRef.current) {
				videoRef.current.removeEventListener('timeupdate', handleLoopVideo);
			}
		};
	}, [handleLoopVideo, videoShareTimeCodes]);

	const requestedVideoshareFile = useRef();

	const handleChangeVideoShareTimeCodes = useCallback((startTime, endTime) => {
		if (startTime !== videoShareTimeCodes.startTime) {
			setVideoShareTimeCodes((prev) => ({ ...prev, startTime }));
			videoRef.current.currentTime = startTime;
		}
		if (endTime !== videoShareTimeCodes.endTime) {
			setVideoShareTimeCodes((prev) => ({ ...prev, endTime }));
		}
	}, [videoShareTimeCodes.endTime, videoShareTimeCodes.startTime]);

	const requestVideoshare = useCallback(async (requestVideoshareFile) => {
		requestedVideoshareFile.current = requestVideoshareFile;

		try {
			setVideoshareRequestError(undefined);

			const video = document.createElement('video');
			videoRef.current = video;
			video.preload = 'auto';
			// video.loop = true;
			video.crossOrigin = 'anonymous';
			video.volume = 1;
			video.src = URL.createObjectURL(requestVideoshareFile);

			await video.play();
			video.pause();

			setVideoshareData({
				name: requestVideoshareFile.name,
				duration: videoRef.current.duration,
				src: video.src,
			});

			if (!requestedVideoshareFile.current) return;

			const canvas = document.createElement('canvas');
			const context = canvas.getContext('2d');

			const canvasSize = getCanvasSize(resolutionRef.current);

			canvas.width = canvasSize.width;
			canvas.height = canvasSize.height;

			const audioContext = new AudioContext();

			const destination = audioContext.createMediaStreamDestination();

			const gainNode = audioContext.createGain();
			gainNode.gain.value = 1;
			gainNode.connect(destination);

			const source = audioContext.createMediaElementSource(video);
			source.connect(gainNode);

			let nextContainCallTime = 0;
			let drawSize = contain(
				video.videoWidth,
				video.videoHeight,
				canvas.width,
				canvas.height,
			);

			const trottledContain = () => {
				const now = Perf.now();
				if (now > nextContainCallTime) {
					drawSize = contain(
						video.videoWidth,
						video.videoHeight,
						canvas.width,
						canvas.height,
					);
					nextContainCallTime = now + 2000;
				}
				return drawSize;
			};

			console.log({ canvasSize });

			let lastRender = Perf.now();
			const interval = Math.floor(1000 / MAX_FPS);
			const mediastream = canvas.captureStream(0);

			const animation = () => {
				if (!requestedVideoshareFile.current) {
					console.log('Videoshare / free resources');
					return;
				}

				requestAnimationFrame(animation);

				const now = Perf.now();
				const delta = now - lastRender;
				if (delta < interval) return;
				lastRender = now - (delta % interval);

				const { x, y, width, height } = trottledContain();
				context.drawImage(video, x, y, width, height);
				mediastream.getVideoTracks()[0].requestFrame();
			};
			requestAnimationFrame(animation);

			const videoTracks = mediastream.getTracks().map((videoTrack) => {
				// Firefox MediaStreamTrack.getSettings returns an empty object in case of captureStream
				videoTrack.firefoxFixCaptureSettings = {
					width: canvas.width,
					height: canvas.height,
				};

				return videoTrack;
			});

			const audioTracks = destination.stream.getTracks();

			setVideoshareActiveTracks((state) => [...state, ...videoTracks, ...audioTracks]);
		} catch (error) {
			console.error(error);
			setVideoshareRequestError(error);
		}
	}, []);

	const pauseVideo = useCallback(() => {
		const saveTime = videoRef.current.currentTime;
		videoRef.current.pause();
		setIsVideoPaused(true);
		videoRef.current.currentTime = saveTime;
	}, []);

	const stopVideoshare = useCallback(() => {
		console.log('stopVideoshare');
		setVideoshareRequestError(undefined);
		setVideoshareData(undefined);

		requestedVideoshareFile.current = undefined;

		videoRef.current = undefined;

		videoshareActiveTracks.forEach(stopTrack);
		setVideoshareActiveTracks([]);
	}, [videoshareActiveTracks]);

	useEffect(() => {
		const handleTrackEnded = ({ target: track }) => {
			console.log('handleTrackEnded', track.kind);
			track.removeEventListener('trackended', handleTrackEnded);
			setVideoshareActiveTracks((state) => state.filter((t) => t !== track));
		};

		videoshareActiveTracks.forEach((track) => {
			track.addEventListener('ended', handleTrackEnded);
		});

		return () => {
			videoshareActiveTracks.forEach((track) => {
				track.removeEventListener('ended', handleTrackEnded);
			});
		};
	}, [videoshareActiveTracks]);

	// useEffect(() => {
	// 	const doStartVideoshare = enabledVideoshareFile
	// 		&& requestedVideoshareFile.current !== enabledVideoshareFile;
	// 	const doStopVideoshare = !enabledVideoshareFile && requestedVideoshareFile.current;

	// 	console.log({
	// 		enabledVideoshareFile,
	// 		'requestedVideoshareFile.current': requestedVideoshareFile.current,
	// 		doStartVideoshare,
	// 		doStopVideoshare,
	// 	});

	// 	if (doStopVideoshare) stopVideoshare();
	// 	if (doStartVideoshare) requestVideoshare(enabledVideoshareFile);
	// }, [enabledVideoshareFile, requestVideoshare, stopVideoshare]);

	useEffect(() => {
		const shouldStopVideoshare = requestedVideoshareFile.current && (disabled || !isAllowed);
		console.log({ shouldStopVideoshare });
		if (shouldStopVideoshare) stopVideoshare();
	}, [disabled, isAllowed, stopVideoshare]);

	useEffect(() => {
		if (videoshareActiveTracks.length > 0 && !isHost) {
			playVideo();
		}
	}, [isHost, playVideo, videoshareActiveTracks]);

	const videoshareActiveTracksRef = useRef(videoshareActiveTracks);
	useEffect(
		() => { videoshareActiveTracksRef.current = videoshareActiveTracks; },
		[videoshareActiveTracks],
	);
	if (videoRef.current?.readyState === 4 && videoRef.current.paused) {
		videoRef.current.currentTime = videoShareTimeCodes.startTime;
	}
	// cleanup
	useEffect(() => () => {
		console.log('cleanup');
		requestedVideoshareFile.current = undefined;

		if (videoRef.current) {
			pauseVideo();
			videoRef.current = undefined;
		}

		videoshareActiveTracksRef.current.forEach(stopTrack);
	}, [pauseVideo]);

	// useEffect(() => {
	// 	if (screenshareActive) setVideoshareFile(undefined);
	// }, [screenshareActive]);

	const value = useMemo(() => ({
		playVideo,
		pauseVideo,
		requestVideoshare,
		handleChangeVideoShareTimeCodes,
		stopVideoshare,
		videoshareActive,
		videoshareActiveTracks,
		videoshareData,
		videoshareMediastream,
		videoshareRequestError,
		isVideoPaused,
	}), [
		playVideo,
		pauseVideo,
		requestVideoshare,
		handleChangeVideoShareTimeCodes,
		stopVideoshare,
		videoshareActive,
		videoshareActiveTracks,
		videoshareData,
		videoshareMediastream,
		videoshareRequestError,
		isVideoPaused,
	]);

	return (
		<MediaVideoshareContext.Provider value={value}>
			{children}
		</MediaVideoshareContext.Provider>
	);
};

MediaVideoshare.propTypes = {
	children: PropTypes.node.isRequired,
	disabled: PropTypes.bool,
	isHost: PropTypes.bool,
	resolution: PropTypes.oneOf(Object.values(Resolution)),
};

MediaVideoshare.defaultProps = {
	disabled: false,
	isHost: false,
	resolution: Resolution.P720,
};
