import React from 'react';
import { ModalScreen } from '../../Modal/Screen/Screen';
import { ModalScreenHeader } from '../../Modal/Screen/Header';
import { SurveyModalTitle } from './ModalTitle';
import { OverviewTab } from '../Overview/OverviewTab';
import { SurveyModalsModalBody } from './ModalBody';
import { useSurvey } from '../Providers/SurveyProvider';

export const SurveyOverviewModal = () => {
	const {
		chosenParticipant,
		isOverviewSurveyOpen,
		handleCloseOverviewSurvey,
		survey,
	} = useSurvey();

	return chosenParticipant ? (
		<ModalScreen
			className="p-0"
			bodyClassName="p-0"
			isOpen={isOverviewSurveyOpen}
			onClose={handleCloseOverviewSurvey}
			header={(
				<ModalScreenHeader
					onClose={handleCloseOverviewSurvey}
					onBack={handleCloseOverviewSurvey}
					title={(
						<SurveyModalTitle
							avatar={chosenParticipant.avatar}
							title={chosenParticipant.nickname}
							surveyTitle={survey?.title}
						/>
					)}
				/>
			)}
		>
			{survey && (
				<SurveyModalsModalBody>
					<div className="px-5 py-3">
						<OverviewTab questions={survey.questions} />
					</div>
				</SurveyModalsModalBody>
			)}
		</ModalScreen>
	) : null;
};
