export const MembershipModalTab = {
	MEMBERSHIP: 'MEMBERSHIP',
	PRICING: 'PRICING',
	CUSTOM_PLAN: 'CUSTOM_PLAN',
	BILLING: 'BILLING',
	POINTS: 'POINTS',
	CHECKOUT: 'CHECKOUT',
	BUY_POINTS: 'BUY_POINTS',
	CASH_OUTS: 'CASH_OUTS',
};
