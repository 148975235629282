import React from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-unresolved
import { useNickname, useMixing } from '@technomiam/react-video';

// import { Label, Input, FormGroup } from 'reactstrap';
import { ButtonPillOutline } from '../../Button';

export const Nickname = () => {
	const { toggle, isDrawNicknameEnabled } = useNickname();
	const { t } = useTranslation();

	const { notifyDrawNickname } = useMixing();

	const handleClick = () => {
		notifyDrawNickname(!isDrawNicknameEnabled);
		toggle();
	};

	return (
		<section className="FloatingPiP h-100 d-flex flex-column justify-content-between overflow-hidden flex-grow-1">
			<div className="w-100">
				<ButtonPillOutline color="secondary" className="d-block" onClick={handleClick}>
					{isDrawNicknameEnabled ? t('Nickname.Nickname.disable') : t('Nickname.Nickname.enable')}
				</ButtonPillOutline>
			</div>
		</section>
	);
};
