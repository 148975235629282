import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import { ModalLoadingTabPane } from './LoadingTabPane';

export const ModalLazyContent = ({ children }) => (
	<Suspense fallback={<ModalLoadingTabPane />}>
		{children}
	</Suspense>
);

ModalLazyContent.propTypes = {
	children: PropTypes.node.isRequired,
};
