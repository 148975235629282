import PropTypes from 'prop-types';
import React from 'react';
import { TabPane } from 'reactstrap';
import { ModalLazyContent } from '../Modal/LazyContent';
import { ModalScreenHeader } from '../Modal/Screen/Header';
import { ModalScreen } from '../Modal/Screen/Screen';
import { SignupForm } from './Form';

export const SignupModal = ({ isOpen, toggle }) => (
	<ModalScreen
		bodyClassName="py-0 bg-light"
		contentClassName="border-0 shadow-lg rounded overflow-hidden m-auto"
		isOpen={isOpen}
		onClose={toggle}
		size="xl"
		header={(
			<ModalScreenHeader
				onClose={toggle}
				title="Create an Account"
				subTitle="Create your account and join Beeyou"
				emphasisOnTitle
				noBorder
			/>
		)}
	>
		<TabPane tabId="SignUp" className="h-100 px-5">
			<ModalLazyContent>
				<SignupForm closeModal={toggle} />
			</ModalLazyContent>
		</TabPane>
	</ModalScreen>
);

SignupModal.propTypes = {
	isOpen: PropTypes.bool,
	toggle: PropTypes.func,
};

SignupModal.defaultProps = {
	isOpen: false,
	toggle: () => {},
};
