import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import {
	FaComment,
	FaEnvelope,
	FaLink,
	FaMobileAlt,
	FaUser,
	FaUsers,
} from 'react-icons/fa';
import {
	ShareTabEmail,
	ShareTabGroups,
	ShareTabLink,
	ShareTabPost,
	ShareTabSms,
	ShareTabUsers,
} from './Tab';
import { ResourceAccessRole } from '../../lib/ResourceAccessRole';
import { ShareTabType } from './Share.helper';
import ReallyPerfectScrollbar from '../ReallyPerfectScrollbar/ReallyPerfectScrollbar';

import './Share.scss';

export const Share = ({
	alreadySharedUsers,
	onShare,
	shareTabTypes,
	shareButtonLabel,
	roles,
	openRole,
	channel,
	post,
	studio,
	video,
}) => {
	const { t } = useTranslation();
	const [activeMenu, setActiveMenu] = useState(shareTabTypes[0]);
	const [selectedRole, setSelectedRole] = useState(openRole || roles[0]);

	const handleClickMenu = useCallback((tab) => {
		if (shareTabTypes.includes(tab)) setActiveMenu(tab);
	}, [shareTabTypes]);

	useEffect(() => {
		if (!shareTabTypes.includes(activeMenu)) setActiveMenu(shareTabTypes[0]);
		if (activeMenu !== ShareTabType.USERS
			&& selectedRole === ResourceAccessRole.OPERATOR) {
			setSelectedRole(ResourceAccessRole.PARTICIPANT);
		}
	}, [shareTabTypes, activeMenu, selectedRole]);

	const defaultMessage = alreadySharedUsers?.length > 0
		? alreadySharedUsers[alreadySharedUsers.length - 1].message
		: undefined;

	return (
		<div className="Share h-100 d-flex flex-column">
			<div className="nav-line-dark w-100">
				<Nav className="w-100 d-flex nav-line justify-content-around">
					{shareTabTypes?.includes(ShareTabType.USERS) && (
						<NavItem>
							<NavLink
								className={clsx({ active: activeMenu === ShareTabType.USERS }, 'd-flex flex-column px-1')}
								onClick={() => { handleClickMenu(ShareTabType.USERS); }}
							>
								<Button
									className={clsx({ active: activeMenu === ShareTabType.USERS }, 'btn-pill d-30 p-0 ')}
									color="neutral-secondary"
									title={t('Share.Share.users')}
								>
									<span className="btn-wrapper--icon">
										<FaUser />
									</span>
								</Button>
								<small>{t('Share.Share.users')}</small>
							</NavLink>
						</NavItem>
					)}
					{shareTabTypes?.includes(ShareTabType.EMAIL) && (
						<NavItem>
							<NavLink
								className={clsx({ active: activeMenu === ShareTabType.EMAIL }, 'd-flex flex-column px-1')}
								onClick={() => { handleClickMenu(ShareTabType.EMAIL); }}
							>
								<Button
									className={clsx({ active: activeMenu === ShareTabType.EMAIL }, 'btn-pill d-30 p-0 ')}
									color="neutral-secondary"
									title={t('Share.Share.email')}
								>
									<span className="btn-wrapper--icon">
										<FaEnvelope />
									</span>
								</Button>
								<small>{t('Share.Share.email')}</small>
							</NavLink>
						</NavItem>
					)}
					{shareTabTypes?.includes(ShareTabType.SMS) && (
						<NavItem>
							<NavLink
								className={clsx({ active: activeMenu === ShareTabType.SMS }, 'd-flex flex-column px-1')}
								onClick={() => { handleClickMenu(ShareTabType.SMS); }}
							>
								<Button
									className={clsx({ active: activeMenu === ShareTabType.SMS }, 'btn-pill d-30 p-0 ')}
									color="neutral-secondary"
									title={t('Share.Share.sms')}
								>
									<span className="btn-wrapper--icon">
										<FaMobileAlt />
									</span>
								</Button>
								<small>{t('Share.Share.sms')}</small>
							</NavLink>
						</NavItem>
					)}
					{shareTabTypes?.includes(ShareTabType.LINK) && (
						<NavItem>
							<NavLink
								className={clsx({ active: activeMenu === ShareTabType.LINK }, 'd-flex flex-column px-1')}
								onClick={() => { handleClickMenu(ShareTabType.LINK); }}
							>
								<Button
									className={clsx({ active: activeMenu === ShareTabType.LINK }, 'btn-pill d-30 p-0 ')}
									color="neutral-secondary"
									title={t('Share.Share.link')}
								>
									<span className="btn-wrapper--icon">
										<FaLink />
									</span>
								</Button>
								<small>{t('Share.Share.link')}</small>
							</NavLink>
						</NavItem>
					)}
					{shareTabTypes?.includes(ShareTabType.GROUPS) && (
						<NavItem>
							<NavLink
								className={clsx({ active: activeMenu === ShareTabType.GROUPS }, 'd-flex flex-column px-1')}
								onClick={() => { handleClickMenu(ShareTabType.GROUPS); }}
							>
								<Button
									className={clsx({ active: activeMenu === ShareTabType.GROUPS }, 'btn-pill d-30 p-0 ')}
									color="neutral-secondary"
									title={t('Share.Share.groups')}
								>
									<span className="btn-wrapper--icon">
										<FaUsers />
									</span>
								</Button>
								<small>{t('Share.Share.groups')}</small>
							</NavLink>
						</NavItem>
					)}
					{shareTabTypes?.includes(ShareTabType.POST) && (
						<NavItem>
							<NavLink
								className={clsx({ active: activeMenu === ShareTabType.POST }, 'd-flex flex-column px-1')}
								onClick={() => { handleClickMenu(ShareTabType.POST); }}
							>
								<Button
									className={clsx({ active: activeMenu === ShareTabType.POST }, 'btn-pill d-30 p-0 ')}
									color="neutral-secondary"
									title={t('Share.Share.post')}
								>
									<span className="btn-wrapper--icon">
										<FaComment />
									</span>
								</Button>
								<small>{t('Share.Share.post')}</small>
							</NavLink>
						</NavItem>
					)}
				</Nav>
			</div>
			<TabContent activeTab={activeMenu} className="TabContent h-100 overflow-hidden mt-2 mb-3">
				{shareTabTypes.length > 0 && shareTabTypes.includes(ShareTabType.USERS) && (
					<TabPane tabId={ShareTabType.USERS} className="h-100">
						<ReallyPerfectScrollbar>
							<ShareTabUsers
								alreadySharedUsers={alreadySharedUsers}
								defaultMessage={defaultMessage}
								onShare={onShare}
								roles={roles}
								selectedRole={selectedRole}
								setSelectedRole={setSelectedRole}
								shareButtonLabel={shareButtonLabel}
								studio={studio}
							/>
						</ReallyPerfectScrollbar>
					</TabPane>
				)}
				{shareTabTypes.length > 0 && shareTabTypes.includes(ShareTabType.EMAIL) && (
					<TabPane tabId={ShareTabType.EMAIL} className="h-100">
						<ReallyPerfectScrollbar>
							<ShareTabEmail
								alreadySharedUsers={alreadySharedUsers}
								defaultMessage={defaultMessage}
								onShare={onShare}
								roles={roles}
								selectedRole={selectedRole}
								setSelectedRole={setSelectedRole}
								shareButtonLabel={shareButtonLabel}
							/>
						</ReallyPerfectScrollbar>
					</TabPane>
				)}
				{shareTabTypes.length > 0 && shareTabTypes.includes(ShareTabType.SMS) && (
					<TabPane tabId={ShareTabType.SMS} className="h-100">
						<ReallyPerfectScrollbar>
							<ShareTabSms
								alreadySharedUsers={alreadySharedUsers}
								defaultMessage={defaultMessage}
								onShare={onShare}
								roles={roles}
								selectedRole={selectedRole}
								setSelectedRole={setSelectedRole}
								shareButtonLabel={shareButtonLabel}
							/>
						</ReallyPerfectScrollbar>
					</TabPane>
				)}
				{shareTabTypes.length > 0 && shareTabTypes.includes(ShareTabType.LINK) && (
					<TabPane tabId={ShareTabType.LINK} className="h-100">
						<ReallyPerfectScrollbar>
							<ShareTabLink
								channel={channel}
								post={post}
								roles={roles}
								selectedRole={selectedRole}
								setSelectedRole={setSelectedRole}
								studio={studio}
								video={video}
							/>
						</ReallyPerfectScrollbar>
					</TabPane>
				)}
				{shareTabTypes.length > 0 && shareTabTypes.includes(ShareTabType.GROUPS) && (
					<TabPane tabId={ShareTabType.GROUPS} className="h-100">
						<ReallyPerfectScrollbar>
							<ShareTabGroups
								alreadySharedUsers={alreadySharedUsers}
								defaultMessage={defaultMessage}
								onShare={onShare}
								selectedRole={selectedRole}
								setSelectedRole={setSelectedRole}
								shareButtonLabel={shareButtonLabel}
								roles={roles}
							/>
						</ReallyPerfectScrollbar>
					</TabPane>
				)}
				{shareTabTypes.length > 0 && shareTabTypes.includes(ShareTabType.POST) && (
					<TabPane tabId={ShareTabType.POST} className="h-100">
						<ReallyPerfectScrollbar>
							<ShareTabPost
								post={post}
								video={video}
							/>
						</ReallyPerfectScrollbar>
					</TabPane>
				)}
			</TabContent>
		</div>
	);
};

Share.propTypes = {
	alreadySharedUsers: PropTypes.arrayOf(PropTypes.shape({
		message: PropTypes.string,
	})),
	openRole: PropTypes.oneOf(Object.values(ResourceAccessRole)),
	onShare: PropTypes.func.isRequired,
	shareTabTypes: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(ShareTabType))).isRequired,
	shareButtonLabel: PropTypes.string,
	channel: PropTypes.shape({
		_id: PropTypes.string.isRequired,
	}),
	roles: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(ResourceAccessRole))).isRequired,
	studio: PropTypes.shape({
		_id: PropTypes.number,
	}),
	video: PropTypes.shape({
		_id: PropTypes.string.isRequired,
	}),
	post: PropTypes.shape({}),
};

Share.defaultProps = {
	alreadySharedUsers: [],
	openRole: undefined,
	channel: undefined,
	studio: undefined,
	video: undefined,
	post: undefined,
	shareButtonLabel: 'Share.Buttons.share',
};
