import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
	FormGroup,
	Input,
} from 'reactstrap';
import { Field, FieldError } from 'react-jsonschema-form-validation';

import { removeNonNumeric } from './PasswordInput';

export const verifyInteger = (value) => {
	const stringNumber = removeNonNumeric(value);
	if (stringNumber.length === 0) return stringNumber;
	const number = parseInt(stringNumber, 10);
	return Math.max(number, 0);
};

export const StudioScheduleSettingsFormIntegerInput = ({
	value,
	onChange,
	name,
	placeholder,
	autofocus,
}) => {
	const inputRef = useRef();

	useEffect(() => {
		if (autofocus) {
			inputRef.current.focus();
		}
	}, [autofocus]);

	return (
		<FormGroup className="mt-1">
			<Field
				innerRef={inputRef}
				component={Input}
				name={name}
				placeholder={placeholder}
				type="number"
				value={value}
				onChange={(e) => onChange(verifyInteger(e.target.value))}
			/>
			<FieldError name={name} />
		</FormGroup>
	);
};

StudioScheduleSettingsFormIntegerInput.propTypes = {
	value: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string,
	]).isRequired,
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	autofocus: PropTypes.bool,
};

StudioScheduleSettingsFormIntegerInput.defaultProps = {
	name: '',
	placeholder: '',
	autofocus: false,
};
