export default class MediastreamCache {
	cache = {};

	cleanMediastreams = (streams) => {
		const ids = streams.map(({ id }) => id);
		Object.keys(this.cache).forEach((id) => {
			if (ids.indexOf(id) > -1) return;
			delete this.cache[id];
		});
	};

	cleanMediastreamTracks = (tracks) => {
		const mediastreams = Object.values(this.cache);

		// Remove deleted tracks
		mediastreams.forEach((mediaStream) => {
			const mediaStreamTracks = mediaStream.getTracks();
			const removedMediaStreamTracks = mediaStreamTracks
				.filter((mst) => !tracks.find(({ mediaStreamTrack }) => mediaStreamTrack === mst));
			removedMediaStreamTracks.forEach((mediaStreamTrack) => {
				mediaStream.removeTrack(mediaStreamTrack);
				mediaStream.dispatchEvent(new MediaStreamTrackEvent(
					'removetrack',
					{ track: mediaStreamTrack },
				));
			});
		});
	};

	populateMediastreams = (streams) => {
		streams.forEach((stream) => {
			const mediaStream = this.cache[stream.id] || new MediaStream();
			mediaStream.stream = stream;
			this.cache[stream.id] = mediaStream;
		});
	};

	populateMediastreamTracks = (tracks) => {
		const mediastreams = Object.values(this.cache);
		tracks.forEach((track) => {
			mediastreams
				.filter(
					({ stream }) => stream.tracks.indexOf(track.id) > -1,
				)
				.forEach((mediaStream) => {
					const { mediaStreamTrack } = track;
					if (mediaStream.getTracks().indexOf(mediaStreamTrack) > -1) return;
					mediaStream.addTrack(mediaStreamTrack);
					mediaStream.dispatchEvent(new MediaStreamTrackEvent(
						'addtrack',
						{ track: mediaStreamTrack },
					));
				});
		});
	};

	getMediastreams = (streams, tracks) => {
		this.cleanMediastreams(streams);
		this.populateMediastreams(streams);
		this.cleanMediastreamTracks(tracks);
		this.populateMediastreamTracks(tracks);
		return streams.map(({ id }) => this.cache[id]);
	};
}
