import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useAsync } from 'react-async-hook';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';

import { fetchOne } from '../../api/ads';
import { getVideoSrc } from '../../lib/file';
import { useAsyncErrorLog } from '../../lib/hooks';
import { HLSPlayer } from '../HLSPlayer/HLSPlayer';
import { ButtonPill } from '../Button';
import { isIOS, isSafari } from '../../lib/userAgent';

const SKIP_AD_TIMEOUT = 5000;

export const AdVideo = ({
	canSkip,
	onEnded,
	onSkip,
}) => {
	const { t } = useTranslation();
	const [ad, setAd] = useState();
	const [skipAdTimeoutExpired, setSkipAdTimeoutExpired] = useState(false);
	const [skipAdRemaining, setSkipAdRemaining] = useState(5);
	const [isAdPlaying, setIsAdPlaying] = useState(false);
	const [isRequestingPlay, setIsRequestingPlay] = useState(false);

	const { error } = useAsync(async () => {
		const { data } = await fetchOne();
		if (!data) {
			onEnded();
			return;
		}
		setAd(data);
	}, []);

	useAsyncErrorLog({ error });

	useEffect(() => {
		if (isAdPlaying) {
			const time = Date.now() + SKIP_AD_TIMEOUT;
			const interval = setInterval(() => {
				const remainingSeconds = Math.round((time - Date.now()) / 1000);
				setSkipAdRemaining(remainingSeconds);
			}, 1000);

			return () => { clearInterval(interval); };
		}
		return undefined;
	}, [isAdPlaying]);

	useEffect(() => {
		if (isAdPlaying) {
			const timeout = setTimeout(() => {
				setSkipAdTimeoutExpired(true);
			}, SKIP_AD_TIMEOUT);

			return () => { clearTimeout(timeout); };
		}
		return undefined;
	}, [isAdPlaying]);

	const src = ad && getVideoSrc(ad);

	const handleClickSkipAd = useCallback(() => {
		ReactGA.event({ category: 'studio', action: 'skip_ad', label: 'User clicked on skip ad button' });
		onSkip();
	}, [onSkip]);

	const handleWatchAd = useCallback(() => setIsRequestingPlay(true), []);
	const handleAdPlaying = useCallback(() => setIsAdPlaying(true), []);

	return (
		<div className="d-flex h-100 w-100 overflow-hidden align-items-center">
			{src && (
				<HLSPlayer
					controls={false}
					isRequestingPlay={isRequestingPlay}
					onEnded={onEnded}
					onError={onEnded}
					onVideoPlaying={handleAdPlaying}
					src={src}
					vodId={ad._id}
				/>
			)}
			{!isAdPlaying && isSafari && isIOS && (
				<ButtonPill
					className="position-absolute zIndex-2"
					onClick={handleWatchAd}
					style={{
						bottom: '10%',
						right: '2%',
					}}
				>
					{t('Video.watch')}
				</ButtonPill>
			)}
			{isAdPlaying && canSkip && (
				<ButtonPill
					className="position-absolute zIndex-2"
					disabled={!skipAdTimeoutExpired}
					onClick={handleClickSkipAd}
					style={{
						bottom: '10%',
						right: '2%',
					}}
				>
					{skipAdTimeoutExpired ? t('Video.skip') : t('Video.skipRemaining', { skipAdRemaining }) }
				</ButtonPill>
			)}
		</div>
	);
};

AdVideo.propTypes = {
	canSkip: PropTypes.bool,
	onEnded: PropTypes.func,
	onSkip: PropTypes.func,
};

AdVideo.defaultProps = {
	canSkip: false,
	onEnded: undefined,
	onSkip: undefined,
};
