import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { createDispatchHook, createSelectorHook, createStoreHook, Provider } from 'react-redux';

import store from '../../store';

export const ReactVideoContext = React.createContext({});

export const useReactVideo = () => useContext(ReactVideoContext);

export const ChannelStreamReduxContext = React.createContext();
export const useStore = createStoreHook(ChannelStreamReduxContext);
export const useDispatch = createDispatchHook(ChannelStreamReduxContext);
export const useSelector = createSelectorHook(ChannelStreamReduxContext);

export const ReactVideoProvider = ({
	children,
	control,
	getAvatarUrl,
	getConnectionConfig,
	user,
}) => {
	const contextValue = useMemo(() => ({
		control,
		getAvatarUrl,
		getConnectionConfig,
		user,
	}), [
		control,
		getAvatarUrl,
		getConnectionConfig,
		user,
	]);

	return (
		<Provider context={ChannelStreamReduxContext} store={store}>
			<ReactVideoContext.Provider value={contextValue}>
				{children}
			</ReactVideoContext.Provider>
		</Provider>
	);
};

ReactVideoProvider.propTypes = {
	children: PropTypes.node.isRequired,
	control: PropTypes.bool,
	getAvatarUrl: PropTypes.func.isRequired,
	getConnectionConfig: PropTypes.func.isRequired,
	user: PropTypes.shape({}).isRequired,
};

ReactVideoProvider.defaultProps = {
	control: false,
};
