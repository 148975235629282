import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Slide = ({
	direction,
	width,
	minWidth,
	menuIsClosing,
	setIsMenuOpen,
	children,
}) => {
	const [menuIsOpening, setMenuIsOpening] = useState(false);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	const handleWIndowResize = () => {
		setWindowWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener('resize', handleWIndowResize);
		return () => { window.removeEventListener('resize', handleWIndowResize); };
	}, []);

	const menuContainerStyles = {
		position: 'fixed',
		width: windowWidth < 700 ? minWidth : width,
		zIndex: 1900,
		top: 0,
		left: direction === 'right' ? null : 0,
		right: direction === 'right' ? 0 : null,
		height: '100%',
		background: 'whitesmoke',
		transform: menuIsOpening
			? 'translateX(0)'
			: `translateX(${direction === 'right' ? '+' : '-'}100%)`,
		transition: 'transform 0.5s ease',
	};

	useEffect(() => {
		setMenuIsOpening(true);
	}, []);

	useEffect(() => {
		if (menuIsClosing) setMenuIsOpening(false);
	}, [menuIsClosing]);

	const onTransitionEnd = (e) => {
		if (menuIsClosing && e.currentTarget === e.target) {
			setIsMenuOpen(false);
		}
	};

	return (
		<div
			style={menuContainerStyles}
			onTransitionEnd={onTransitionEnd}
			onClick={(e) => e.stopPropagation()}
		>
			{children}
		</div>
	);
};

Slide.propTypes = {
	direction: PropTypes.oneOf(['left', 'right']).isRequired,
	width: PropTypes.string.isRequired,
	minWidth: PropTypes.string.isRequired,
	menuIsClosing: PropTypes.bool.isRequired,
	setIsMenuOpen: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
};

export default Slide;
