import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import aspectRatioImg from './ratio-16x9.png';
import './RatioContainer.scss';

const RatioContainer = ({ children, className }) => {
	const { t } = useTranslation();
	return (
		<div className={clsx('RatioContainer', className)}>
			<img alt={t('RatioContainer.RatioContainer.ratio')} className="RatioContainer_ratio" src={aspectRatioImg} />
			<div className="RatioContainer_content">
				{children}
			</div>
		</div>
	);
};

RatioContainer.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

RatioContainer.defaultProps = {
	children: undefined,
	className: undefined,
};

export default RatioContainer;
