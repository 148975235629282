import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import RatioContainer from '../RatioContainer/RatioContainer';
import { minutes, seconds } from './helpers';
import { LayerBounds } from '../../lib/studio';
import './Image.scss';

const PreviewImage = ({
	className,
	duration,
	imageLayerConfig,
	noDuration,
	...props
}) => {
	const imagePosition = useMemo(() => {
		const width = imageLayerConfig.width / LayerBounds.width;
		const height = imageLayerConfig.height / LayerBounds.height;

		const x = imageLayerConfig.x / LayerBounds.width;
		const y = imageLayerConfig.y / LayerBounds.height;

		return {
			left: `${x * 100}%`,
			top: `${y * 100}%`,
			width: `${width * 100}%`,
			height: `${height * 100}%`,
		};
	}, [imageLayerConfig]);

	return (
		<div className={clsx('PreviewImage', className)}>
			<RatioContainer>
				<img
					alt="Preview"
					className="position-absolute"
					style={{
						zIndex: 1,
						maxWidth: '100%',
						maxHeight: '100%',
						objectFit: 'contain',
						...imagePosition,
					}}
					{...props}
				/>
				{Number.isFinite(duration) && !noDuration && (
					<span className="Preview_duration">
						{minutes(duration)}:{seconds(duration)}
					</span>
				)}
			</RatioContainer>
		</div>
	);
};

PreviewImage.propTypes = {
	className: PropTypes.string,
	duration: PropTypes.number,
	imageLayerConfig: PropTypes.shape({
		x: PropTypes.number,
		y: PropTypes.number,
		width: PropTypes.number,
		height: PropTypes.number,
	}),
	noDuration: PropTypes.bool,
};

PreviewImage.defaultProps = {
	className: '',
	duration: undefined,
	imageLayerConfig: {
		x: 0,
		y: 0,
		width: LayerBounds.width,
		height: LayerBounds.height,
	},
	noDuration: false,
};

export default PreviewImage;
