import React from 'react';
import { useTranslation } from 'react-i18next';

export const Loading = () => {
	const { t } = useTranslation();
	return (
		<div className="d-flex align-items-center h-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
			<div className="w-50 mx-auto">
				{t('Components.Loading')}
			</div>
		</div>
	);
};
