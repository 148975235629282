import { useEffect, useRef } from 'react';

// see https://github.com/microsoft/fluentui/blob/master/packages/react-hooks/src/useConst.ts
export const useConst = (initialValue) => {
	const ref = useRef();
	if (typeof ref.current === 'undefined') {
		ref.current = {
			value: typeof initialValue === 'function' ? initialValue() : initialValue,
		};
	}
	return ref.current.value;
};

export function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	}, [value]);
	return ref.current;
}
