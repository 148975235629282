import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Button } from 'reactstrap';
import {
	useMixing,
	Layout,
	PipLayout,
	usePipLayout,
} from '@technomiam/react-video';

import './LayoutSelector.scss';

export const ChannelButtonsPanelLayoutSelector = ({ setShowPipLayoutSelector }) => {
	const { setDisplayMode, displayMode } = usePipLayout();
	const { notifyPipMode } = useMixing();

	const handleClick = async (layout) => {
		notifyPipMode(layout);
		setDisplayMode(layout);
	};

	useEffect(() => {
		const handleClickOutside = () => { setShowPipLayoutSelector(false); };
		// setTimeout because the event listener is added before
		// the click event on the button bubbles to the document.
		setTimeout(() => {
			document.addEventListener('click', handleClickOutside);
		}, 0);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [setShowPipLayoutSelector]);

	return (
		<>
			{Object.keys(Layout).map((layout) => (
				<Button
					className={clsx(
						'p-0 w-100 mr-1 d-flex shadow-none rounded-sm align-items-center justify-content-center',
						{ isSelected: displayMode === layout },
					)}
					color={displayMode === layout ? 'primary' : 'white'}
					key={layout}
					onClick={() => { handleClick(layout); }}
				>
					<PipLayout layout={layout} />
				</Button>
			))}
		</>
	);
};

ChannelButtonsPanelLayoutSelector.propTypes = {
	setShowPipLayoutSelector: PropTypes.func.isRequired,
};

ChannelButtonsPanelLayoutSelector.defaultProps = {
};
