import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ButtonPillOutline } from '../Button';
import { UserSettingsModal, useUserSettings } from '../UserSettings/Context';
import { MembershipModalTab } from '../UserSettings/MembershipModalTab';

const BuyPointsButton = ({ children, ...props }) => {
	const { t } = useTranslation();
	const { openModal } = useUserSettings();

	return (
		<ButtonPillOutline
			color="secondary"
			size="sm"
			onClick={() => openModal(UserSettingsModal.MEMBERSHIP, MembershipModalTab.BUY_POINTS)}
			{...props}
		>
			{children || t('Points.BuyPointsButton')}
		</ButtonPillOutline>
	);
};

BuyPointsButton.propTypes = {
	children: PropTypes.node,
};

BuyPointsButton.defaultProps = {
	children: undefined,
};

export default BuyPointsButton;
