import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef } from 'react';
import {
	connectChannelSockets,
	disconnectChannelSockets,
	joinChannel,
	onEventPostDelete,
	onEventPostLikeChange,
	onEventPostPinChange,
	onEventPostNew,
	onEventPostUpdate,
	onEventChatMessage,
	onEventChatMessageDelete,
	onEventChatMessagesClear,
	onEventChatMessageUpdate,
	onEventChatStatus,
	onEventConnectionAll,
	onEventConnectionLeft,
	onEventConnectionNew,
	onEventStudioStatus,
	onEventVoteActive,
	onEventHandRaised,
	onEventModerationBan,
	onEventModerationUnban,
	onEventPostCommentNew,
	onEventPostCommentDelete,
	onEventPostCommentUpdate,
	onEventPostCommentLikeChange,
} from '../../../api/ws/channel';
import { sessionId } from '../../../lib/session';
import { identity, useAuthentication } from '../../Authentication/Authentication';
import { useStudio } from '../../Studio/Provider';
import { useVote } from '../../Vote/Provider';
import { useChat } from '../Chat/Provider';
import { useChannelConnection } from './ChannelConnectionProvider';
import { useHandRaising } from '../Guest/HandRaisingProvider';
import { ResourceAccessRole } from '../../../lib/ResourceAccessRole';
import { useModeration } from '../Moderation/Context';
import {
	useHandleDeletedPost,
	useHandlePostLikesChange,
	useHandleNewPost,
	useHandleUpdatedPost,
	useHandlePostPinnedChange,
} from '../../../api-hooks/channel/posts';
import {
	useHandleDeletePostComment,
	useHandleNewPostComment,
	useHandlePostCommentLikesChange,
	useHandleUpdatePostComment,
} from '../../../api-hooks/channel/comments';

const execIfHandlerExists = (handler, ...args) => {
	if (handler) return handler(...args);
	return undefined;
};

export const ChannelSocketConnector = ({
	hashtag,
	role,
	studioId,
}) => {
	const { handleEventVoteActive } = useVote();
	const { setGuestRaisedHand } = useHandRaising();
	const {
		handleEventModerationBan,
		handleEventModerationUnban,
	} = useModeration();
	const {
		handleEventChatMessage,
		handleEventChatMessageUpdate,
		handleEventChatMessageDelete,
		handleEventChatMessagesClear,
		handleEventChatStatus,
	} = useChat();
	const {
		handleEventConnectionNew,
		handleEventConnectionLeft,
		handleEventConnectionAll,
	} = useChannelConnection();
	const { handleEventStudioStatus } = useStudio();
	const { isLoggedIn } = useAuthentication();

	const handleNewPost = useHandleNewPost(hashtag);
	const handleUpdatedPost = useHandleUpdatedPost(hashtag);
	const handleDeletedPost = useHandleDeletedPost(hashtag);
	const handleLikedPost = useHandlePostLikesChange(hashtag);
	const handlePinChange = useHandlePostPinnedChange(hashtag);

	const handleNewPostComment = useHandleNewPostComment(hashtag);
	const handleDeletePostComment = useHandleDeletePostComment(hashtag);
	const handleUpdatePostComment = useHandleUpdatePostComment();
	const handleLikedPostComment = useHandlePostCommentLikesChange();

	const handlersRef = useRef();
	useEffect(() => {
		handlersRef.current = {
			handleEventVoteActive,
			setGuestRaisedHand,
			handleEventModerationBan,
			handleEventModerationUnban,
			handleEventChatMessage,
			handleEventChatMessageUpdate,
			handleEventChatMessageDelete,
			handleEventChatMessagesClear,
			handleEventChatStatus,
			handleEventConnectionNew,
			handleEventConnectionLeft,
			handleEventConnectionAll,
			handleEventStudioStatus,
			handleNewPost,
			handleUpdatedPost,
			handleDeletedPost,
			handleLikedPost,
			handlePinChange,
			handleNewPostComment,
			handleDeletePostComment,
			handleUpdatePostComment,
			handleLikedPostComment,
		};
	}, [
		handleEventVoteActive,
		setGuestRaisedHand,
		handleEventModerationBan,
		handleEventModerationUnban,
		handleEventChatMessage,
		handleEventChatMessageUpdate,
		handleEventChatMessageDelete,
		handleEventChatMessagesClear,
		handleEventChatStatus,
		handleEventConnectionNew,
		handleEventConnectionLeft,
		handleEventConnectionAll,
		handleEventStudioStatus,
		handleNewPost,
		handleUpdatedPost,
		handleDeletedPost,
		handleLikedPost,
		handlePinChange,
		handleNewPostComment,
		handleDeletePostComment,
		handleUpdatePostComment,
		handleLikedPostComment,
	]);

	const offAllEvents = useRef([]);
	const leaveChannel = useRef(() => {});

	const subscribeChannel = useCallback(async () => {
		const { token } = await identity.getAccessToken();

		await connectChannelSockets({ params: { sessionId, token } });

		leaveChannel.current = joinChannel(
			hashtag,
			role,
			studioId,
		);

		offAllEvents.current = [
			onEventModerationBan((...args) => execIfHandlerExists(
				handlersRef.current.handleEventModerationBan,
				...args,
			)),
			onEventModerationUnban((...args) => execIfHandlerExists(
				handlersRef.current.handleEventModerationUnban,
				...args,
			)),
			onEventVoteActive((...args) => execIfHandlerExists(
				handlersRef.current.handleEventVoteActive,
				...args,
			)),
			onEventChatMessage((...args) => execIfHandlerExists(
				handlersRef.current.handleEventChatMessage,
				...args,
			)),
			onEventChatMessageUpdate((...args) => execIfHandlerExists(
				handlersRef.current.handleEventChatMessageUpdate,
				...args,
			)),
			onEventChatMessageDelete((...args) => execIfHandlerExists(
				handlersRef.current.handleEventChatMessageDelete,
				...args,
			)),
			onEventChatMessagesClear((...args) => execIfHandlerExists(
				handlersRef.current.handleEventChatMessagesClear,
				...args,
			)),
			onEventChatStatus((...args) => execIfHandlerExists(
				handlersRef.current.handleEventChatStatus,
				...args,
			)),
			onEventConnectionNew((...args) => execIfHandlerExists(
				handlersRef.current.handleEventConnectionNew,
				...args,
			)),
			onEventConnectionLeft((...args) => execIfHandlerExists(
				handlersRef.current.handleEventConnectionLeft,
				...args,
			)),
			onEventConnectionAll((...args) => execIfHandlerExists(
				handlersRef.current.handleEventConnectionAll,
				...args,
			)),
			onEventStudioStatus((...args) => execIfHandlerExists(
				handlersRef.current.handleEventStudioStatus,
				...args,
			)),
			onEventPostNew((...args) => execIfHandlerExists(
				handlersRef.current.handleNewPost,
				...args,
			)),
			onEventPostUpdate((...args) => execIfHandlerExists(
				handlersRef.current.handleUpdatedPost,
				...args,
			)),
			onEventPostDelete((...args) => execIfHandlerExists(
				handlersRef.current.handleDeletedPost,
				...args,
			)),
			onEventPostLikeChange((...args) => execIfHandlerExists(
				handlersRef.current.handleLikedPost,
				...args,
			)),
			onEventPostPinChange((...args) => execIfHandlerExists(
				handlersRef.current.handlePinChange,
				...args,
			)),
			onEventPostCommentNew((...args) => execIfHandlerExists(
				handlersRef.current.handleNewPostComment,
				...args,
			)),
			onEventPostCommentDelete((...args) => execIfHandlerExists(
				handlersRef.current.handleDeletePostComment,
				...args,
			)),
			onEventPostCommentUpdate((...args) => execIfHandlerExists(
				handlersRef.current.handleUpdatePostComment,
				...args,
			)),
			onEventPostCommentLikeChange((...args) => execIfHandlerExists(
				handlersRef.current.handleLikedPostComment,
				...args,
			)),
			onEventHandRaised((...args) => execIfHandlerExists(
				handlersRef.current.setGuestRaisedHand,
				...args,
			)),
		];
	}, [handlersRef, hashtag, role, studioId]);

	const unsubscribeChannel = useCallback(() => {
		offAllEvents.current.forEach((offEvent) => offEvent());
		leaveChannel.current();

		disconnectChannelSockets();
	}, []);

	useEffect(() => {
		if (isLoggedIn) {
			subscribeChannel();
			return unsubscribeChannel;
		}
		return undefined;
	}, [isLoggedIn, subscribeChannel, unsubscribeChannel]);

	return null;
};

ChannelSocketConnector.propTypes = {
	hashtag: PropTypes.string.isRequired,
	role: PropTypes.oneOf(Object.values(ResourceAccessRole)),
	studioId: PropTypes.string,
};

ChannelSocketConnector.defaultProps = {
	role: ResourceAccessRole.PUBLIC,
	studioId: undefined,
};
