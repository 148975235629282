import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState, useRef } from 'react';
import {
	FaCog,
	FaCompress,
	FaCropAlt,
	FaEllipsisH,
	FaExpand,
	FaPaintBrush,
	FaTimes,
	FaWrench,
	FaCrop,
} from 'react-icons/fa';
import { BsFillPipFill, BsListCheck } from 'react-icons/bs';
import clsx from 'clsx';
import {
	Modal,
	Button,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
	Badge,
	TabPane,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
	PlayerModes,
	Preview,
	useDrawer,
	useMediaUser,
	usePlayerMode,
} from '@technomiam/react-video';

import { isDesktop, isSafari, isChromeOS } from '../../../lib/userAgent';
import { GuestLocalVideoSharing } from './LocalUserMedia/GuestLocalVideoStreaming';
import { LocalCam } from './LocalUserMedia/LocalCam';
import { LocalScreenShare } from './LocalUserMedia/LocalScreenShare';
import { LocalMic } from './LocalUserMedia/LocalMic';
import { LocalDrawer } from './LocalUserMedia/LocalDrawer';
import { GuestHandRaising } from './Guest/HandRaising';
import { RecordButton } from './LocalUserMedia/RecordButton';
import { ChannelModeListening, ChannelModeSpeakerSwitch } from './ChannelMode/ChannelMode';
import { ChannelButtonsPanelLayoutSelector } from './LayoutSelector';
import { ReactComponent as PipLayoutIcon } from './pipLayoutIcon.svg';
import { MediaManager } from '../../UserMedia/Manager/Manager';
import { useChannelConnection } from '../SocketWrapper/ChannelConnectionProvider';
import { useStudio } from '../../Studio/Context';
import { Apps } from '../../../views/Controlroom/Apps';
import { ResourceAccessRole } from '../../../lib/ResourceAccessRole';
import { useOutsideClickEvent } from '../../../lib/click';
import { useAppsTab } from '../../../views/Controlroom/AppsTabProvider';

const isScreenShareCapable = (isDesktop || isChromeOS) && navigator.mediaDevices?.getDisplayMedia;
const isLocalVideoSharingCapable = !isSafari;

export const ChannelButtonsPanelForStudio = ({
	centerChildren,
	channelDescription,
	handleClickFullScreen,
	handleToggleCropVideo,
	handleToggleSettings,
	hashtag,
	isController,
	isFullscreen,
	isHandRaised,
	isMobilePortrait,
	leftChildren,
	onClickRaiseHand,
	onKeySelectBackground,
	rightChildren,
	setAllMuted,
}) => {
	const { t } = useTranslation();
	const { isDrawEnabled, setShowCanvas, showCanvas } = useDrawer();
	const { mode } = usePlayerMode();
	const { userActiveTracks } = useMediaUser();
	const [showPipLayoutSelector, setShowPipLayoutSelector] = useState(false);
	const { studio } = useStudio();
	const { setTab } = useAppsTab();

	const [isDropDownToolsOpen, setIsDropDownToolsOpen] = useState(false);
	const dropdownRef = useRef(null);

	const closeDropDownTools = useCallback(() => setIsDropDownToolsOpen(false), []);

	useOutsideClickEvent(closeDropDownTools, dropdownRef);

	const updateAppsTab = useCallback((tab) => {
		setTab(tab);
	}, [setTab]);

	const openInSeparateWindow = useCallback(() => {
		window.open(`/${studio.owner.hashtag}/studio/${studio.code}/tools`, 'popup', 'width=800,height=600');
	}, [studio]);
	// const userActiveTracks = useMemo(() => [], []);

	const isNonDefaultConfigUserMediaActive = useMemo(
		() => !!(userActiveTracks || []).find((track) => (track.configId !== 0)),
		[userActiveTracks],
	);

	const handleToggleDraw = () => {
		setShowCanvas(!showCanvas);
	};

	const [showSettings, setShowSettings] = useState(false);

	const handleClickLayoutSelector = () => {
		setShowPipLayoutSelector((prevState) => !prevState);
	};

	const [showTools, setShowTools] = useState(false);
	const { connectedClients = [] } = useChannelConnection();

	return (
		<>
			{showSettings && (
				<Modal
					centered
					contentClassName="border-0 shadow-lg rounded overflow-hidden bg-darker form-dark align-self-stretch"
					isOpen={showSettings}
					toggle={() => setShowSettings((state) => !state)}
					size="lg"
					zIndex={2000}
				>
					<Button
						color="neutral-secondary"
						className="ml-auto mr-2 shadow btn-pill d-30 p-0 m-2"
						title={t('ChannelButtonsPanel.ChannelButtonsPanel.close')}
						onClick={() => setShowSettings((state) => !state)}
					>
						<span className="btn-wrapper--icon">
							<FaTimes />
						</span>
					</Button>
					<div className="mx-auto col-md-12 col-lg-10 col-xl-7 mt-2">
						<h3 className="display-4 mb-2 font-weight-bold">{t('ChannelButtonsPanel.ChannelButtonsPanel.inputDevices')}</h3>
						<p className="font-size-lg mb-5">
							{t('ChannelButtonsPanel.ChannelButtonsPanel.chooseSource')}
						</p>
						<MediaManager onKeySelectBackground={onKeySelectBackground} />
					</div>
				</Modal>
			)}
			{showTools && (
				<Modal
					centered
					contentClassName="border-0 shadow-lg rounded overflow-hidden bg-darker form-dark align-self-stretch"
					isOpen={showTools}
					toggle={() => setShowTools((state) => !state)}
					size="md"
					zIndex={2000}
				>
					<Button
						color="neutral-secondary"
						className="ml-auto mr-2 shadow btn-pill d-30 p-0 m-2"
						title={t('ChannelButtonsPanel.ChannelButtonsPanel.close')}
						onClick={openInSeparateWindow}
					>
						<span className="btn-wrapper--icon">
							<FaTimes />
						</span>
					</Button>
					<div className="mx-auto col-md-12 col-lg-10 col-xl-7 mt-2">
						<h3 className="display-4 mb-2 font-weight-bold">{t('Controlroom.RightSidebar.tools')}</h3>

						<TabPane tabId="tools" className="h-100">
							<div className="h-100  d-flex flex-column">
								<Apps
									hashtag={hashtag}
									connectedUsers={
										connectedClients.filter((u) => u.role === ResourceAccessRole.PARTICIPANT)
									}
								/>
							</div>
						</TabPane>
					</div>
				</Modal>
			)}
			{showPipLayoutSelector && (
				<div className="ChannelButtonsPanelForStudio_PipLayout position-absolute left-0 w-100">
					<div className="d-flex pl-1 pt-1 pb-1 w-100">
						<ChannelButtonsPanelLayoutSelector
							setShowPipLayoutSelector={setShowPipLayoutSelector}
						/>
					</div>
				</div>
			)}
			{!isController && (
				<div className="mr-auto ml-0 d-none d-md-inline-flex">
					<Preview className="rounded-sm overflow-hidden mr-2" />
				</div>
			)}
			<div className={clsx('w-100 d-flex flex-column position-relative')}>
				<div className="w-100 d-flex justify-content-between align-items-center position-relative">
					<div className="ForStudio LeftContent d-flex align-items-center">
						<Button
							id="Settings"
							tag="span"
							color="neutral-secondary"
							className="Settings d-40 p-0 m-1 btn-animated-icon btn-animated-icon--secondary flex-shrink-0 shadow-none bg-transparent"
							onClick={() => (
								handleToggleSettings ? handleToggleSettings() : setShowSettings(true)
							)}
							title={t('ChannelButtonsPanel.ChannelButtonsPanel.settings')}
						>
							<span className="btn-wrapper--icon d-inline-block">
								<FaCog size={25} />
							</span>
							{isNonDefaultConfigUserMediaActive && (
								<Badge
									color="primary"
									className="badge-circle badge-position top-0 right-0 zIndex-2 shadow-none"
								>
							&nbsp;
								</Badge>
							)}
						</Button>
						<LocalCam className="LocalCam d-40 p-0 mr-1 flex-shrink-0" title={t('ChannelButtonsPanel.ChannelButtonsPanel.camera')} />
						<LocalMic className="LocalMic d-40 p-0 mr-1 flex-shrink-0" title={t('ChannelButtonsPanel.ChannelButtonsPanel.mic')} />
						{isScreenShareCapable && (
							<LocalScreenShare className="d-40 p-0 mr-1 flex-shrink-0 d-none d-sm-inline-block" />
						)}
						{(!isController && isLocalVideoSharingCapable) && (
							<GuestLocalVideoSharing
								className="d-40 p-0 flex-shrink-0 d-none d-sm-inline-block"
								hashtag={hashtag}
							/>
						)}
						{isController && (
							<div className="d-none d-sm-flex">
								<RecordButton
									hashtag={hashtag}
									className="d-40 p-0 mr-1 btn-animated-icon btn-animated-icon--secondary flex-shrink-0 bg-transparent"
								/>
								<ChannelModeListening
									className="neutral-secondary  d-40 p-0 mr-1 bg-transparent"
									setAllMuted={setAllMuted}
								/>
							</div>
						)}
						<UncontrolledDropdown direction="up" className="DropdownMoreButtons d-sm-none">
							<DropdownToggle
								color="neutral-secondary  d-40 p-0 mr-1"
								title={t('ChannelButtonsPanel.ChannelButtonsPanel.defineHearMode')}
							>
								<FaEllipsisH />

							</DropdownToggle>
							<DropdownMenu
								className="bg-dark shadow-none border border-secondary p-2 d-flex justify-content-around"
							>
								{isScreenShareCapable && (
									<LocalScreenShare className="d-40 p-0 mr-1 my-1 flex-shrink-0" />
								)}
								{isMobilePortrait && isFullscreen && (
									<Button
										className="d-40 p-0 mr-1 my-1 flex-shrink-0 btn-toggle-crop"
										color="neutral-secondary"
										title={t('ChannelButtonsPanel.ChannelButtonsPanel.toggleCrop')}
										onClick={handleToggleCropVideo}
									>
										<span className="btn-wrapper--icon d-inline-block ">
											<FaCropAlt />
										</span>
									</Button>
								)}
								{!isController && (
									<>
										<GuestHandRaising
											className="d-40 p-0 my-1 flex-shrink-0"
											onClickRaiseHand={onClickRaiseHand}
											isHandRaised={isHandRaised}
										/>
										{(isLocalVideoSharingCapable) && (
											<GuestLocalVideoSharing
												className="d-40 p-0 m-1 flex-shrink-0"
												hashtag={hashtag}
											/>
										)}
										{isFullscreen && (
											channelDescription
										)}
									</>
								)}
								{isController && (
									<>
										<RecordButton
											hashtag={hashtag}
											className="d-40 p-0 my-1 btn-animated-icon btn-animated-icon--secondary flex-shrink-0"
										/>
										<ChannelModeListening
											className="neutral-secondary  d-40 p-0 ml-2 my-1"
											setAllMuted={setAllMuted}
										/>
										<Button
											className="d-40 p-0 flex-shrink-0 ml-2 my-1"
											color={mode === PlayerModes.DRAW && showCanvas ? 'primary bg-transparent' : 'neutral-secondary bg-transparent'}
											title={t('ChannelButtonsPanel.ChannelButtonsPanel.toggleDrawing')}
											onClick={handleToggleDraw}
										>
											<span className="btn-wrapper--icon d-inline-block ">
												<FaPaintBrush />
											</span>
										</Button>
										<Button
											className={clsx(
												'mt-auto mb-auto d-40 p-0 flex-shrink-0 ml-2 my-1k d-flex align-items-center justify-content-center bg-transparent',
												{ 'text-primary': showPipLayoutSelector },
											)}
											color="neutral-secondary"
											title={t('ChannelButtonsPanel.ChannelButtonsPanel.selectLayout')}
											onClick={handleClickLayoutSelector}
										>
											<PipLayoutIcon width="30" height="30" />
										</Button>
									</>
								)}
							</DropdownMenu>
						</UncontrolledDropdown>
						{!isController ? (
							<div className="border-left ml-2 border-second d-none d-sm-block">
								<GuestHandRaising
									className="d-40 p-0 mx-1 ml-2 bg-transparent"
									onClickRaiseHand={onClickRaiseHand}
									isHandRaised={isHandRaised}
								/>
							</div>
						) : (
							<>
								<Button
									tag="span"
									className={clsx(
										'mr-1 d-40 p-0 mr-1 flex-shrink-0 d-none d-sm-flex align-items-center justify-content-center bg-transparent',
										{ 'text-primary': showPipLayoutSelector },
									)}
									color="neutral-secondary"
									title={t('ChannelButtonsPanel.ChannelButtonsPanel.selectLayout')}
									onClick={handleClickLayoutSelector}
								>
									<PipLayoutIcon width="25" height="25" />
								</Button>
								<ChannelModeSpeakerSwitch
									className="neutral-secondary dh-40 p-0 switch-primary mr-1"
								/>
							</>
						)}
						{leftChildren}
					</div>
					{centerChildren && (
						<div className="CenterContent d-flex align-items-center justify-content-center flex-grow-1">
							{centerChildren}
						</div>
					)}
					<div className="RightContent d-flex align-items-center" ref={dropdownRef}>
						{isController && (
							<UncontrolledDropdown direction="down">
								<DropdownToggle color="neutral-secondary" className="bg-transparent shadow-none p-2">
									<span className="btn-wrapper--icon">
										<FaWrench />
									</span>
								</DropdownToggle>
								<DropdownMenu isOpen={isDropDownToolsOpen} className="shadow-none p-0">
									<span
										className="dropdown-item cursor-pointer"
										onClick={() => updateAppsTab('votes')}
									>
										<span className="btn-wrapper--icon mr-2">
											<BsListCheck />
										</span>
										Vote
									</span>
									<span
										className="dropdown-item cursor-pointer"
										onClick={() => updateAppsTab('draw')}
									>
										<span className="btn-wrapper--icon mr-2">
											<FaPaintBrush />
										</span>
										Draw
									</span>
									<span
										className="dropdown-item cursor-pointer"
										onClick={() => updateAppsTab('floatingpips')}
									>
										<span className="btn-wrapper--icon mr-2">
											<BsFillPipFill />
										</span>
										PIP
									</span>
									<span
										className="dropdown-item cursor-pointer"
										onClick={() => updateAppsTab('cropLive')}
									>
										<span className="btn-wrapper--icon mr-2">
											<FaCrop />
										</span>
										Zoom
									</span>
								</DropdownMenu>
							</UncontrolledDropdown>
						)}
						{isFullscreen && (
							<div className="d-none d-sm-block">
								{channelDescription}
							</div>
						)}
						{isMobilePortrait && isFullscreen && (
							<Button
								className="d-40 p-0  flex-shrink-0 mr-1 d-none d-sm-block"
								color="neutral-secondary bg-transparent"
								title={t('ChannelButtonsPanel.ChannelButtonsPanel.toggleCrop')}
								onClick={handleToggleCropVideo}
							>
								<span className="btn-wrapper--icon d-inline-block ">
									<FaCropAlt />
								</span>
							</Button>
						)}
						{rightChildren}
						{(isDrawEnabled && !isController) && (
							<>
								<div className="d-40 mr-1" />
								<div
									className={clsx(
										'ChannelButtonsPanel_drawer r-0 top-0 right-0 d-flex align-items-center',
										showCanvas && 'open',
									)}
									onClick={(e) => { e.stopPropagation(); }}
								>
									<Button
										className="d-40 p-0 mr-2"
										color="neutral-secondary"
										title={t('ChannelButtonsPanel.ChannelButtonsPanel.toggleDrawing')}
										onClick={handleToggleDraw}
									>
										<span className="btn-wrapper--icon d-inline-block">
											{showCanvas ? <FaTimes /> : <FaPaintBrush />}
										</span>
									</Button>
									{showCanvas && <LocalDrawer />}
								</div>
							</>
						)}
						{!!handleClickFullScreen && (
							<Button
								className="d-40 p-0 btn-no-focus mx-1"
								color="neutral-secondary bg-transparent"
								title={t('ChannelButtonsPanel.ChannelButtonsPanel.toggleScreenMode')}
								onClick={(e) => handleClickFullScreen() && e.preventDefault()}
							>
								<span className="btn-wrapper--icon d-inline-block">
									{isFullscreen
										? <FaCompress size={25} />
										: <FaExpand size={25} />}
								</span>
							</Button>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

ChannelButtonsPanelForStudio.propTypes = {
	centerChildren: PropTypes.node,
	channelDescription: PropTypes.node,
	handleClickFullScreen: PropTypes.func,
	handleToggleCropVideo: PropTypes.func,
	handleToggleSettings: PropTypes.func,
	hashtag: PropTypes.string,
	isController: PropTypes.bool,
	isFullscreen: PropTypes.bool,
	isHandRaised: PropTypes.bool,
	isMobilePortrait: PropTypes.bool,
	leftChildren: PropTypes.node,
	onClickRaiseHand: PropTypes.func,
	onKeySelectBackground: PropTypes.func,
	rightChildren: PropTypes.node,
	setAllMuted: PropTypes.func,
};

ChannelButtonsPanelForStudio.defaultProps = {
	centerChildren: null,
	channelDescription: null,
	handleClickFullScreen: undefined,
	handleToggleCropVideo: undefined,
	handleToggleSettings: undefined,
	hashtag: null,
	isController: false,
	isFullscreen: false,
	isHandRaised: false,
	isMobilePortrait: false,
	leftChildren: null,
	onClickRaiseHand: undefined,
	onKeySelectBackground: undefined,
	rightChildren: null,
	setAllMuted: undefined,
};
