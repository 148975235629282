import { password } from '../Login/password.schema';

export const AccountType = {
	PERSONAL: 'PERSONAL',
};

export const AccountTypeLabel = {
	PERSONAL: 'For my personal use',
};

export const IdentificationField = {
	EMAIL: 'EMAIL',
	PHONE: 'PHONE',
};

export const IdentificationFieldLabel = {
	EMAIL: 'Email',
	PHONE: 'Phone',
};

const nickname = {
	type: 'string',
	minLength: 2,
	maxLength: 50,
};

export const properties = {
	accountType: { type: 'string', enum: Object.values(AccountType) },
	nickname,
	identificationField: { type: 'string', enum: Object.values(IdentificationField) },
	email: { type: 'string', format: 'email' },
	phoneNumber: { type: 'string', phone: true },
	password,
};

export const signupSchema = {
	type: 'object',
	additionalProperties: false,
	properties,
	required: [
		'accountType',
		'nickname',
		'password',
		'identificationField',
	],
};
