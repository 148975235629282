export const passwordUppercaseSchema = {
	type: 'string',
	pattern: '^(?=.*[A-Z])',
	message: 'Password must contain at least 1 uppercase letter',
};

export const passwordSpecialCharacterSchema = {
	type: 'string',
	pattern: '^(?=.*[@$!%*?&.,#^()_\\-\\[\\]{}])',
	message: 'Password must contain at least 1 special character',
};

export const passwordNumberSchema = {
	type: 'string',
	pattern: '^(?=.*\\d)',
	message: 'Password must contain at least 1 number',
};

export const passwordFullSchema = {
	type: 'string',
	pattern: '^[A-Za-z\\d@$!%*?&.,#^()_\\-\\[\\]{}]+$',
	message: 'Password must contain only letters, numbers and special characters',
};

export const getPasswordValidationMessage = (pattern) => {
	switch (pattern) {
	case passwordUppercaseSchema.pattern:
		return passwordUppercaseSchema.message;
	case passwordSpecialCharacterSchema.pattern:
		return passwordSpecialCharacterSchema.message;
	case passwordNumberSchema.pattern:
		return passwordNumberSchema.message;
	case passwordFullSchema.pattern:
		return passwordFullSchema.message;
	default:
		return null;
	}
};

export const password = {
	allOf: [
		{
			type: 'string',
			minLength: 12,
		},
		passwordUppercaseSchema,
		passwordSpecialCharacterSchema,
		passwordNumberSchema,
		passwordFullSchema,
	],
};

export const properties = {
	password,
};

export const schema = {
	type: 'object',
	additionalProperties: false,
	properties,
	required: [
		'password',
	],
};
