/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Pagination, PaginationItem, UncontrolledDropdown } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { usePagination } from './Provider';

import './ItemLine.scss';

export const PaginationItemLine = ({ isDisabled }) => {
	const { t } = useTranslation();
	const {
		currentPage,
		itemsPerPage,
		changeItemsPerPage,
		itemsCount,
	} = usePagination();

	const itemsPerPageOptions = useMemo(() => [
		{ value: 5, label: '5' },
		{ value: 10, label: '10' },
		{ value: 20, label: '20' },
		{ value: 50, label: '50' },
		{ value: 100, label: '100' },
		{ value: -1, label: t('Pagination.ItemLine.showAll') },
	], [t]);

	const handleChangeItemsPerPage = useCallback((option) => () => {
		changeItemsPerPage(option.value);
	}, [changeItemsPerPage]);

	return (
		<Pagination listClassName="flex-wrap justify-content-center">
			{itemsCount > 0 ? (
				<PaginationItem className="d-flex align-items-center mr-2">
					<UncontrolledDropdown direction="up">
						<DropdownToggle caret className="ItemLine__toggle shadow-none border-0 bg-transparent content-dark" color="" disabled={isDisabled}>
							{itemsPerPage !== -1 ? itemsPerPage : t('Pagination.ItemLine.all')} {t('Pagination.ItemLine.items')} {itemsPerPage !== -1 ? t('Pagination.ItemLine.perPage') : ''}
						</DropdownToggle>
						<DropdownMenu end className="w-100 bg-light">
							{itemsPerPageOptions.map((option) => (
								<DropdownItem
									key={`select-items-per-page-${option.value}`}
									onClick={handleChangeItemsPerPage(option)}
									className="ItemLine__option"
								>
									{option.label}
								</DropdownItem>
							))}
						</DropdownMenu>
					</UncontrolledDropdown>
				</PaginationItem>
			) : null}
			<PaginationItem className="d-flex align-items-center text-secondary">
				{itemsPerPage !== -1 && itemsCount > 0
					? `${t('Pagination.ItemLine.showing')} ${
						(currentPage - 1) * itemsPerPage + 1
					} - ${
						(currentPage * itemsPerPage) > itemsCount ? itemsCount : currentPage * itemsPerPage
					} ${t('Pagination.ItemLine.outOf')} ${itemsCount}`
					: (itemsCount > 0 ? `${t('Pagination.ItemLine.totalItems')}: ${itemsCount}` : `${t('Pagination.ItemLine.totalItems')}: 0`)}
			</PaginationItem>
		</Pagination>
	);
};

PaginationItemLine.propTypes = {
	isDisabled: PropTypes.bool,
};

PaginationItemLine.defaultProps = {
	isDisabled: false,
};
