import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAsyncCallback } from 'react-async-hook';
import { Field, FieldError, Form } from 'react-jsonschema-form-validation';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { TooltipDisabledViewers } from '../../../views/Controlroom/AccessSummary/AccessSummary';
import FormSubmit from '../../Form/Submit';
import ReallyPerfectScrollbar from '../../ReallyPerfectScrollbar/ReallyPerfectScrollbar';
import { useStudio } from '../../Studio/Context';
import { OptionInput } from '../common/OptionInput';
import { TemplateSelect } from '../common/TemplateSelect';
import './Create.scss';
import { createVoteSchema } from './createVote.schema';

export const VoteAudienceType = {
	PARTICIPANT: 'PARTICIPANT',
	VIEWER: 'VIEWER',
};

export const VoteCreate = ({ currentVote, onSubmit, onCancel }) => {
	const { t } = useTranslation();
	const [vote, setVote] = useState({
		title: '',
		options: [{ label: '' }, { label: '' }],
		durationMinutes: 1,
		durationSeconds: 0,
		position: 'top',
		category: '',
		audienceType: VoteAudienceType.PARTICIPANT,
		templateId: null,
	});

	const { studio } = useStudio();

	const disabled = useMemo(() => !!vote.templateId, [vote]);
	const viewersDisabled = useMemo(() => !studio.isLiveEnabled, [studio]);

	useEffect(() => {
		if (currentVote) {
			setVote({
				title: currentVote.title,
				options: currentVote.options,
				durationMinutes: Math.floor(currentVote.duration / 60),
				durationSeconds: Math.floor(currentVote.duration % 60),
				position: currentVote.position,
				category: currentVote.category,
				audienceType: viewersDisabled ? VoteAudienceType.PARTICIPANT : currentVote.audienceType,
				templateId: currentVote.template ?? null,
			});
		}
	}, [currentVote, setVote, viewersDisabled]);

	const handleSubmitAsync = useAsyncCallback(() => onSubmit({
		title: vote.title,
		options: vote.options.map((option) => ({ label: option.label, correct: option.correct })),
		duration: vote.durationMinutes * 60 + vote.durationSeconds,
		position: vote.position,
		category: vote.category,
		audienceType: vote.audienceType,
		templateId: vote.templateId,
	}));

	if (handleSubmitAsync.error) console.error(handleSubmitAsync.error);

	const handleChoiceChange = useCallback((options) => {
		setVote((cp) => ({ ...cp, options }));
	}, [setVote]);

	const handleChange = useCallback((data) => {
		handleSubmitAsync.reset();
		setVote(data);
	}, [handleSubmitAsync]);

	const handleSelectChange = useCallback((template) => {
		setVote({
			title: !template ? '' : template.title,
			options: !template ? [{ label: '' }, { label: '' }] : template.options.map((option) => ({ label: option.label })),
			durationMinutes: !template ? 1 : Math.floor(template.defaultDuration / 60),
			durationSeconds: !template ? 0 : Math.floor(template.defaultDuration % 60),
			position: !template ? 'top' : template.defaultPosition,
			category: !template ? '' : template.category ?? '',
			// eslint-disable-next-line no-nested-ternary
			audienceType: !template
				? VoteAudienceType.PARTICIPANT
				// eslint-disable-next-line no-nested-ternary
				: template.audienceType
					? (viewersDisabled ? VoteAudienceType.PARTICIPANT : template.audienceType)
					: VoteAudienceType.PARTICIPANT,
			templateId: !template ? null : template._id,
		});
	}, [setVote, viewersDisabled]);

	return (
		<Form
			className="VoteCreate h-75 flex-grow-1 d-flex flex-column justify-content-between"
			data={vote}
			onChange={handleChange}
			onSubmit={handleSubmitAsync.execute}
			schema={createVoteSchema}
		>
			<ReallyPerfectScrollbar>
				<section className="flex-grow-1 overflow-hidden">
					<FormGroup>
						<Label className="text-secondary">{t('Votes.Create.template')}</Label>
						<TemplateSelect onChange={handleSelectChange} templateId={vote.templateId} />
					</FormGroup>
					<FormGroup>
						<Label className="text-secondary">{t('Votes.Create.category')} <i>({t('Votes.Create.optional')})</i></Label>
						<Field
							component={Input}
							name="category"
							placeholder={t('Votes.Create.egCategory')}
							type="text"
							className={disabled ? 'bg-secondary content-secondary' : ''}
							value={vote.category}
							disabled={disabled}
						/>
						<FieldError name="category" />
					</FormGroup>
					<FormGroup>
						<Label className="text-secondary">{t('Votes.Create.title')}</Label>
						<Field
							component={Input}
							name="title"
							placeholder={t('Votes.Create.egTitle')}
							type="textarea"
							className={disabled ? 'bg-secondary content-secondary' : ''}
							value={vote.title}
							disabled={disabled}
						/>
						<FieldError name="title" />
					</FormGroup>
					<Label className="text-secondary">{t('Votes.Create.audience')}</Label>
					<FormGroup check>
						<Field
							component={Input}
							type="radio"
							name="audienceType"
							id="participantsAudience"
							value={VoteAudienceType.PARTICIPANT}
							checked={vote.audienceType === VoteAudienceType.PARTICIPANT}
						/>
						<Label check for="participantsAudience" className="blue">
							{t('Votes.Create.participants')}
						</Label>
					</FormGroup>
					<FormGroup id="viewersAudienceType" check>
						<Field
							component={Input}
							type="radio"
							name="audienceType"
							id="viewersAudience"
							value={VoteAudienceType.VIEWER}
							checked={vote.audienceType === VoteAudienceType.VIEWER}
							disabled={viewersDisabled}
						/>
						<Label check for="viewersAudience" className="blue">
							{t('Votes.Create.viewers')}
						</Label>
					</FormGroup>
					{viewersDisabled && (
						<TooltipDisabledViewers
							target="viewersAudienceType"
							text="To send votes to viewers"
						/>
					)}
					<FormGroup className="mt-2">
						<Label className="text-secondary">{t('Votes.Create.options')}</Label>
						<OptionInput
							onChange={handleChoiceChange}
							value={vote.options}
							disabled={disabled}
						/>
						<FieldError name="options" />
					</FormGroup>
					<FormGroup>
						<Label className="text-secondary">{t('Votes.Create.duration')}</Label>
						<Row className="align-items-center">
							<Col sm={3}>
								<Field
									component={Input}
									className="no-arrows"
									name="durationMinutes"
									type="number"
									min={1}
									max={60}
									value={vote.durationMinutes}
								/>
							</Col>
							:
							<Col sm={3}>
								<Field
									component={Input}
									className="no-arrows"
									name="durationSeconds"
									type="number"
									min={0}
									max={60}
									value={vote.durationSeconds}
								/>
							</Col>
						</Row>
						<FieldError name="durationMinutes" />
						<FieldError name="durationSeconds" />
					</FormGroup>
					<FormGroup tag="fieldset">
						<Label className="text-secondary">{t('Votes.Create.position')}</Label>
						<Row>
							<Col sm={4}>
								<Label>
									<Field
										component={Input}
										name="position"
										id="positionRadioTop"
										type="radio"
										label={t('Votes.Create.position.top')}
										value="top"
										checked={vote.position === 'top'}
									/>
									{' '}{t('Votes.Create.position.top')}
								</Label>
							</Col>
							<Col sm={4}>
								<Label>
									<Field
										component={Input}
										name="position"
										id="positionRadioMid"
										type="radio"
										label={t('Votes.Create.position.mid')}
										value="mid"
										checked={vote.position === 'mid'}
									/>
									{' '}{t('Votes.Create.position.mid')}
								</Label>
							</Col>
							<Col sm={4}>
								<Label>
									<Field
										component={Input}
										name="position"
										id="positionRadioBot"
										type="radio"
										label={t('Votes.Create.position.bot')}
										value="bot"
										checked={vote.position === 'bot'}
									/>
									{' '}{t('Votes.Create.position.bot')}
								</Label>
							</Col>
						</Row>
					</FormGroup>
					{handleSubmitAsync.error && (
						<Alert color="danger">
							{t('Global.error')}
						</Alert>
					)}
				</section>
			</ReallyPerfectScrollbar>

			<footer className="d-flex w-100 align-items-center">
				<Button
					color="neutral-secondary"
					className="btn-pill mr-2"
					disabled={handleSubmitAsync.loading}
					onClick={onCancel}
				>
					{t('Votes.Create.cancel')}
				</Button>
				<FormSubmit
					color="primary"
					className="btn-pill"
					loading={handleSubmitAsync.loading}
				>
					{t('Votes.Create.start')}
				</FormSubmit>
			</footer>
		</Form>
	);
};

VoteCreate.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	currentVote: PropTypes.shape({
		title: PropTypes.string.isRequired,
		options: PropTypes.arrayOf(PropTypes.shape({
			_id: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
			// percentage: PropTypes.number.isRequired,
		})).isRequired,
		publishedAt: PropTypes.string,
		endedAt: PropTypes.string,
		duration: PropTypes.number,
		defaultDuration: PropTypes.number,
		position: PropTypes.oneOf(['top', 'bot', 'mid']),
		defaultPosition: PropTypes.oneOf(['top', 'bot', 'mid']),
		template: PropTypes.string,
		category: PropTypes.string,
		audienceType: PropTypes.string,
	}),
};

VoteCreate.defaultProps = {
	currentVote: null,
};
