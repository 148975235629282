import { authenticatedApi } from '../api';

const API_NOTIFICATION_PATH = '/notification';

export const fetchNotifications = async (lastTimestamp) => authenticatedApi.get(
	API_NOTIFICATION_PATH,
	{ params: { lastTimestamp } },
);

export const fetchNotificationsUnreadCount = async () => authenticatedApi.get(
	`${API_NOTIFICATION_PATH}/unread/count`,
);

export const markNotificationBoardRead = async (readAt) => authenticatedApi.post(
	`${API_NOTIFICATION_PATH}/read`,
	{ readAt },
);
