export const StudioStatus = {
	CANCELLED: 'CANCELLED',
	PENDING: 'PENDING',
	PLANNED: 'PLANNED',
	WAITING_FOR_CONTROLLER: 'WAITING_FOR_CONTROLLER',
	RUNNING: 'RUNNING',
	STOPPED: 'STOPPED',
};

export const StudioState = {
	CANCELLED: 'CANCELLED',
	ONGOING: 'ONGOING',
	NOT_STARTED: 'NOT_STARTED',
	TERMINATED: 'TERMINATED',
};

export const generatePassword = (length) => {
	let password = '';
	while (password.length < length) {
		password += Math.floor(Math.random() * 10);
	}
	return password;
};
