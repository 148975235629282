import React, { useEffect, useRef, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useMixing } from '../ReactVideo/Mixing';
import { useCrop } from './CropProvider';
import { isMobile } from '../../lib/userAgent';

const CANVAS_SIZE = { width: 1280, height: 720 };
let mouseClickPos = { x: 0, y: 0 };

export const PlayerLiveCropOverlay = ({
	size,
}) => {
	const { notifyCrop, channelMixerStatus } = useMixing();
	const { setIsCropEnabled, isCoefficientEnabled } = useCrop();

	const canvasRef = useRef(null);
	const [isDrawingMode, setIsDrawingMode] = useState(false);
	const [rect, setRect] = useState(undefined);

	const scaleFactor = useMemo(() => ({
		horizontal: size.width / (channelMixerStatus?.liveResolution?.width || 1),
		vertical: size.height / (channelMixerStatus?.liveResolution?.height || 1),
	}), [size, channelMixerStatus?.liveResolution]);

	useEffect(() => {
		const canvas = canvasRef.current;
		const context = canvas?.getContext('2d');
		let canvasRect;
		if (isMobile) {
			canvasRect = canvas?.getBoundingClientRect();
		}

		const xFactor = CANVAS_SIZE.width / size.width;
		const yFactor = CANVAS_SIZE.height / size.height;

		const handleRectOverflow = (width, height) => {
			const overflowWidth = (mouseClickPos.x + width) * scaleFactor.horizontal > size.width
				|| (mouseClickPos.x + width) * scaleFactor.horizontal < 0;
			const overflowHeight = (mouseClickPos.y + height) * scaleFactor.vertical > size.height
				|| (mouseClickPos.y + height) * scaleFactor.vertical < 0;

			if (isCoefficientEnabled) {
				if (!overflowHeight && !overflowWidth) {
					return { width, height };
				}
				if (rect) {
					return {
						width: rect.width,
						height: rect.height,
					};
				}
			} else if (rect) {
				return {
					width: overflowWidth ? rect.width : width,
					height: overflowHeight ? rect.height : height,
				};
			}
			return { width: 0, height: 0 };
		};

		const handleDrawRect = (mousePosition) => {
			const { width, height } = handleRectOverflow(
				(mousePosition.x - mouseClickPos.x) / scaleFactor.horizontal,
				isCoefficientEnabled ? (
					(mousePosition.x - mouseClickPos.x) / scaleFactor.horizontal) * (9.0 / 16.0)
					: (mousePosition.y - mouseClickPos.y) / scaleFactor.vertical,
			);

			context.strokeStyle = '#FEC82E';
			context.clearRect(
				0,
				0,
				canvas.width,
				canvas.height,
			);
			context.strokeRect(
				mouseClickPos.x,
				mouseClickPos.y,
				width,
				height,
			);

			setRect({
				x: mouseClickPos.x,
				y: mouseClickPos.y,
				width,
				height,
				size: CANVAS_SIZE,
			});
		};

		const mouseUp = () => {
			setIsDrawingMode(false);
			if (!rect) return;
			notifyCrop(rect);
			setIsCropEnabled(false);
		};

		const mouseDown = (e) => {
			if (isMobile) {
				e.offsetX = e.touches[0].clientX - canvasRect.left;
				e.offsetY = e.touches[0].clientY - canvasRect.top;
			}
			const mousePosition = {
				x: e.offsetX * xFactor,
				y: e.offsetY * yFactor,
			};

			setIsDrawingMode(true);
			context.lineWidth = 2;

			mouseClickPos = mousePosition;
		};

		const mouseMove = (e) => {
			if (isMobile) {
				e.preventDefault();
				e.offsetX = e.touches[0].clientX - canvasRect.left;
				e.offsetY = e.touches[0].clientY - canvasRect.top;
			}
			const mousePosition = {
				x: e.offsetX * xFactor,
				y: e.offsetY * yFactor,
			};
			if (!isDrawingMode) return;

			handleDrawRect(mousePosition);
		};

		if (context) {
			if (isMobile) {
				canvas.addEventListener('touchmove', mouseMove);
				document.addEventListener('touchend', mouseUp);
				canvas.addEventListener('touchstart', mouseDown);
			} else {
				canvas.addEventListener('mousemove', mouseMove);
				document.addEventListener('mouseup', mouseUp);
				canvas.addEventListener('mousedown', mouseDown);
			}
		}

		// eslint-disable-next-line consistent-return
		return () => {
			if (canvas) {
				if (isMobile) {
					canvas.removeEventListener('touchmove', mouseMove);
					document.removeEventListener('touchend', mouseUp);
					canvas.removeEventListener('mousedown', mouseDown);
				} else {
					canvas.removeEventListener('mousemove', mouseMove);
					document.removeEventListener('mouseup', mouseUp);
					canvas.removeEventListener('mousedown', mouseDown);
				}
			}
		};
	}, [
		isDrawingMode,
		channelMixerStatus?.liveResolution,
		notifyCrop,
		scaleFactor,
		size,
		rect,
		setIsCropEnabled,
		isCoefficientEnabled,
	]);

	return (
		<canvas
			style={{
				cursor: 'crosshair',
				height: size.height,
				width: size.width,
				left: size.left,
				zIndex: 1,
			}}
			width={CANVAS_SIZE.width}
			height={CANVAS_SIZE.height}
			className="PlayerCanvas position-absolute"
			ref={canvasRef}
		/>
	);
};

PlayerLiveCropOverlay.propTypes = {
	size: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
		top: PropTypes.number,
		left: PropTypes.number,
	}).isRequired,
};
