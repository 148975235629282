import React, { createContext, useContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useAsync } from 'react-async-hook';

import { generatePassword } from '../../../components/StudioSchedule/helper';
import { useAsyncErrorLog } from '../../../lib/hooks';
import { fetchStudioConfig } from '../../../api/studio/studio';

import { useStudio } from '../../../components/Studio/Provider';

const StudioAccessContext = createContext({});

export const useStudioAccess = () => useContext(StudioAccessContext);

const getStudioAccessInitialState = (studioConfig) => ({
	password: studioConfig?.password || generatePassword(6),
	price: {
		participant: studioConfig?.price.participant || '',
		viewer: studioConfig?.price.viewer || '',
		participantPerMinute: studioConfig?.price.participantPerMinute || '',
		viewerPerMinute: studioConfig?.price.viewerPerMinute || '',
	},
	currencies: {
		participant: studioConfig?.currencies.participant || [],
		viewer: studioConfig?.currencies.viewer || [],
		participantPerMinute: studioConfig?.currencies.participantPerMinute || [],
		viewerPerMinute: studioConfig?.currencies.viewerPerMinute || [],
	},
});

export const StudioAccessProvider = ({ children }) => {
	const { studio } = useStudio();
	const [data, setData] = useState(getStudioAccessInitialState());
	const [showAccess, setShowAccess] = useState(false);

	const { error: fetchError, loading, result: studioConfig } = useAsync(async () => {
		const result = await fetchStudioConfig(studio._id);
		return result.data;
	}, [studio._id]);
	useAsyncErrorLog({ error: fetchError });

	useEffect(() => {
		if (!studioConfig) return;
		setData(getStudioAccessInitialState(studioConfig));
	}, [studioConfig]);

	const studioAccessValue = useMemo(() => ({
		data,
		loading,
		setData,
		showAccess,
		studio,
		studioConfig,
		toggleShowAccess: () => setShowAccess(!showAccess),
	}), [data, loading, showAccess, studio, studioConfig]);

	return (
		<StudioAccessContext.Provider value={studioAccessValue}>
			{children}
		</StudioAccessContext.Provider>

	);
};

StudioAccessProvider.propTypes = {
	children: PropTypes.node,
};

StudioAccessProvider.defaultProps = {
	children: undefined,
};
