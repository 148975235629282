import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field } from 'react-jsonschema-form-validation';
import { Alert, Col, FormGroup, Input, Row } from 'reactstrap';
import { isEmail } from 'react-multi-email';
import { useFetchProfile, useUpdateProfile } from '../../api-hooks/profile/me';
import { useBeginVerification, useConfirmVerification } from '../../api-hooks/verification.js/verification';
import { VerificationType } from '../../lib/VerificationType';
import { ButtonLoading, ButtonPill, ButtonPillOutline } from '../Button';
import { FormLabel } from '../Form/Label';
import { InputUnverifiedBadge } from './UnverifiedBadge';
import { InputVerifiedBadge } from './VerifiedBadge';

export const InputVerificationOrientation = {
	HORIZONTAL: 'horizontal',
	VERTICAL: 'vertical',
};

export const InputVerification = ({ value, type, input, orientation }) => {
	const [code, setCode] = useState('');
	const [verificationToken, setVerificationToken] = useState();
	const [isAlreadyVerified, setIsAlreadyVerified] = useState(false);

	const { data: profile } = useFetchProfile();
	const { mutate: updateProfile } = useUpdateProfile();

	const {
		mutate: beginVerification,
		isLoading: isBeginVerificationLoading,
		isError: isBeginVerificationError,
		isSuccess: isBeginVerificationSuccess,
		reset: resetBeginVerification,
		error: beginVerificationError,
	} = useBeginVerification();

	const {
		mutate: confirmVerification,
		isLoading: isConfirmVerificationLoading,
		isError: isConfirmVerificationError,
		isSuccess: isConfirmVerificationSuccess,
		reset: resetConfirmVerification,
	} = useConfirmVerification();

	const showVerifyButton = useMemo(
		() => !isAlreadyVerified
			&& !isConfirmVerificationSuccess
			&& (type === VerificationType.EMAIL
				? profile?.email !== value
				: profile?.phoneNumber !== value),
		[
			isAlreadyVerified,
			isConfirmVerificationSuccess,
			profile,
			type,
			value,
		],
	);

	const fieldName = useMemo(() => (type === VerificationType.EMAIL
		? 'email'
		: 'phoneNumber'),
	[type]);

	const handleBeginVerification = useCallback(() => {
		resetConfirmVerification();

		beginVerification({ [fieldName]: value }, {
			onSuccess: (data) => {
				if (data.verified) {
					setIsAlreadyVerified(true);

					if (profile) {
						updateProfile({ [fieldName]: value });
					}
				} else {
					setVerificationToken(data.verification_token);
					setIsAlreadyVerified(false);
				}
			},
		});
	}, [resetConfirmVerification, beginVerification, fieldName, value, profile, updateProfile]);

	const handleConfirmVerification = useCallback(() => {
		confirmVerification({ code, verification_token: verificationToken }, {
			onSuccess: () => {
				resetBeginVerification();
				setIsAlreadyVerified(false);
				setCode('');

				if (profile) {
					updateProfile({ [fieldName]: value });
				}
			},
		});
	}, [
		confirmVerification,
		code,
		verificationToken,
		resetBeginVerification,
		profile,
		updateProfile,
		fieldName,
		value,
	]);

	const handleCancelVerification = useCallback(() => {
		resetBeginVerification();
		resetConfirmVerification();
		setIsAlreadyVerified(false);
	}, [resetBeginVerification, resetConfirmVerification]);

	useEffect(() => {
		handleCancelVerification();
	}, [handleCancelVerification, value]);

	const verificationStarted = useMemo(
		() => isBeginVerificationSuccess && !isAlreadyVerified,
		[isAlreadyVerified, isBeginVerificationSuccess],
	);

	const verificationDisabled = useMemo(() => (
		!!(type === VerificationType.EMAIL && (!value || !isEmail(value)))
	), [type, value]);

	return (
		<div>
			<Row className={`${orientation === InputVerificationOrientation.VERTICAL ? 'flex-column' : ''}`}>
				<Col>
					<FormLabel>
						{type === VerificationType.EMAIL ? 'Email' : 'Phone'}
					</FormLabel>
					{input}
					{isConfirmVerificationSuccess || !showVerifyButton ? (
						<div className="mt-2">
							<InputVerifiedBadge />
						</div>
					) : null}
					{showVerifyButton && (!isBeginVerificationSuccess || isAlreadyVerified) && (
						<div className="d-flex align-items-center mt-2">
							<InputUnverifiedBadge />
							<ButtonLoading
								component={ButtonPillOutline}
								color={verificationStarted ? 'success' : 'primary'}
								size="sm"
								onClick={handleBeginVerification}
								type="button"
								loading={isBeginVerificationLoading}
								className="ml-2"
								disabled={verificationDisabled}
							>
								Verify
							</ButtonLoading>
						</div>
					)}
				</Col>
				<Col>
					<FormGroup className={`${orientation === InputVerificationOrientation.VERTICAL ? 'mt-2' : ''}`}>
						<FormLabel>Verification Code</FormLabel>
						<Field
							component={Input}
							name="text"
							placeholder="XXXXXX"
							type="text"
							value={code}
							onChange={(e) => setCode(e.target.value)}
							disabled={!verificationStarted}
						/>
					</FormGroup>
					{verificationStarted && (
						<>
							<ButtonLoading
								component={ButtonPill}
								color="primary"
								size="sm"
								onClick={handleConfirmVerification}
								type="button"
								loading={isConfirmVerificationLoading}
							>
								Confirm
							</ButtonLoading>
							<ButtonPillOutline
								color="secondary"
								size="sm"
								className="ml-2"
								type="button"
								onClick={handleCancelVerification}
							>
								Cancel
							</ButtonPillOutline>
						</>
					)}
				</Col>
			</Row>
			<div className="mb-3">
				{isAlreadyVerified && (
					<Alert color="success" className="mt-3">
						{`${type === VerificationType.EMAIL ? 'Email' : 'SMS'} is already verified`}
					</Alert>
				)}
				{verificationStarted && (
					<Alert color="success" className="mt-3">
						{`${type === VerificationType.EMAIL ? 'Email' : 'SMS'} with a code has been sent`}
					</Alert>
				)}
				{isBeginVerificationError && (
					<Alert color="danger" className="mt-3">
						{beginVerificationError.response.data.message}
					</Alert>
				)}
				{isConfirmVerificationError && (
					<Alert color="danger" className="mt-3">
						The code you entered was wrong
					</Alert>
				)}
			</div>
		</div>
	);
};

InputVerification.propTypes = {
	value: PropTypes.string,
	type: PropTypes.string.isRequired,
	orientation: PropTypes.oneOf(Object.values(InputVerificationOrientation)),
	input: PropTypes.node,
};

InputVerification.defaultProps = {
	value: PropTypes.string,
	orientation: InputVerificationOrientation.VERTICAL,
	input: undefined,
};
