import React from 'react';
import PropTypes from 'prop-types';
import { ModalScreen } from '../../Modal/Screen/Screen';
import { useUserSettings } from '../Context';
import { AnalyticsAndAdvertisingContent } from './Content';

import './Modal.scss';

export const AnalyticsAndAdvertisingModal = ({ isOpen }) => {
	const { closeModal } = useUserSettings();

	return (
		<ModalScreen
			bodyClassName="py-0"
			isOpen={isOpen}
			onClose={closeModal}
			className="AnalyticsAndAdvertisingModal mx-auto"
		>
			<AnalyticsAndAdvertisingContent />
		</ModalScreen>
	);
};

AnalyticsAndAdvertisingModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
};
