import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Hexagon.scss';

const Hexagon = ({
	children,
	className,
	tag: Tag,
	...props
}) => (
	<Tag className={classnames('Hexagon', className)} {...props}>
		<div className="HexagonShape">
			{ children }
		</div>
	</Tag>
);

Hexagon.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	tag: PropTypes.elementType,
};

Hexagon.defaultProps = {
	children: null,
	className: '',
	tag: 'div',
};

export default Hexagon;
