import PropTypes from 'prop-types';
import React from 'react';
import { VoteProvider } from '../../Vote/Provider';
import { ChatProvider } from '../Chat/Provider';
import { ChannelConnectionProvider } from './ChannelConnectionProvider';
import { ChannelSocketConnector } from './Connector';
import { ResourceAccessRole } from '../../../lib/ResourceAccessRole';

const StudioOnlyProviders = ({ children, studioId }) => {
	if (!studioId) return children;
	return (
		<VoteProvider>
			<ChatProvider studioId={studioId}>
				{children}
			</ChatProvider>
		</VoteProvider>
	);
};

StudioOnlyProviders.propTypes = {
	children: PropTypes.node.isRequired,
	studioId: PropTypes.string,
};

StudioOnlyProviders.defaultProps = {
	studioId: undefined,
};

export const ChannelSocketWrapper = ({
	children,
	hashtag,
	role,
	studioId,
}) => (
	<ChannelConnectionProvider>
		<StudioOnlyProviders studioId={studioId}>
			<ChannelSocketConnector
				hashtag={hashtag}
				role={role}
				studioId={studioId}
			/>
			{children}
		</StudioOnlyProviders>
	</ChannelConnectionProvider>
);

ChannelSocketWrapper.propTypes = {
	children: PropTypes.node.isRequired,
	hashtag: PropTypes.string.isRequired,
	role: PropTypes.oneOf(Object.values(ResourceAccessRole)),
	studioId: PropTypes.string,
};

ChannelSocketWrapper.defaultProps = {
	role: ResourceAccessRole.PUBLIC,
	studioId: undefined,
};
