import { useCallback, useEffect, useMemo } from 'react';

import { SOURCE_TYPE_PARTICIPANT } from './stream';
import { selectControllerVideoSource } from '../store/selectors/sources';
import { useMediaVideoshare } from '../components/Media/Videoshare';
import { useSelector } from '../components/ReactVideo/Provider';
import { useMixing } from '../components/ReactVideo/Mixing';

export const useLocalVideoStreaming = ({ hashtag }) => {
	const { channelMixerStatus } = useMixing();
	const controllerVideoshareSource = useSelector(
		(state) => selectControllerVideoSource(state, { hashtag }),
	);

	const {
		pauseVideo,
		playVideo,
		requestVideoshare,
		handleChangeVideoShareTimeCodes,
		stopVideoshare,
		videoshareActive,
		videoshareData,
		videoshareMediastream,
		videoshareRequestError,
	} = useMediaVideoshare();

	const isVideosharePublished = controllerVideoshareSource && videoshareActive;
	const isVideoshareOnLive = !!channelMixerStatus?.sources?.find(
		({ id }) => id === controllerVideoshareSource?.sourceId,
	);

	const shouldPlayVideoshare = isVideosharePublished && isVideoshareOnLive;
	const shouldPauseVideoshare = isVideosharePublished && isVideoshareOnLive;

	const toggleVideoshare = useCallback((event) => {
		if (videoshareActive) {
			event.preventDefault(); // Fix Firefox opens the file input on stop too
			event.stopPropagation();
			stopVideoshare();
		}
	}, [
		stopVideoshare,
		videoshareActive,
	]);

	useEffect(() => {
		if (shouldPauseVideoshare) pauseVideo();
		else if (shouldPlayVideoshare) playVideo();
	}, [pauseVideo, playVideo, shouldPauseVideoshare, shouldPlayVideoshare]);

	console.log(({
		controllerVideoshareSource,
		isVideoshareOnLive,
		isVideosharePublished,
		shouldPlayVideoshare,
		shouldPauseVideoshare,
		videoshareMediastream,
	}));

	const videoshareSource = useMemo(() => controllerVideoshareSource || {
		type: SOURCE_TYPE_PARTICIPANT,
	}, [controllerVideoshareSource]);

	return useMemo(() => ({
		handleChangeVideoShareTimeCodes,
		isVideosharePublished,
		requestVideoshare,
		toggleVideoshare,
		videoshareActive,
		videoshareData,
		videoshareMediastream,
		videoshareRequestError,
		videoshareSource,
	}), [
		handleChangeVideoShareTimeCodes,
		isVideosharePublished,
		requestVideoshare,
		toggleVideoshare,
		videoshareActive,
		videoshareData,
		videoshareMediastream,
		videoshareRequestError,
		videoshareSource,
	]);
};
