import { useCallback, useEffect, useState } from 'react';
import { useCreateSubscription, useDeleteSubscription, useDisableNotifications } from '../api-hooks/notify/notify';
import { useFetchProfile } from '../api-hooks/profile/me';
import { useWebPush } from '../components/WebPush/Provider';

const { VITE_WEB_PUSH_PUBLIC_KEY } = import.meta.env;
const LOCAL_STORAGE_KEY = 'beeyou_pwa_modal';

const isContactListsupported = () => ('contacts' in navigator && 'getProperties' in navigator.contacts);
const hasWebPushSupport = () => ('Notification' in window && 'PushManager' in window && 'serviceWorker' in navigator && navigator.serviceWorker.controller);

export const useRegisterAndSubscribe = () => {
	const { mutate: createSubscription } = useCreateSubscription();

	return useCallback(async () => {
		try {
			if (hasWebPushSupport()) {
				const registration = await navigator.serviceWorker.ready;

				const subscription = await registration.pushManager.subscribe({
					userVisibleOnly: true,
					applicationServerKey: VITE_WEB_PUSH_PUBLIC_KEY,
				});

				createSubscription(subscription);
			}
		} catch (error) {
			console.error('SW Failed to subscribe user:', error);
		}
	}, [createSubscription]);
};

export const useOpenNotificationsModal = () => {
	const { openModal } = useWebPush();
	const registerAndSubscribe = useRegisterAndSubscribe();
	const { data: profile } = useFetchProfile();
	const { mutate: createSubscription } = useCreateSubscription();

	return useCallback(async () => {
		if (!profile || profile.pushNotification === false) {
			return;
		}
		if (hasWebPushSupport()) {
			const registration = await navigator.serviceWorker.ready;
			if (!registration.active || Notification.permission === 'denied') {
				return;
			}

			const existingSubscription = await registration.pushManager.getSubscription();
			if (existingSubscription) {
				createSubscription(existingSubscription);
				return;
			}

			if (Notification.permission === 'granted') {
				registerAndSubscribe();
			} else {
				openModal();
			}
		}
	}, [profile, createSubscription, registerAndSubscribe, openModal]);
};

export const useHandleDeleteDisableSubscription = () => {
	const { mutateAsync: deleteSubscription } = useDeleteSubscription();
	const { mutateAsync: disableNotifications } = useDisableNotifications();

	return useCallback(async (disable = false) => {
		if (hasWebPushSupport()) {
			const userRegistration = await navigator.serviceWorker.ready;
			const existingSubscription = await userRegistration.pushManager.getSubscription();
			if (existingSubscription) {
				if (disable) {
					await disableNotifications(existingSubscription.endpoint);
				} else {
					await deleteSubscription(existingSubscription.endpoint);
				}
			}
		}
	}, [deleteSubscription, disableNotifications]);
};

export const useHandleBeforeinstallprompt = () => useCallback((handleSetDeferredPrompt) => {
	window.addEventListener('beforeinstallprompt', (e) => {
		e.preventDefault();
		handleSetDeferredPrompt(e);
	});
}, []);

export const useHandleInstallApp = () => useCallback(
	(handleSetDeferredPrompt, deferredPrompt, closeModal) => {
		if (deferredPrompt) {
			deferredPrompt.prompt();
			deferredPrompt.userChoice.then((choiceResult) => {
				if (choiceResult.outcome === 'accepted') {
					closeModal();
					localStorage.setItem(LOCAL_STORAGE_KEY, 3);
					handleSetDeferredPrompt(null);
				} else {
					closeModal();
				}
			});
		}
	}, [],
);

export const useIsAppAlreadyInstalled = () => {
	const [isInstalled, setIsInstalled] = useState(false);

	useEffect(() => {
		const mediaQuery = window.matchMedia('(display-mode: standalone)');
		const isPwaInstalled = (
			window.navigator.standalone
			|| (mediaQuery.media === '(display-mode: standalone)' && mediaQuery.matches)
		);
		setIsInstalled(isPwaInstalled);
	}, []);

	return isInstalled;
};

export const useAvailableProperties = () => {
	const [availableProperties, setAvailableProperties] = useState([]);

	const check = useCallback(async () => {
		if (isContactListsupported()) {
			const properties = await navigator.contacts.getProperties();

			setAvailableProperties(properties);
		}
	}, []);

	useEffect(() => {
		check();
	}, [check]);

	return availableProperties;
};
