import React, { useCallback, useEffect, useState } from 'react';
import { Alert, FormGroup, Input } from 'reactstrap';
import { Field, FieldError, Form as SchemaForm } from 'react-jsonschema-form-validation';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { useTranslation } from 'react-i18next';

import { FormLabel } from '../../Form/Label';
import { FormModal } from '../../Form/Modal';
import { ReportType, useModeration } from './Context';
import FormSubmit from '../../Form/Submit';
import { fetchAllReportCategory } from '../../../api/moderation/reportCategory';

const formSchema = {
	type: 'object',
	additionalProperties: false,
	properties: {
		reason: {
			type: 'string',
			maxLength: 2000,
		},
		category: {
			type: 'string',
		},
	},
	required: [
		'reason',
		'category',
	],
};

export const ModerationReportModal = () => {
	const { t } = useTranslation();
	const {
		reportChannel,
		reportModalData,
		reportStudio,
		reportVideo,
		closeReportModal,
	} = useModeration();

	const [data, setData] = useState({});

	const handleChange = useCallback((d) => {
		setData((state) => ({ ...state, ...d }));
	}, []);

	const handleSubmitAsync = useAsyncCallback(async () => {
		switch (reportModalData.type) {
		case ReportType.CHANNEL:
			return reportChannel(
				reportModalData.channel._id,
				data.category,
				data.reason,
			);
		case ReportType.STUDIO:
			return reportStudio(
				reportModalData.channel._id,
				data.category,
				data.reason,
				reportModalData.studio._id,
			);
		case ReportType.VIDEO:
			return reportVideo(
				reportModalData.video._id,
				data.category,
				data.reason,
			);
		default: throw TypeError('Invalid report type');
		}
	});

	const isSuccess = handleSubmitAsync.status === 'success';

	const { result: reportCategories = [] } = useAsync(async () => {
		const { data: categories } = await fetchAllReportCategory();
		return categories;
	}, []);

	const label = [ReportType.CHANNEL, ReportType.STUDIO].includes(reportModalData.type)
		? reportModalData.channel.nickname
		: reportModalData.video.title;

	useEffect(() => {
		if (isSuccess) {
			const timeout = setTimeout(() => { closeReportModal(); }, 2500);
			return () => { clearTimeout(timeout); };
		}
		return undefined;
	}, [closeReportModal, isSuccess]);

	return (
		<FormModal
			isOpen
			toggle={closeReportModal}
		>
			<h2 className="display-4 mb-5 font-weight-bold">{t('Moderation.ReportModal.Report')} &ldquo;{label}&rdquo;</h2>
			<SchemaForm
				data={data}
				onChange={handleChange}
				onSubmit={handleSubmitAsync.execute}
				schema={formSchema}
			>
				<div className="d-flex flex-column">
					<FormGroup>
						<FormLabel>{t('Moderation.ReportModal.category')}</FormLabel>
						<Field component={Input} type="select" name="category" value={data.category}>
							<option value="">{t('Moderation.ReportModal.selectCategory')}</option>
							{
								reportCategories.map((category) => (
									<option key={category.key} value={category.key}>{category.name}</option>
								))
							}
						</Field>
						<FieldError name="category" />
					</FormGroup>
					<FormGroup>
						<FormLabel>{t('Moderation.ReportModal.reason')}</FormLabel>
						<Field
							component={Input}
							type="textarea"
							name="reason"
							id="reason"
							value={data.reason}
							multiline
						/>
						<FieldError name="reason" />
					</FormGroup>
				</div>
				{isSuccess && (
					<Alert className="mt-2">{t('Moderation.ReportModal.reportSent')}</Alert>
				)}
				{handleSubmitAsync.error && (
					<Alert color="danger">
						{t('Global.error')}
					</Alert>
				)}
				<footer className="d-flex mt-4">
					<FormSubmit
						className="ml-auto mr-auto"
						disabled={handleSubmitAsync.loading || isSuccess}
						loading={handleSubmitAsync.loading}
					>
						{t('Moderation.ReportModal.sendReport')}
					</FormSubmit>
				</footer>
			</SchemaForm>
		</FormModal>
	);
};

ModerationReportModal.propTypes = {

};
