import React, {
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
} from 'react';
import PropTypes from 'prop-types';

const AppsTabProviderContext = createContext();

export const useAppsTab = () => useContext(AppsTabProviderContext);

export const AppsTabProvider = ({ children }) => {
	const [currentTab, setCurrentTab] = useState('votes');

	const setTab = useCallback((tab) => {
		setCurrentTab(tab);
	}, [setCurrentTab]);

	const contextValue = useMemo(() => ({
		setTab,
		currentTab,
	}), [
		setTab,
		currentTab,
	]);

	return (
		<AppsTabProviderContext.Provider value={contextValue}>
			{children}
		</AppsTabProviderContext.Provider>
	);
};

AppsTabProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
