import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Input, Modal, ModalBody, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { usePoints } from '../../Membership/Points';
import { ModalScreenHeader } from '../../Modal/Screen/Header';
import { UserSettingsModal, useUserSettings } from '../../UserSettings/Context';
import { MembershipModalTab } from '../../UserSettings/MembershipModalTab';
import { useStudioSchedule } from '../Context';
import './Estimation.scss';

const formatPrice = (price) => (
	price >= 1000
		? `${Math.floor(price / 1000)},${price % 1000 < 100 ? '0' : ''}${price % 1000 < 10 ? '0' : ''}${price % 1000}p`
		: `${price}p`
);

export const StudioScheduleManagerEstimation = ({
	onClose,
	closeAllModals,
	isOpen,
	formData,
}) => {
	const { t } = useTranslation();
	const { PRICES } = useStudioSchedule();
	const { balance, chipBalance, balanceWithChips } = usePoints();
	const { openModal } = useUserSettings();

	const studioCost = useMemo(
		() => (formData.isRecordEnabled ? PRICES.studioMinuteIfRecording : PRICES.studioMinute),
		[formData, PRICES],
	);
	const includeViewers = useMemo(() => formData.isPublic, [formData]);
	const [participantsCount, setParticipantsCount] = useState(1);
	const [viewersCount, setViewersCount] = useState(1);
	const [duration, setDuration] = useState(30);

	const participantsPrice = useMemo(
		() => PRICES.participantMinute * participantsCount,
		[participantsCount, PRICES],
	);

	const viewersPrice = useMemo(
		() => (includeViewers ? PRICES.viewerMinute * viewersCount : 0),
		[PRICES, viewersCount, includeViewers],
	);

	const total = useMemo(
		() => PRICES.studioStart + duration * (studioCost + viewersPrice + participantsPrice),
		[duration, participantsPrice, PRICES.studioStart, viewersPrice, studioCost],
	);
	const showError = useMemo(() => total > balanceWithChips, [total, balanceWithChips]);

	const totalShow = useMemo(() => formatPrice(total), [total]);
	const showBalance = useMemo(() => formatPrice(balance), [balance]);

	return (
		<Modal
			centered
			contentClassName="border-0 shadow-lg rounded overflow-hidden align-self-start bg-light content-light"
			isOpen={isOpen}
			size="lg"
			zIndex={2000}
			toggle={onClose}
		>
			<ModalScreenHeader
				onClose={onClose}
				title={t('Manager.Estimation.priceEstimator')}
			/>
			<ModalBody className="StudioScheduleManagerEstimation">
				<div>
					<p className="m-0 p-0">
						{t('Manager.Estimation.adjustValues')}
					</p>
					<p className="m-0 p-0">{t('Manager.Estimation.studioRate')}</p>
				</div>
				<Container className="pt-3">
					<Row className="font-size-sm content-modal-5 g-0">
						<Col className="py-1 px-0" lg="7">{t('Manager.Estimation.factor')}</Col>
						<Col className="py-1 px-0 d-flex justify-content-center" lg="1">{t('Manager.Estimation.number')}</Col>
						<Col className="py-1 px-0" lg="1" />
						<Col className="py-1 px-0 d-flex justify-content-center" lg="1">{t('Manager.Estimation.points')}</Col>
						<Col className="py-1 px-0" lg="1" />
					</Row>
					<Row className="g-0">
						<Col className="border-top border-dark py-2 px-0 d-flex align-items-center">
							{t('Manager.Estimation.studioStartCost')}
						</Col>
						<Col className="border-top border-dark py-3 px-0 d-flex align-items-center justify-content-center" lg="1">
							<span>{t('Manager.Estimation.price.once')}</span>
						</Col>
						<Col className="border-top border-dark py-3 p-0 d-flex align-items-center justify-content-center" lg="1" />
						<Col className="border-top border-dark py-3 p-0 d-flex align-items-center justify-content-center" lg="1">
							<span>{`${PRICES.studioStart}p`}</span>
						</Col>
						<Col className="border-top border-dark py-3 p-0 d-flex align-items-center justify-content-center" lg="1">=</Col>
						<Col className="py-2 px-0 bg-dark border-dark number-col" lg="1">
							<div className="w-100 h-100 d-flex align-items-center justify-content-center">
								<span>{`${PRICES.studioStart}p`}</span>
							</div>
						</Col>
					</Row>
					<Row className="g-0">
						<Col className="border-top border-dark py-2 px-0 d-flex align-items-center" lg="7">
							Participants
						</Col>
						<Col className="border-top border-dark py-2 px-0 d-flex align-items-center justify-content-center" lg="1">
							<Input value={participantsCount} onChange={(e) => setParticipantsCount(e.target.value)} min={0} max={999} type="number" step="1" />
						</Col>
						<Col className="border-top border-dark py-2 px-0 d-flex align-items-center justify-content-center" lg="1">X</Col>
						<Col className="border-top border-dark py-2 px-0 d-flex align-items-center justify-content-center" lg="1">
							<span>{formatPrice(PRICES.participantMinute)}</span>
						</Col>
						<Col className="border-top border-dark py-2 px-0 d-flex align-items-center justify-content-center" lg="1">=</Col>
						<Col className="border-top border-dark py-2 px-0 d-flex align-items-center justify-content-center bg-dark" lg="1">
							<span>{formatPrice(participantsPrice)}</span>
						</Col>
					</Row>
					{includeViewers && (
						<Row className="g-0">
							<Col className="border-top border-dark py-2 px-0 d-flex align-items-center" lg="7">
								{t('Manager.Estimation.viewers')}
							</Col>
							<Col className="border-top border-dark py-2 px-0 d-flex align-items-center justify-content-center" lg="1">
								<Input value={viewersCount} onChange={(e) => setViewersCount(e.target.value)} min={0} max={999} type="number" step="1" />
							</Col>
							<Col className="border-top border-dark py-2 px-0 d-flex align-items-center justify-content-center" lg="1">X</Col>
							<Col className="border-top border-dark py-2 px-0 d-flex align-items-center justify-content-center" lg="1">
								<span>{formatPrice(PRICES.viewerMinute)}</span>
							</Col>
							<Col className="border-top border-dark py-2 px-0 d-flex align-items-center justify-content-center" lg="1">=</Col>
							<Col className="py-2 px-0 bg-dark border-light number-col" lg="1">
								<div className="sign-wrapper">
									<span>+</span>
								</div>
								<div className="w-100 h-100 d-flex align-items-center justify-content-center">
									<span>{formatPrice(viewersPrice)}</span>
								</div>
							</Col>
						</Row>
					)}
					<Row className="g-0">
						<Col className="border-top border-dark py-2 px-0 d-flex align-items-center" lg="7">
							{t('Manager.Estimation.studioCost')}
						</Col>
						<Col className="border-top border-dark py-3 px-0 d-flex align-items-center justify-content-center" lg="1">
							<span>{t('Manager.Estimation.studioCost.perMin')}</span>
						</Col>
						<Col className="border-top border-dark py-3 p-0 d-flex align-items-center justify-content-center" lg="1" />
						<Col className="border-top border-dark py-3 p-0 d-flex align-items-center justify-content-center" lg="1">
							<span>{`${studioCost}p`}</span>
						</Col>
						<Col className="border-top border-dark py-3 p-0 d-flex align-items-center justify-content-center" lg="1">=</Col>
						<Col className="py-2 px-0 bg-dark border-light number-col" lg="1">
							<div className="sign-wrapper">
								<span>+</span>
							</div>
							<div className="w-100 h-100 d-flex align-items-center justify-content-center">
								<span>{`${studioCost}p`}</span>
							</div>
						</Col>
					</Row>
					<Row className="g-0">
						<Col className="border-top border-dark py-2 px-0 d-flex align-items-center" lg="7">
							{t('Manager.Estimation.studio.Duration')}
						</Col>
						<Col className="border-top border-dark py-2 px-0 d-flex align-items-center justify-content-center" lg="1">
							<Input value={duration} onChange={(e) => setDuration(e.target.value)} min={0} max={999} type="number" step="1" />
						</Col>
						<Col className="border-top border-dark py-2 px-0 d-flex align-items-center justify-content-center" lg="1">{t('Manager.Estimation.studio.Duration.min')}</Col>
						<Col className="border-top border-dark py-2 px-0 d-flex align-items-center justify-content-center" lg="1" />
						<Col className="border-top border-dark py-2 px-0 d-flex align-items-center justify-content-center" lg="1">=</Col>
						<Col className="py-2 px-0 bg-dark border-light number-col" lg="1">
							<div className="sign-wrapper">
								<span>x</span>
							</div>
							<div className="w-100 h-100 d-flex align-items-center justify-content-center">
								<span>{duration}</span>
							</div>
						</Col>
					</Row>
					<Row className="border-top-bold g-0">
						<Col className="py-3 px-0" lg="9">
							{showError && (
								<p className="m-0 error">
									{t('Manager.Estimation.error.actionToDo')}
									<Link
										className="ml-1 error underline"
										onClick={() => openModal(
											UserSettingsModal.MEMBERSHIP, MembershipModalTab.BUY_POINTS,
										)}
									>
										{t('Manager.Estimation.error.actionToDo.buyPoints')}
									</Link>
								</p>
							)}
							{!showError && <p className="m-0 success">{t('Manager.Estimation.balance.suffisant')}</p>}
							<p className="m-0">{`${t('Manager.Estimation.balance.amount')}: ${showBalance}${chipBalance ? ` (${chipBalance} chips)` : ''}`}</p>
						</Col>
						<Col className="py-3 px-0" lg="1" />
						<Col className="py-3 px-0 d-flex align-items-start justify-content-start font-size-xl" lg="1">{t('Manager.Estimation.total')}</Col>
						<Col className="py-3 px-0 d-flex align-items-start justify-content-center font-size-xl font-weight-bold" lg="1">{totalShow}</Col>
					</Row>
				</Container>
			</ModalBody>
		</Modal>
	);
};

StudioScheduleManagerEstimation.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	closeAllModals: PropTypes.func.isRequired,
	formData: PropTypes.shape({
		isPublic: PropTypes.bool.isRequired,
		isRecordEnabled: PropTypes.bool.isRequired,
	}).isRequired,
};

StudioScheduleManagerEstimation.defaultProps = {
};
