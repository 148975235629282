import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { FaUsers } from 'react-icons/fa';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { EditableTitle } from '../../../views/Controlroom/Settings/Library/Surveys/Tab';
import { ButtonPill } from '../../Button';
import { ModalScreenHeader } from '../../Modal/Screen/Header';
import { ModalScreen } from '../../Modal/Screen/Screen';
import { useSurveyTemplate } from '../../Survey/Providers/SurveyTemplateProvider';
import { UserSettingsModal, useUserSettings } from '../../UserSettings/Context';

export const ChannelPostSurveyModal = ({ isOpen, closeModal, onSelectSurvey }) => {
	const {
		fetchSurveyTemplates,
		handleOpenSurveyTemplateModal,
		surveyTemplates,
	} = useSurveyTemplate();

	const { t } = useTranslation();

	const { openModal } = useUserSettings();

	useEffect(() => {
		fetchSurveyTemplates();
	}, [fetchSurveyTemplates]);

	const handleOpenSurvey = useCallback((
		surveyTemplateId,
		copy,
		focusTab,
	) => {
		handleOpenSurveyTemplateModal(
			surveyTemplateId,
			copy,
			focusTab,
		);
	}, [handleOpenSurveyTemplateModal]);

	return (
		<ModalScreen
			bodyClassName="bg-dark content-white py-0"
			header={(
				<ModalScreenHeader
					onClose={closeModal}
					title="Choose a survey to post"
				/>
			)}
			isOpen={isOpen}
			onClose={closeModal}
		>
			{
				surveyTemplates?.data?.length ? (
					<>
						{
							surveyTemplates.data.map((surveyTemplate) => (!surveyTemplate.deleted ? (
								<div key={`survey-templates-list-${surveyTemplate._id}`} className="SettingsLibrarySurveysTab_row">
									<Row className="d-flex align-items-center justify-content-between p-3 w-100 m-0">
										<Col xs="8" className="p-0">
											<EditableTitle
												id={surveyTemplate._id}
												title={surveyTemplate.title}
												completed={surveyTemplate.completed}
												openSurveyTemplateModal={handleOpenSurvey}
											/>
										</Col>
										<Col xs="2" className="d-flex justify-content-center p-0">
											{surveyTemplate.completed && !surveyTemplate.deleted && (
												<span
													className="btn-wrapper--icon mr-3 text-secondary cursor-pointer"
													onClick={() => {
														onSelectSurvey(surveyTemplate);
													}}
												>
													<FaUsers /> {surveyTemplate.participantsCount}
												</span>
											)}
										</Col>
										<Col xs="2" className="d-flex justify-content-end p-0">
											{surveyTemplate.completed && !surveyTemplate.deleted && (
												<ButtonPill
													size="sm"
													className=""
													color="neutral-secondary"
													onClick={() => {
														onSelectSurvey(surveyTemplate);
													}}
												>
													Select
												</ButtonPill>
											)}
										</Col>
									</Row>
									<div className="divider w-100" />
								</div>
							) : null))
						}
					</>
				) : (
					<div className="content-dark text-center p-5 m-0">
						<p>{t('UserSettings.SurveyVoteTemplateModal.thereAreNoSurveys')}</p>
						<ButtonPill
							color="primary"
							onClick={() => openModal(UserSettingsModal.SURVEY_POLL)}
						>
							{t('UserSettings.SurveyVoteTemplateModal.createSurvey')}
						</ButtonPill>
					</div>
				)
			}

		</ModalScreen>
	);
};

ChannelPostSurveyModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeModal: PropTypes.func.isRequired,
	onSelectSurvey: PropTypes.func.isRequired,
};
