import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import ViewForbidden from '../../views/Forbidden/Forbidden';
import ViewUnauthorized from '../../views/Forbidden/Unauthorized';
import { Role, useAuthentication } from './Authentication';

const RouteIfRoleMatch = ({
	role,
	component: Component,
	...routeProps
}) => {
	const authentication = useAuthentication();

	const render = (props) => {
		if (authentication.isPending) return null; // wait for first getUser
		if (!authentication.isLoggedIn) {
			return <ViewForbidden />;
		}
		if (authentication.user.role !== role) {
			return <ViewUnauthorized />;
		}
		// eslint-disable-next-line react/jsx-props-no-spreading
		return <Component {...props} />;
	};

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <Route {...routeProps} render={render} />;
};

RouteIfRoleMatch.propTypes = {
	role: PropTypes.oneOf(Object.values(Role)).isRequired,
	component: PropTypes.elementType.isRequired,
};

export default RouteIfRoleMatch;
