import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useMixing } from '../ReactVideo/Mixing';
import { PlayerLivePiPOverlay } from './PiPOverlay';
import { useGfx } from '../Gfx/Provider';

const layerIndexRegex = /(?:pip)(\d+)$/;
const getLayerIndex = (layer) => {
	const regLayer = layerIndexRegex.exec(layer);
	return regLayer?.length === 2 ? parseInt(regLayer[1], 10) : null;
};

export const PlayerLivePiPHandler = ({ size }) => {
	const { channelMixerStatus } = useMixing();
	const { gfxInEdition } = useGfx();

	const pips = useMemo(
		() => (channelMixerStatus?.sources || []).filter((src) => src.isResizable)
			.sort((pipA, pipB) => (getLayerIndex(pipA.layer) < getLayerIndex(pipB.layer) ? 1 : -1)),
		[channelMixerStatus?.sources],
	);

	return (
		<div
			className="PlayerLivePiPHandler"
			style={{
				position: 'absolute',
				left: `${size.left}px`,
				right: `${size.left || 0}px`,
				zIndex: 4,
			}}
		>
			{channelMixerStatus && (gfxInEdition ? [gfxInEdition] : pips).map((pip) => (
				<PlayerLivePiPOverlay
					isGfxInEdition={!!gfxInEdition}
					key={pip.layer || pip.asset?._id}
					size={size}
					pip={pip}
				/>
			))}
		</div>
	);
};

PlayerLivePiPHandler.propTypes = {
	size: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
		left: PropTypes.number,
		right: PropTypes.number,
	}),
};

PlayerLivePiPHandler.defaultProps = {
	size: {},
};
