import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { ResourceAccessRole } from '../../../lib/ResourceAccessRole';
import { ResourceAccessLevel } from '../../../lib/ResourceAccessLevel';
import { Sound, useSound } from '../../Sound/Provider';

const ChannelConnectionContext = createContext({});

export const useChannelConnection = () => useContext(ChannelConnectionContext);

const getUniquePersonalKey = (c) => `${c.hashtag}-${c._id}-${c.role}`;
const getUniquePersonalKeyByRole = (c, role) => `${c.hashtag}-${c._id}-${role}`;

const cleanUpClient = (client, clients) => {
	Object.keys(ResourceAccessRole).forEach((role) => {
		clients[getUniquePersonalKeyByRole(client, role)] = (
			clients[getUniquePersonalKeyByRole(client, role)] ?? []
		).filter((c) => !c.hasLeft);
	});
};

export const ChannelConnectionProvider = ({ children }) => {
	const [allClients, setAllClients] = useState({});
	const [connectedClients, setConnectedClients] = useState([]);
	const { playSound } = useSound();
	const timeout = useRef({});

	const handleEventConnectionNew = useCallback((client) => {
		clearTimeout(timeout.current[client._id]);

		setAllClients((aClients) => {
			const nClients = { ...aClients };
			nClients[getUniquePersonalKey(client)] = [
				...(nClients[getUniquePersonalKey(client)] ?? []),
				client,
			];
			cleanUpClient(client, nClients);

			return nClients;
		});

		if (![ResourceAccessRole.PUBLIC].includes(client.role)) {
			playSound(Sound.NEW_CONNECTION);
		}
	}, [playSound]);

	const handleEventConnectionLeft = useCallback((client, forceClear = false) => {
		clearTimeout(timeout.current[client._id]);

		setAllClients((aClients) => {
			const nClients = { ...aClients };

			if (forceClear) {
				cleanUpClient(client, nClients);
			} else {
				nClients[getUniquePersonalKey(client)] = (
					nClients[getUniquePersonalKey(client)] ?? []
				).map((c) => (c.uuid === client.uuid ? ({ ...c, hasLeft: true }) : c));

				timeout.current[client._id] = setTimeout(
					() => handleEventConnectionLeft(client, true),
					500,
				);
			}

			return nClients;
		});
	}, []);

	const handleEventConnectionAll = useCallback((clients) => {
		setAllClients((aClients) => {
			const nClients = { ...aClients };

			clients.forEach((client) => {
				nClients[getUniquePersonalKey(client)] = [
					...(nClients[getUniquePersonalKey(client)] ?? []),
					client,
				];
			});

			return nClients;
		});
	}, []);

	useEffect(() => {
		if (allClients) {
			const newClients = Object.keys(allClients)
				.map((key) => allClients[key][0])
				.filter((el) => !!el);

			newClients.sort((c1, c2) => {
				if (c1.role === c2.role) {
					return c1.nickname.localeCompare(c2.nickname);
				}

				return ResourceAccessLevel[c2.role] - ResourceAccessLevel[c1.role];
			});

			setConnectedClients(newClients);
		}
	}, [allClients]);

	// clean up any timers left
	useEffect(() => () => {
		Object.keys(timeout.current).forEach((key) => {
			clearTimeout(timeout.current[key]);
		});
	}, []);

	const value = useMemo(() => ({
		connectedClients,
		handleEventConnectionNew,
		handleEventConnectionLeft,
		handleEventConnectionAll,
	}), [
		connectedClients,
		handleEventConnectionNew,
		handleEventConnectionLeft,
		handleEventConnectionAll,
	]);

	return (
		<ChannelConnectionContext.Provider value={value}>
			{children}
		</ChannelConnectionContext.Provider>
	);
};

ChannelConnectionProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
