import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Col, FormGroup, Input, Label, Progress, Row } from 'reactstrap';
import { useAsyncCallback } from 'react-async-hook';
import { useTranslation } from 'react-i18next';
import { format } from 'fecha';

import { toPercentage } from '../../../lib/number';
import { formatCountdown } from '../../../lib/time';
import { repositionVote } from '../../../api/channel/vote';
import ReallyPerfectScrollbar from '../../ReallyPerfectScrollbar/ReallyPerfectScrollbar';
import { VoteAudienceType } from './Create';
import { useVote } from '../Provider';

// 30 seconds delay + 1 for safety (since we consider the vote over with a 0.5 second margin)
const VIEWER_DELAY = 31; // In seconds

export const VoteRemainingTime = ({
	onEnd,
	timestamp,
}) => {
	const { t } = useTranslation();
	const date = useMemo(() => new Date(timestamp), [timestamp]);

	const [now, setNow] = useState(new Date());

	const value = useMemo(() => {
		if (date <= now) {
			return t('Votes.Preview.endedOn', {
				date: format(new Date(date), t('Global.DateFormat.onDateAtTime')),
			});
		}

		return formatCountdown(now, date);
	}, [date, now, t]);

	useEffect(() => {
		let timeout = null;
		if (date >= now) {
			timeout = setTimeout(() => setNow(new Date()), 1000);
		} else if (onEnd) {
			onEnd();
		}

		return () => clearTimeout(timeout);
	});

	return value;
};

VoteRemainingTime.propTypes = {
	onEnd: PropTypes.func,
	timestamp: PropTypes.string.isRequired,
};

VoteRemainingTime.defaultProps = {
	onEnd: null,
};

export const VotePreview = ({ vote, onClose, onPublish, onEndPublish, onEnd }) => {
	const { t } = useTranslation();
	const { activeVote } = useVote();
	// TODO: socket support
	const isVoteOver = vote && vote.endedAt
		&& (new Date() - new Date(vote.endedAt) > -500);

	const [currentPosition, setCurrentPosition] = useState(vote?.position ?? 'top');
	useEffect(() => {
		if (vote) {
			setCurrentPosition((prevPos) => (prevPos !== vote.position ? vote.position : prevPos));
		}
	}, [vote, setCurrentPosition]);

	const isVoteForViewers = vote.audienceType === VoteAudienceType.VIEWER;
	const voteEndedSince = (Date.now() - new Date(vote.endedAt)) / 1000; // In seconds

	// We add a 30 seconds delay if the vote is for viewers
	const [
		timeLeftBeforePublishable,
		setTimeLeftBeforePublishable,
	] = useState(voteEndedSince > VIEWER_DELAY ? 0 : VIEWER_DELAY);
	useEffect(() => {
		if (isVoteOver && isVoteForViewers && voteEndedSince < VIEWER_DELAY) {
			const interval = setInterval(() => {
				const timeLeft = Math.floor(
					(VIEWER_DELAY * 1000 - (Date.now() - new Date(vote.endedAt))) / 1000,
				);
				if (timeLeft <= 0) {
					clearInterval(interval);
					setTimeLeftBeforePublishable(0);
					return;
				}
				setTimeLeftBeforePublishable(timeLeft);
			}, 1000);
			return () => clearInterval(interval);
		}
		return () => {};
	}, [setTimeLeftBeforePublishable, vote.endedAt, voteEndedSince, isVoteOver, isVoteForViewers]);
	const waitForViewers = isVoteForViewers
		&& timeLeftBeforePublishable > 0
		&& timeLeftBeforePublishable < VIEWER_DELAY;

	const handleEndVote = useAsyncCallback(onEnd);
	if (handleEndVote.error) console.error(handleEndVote.error);

	const handlePublishVote = useAsyncCallback(onPublish);
	if (handlePublishVote.error) console.error(handlePublishVote.error);

	const handleRepositionVote = useAsyncCallback(repositionVote);
	if (handleRepositionVote.error) console.error(handleRepositionVote);

	const handleRepositionClick = useCallback((position) => {
		handleRepositionVote.execute(vote._id, position);
		setCurrentPosition(position);
	}, [vote, handleRepositionVote, setCurrentPosition]);

	// TODO: ACKO Test more thoroughly
	useEffect(() => () => {
		if (vote) {
			onEndPublish(vote._id);
		}
	}, [onEndPublish, vote]);

	return vote ? (
		<>
			<ReallyPerfectScrollbar>
				<section className="flex-grow-1">
					<Label className="text-secondary">{t('Votes.Preview.history')}</Label>
					<p className="mb-4">{vote.title}</p>
					<Label className="text-secondary">{t('Votes.Preview.results')}</Label>
					<div className="mb-4">
						{vote.options.map((option) => (
							<Fragment key={option._id}>
								<small>{option.label}</small>
								<Progress multi className="bg-transparent rounded-0">
									<Progress
										bar
										value={(option.percentage ?? 0) <= 0.8 ? option.percentage * 100 : 80}
										// eslint-disable-next-line no-nested-ternary
										color={option._id === vote?.highestAnswer?._id
											? vote.audienceType === VoteAudienceType.VIEWER
												? 'success'
												: 'primary'
											: 'secondary'}
									/>
									<Progress
										bar
										value={20}
										barClassName="content-darker"
										color="transparent"
									>
										{toPercentage(option.percentage || 0)}
									</Progress>
								</Progress>
							</Fragment>
						))}
					</div>
					<Label className="text-secondary mb-0">{t('Votes.Preview.remainingTime')}</Label>
					<p><VoteRemainingTime timestamp={vote.endedAt} /></p>
					<Label className="text-secondary mb-0">{t('Votes.Preview.publishedAt')}</Label>
					<p>{format(new Date(vote.endedAt), t('Global.DateFormat.onDateAtTime'))}</p>
					<Label className="text-secondary mb-0">{t('Votes.Preview.numberOfReplies')}</Label>
					<p>{vote.totalParticipantCount}</p>
					{!isVoteOver && !isVoteForViewers && ( // Viewer vote cant be repositioned (due to delay)
						<>
							<Label className="text-secondary">{t('Votes.Preview.position')}</Label>
							<div className="overflow-hidden">
								<Row>
									<Col sm={3}>
										<FormGroup check>
											<Input
												name="position"
												id="positionRadioTop"
												type="radio"
												label={t('Votes.Preview.position.top')}
												value="top"
												checked={currentPosition === 'top'}
												onChange={() => handleRepositionClick('top')}
												disabled={handleRepositionVote.loading}
											/>
											<Label check for="positionRadioTop">
												{t('Votes.Preview.position.top')}
											</Label>
										</FormGroup>
									</Col>
									<Col sm={3}>
										<FormGroup check>
											<Input
												name="position"
												id="positionRadioMid"
												type="radio"
												label={t('Votes.Preview.position.mid')}
												value="mid"
												checked={currentPosition === 'mid'}
												onChange={() => handleRepositionClick('mid')}
												disabled={handleRepositionVote.loading}
											/>
											<Label check for="positionRadioMid">
												{t('Votes.Preview.position.mid')}
											</Label>
										</FormGroup>
									</Col>
									<Col sm={3}>
										<FormGroup check>
											<Input
												name="position"
												id="positionRadioBot"
												type="radio"
												label={t('Votes.Preview.position.bot')}
												value="bot"
												checked={currentPosition === 'bot'}
												onChange={() => handleRepositionClick('bot')}
												disabled={handleRepositionVote.loading}
											/>
											<Label check for="positionRadioBot">
												{t('Votes.Preview.position.bot')}
											</Label>
										</FormGroup>
									</Col>
								</Row>
							</div>
						</>
					)}
					{handlePublishVote.status === 'success' && (
						<Alert color="success" className="mt-4">
							{t('Votes.Preview.success.reports')}
						</Alert>
					)}
					{(handleEndVote.error || handlePublishVote.error || handleRepositionVote.error) && (
						<Alert color="danger" className="mt-4">
							{t('Global.error')}
						</Alert>
					)}
				</section>
			</ReallyPerfectScrollbar>
			<footer className="mt-2">
				{isVoteOver ? (
					<>
						{waitForViewers && (
							<p>{t('Votes.Preview.waitForViewersToVote', { timeRemaining: timeLeftBeforePublishable })}</p>
						)}
						{activeVote && vote._id !== activeVote._id && (
							<p>{t('Votes.Preview.anotherVoteIngoing')}</p>
						)}
						<div>
							<Button
								color="neutral-secondary"
								className="btn-pill mr-2"
								onClick={onClose}
								disabled={handlePublishVote.loading}
							>
								{t('Votes.Preview.success.close')}
							</Button>
							<Button
								color="primary"
								className="btn-pill"
								disabled={handlePublishVote.loading || !!activeVote || waitForViewers}
								onClick={() => handlePublishVote.execute(vote._id)}
							>
								{t('Votes.Preview.success.publishResults')}
							</Button>
						</div>
					</>
				) : (
					<Button
						color="neutral-secondary"
						className="btn-pill"
						onClick={() => handleEndVote.execute(vote._id)}
						disabled={handleEndVote.loading}
					>
						{t('Votes.Preview.success.endNow')}
					</Button>
				)}
			</footer>
		</>
	) : null;
};

VotePreview.propTypes = {
	vote: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		audienceType: PropTypes.oneOf(Object.values(VoteAudienceType)).isRequired,
		title: PropTypes.string.isRequired,
		options: PropTypes.arrayOf(PropTypes.shape({
			_id: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
			percentage: PropTypes.number.isRequired,
		})).isRequired,
		highestAnswer: PropTypes.shape({
			_id: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
			percentage: PropTypes.number.isRequired,
		}),
		publishedAt: PropTypes.string.isRequired,
		endedAt: PropTypes.string.isRequired,
		position: PropTypes.oneOf(['top', 'bot', 'mid']),
		totalParticipantCount: PropTypes.number.isRequired,
	}),
	onClose: PropTypes.func.isRequired,
	onPublish: PropTypes.func.isRequired,
	onEndPublish: PropTypes.func.isRequired,
	onEnd: PropTypes.func.isRequired,
};

VotePreview.defaultProps = {
	vote: null,
};
