import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FaCheck } from 'react-icons/fa';

import RatioContainer from '../RatioContainer/RatioContainer';
import './Image.scss';

const PlayerImage = ({ className, onRemove, imageId, ...props }) => (
	<RatioContainer
		className={
			clsx(
				'PlayerImage',
				className,
			)
		}
	>
		{onRemove && (
			<FaCheck
				className="position-absolute right-0 p-1 cursor-pointer"
				onClick={() => onRemove(imageId)}
			/>
		)}
		<img alt="Player" className="PlayerImage_img" {...props} />
	</RatioContainer>
);

PlayerImage.propTypes = {
	className: PropTypes.string,
	imageId: PropTypes.string,
	onRemove: PropTypes.func,
};

PlayerImage.defaultProps = {
	className: '',
	onRemove: undefined,
	imageId: undefined,
};

export default PlayerImage;
