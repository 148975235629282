import React, { lazy, useState } from 'react';
import { FaChartBar } from 'react-icons/fa';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { useProfile } from '../../Profile/ProfileContext';
import { AnalyticsProvider } from './Analytics/Provider';

import './Content.scss';

const AnalyticsContent = lazy(() => import('./Analytics/Content'));

export const AnalyticsAndAdvertisingMenuItems = {
	ANALYTICS: 'ANALYTICS',
	ADVERTISING: 'ADVERTISING',
};

export const AnalyticsAndAdvertisingContent = () => {
	const { t } = useTranslation();
	const [activeMenuItem, setActiveMenuItem] = useState(AnalyticsAndAdvertisingMenuItems.ANALYTICS);
	const { profile } = useProfile();

	return (
		<Row className="h-100 flex-column flex-md-row">
			<Col className="p-0 pt-4 AnalyticsAndAdvertisingSidebar bg-dark" md={2} sm={12}>
				<div className="pr-2">
					<p
						className={
							`m-0 text-secondary cursor-pointer pl-3 py-2 ${activeMenuItem === AnalyticsAndAdvertisingMenuItems.ANALYTICS ? 'ActiveMenuItem text-black' : ''}`
						}
						onClick={() => setActiveMenuItem(AnalyticsAndAdvertisingMenuItems.ANALYTICS)}
					>
						<span className="btn-wrapper--icon mr-2">
							<FaChartBar />
						</span>
						{t('AnalyticsAndAdvertising.Content.myAnalytics')}
					</p>
				</div>
			</Col>
			<Col className="bg-dark" md={10} sm={12}>
				{activeMenuItem === AnalyticsAndAdvertisingMenuItems.ANALYTICS && (
					<AnalyticsProvider>
						<AnalyticsContent channelId={profile._id} />
					</AnalyticsProvider>
				)}
			</Col>
		</Row>
	);
};
