import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';

import SidebarMenu from '../SidebarMenu';
import { useSidebar } from './Provider';

const Sidebar = ({ isStudioRoute }) => {
	const { toggleMobile, toggleSidebarMobile, hideSidebarMenu } = useSidebar();

	return (
		<>
			<div className="app-sidebar bg-light content-light">
				<div className="app-sidebar--content">
					<SidebarMenu
						toggleSidebarMobile={toggleSidebarMobile}
						isStudioRoute={isStudioRoute}
						hideSidebarMenu={hideSidebarMenu}
					/>
				</div>
			</div>
			<div
				onClick={toggleSidebarMobile}
				className={clsx('app-sidebar-overlay', {
					'is-active': toggleMobile,
				})}
			/>
		</>
	);
};

Sidebar.propTypes = {
	isStudioRoute: PropTypes.bool,
};

Sidebar.defaultProps = {
	isStudioRoute: false,
};

export default Sidebar;
