import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const PlayerModeContext = React.createContext({});
export const usePlayerMode = () => useContext(PlayerModeContext);

export const PlayerModes = {
	FLOATING_PIP: 'FLOATING_PIP',
	DRAW: 'DRAW',
	CROP: 'CROP',
};

export const PlayerModeProvider = ({
	children,
	control,
}) => {
	const [modeState, setMode] = useState();
	const defaultMode = control ? PlayerModes.FLOATING_PIP : undefined;
	const mode = modeState || defaultMode;

	const contextValue = useMemo(() => ({
		mode,
		modeState,
		setMode,
	}), [
		mode,
		modeState,
		setMode,
	]);

	return (
		<PlayerModeContext.Provider value={contextValue}>
			{children}
		</PlayerModeContext.Provider>
	);
};

PlayerModeProvider.propTypes = {
	children: PropTypes.node.isRequired,
	control: PropTypes.bool,
};

PlayerModeProvider.defaultProps = {
	control: false,
};
