import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { MediaUser } from './User';
import { MediaScreenshare } from './Screenshare';
import { MediaVideoshare } from './Videoshare';
import { Resolution } from './utils';
import { MediaKey } from './MediaKey/MediaKey';
import { useInputs } from '../Inputs';

export const MediaContext = createContext();

export const useMedia = () => useContext(MediaContext);

export const Media = ({
	children,
	disabled,
	isHost,
	resolution,
	user,
}) => {
	const { setEnabled: setInputsEnabled } = useInputs();
	const [enabled, setEnabled] = useState(!disabled);

	const contextValue = useMemo(() => ({
		enabled,
		setEnabled,
	}), [enabled]);

	const previousDisabledValue = useRef(disabled);
	useEffect(() => {
		if (previousDisabledValue.current === disabled) return; // Avoid first render override
		setEnabled(!disabled);
		previousDisabledValue.current = disabled;
	}, [disabled]);

	// Sync Media.enabled and Inputs.enabled
	useEffect(() => {
		setInputsEnabled(enabled);
	}, [enabled, setInputsEnabled]);

	// Disabled input when Media is unmounted
	useEffect(() => () => {
		setInputsEnabled(false);
	}, [setInputsEnabled]);

	return (
		<MediaContext.Provider value={contextValue}>
			<MediaScreenshare
				disabled={!enabled}
				resolution={resolution}
			>
				<MediaVideoshare
					disabled={!enabled}
					isHost={isHost}
					resolution={resolution}
				>
					<MediaUser
						allowAudio={enabled}
						isHost={isHost}
						allowVideo={enabled}
						resolution={resolution}
					>
						<MediaKey
							isHost={isHost}
							user={user}
						>
							{children}
						</MediaKey>
					</MediaUser>
				</MediaVideoshare>
			</MediaScreenshare>
		</MediaContext.Provider>
	);
};

Media.propTypes = {
	children: PropTypes.node.isRequired,
	disabled: PropTypes.bool,
	isHost: PropTypes.bool,
	resolution: PropTypes.oneOf(Object.values(Resolution)),
	user: PropTypes.shape({}),
};

Media.defaultProps = {
	disabled: false,
	isHost: false,
	resolution: Resolution.P720,
	user: undefined,
};
