import { authenticatedApi } from '../api';

const API_MODERATION_REPORT_PATH = '/moderation/report';

export const reportVideo = async (
	id,
	category,
	reason,
) => authenticatedApi.post(
	`${API_MODERATION_REPORT_PATH}/video/${id}`,
	{ reason, category },
);

export const reportChannel = async (
	id,
	category,
	reason,
	studio,
) => authenticatedApi.post(
	`${API_MODERATION_REPORT_PATH}/channel/${id}`,
	{ reason, category, studio },
);
