import React, { useContext } from 'react';

export const StudioScheduleContext = React.createContext();

export const useStudioSchedule = () => useContext(StudioScheduleContext);

export const StudioScheduleModal = {
	EDIT: 'EDIT',
	MANAGE: 'MANAGE',
	CALENDAR: 'CALENDAR',
};

export const StudioScheduleManagerTab = {
	EDIT: 'EDIT',
	EDIT_BANNER: 'EDIT_BANNER',
};
