import React, { useMemo } from 'react';
import PropTypes, { } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Hexagon from '../../Hexagon/Hexagon';
import { getFileUrl, AVATAR_SIZE } from '../../../lib/file';
import defaultAvatar from '../../../images/default-avatar.png';

export const SurveyModalTitle = ({ title, surveyTitle, avatar }) => {
	const { t } = useTranslation();
	const avatarFromPath = useMemo(() => (
		avatar
			? getFileUrl({ name: avatar }, AVATAR_SIZE.width, AVATAR_SIZE.height)
			: defaultAvatar),
	[avatar]);
	return (
		<div className="d-flex p-0 m-0 align-items-center">
			{avatar && (
				<div className="avatar-icon-wrapper mr-2">
					<Hexagon className="avatar-icon shadow-none">
						<img alt={t('Modals.ModalTitle.avatar')} src={avatarFromPath} />
					</Hexagon>
				</div>
			)}
			<div>
				{surveyTitle && <p className="font-size-sm text-secondary m-0 p-0">{t('Modals.ModalTitle.sessionName')}: {surveyTitle}</p>}
				{title}
			</div>
		</div>
	);
};

SurveyModalTitle.propTypes = {
	title: PropTypes.string.isRequired,
	surveyTitle: PropTypes.string,
	avatar: PropTypes.string,
};

SurveyModalTitle.defaultProps = {
	avatar: undefined,
	surveyTitle: undefined,
};
