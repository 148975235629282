import { authenticatedApi } from '../api';

const API_STUDIOS_PATH = '/studios';

export const fetchActiveStudio = async () => authenticatedApi.get(
	`${API_STUDIOS_PATH}/active`,
);

export const requestStudioByCode = async (code, requiredAccess, forceJoin) => authenticatedApi.post(
	`${API_STUDIOS_PATH}/request/bycode/${code}`,
	{ requiredAccess },
	{ params: { forceJoin: forceJoin ? 'true' : 'false' } },
);

export const fetchStudioByCode = async (code) => authenticatedApi.get(
	`${API_STUDIOS_PATH}/bycode/${code}`,
);

export const fetchStudioOverview = async (studioId) => authenticatedApi.get(
	`${API_STUDIOS_PATH}/${studioId}/overview`,
);

export const stopStudio = async (id) => authenticatedApi.delete(
	`${API_STUDIOS_PATH}/${id}`,
);

export const getStudioParticipants = async (id) => authenticatedApi.get(
	`${API_STUDIOS_PATH}/${id}/participants`,
);

export const fetchStudioConfig = async (id) => authenticatedApi.get(
	`${API_STUDIOS_PATH}/config/${id}`,
);

export const fetchStudioSummary = async (id) => authenticatedApi.get(
	`${API_STUDIOS_PATH}/${id}/summary`,
);

export const fetchStudioInvitations = async (id) => authenticatedApi.get(
	`${API_STUDIOS_PATH}/${id}/summary/invitations`,
);

export const fetchStudioMessages = async (id) => authenticatedApi.get(
	`${API_STUDIOS_PATH}/${id}/summary/messages`,
);

export const fetchStudioRecordings = async (id) => authenticatedApi.get(
	`${API_STUDIOS_PATH}/${id}/summary/recordings`,
);

export const fetchStudioParticipants = async (id) => authenticatedApi.get(
	`${API_STUDIOS_PATH}/${id}/summary/participants`,
);

export const fetchStudioViewers = async (id) => authenticatedApi.get(
	`${API_STUDIOS_PATH}/${id}/summary/viewers`,
);

export const fetchStudioPricing = async (id) => authenticatedApi.get(
	`${API_STUDIOS_PATH}/${id}/summary/pricing`,
);

export const fetchEstimateData = async () => authenticatedApi.get(
	`${API_STUDIOS_PATH}/estimate`,
);

export const fetchStudioFeatures = async (id) => authenticatedApi.get(
	`${API_STUDIOS_PATH}/${id}/features`,
);

export const updateStudioAccess = async (id, access) => authenticatedApi.put(
	`${API_STUDIOS_PATH}/access/${id}`,
	{
		...access,
	},
);

export const updateControllerJoined = async (id) => authenticatedApi.put(`${API_STUDIOS_PATH}/controllerjoined/${id}`);

export const golive = async (id, startTime) => authenticatedApi.post(
	`${API_STUDIOS_PATH}/golive/${id}`,
	{ startTime },
);

export const stoplive = async (id) => authenticatedApi.post(
	`${API_STUDIOS_PATH}/stoplive/${id}`,
);

export const fetchStudioPresaleTickets = async (studioId) => authenticatedApi.get(
	`${API_STUDIOS_PATH}/${studioId}/presale/tickets/`,
);

export const refundStudioPresaleTicket = async ({ studioId, transactionId }) => authenticatedApi
	.post(
		`${API_STUDIOS_PATH}/${studioId}/presale/tickets/refund`,
		{ transactionId },
	);

export const answerStudioAttendace = async ({ studioId, attendanceId, answer }) => authenticatedApi
	.post(
		`${API_STUDIOS_PATH}/${studioId}/attendance/${attendanceId}/answer`,
		{ answer },
	);
