import {
	ADD_STREAM_PUBLICATION,
	CLEAR_STREAM_PUBLICATION,
	REMOVE_STREAM_PUBLICATION,
} from '../actions/publications';
import { getIdFromPublication } from '../lib/helpers';

export { STATE_KEY } from '../selectors/publications';

const initialState = [];

const publicationsReducer = (state = initialState, action) => {
	switch (action.type) {
	case ADD_STREAM_PUBLICATION: {
		const publication = action.payload;
		const {
			appData,
			hashtag,
			peerId,
			producerId,
		} = publication;
		const {
			streamType,
			user,
		} = appData;
		let streamIndex = -1;

		const id = getIdFromPublication(publication);

		let stream = state.find((s, i) => {
			if (
				s.id === id
			) {
				streamIndex = i;
				return true;
			}
			return false;
		});

		stream = stream ?? {
			hashtag,
			id,
			peerId,
			streamType,
			publications: [],
			user,
		};

		const publications = stream.publications.filter((pub) => pub.producerId !== producerId);

		stream = {
			...stream,
			publications: [...publications, publication],
		};

		if (streamIndex > -1) {
			state = [
				...state.slice(0, streamIndex),
				stream,
				...state.slice(streamIndex + 1),
			];
		} else {
			state = [...state, stream];
		}

		return state;
	}
	case REMOVE_STREAM_PUBLICATION: {
		const publication = action.payload;
		const {	producerId } = publication;
		let streamIndex;

		const id = getIdFromPublication(publication);

		let stream = state.find((s, i) => {
			if (
				s.id === id
				&& s.publications.find((pub) => pub.producerId === producerId)
			) {
				streamIndex = i;
				return true;
			}
			return false;
		});

		if (!stream) return state;

		stream = {
			...stream,
			publications: stream.publications.filter((pub) => pub.producerId !== producerId),
		};

		if (stream.publications.length < 1) {
			state = [
				...state.slice(0, streamIndex),
				...state.slice(streamIndex + 1),
			];
		} else {
			state = [
				...state.slice(0, streamIndex),
				stream,
				...state.slice(streamIndex + 1),
			];
		}

		return state;
	}
	case CLEAR_STREAM_PUBLICATION:
		return initialState;
	default:
		return state;
	}
};

export default publicationsReducer;
