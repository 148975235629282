import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Container, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { ModalScreenHeader, ModalScreenHeaderNav } from '../../Modal/Screen/Header';
import { ModalScreen } from '../../Modal/Screen/Screen';
import { useModeration } from './Context';
import { ReportActionsHistory } from './ReportActionsHistory';
import { ReportDetailsOverview } from './ReportDetailsOverview';

const ReportDetailsModalTab = {
	DETAILS: 'DETAILS',
	ACTIONS: 'ACTIONS',
};

export const ModerationReportDetailsModal = ({ report, type }) => {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState(ReportDetailsModalTab.DETAILS);
	const { closeReportDetailsModal } = useModeration();

	return (
		<ModalScreen
			bodyClassName="p-0 bg-dark"
			isOpen
			onClose={closeReportDetailsModal}
			size="lg"
			header={(
				<ModalScreenHeader
					onClose={closeReportDetailsModal}
					title={t('ReportDetailsModal.report')}
				>
					<ModalScreenHeaderNav className="mt-3">
						<NavItem>
							<NavLink
								className={clsx('text-center', { active: activeTab === ReportDetailsModalTab.DETAILS })}
								onClick={() => { setActiveTab(ReportDetailsModalTab.DETAILS); }}
							>
								{t('ReportDetailsModal.details')}
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="mx-4 flex-grow-0">
							<NavLink
								className={clsx('justify-content-center', { active: activeTab === ReportDetailsModalTab.ACTIONS })}
								onClick={() => { setActiveTab(ReportDetailsModalTab.ACTIONS); }}
							>
								{t('ReportDetailsModal.actions')}
								<div className="divider" />
							</NavLink>
						</NavItem>
					</ModalScreenHeaderNav>
				</ModalScreenHeader>
			)}
		>
			<TabContent activeTab={activeTab}>
				<TabPane tabId={ReportDetailsModalTab.DETAILS}>
					{activeTab === ReportDetailsModalTab.DETAILS && (
						<Container fluid className="p-2">
							{report && (
								<ReportDetailsOverview report={report} type={type} />
							)}
						</Container>
					)}
				</TabPane>
				<TabPane tabId={ReportDetailsModalTab.ACTIONS}>
					<Container fluid className="p-2">
						{report && (
							<ReportActionsHistory
								report={report}
								type={type}
							/>
						)}
					</Container>
				</TabPane>
			</TabContent>
		</ModalScreen>
	);
};

ModerationReportDetailsModal.propTypes = {
	report: PropTypes.shape({
		_id: PropTypes.string,
	}),
	type: PropTypes.string,
};

ModerationReportDetailsModal.defaultProps = {
	report: undefined,
	type: undefined,
};
