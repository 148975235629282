import React, { useCallback } from 'react';
import { Button } from 'reactstrap';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';

import { useLogin } from '../../components/Login/Provider';

const HeaderAnonymousBox = () => {
	const { openLogin, openSignup } = useLogin();
	const { t } = useTranslation();

	const handleClickSignUp = useCallback((params) => {
		ReactGA.event({ category: 'signup', action: 'click', label: 'User clicked on Sign Up button in Header' });
		openSignup(params);
	}, [openSignup]);
	return (
		<>
			<Button
				className="transition-none flex-shrink-0 px-0"
				color="link"
				onClick={() => openLogin({ showRoutingModalAfterLogin: true })}
				size="sm"
			>
				{t('HeaderAnonymousBox.Index.singIn')}
			</Button>
			<Button
				color="neutral-primary"
				className="ml-2 transition-none flex-shrink-0"
				onClick={() => handleClickSignUp(
					{
						showRoutingModalAfterLogin: true,
						showIntroModalAfterLogin: true,
					},
				)}
				size="sm"
			>
				{t('HeaderAnonymousBox.Index.singUn')}
			</Button>
		</>
	);
};

export default HeaderAnonymousBox;
