import React,
{
	createContext,
	useContext,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import { useInputs } from '../Inputs/Context';
import { MediaUserPermissionModal } from './UserPermissionModal';
import { MediaUserPermissionStatus } from './UserAccessStatus';
import { Resolution, stopTrack } from './utils';
import { MediaUserPerform } from './UserPerformer';

/*
	My advice: don't touch it unless this is already the end of world.
	And if so, keep in mind that Safari won't fight on your side...
*/

export const MediaUserContext = createContext();

export const useMediaUser = () => useContext(MediaUserContext);

// eslint-disable-next-line prefer-arrow-callback
export const MediaUser = React.memo(function MediaUser({
	allowAudio,
	allowVideo,
	children,
	resolution,
}) {
	const inputs = useInputs();
	const {
		activateAudioInput,
		activateVideoInput,
		deactivateAudioInput,
		deactivateVideoInput,
		inputsConfig,
	} = inputs;

	const [userAudioRequestErrors, setUserAudioRequestErrors] = useState([]);
	const [userVideoRequestErrors, setUserVideoRequestErrors] = useState([]);

	const [userAudioPromptStatus, setUserAudioPromptStatus] = useState(
		MediaUserPermissionStatus.INITIAL,
	);
	const [userVideoPromptStatus, setUserVideoPromptStatus] = useState(
		MediaUserPermissionStatus.INITIAL,
	);

	const [userAudioActiveTracks, setUserAudioActiveTracks] = useState([]);
	const [userVideoActiveTracks, setUserVideoActiveTracks] = useState([]);
	const userActiveTracks = useMemo(
		() => [...userAudioActiveTracks, ...userVideoActiveTracks],
		[userAudioActiveTracks, userVideoActiveTracks],
	);

	const setUserAudioRequestErrorByConfigId = useCallback((error, configId) => {
		setUserAudioRequestErrors((prevState) => (
			[
				...prevState.filter((request) => request.configId !== configId),
				{ configId, error },
			]
		));
	}, []);

	const setUserVideoRequestErrorByConfigId = useCallback((error, configId) => {
		setUserVideoRequestErrors((prevState) => (
			[
				...prevState.filter((request) => request.configId !== configId),
				{ configId, error },
			]
		));
	}, []);

	const addTracks = useCallback((tracks, configId, kind) => {
		if (kind === 'audio') {
			setUserAudioActiveTracks((state) => [
				...(state.filter((t) => t.configId !== configId) || []),
				...tracks,
			]);
		} else if (kind === 'video') {
			setUserVideoActiveTracks((state) => [
				...(state.filter((t) => t.configId !== configId) || []),
				...tracks,
			]);
		} else {
			throw new Error(`addTracks: Unknown kind '${kind}'`);
		}
	}, [setUserVideoActiveTracks]);

	const removeTracksByConfigId = useCallback((configId, kind) => {
		if (kind === 'audio' || !kind) {
			setUserAudioActiveTracks(
				(prevState) => prevState.filter((track) => track.configId !== configId),
			);
		} else if (kind === 'video' || !kind) {
			setUserVideoActiveTracks(
				(prevState) => prevState.filter((track) => track.configId !== configId),
			);
		} else {
			throw new Error(`addTracks: Unknown kind '${kind}'`);
		}
	}, [setUserVideoActiveTracks]);

	const getIsUserAudioActive = useCallback(
		(configId) => !!(userAudioActiveTracks || []).find((track) => (
			track.configId === configId
		)),
		[userAudioActiveTracks],
	);
	const getIsUserVideoActive = useCallback(
		(configId) => !!(userVideoActiveTracks || []).find((track) => (
			track.configId === configId
		)),
		[userVideoActiveTracks],
	);

	const useMediastreamsRef = useRef([]); // memoize mediastreams

	const userMediastreams = useMemo(() => {
		if (userActiveTracks.length > 0) {
			const mediaStreams = inputsConfig.map((cfg) => {
				const tracks = userActiveTracks.filter((track) => track.configId === cfg.id);
				if (tracks.length <= 0) return undefined;

				const memoizedMediastream = useMediastreamsRef.current.find((m) => m.configId === cfg.id);
				const memoizedMediastreamTracks = memoizedMediastream?.getTracks() || [];
				if (
					tracks.length === memoizedMediastreamTracks?.length
					&& tracks.every((track) => memoizedMediastreamTracks.includes(track))
				) return memoizedMediastream; // return memoized mediastream if tracks are the same

				// Refresh mediastream when tracks change to avoid player image stuck
				const newMediaStream = new MediaStream(tracks);
				newMediaStream.configId = cfg.id;
				return newMediaStream;
			}).filter(Boolean); // remove undefined mediastreams (if no tracks per configId)

			useMediastreamsRef.current = mediaStreams;
			return mediaStreams;
		}
		return undefined;
	}, [userActiveTracks, inputsConfig]);

	const deleteConfigTracks = useCallback((configId) => {
		setUserAudioActiveTracks((prevState) => [
			...prevState.filter((track) => track.configId !== configId),
		]);
		setUserVideoActiveTracks((prevState) => [
			...prevState.filter((track) => track.configId !== configId),
		]);
	}, []);

	const isVideoAllowed = allowVideo;

	const userActiveTracksRef = useRef(userActiveTracks);
	useEffect(() => { userActiveTracksRef.current = userActiveTracks; }, [userActiveTracks]);

	const resolutionRef = useRef(resolution);
	useEffect(() => { resolutionRef.current = resolution; }, [resolution]);

	useEffect(() => {
		const handleTrackEnded = ({ target: track }) => {
			track.removeEventListener('trackended', handleTrackEnded);
			if (track.kind === 'audio') setUserAudioActiveTracks((state) => state.filter((t) => t !== track));
			if (track.kind === 'video') setUserVideoActiveTracks((state) => state.filter((t) => t !== track));
		};

		userActiveTracks.forEach((track) => {
			track.addEventListener('ended', handleTrackEnded);
		});

		return () => {
			userActiveTracks.forEach((track) => {
				track.removeEventListener('ended', handleTrackEnded);
			});
		};
	}, [userActiveTracks]);

	const enableAudio = useCallback((configId) => {
		if (allowAudio) {
			activateAudioInput(configId);
		}
	}, [
		activateAudioInput,
		allowAudio,
	]);

	const enableVideo = useCallback((configId) => {
		if (isVideoAllowed) {
			activateVideoInput(configId);
		}
	}, [
		activateVideoInput,
		isVideoAllowed,
	]);

	const disableAudio = useCallback((configId) => {
		deactivateAudioInput(configId);
	}, [deactivateAudioInput]);

	const disableVideo = useCallback((configId) => {
		deactivateVideoInput(configId);
	}, [deactivateVideoInput]);

	const toggleAudio = useCallback((configId) => {
		if (getIsUserAudioActive(configId)) disableAudio(configId);
		else enableAudio(configId);
	}, [disableAudio, enableAudio, getIsUserAudioActive]);

	const toggleVideo = useCallback((configId) => {
		if (getIsUserVideoActive(configId)) disableVideo(configId);
		else enableVideo(configId);
	}, [disableVideo, enableVideo, getIsUserVideoActive]);

	const stopConfigTracks = useCallback((configId, kind) => {
		let configTracks = (userActiveTracks || []).filter((track) => track.configId === configId);
		if (kind) {
			configTracks = configTracks.filter((track) => track.kind === kind);
		}
		configTracks.forEach((t) => { stopTrack(t); });
	}, [userActiveTracks]);

	// cleanup
	useEffect(() => () => {
		userActiveTracksRef.current.forEach(stopTrack);
	}, []);

	const value = useMemo(() => ({
		addTracks,
		deleteConfigTracks,
		getIsUserAudioActive,
		getIsUserVideoActive,
		removeTracksByConfigId,
		toggleAudio,
		toggleVideo,
		userActiveTracks,
		userAudioActiveTracks,
		userAudioPromptStatus,
		userAudioRequestErrors,
		userMediastreams,
		userVideoActiveTracks,
		userVideoPromptStatus,
		userVideoRequestErrors,
	}), [
		addTracks,
		deleteConfigTracks,
		getIsUserAudioActive,
		getIsUserVideoActive,
		removeTracksByConfigId,
		toggleAudio,
		toggleVideo,
		userActiveTracks,
		userAudioActiveTracks,
		userAudioPromptStatus,
		userAudioRequestErrors,
		userMediastreams,
		userVideoActiveTracks,
		userVideoPromptStatus,
		userVideoRequestErrors,
	]);

	return (
		<MediaUserContext.Provider value={value}>
			{children}
			<MediaUserPermissionModal
				userAudioPromptStatus={userAudioPromptStatus}
				userVideoPromptStatus={userVideoPromptStatus}
			/>
			{inputsConfig.map((cfg) => (
				<MediaUserPerform
					key={cfg.id}
					isVideoAllowed={isVideoAllowed}
					allowAudio={allowAudio}
					addTracks={addTracks}
					inputConfig={cfg}
					resolution={resolution}
					removeTracksByConfigId={removeTracksByConfigId}
					userAudioRequestError={userAudioRequestErrors.find((e) => (
						e.configId === cfg.id && e.error
					))}
					userVideoRequestError={userVideoRequestErrors.find((e) => (
						e.configId === cfg.id && e.error
					))}
					setUserAudioPromptStatus={setUserAudioPromptStatus}
					setUserVideoPromptStatus={setUserVideoPromptStatus}
					setUserAudioRequestError={setUserAudioRequestErrorByConfigId}
					setUserVideoRequestError={setUserVideoRequestErrorByConfigId}
					stopConfigTracks={stopConfigTracks}
				/>
			))}
		</MediaUserContext.Provider>
	);
});

MediaUser.propTypes = {
	allowAudio: PropTypes.bool,
	allowVideo: PropTypes.bool,
	children: PropTypes.node.isRequired,
	resolution: PropTypes.oneOf(Object.values(Resolution)),
};

MediaUser.defaultProps = {
	allowAudio: false,
	allowVideo: false,
	resolution: Resolution.P720,
};
