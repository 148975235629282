import { authenticatedApi } from '../../api';

export const API_ADMIN_MODERATION_BANS_PATH = '/admin/log';

export const fetchAdminUserStatusLogs = (userId) => authenticatedApi.get(
	`${API_ADMIN_MODERATION_BANS_PATH}/user-status/${userId}`,
);

export const fetchAdminVodStatusLogs = (videoId) => authenticatedApi.get(
	`${API_ADMIN_MODERATION_BANS_PATH}/vod-status/${videoId}`,
);

export const fetchAdminSubscriptionStatusLogs = (channelId) => authenticatedApi.get(
	`${API_ADMIN_MODERATION_BANS_PATH}/subscription-status/${channelId}`,
);
