import React, { useId } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormGroup, Input, Label } from 'reactstrap';
import { FormLabel } from '../Form/Label';
import { ShareTabType } from './Share.helper';
import { ResourceAccessRole } from '../../lib/ResourceAccessRole';

export const ShareRoleInput = ({
	roles,
	value,
	onChange,
	shareTabType,
}) => {
	const { t } = useTranslation();

	const id = useId();
	const getRadioId = (role) => `ShareRoleInput_${role}_${id.replaceAll(':', '__')}`;

	// Remove the operator roles in all tabs except in the users tab
	const filteredRoles = roles.filter((role) => (role
		&& (shareTabType === ShareTabType.USERS || role !== ResourceAccessRole.OPERATOR)
		&& [
			ResourceAccessRole.PARTICIPANT,
			ResourceAccessRole.VIEWER,
			ResourceAccessRole.OPERATOR,
		].includes(role)));

	return filteredRoles.length > 0 && (
		<FormGroup tag="fieldset">
			<FormLabel>{t('Share.RoleInput.inviteAs')}</FormLabel>
			<div className="d-flex">
				{filteredRoles.map((role) => (
					<FormGroup check className="mr-3" key={role}>
						<Input
							id={getRadioId(role)}
							type="radio"
							name="role"
							onChange={() => { onChange(role); }}
							checked={value === role}
						/>
						<Label check for={getRadioId(role)}>
							{t(`Share.RoleInput.role.${role}`)}
						</Label>
					</FormGroup>
				))}
			</div>
		</FormGroup>
	);
};

ShareRoleInput.propTypes = {
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	roles: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(ResourceAccessRole))).isRequired,
	shareTabType: PropTypes.oneOf(Object.keys(ShareTabType)).isRequired,
};
