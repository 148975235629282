import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
	Button, ButtonDropdown, ButtonGroup, DropdownMenu, DropdownToggle, Nav,
	NavItem,
	NavLink,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as StudioIcon } from '../../images/ic-hp-my-studio.svg';
import { getLink, Path } from '../../RoutePath';
import { ButtonPillOutline } from '../Button';
import { CompleteProfileModal } from '../Profile/CompleteModal';
import { useProfile } from '../Profile/ProfileContext';
import './Button.scss';
import { StudioModal } from './Context';
import { useStudio } from './Provider';
import { StudioScheduleModal, useStudioSchedule } from '../StudioSchedule/Context';
import { useCurrentStudio } from './useCurrentStudio';

export const StudioButtonActive = () => {
	const { t } = useTranslation();
	const { setModalOpen, studio } = useStudio();

	const handleTerminateClick = () => {
		setModalOpen(StudioModal.TERMINATE);
	};

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [showCompleteProfileModal, setShowCompleteProfileModal] = useState(false);
	const { canStartStudio, openModal } = useStudioSchedule();
	const {
		currentStudio,
		isCurrentStudioStarted,
		isCurrentStudioMine,
		isCurrentStudioOperatedByMe,
	} = useCurrentStudio();
	const { profile } = useProfile();

	const isControlledStudioStarted = isCurrentStudioStarted
		&& (isCurrentStudioMine || isCurrentStudioOperatedByMe);

	return (
		<ButtonGroup>
			<Button
				color="danger"
				className="flex-shrink-0 font-weight-bold pl-3"
				disabled={!studio || studio.status !== 'RUNNING'}
				onClick={handleTerminateClick}
			>
				<StudioIcon className="mr-3" />
				{t('Studio.Button.end')}
			</Button>
			<ButtonDropdown
				isOpen={dropdownOpen}
				toggle={() => setDropdownOpen(!dropdownOpen)}
			>
				<DropdownToggle
					caret
					className="px-1 StudioButtonDropdown"
					color={isControlledStudioStarted ? 'neutral-danger' : 'neutral-primary'}
				/>
				<DropdownMenu end className="overflow-hidden p-2 bg-light border border-dark">
					<Nav pills className="nav-neutral-secondary flex-column font-weight-normal">
						<NavItem>
							<NavLink className="font-weight-normal" onClick={() => openModal(StudioScheduleModal.MANAGE)}>
								{t('Studio.Button.manage')}
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink className="font-weight-normal" onClick={() => openModal(StudioScheduleModal.CALENDAR)}>
								{t('Studio.Button.calendar')}
							</NavLink>
						</NavItem>
						{isControlledStudioStarted && (
							<NavItem>
								<NavLink className="font-weight-normal" onClick={() => openModal(StudioScheduleModal.EDIT, currentStudio._id)}>
									{t('Studio.Button.controller')}
								</NavLink>
							</NavItem>
						)}
						{!profile?.completedRewardClaimed && (
							<NavItem>
								<NavLink onClick={() => setShowCompleteProfileModal(true)}>
									{t('Studio.Button.getChips')}
								</NavLink>
							</NavItem>
						)}
					</Nav>
				</DropdownMenu>
			</ButtonDropdown>
		</ButtonGroup>
	);
};

const StudioButtonInactive = ({ onClick, disabled, isStudioStarted }) => {
	const { t } = useTranslation();
	return (
		<Button
			tag={Link}
			to={getLink(Path.STUDIO)}
			color={isStudioStarted ? 'neutral-danger' : 'neutral-primary'}
			className={`flex-shrink-0 StudioButtonInactive ${isStudioStarted ? 'content-danger' : 'content-primary-hover'}`}
			onClick={onClick}
			disabled={disabled}
		>
			<span className="btn-wrapper--icon d-inline-flex align-middle">
				<StudioIcon height="20px" />
			</span>
			<span className="btn-wrapper--label d-none d-lg-inline font-weight-bold">
				{t('Studio.Button.studio')}
			</span>
		</Button>
	);
};

export const StudioButtonLeave = () => {
	const history = useHistory();
	const { t } = useTranslation();

	const handleLeaveStudio = useCallback(() => {
		history.push('/');
	}, [history]);

	return (
		<ButtonPillOutline
			color="secondary"
			className="flex-shrink-0 shadow-none mr-2"
			onClick={handleLeaveStudio}
			size="sm"
		>
			{t('Studio.Button.exit')}
		</ButtonPillOutline>
	);
};

StudioButtonInactive.propTypes = {
	disabled: PropTypes.bool,
	isStudioStarted: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
};
StudioButtonInactive.defaultProps = {
	disabled: false,
	isStudioStarted: false,
};

export const StudioButton = () => {
	const [showCompleteProfileModal, setShowCompleteProfileModal] = useState(false);
	const { profile } = useProfile();

	return (
		<div className="d-flex align-items-center">
			{!profile?.completedRewardClaimed && (
				<CompleteProfileModal
					isOpen={showCompleteProfileModal}
					close={() => setShowCompleteProfileModal(false)}
				/>
			)}
		</div>
	);
};
