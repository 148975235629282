import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Path, getLink } from '../../../RoutePath';
import { useCurrentStudio } from '../../../components/Studio/useCurrentStudio';
import { StudioScheduleModal, useStudioSchedule } from '../../../components/StudioSchedule/Context';
import { MyAccountModalTab, UserSettingsModal, useUserSettings } from '../../../components/UserSettings/Context';
import { ReactComponent as MyStudioIcon } from '../../../images/ic-hp-my-studio.svg';
import { MenuCollapse } from '../MenuCollapse/MenuCollapse';

export const SidebarMenuStudio = ({ isCurrentLocationHome, hideSidebarMenu }) => {
	const { t } = useTranslation();
	const { openModal } = useStudioSchedule();
	const {
		currentStudio,
		isCurrentStudioStarted,
		isCurrentStudioMine,
		isCurrentStudioOperatedByMe,
	} = useCurrentStudio();

	const { openModal: openUserSettingsModal } = useUserSettings();

	const isControlledStudioStarted = useMemo(() => isCurrentStudioStarted
    && (isCurrentStudioMine || isCurrentStudioOperatedByMe),
	[isCurrentStudioMine, isCurrentStudioOperatedByMe, isCurrentStudioStarted]);

	const goLiveMenuContent = [
		{
			label: isControlledStudioStarted ? t('SidebarMenu.Index.join') : t('SidebarMenu.Index.start'),
			to: getLink(Path.STUDIO),
			hideSidebarMenu: true,
		},
		isControlledStudioStarted && ({
			label: t('SidebarMenu.Index.controller'),
			toggleSidebarOnClick: !isCurrentLocationHome,
			onClick: () => openModal(StudioScheduleModal.EDIT, currentStudio?._id),
		}),
		{
			label: t('SidebarMenu.Index.calendar'),
			to: '',
			toggleSidebarOnClick: !isCurrentLocationHome,
			onClick: () => openModal(StudioScheduleModal.CALENDAR),
		},
		{
			label: t('SidebarMenu.Index.libraries'),
			to: '',
			toggleSidebarOnClick: !isCurrentLocationHome,
			onClick: () => openUserSettingsModal(UserSettingsModal.LIBRARY),
		},
		{
			label: t('SidebarMenu.Index.settings'),
			to: '',
			toggleSidebarOnClick: !isCurrentLocationHome,
			onClick: () => openUserSettingsModal(UserSettingsModal.ACCOUNT, MyAccountModalTab.SETTINGS),
		},
		{
			label: t('SidebarMenu.Index.analytics'),
			to: '',
			toggleSidebarOnClick: !isCurrentLocationHome,
			onClick: () => openUserSettingsModal(UserSettingsModal.ANALYTICS_AND_ADVERTISING),
		},
	];

	const goLiveMenu = {
		label: 'Go Live',
		icon: <MyStudioIcon className="icon" />,
	};

	return (
		<MenuCollapse
			parentItem={goLiveMenu}
			childItems={goLiveMenuContent}
			hideSidebarMenu={hideSidebarMenu}
		/>
	);
};

SidebarMenuStudio.propTypes = {
	isCurrentLocationHome: PropTypes.bool,
	hideSidebarMenu: PropTypes.func,
};

SidebarMenuStudio.defaultProps = {
	isCurrentLocationHome: false,
	hideSidebarMenu: undefined,
};
