import React, {
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import RatioContainer from '../RatioContainer/RatioContainer';
import { minutes, seconds } from './helpers';
import { PlayerColorPicker } from './ColorPicker';
import { LayerBounds } from '../../lib/studio';
import './Preview.scss';

const { MediaStream } = window;

export const propTypes = {
	autoPlay: PropTypes.bool,
	className: PropTypes.string,
	controls: PropTypes.bool,
	cover: PropTypes.bool,
	duration: PropTypes.number,
	muted: PropTypes.bool,
	noDuration: PropTypes.bool,
	noTimeline: PropTypes.bool,
	preload: PropTypes.oneOf(['auto', 'metadata', 'none']),
	src: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(MediaStream),
	]),
	volume: PropTypes.number,
	allowColorPicking: PropTypes.bool,
	onPickColor: PropTypes.func,
	videoLayerConfig: PropTypes.shape({
		x: PropTypes.number,
		y: PropTypes.number,
		width: PropTypes.number,
		height: PropTypes.number,
	}),
};

const defaultProps = {
	autoPlay: false,
	className: '',
	controls: false,
	cover: false,
	duration: undefined,
	muted: false,
	noDuration: false,
	noTimeline: false,
	preload: 'metadata',
	src: null,
	volume: 100,
	allowColorPicking: false,
	onPickColor: undefined,
	videoLayerConfig: {
		x: 0,
		y: 0,
		width: LayerBounds.width,
		height: LayerBounds.height,
	},
};

const Preview = ({
	autoPlay,
	className,
	controls,
	cover,
	duration,
	muted,
	noDuration,
	noTimeline,
	preload,
	src,
	volume,
	allowColorPicking,
	onPickColor,
	videoLayerConfig,
	...rest
}) => {
	const [videoDuration, setVideoDuration] = useState();
	const [isPlaying, setPlaying] = useState(false);
	const video = useRef(null);

	if (typeof src === 'string') src = `${src}#t=0.1`;

	useEffect(() => {
		if (src instanceof MediaStream) {
			if (video.current.srcObject !== src) { // fix firefox
				video.current.srcObject = src;
			}
		} else {
			video.current.srcObject = undefined;
		}

		if (!src) {
			setPlaying(false);
		}
	}, [src]);

	const handleLoadedMetadata = useCallback((event) => {
		setVideoDuration(event.target.duration);
	}, []);

	const handlePause = useCallback(() => {
		setPlaying(false);
	}, []);

	const handlePlaying = useCallback(() => {
		setPlaying(true);
	}, []);

	const isMediaStream = src && (src instanceof MediaStream);

	// Prevent memory leak
	useEffect(() => {
		const currentVideoElement = video.current;
		return () => {
			if (currentVideoElement) {
				currentVideoElement.srcObject = undefined;
			}
		};
	}, []);

	const srcRef = useRef(src);
	useEffect(() => { srcRef.current = src; });

	// Force play when autoPlay changes
	useEffect(() => {
		// check if src is defined because on safari the play request fails on unmount.
		// (because of the useEffect cleanup above: "currentVideoElement.srcObject = undefined;")
		// An AbortError raise which seems not correctly handled
		// by react error manager (no stack trace)
		if (autoPlay && srcRef.current) video.current.play().catch((err) => console.warn(err));
	}, [autoPlay]);

	const mutedRef = useRef(muted);
	useLayoutEffect(() => { mutedRef.current = muted; }, [muted]);

	const controlsRef = useRef(controls);
	useLayoutEffect(() => { controlsRef.current = controls; }, [controls]);

	// Fix: chrome shows white bar under controls when there is no video
	// => Force update controls when mediastream tracks are udpated
	useEffect(() => {
		if (isMediaStream) {
			const onTrackChange = () => {
				if (!video.current) return;
				video.current.controls = false;
				// Fix chrome mutes audio if there multiple audio tracks
				video.current.muted = true;
				setTimeout(() => {
					if (!video.current) return;
					video.current.controls = !!controlsRef.current;
					video.current.muted = !!mutedRef.current;
				});
			};
			src.addEventListener('addtrack', onTrackChange);
			src.addEventListener('removetrack', onTrackChange);
			return () => {
				src.removeEventListener('addtrack', onTrackChange);
				src.removeEventListener('removetrack', onTrackChange);
			};
		}
		return undefined;
	}, [isMediaStream, src]);

	useEffect(() => {
		if (!video.current) return;
		video.current.volume = volume / 100;
	}, [volume]);

	const videoPosition = useMemo(() => {
		const width = videoLayerConfig.width / LayerBounds.width;
		const height = videoLayerConfig.height / LayerBounds.height;

		const x = videoLayerConfig.x / LayerBounds.width;
		const y = videoLayerConfig.y / LayerBounds.height;

		return {
			left: `${x * 100}%`,
			top: `${y * 100}%`,
			width: `${width * 100}%`,
			height: `${height * 100}%`,
		};
	}, [videoLayerConfig]);

	const durationValue = Math.floor(duration ?? videoDuration ?? 0);

	return (
		<RatioContainer
			className={
				clsx(
					'Preview',
					{
						cover,
						isPlaying,
						noTimeline,
						paused: !isPlaying,
					},
					className,
				)
			}
			{...rest}
		>
			{allowColorPicking && <PlayerColorPicker videoRef={video} onClick={onPickColor} />}
			<div className="Preview_container">
				<video
					className="position-absolute"
					style={videoPosition}
					autoPlay={autoPlay}
					controls={controls}
					muted={muted}
					controlsList="nodownload"
					onLoadedMetadata={handleLoadedMetadata}
					onPause={handlePause}
					onPlaying={handlePlaying}
					playsInline
					preload={preload}
					ref={video}
					src={!isMediaStream ? src : null}
				/>
				{
					!controls
					&& !noDuration
					&& Number.isFinite(durationValue)
					&& durationValue > 0
					&& (
						<span className="Preview_duration">
							{minutes(durationValue)}:{seconds(durationValue)}
						</span>
					)
				}
			</div>
		</RatioContainer>
	);
};

Preview.propTypes = propTypes;
Preview.defaultProps = defaultProps;

export default React.memo(Preview);
