// eslint-disable-next-line import/no-extraneous-dependencies
import { SoupSession } from '@technomiam/soup-client';
import {
	connect as connectSignaling,
	disconnect as disconnectSignaling,
} from './ws/signaling';

// eslint-disable-next-line no-underscore-dangle
let _soupSession;

export function soupSession() {
	return _soupSession;
}

// window.localStorage.setItem('debug', 'mediasoup-client*');

export async function connect(connectionConfig, hashtag) {
	const signaling = await connectSignaling(connectionConfig);
	_soupSession = new SoupSession(signaling, hashtag);
	return _soupSession;
}

export function disconnect() {
	if (!_soupSession) return;
	_soupSession.leave();
	_soupSession.destroy();
	_soupSession = null;
	disconnectSignaling();
}

export async function join(hashtag) {
	await _soupSession.join(hashtag);
}

export async function publishTrack(track, appData, encodings) {
	return _soupSession.publishTrack(track, appData, encodings);
}

export async function unpublishTrack(track) {
	return _soupSession.unpublishTrack(track);
}

export async function updateTrack(track, appData) {
	return _soupSession.updateTrack(track, { appData });
}
