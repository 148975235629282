import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FaUserFriends } from 'react-icons/fa';
import Select from 'react-select';

import { useAsyncCallback } from 'react-async-hook';
import { Form, Field, FieldError } from 'react-jsonschema-form-validation';
import { Alert, Badge, FormGroup, Input } from 'reactstrap';
import Hexagon from '../../Hexagon/Hexagon';
import { getAvatar } from '../../StudioSchedule/Participants/Participants.helper';
import { ButtonPillOutline } from '../../Button';
import { FormLabel } from '../../Form/Label';
import { ResourceAccessRole } from '../../../lib/ResourceAccessRole';
import { ShareRecipientType } from '../../Notification/Notification.helper';
import { ShareRoleInput } from '../RoleInput';
import { ShareTabType } from '../Share.helper';
import FormSubmit from '../../Form/Submit';
import { useProfile } from '../../Profile/ProfileContext';
import { useFetchUserSuggestions } from '../../../api-hooks/search';

const formSchema = {
	type: 'object',
	additionalProperties: false,
	properties: {
		message: {
			type: 'string',
			maxLength: 100,
		},
		users: {
			type: 'array',
			minItems: 1,
		},
	},
};

const getInitialData = (message) => ({
	users: [],
	message: message || '',
});

const customDropdownStyles = {
	dropdownIndicator: (baseStyles) => ({
		...baseStyles,
		display: 'none',
	}),
	indicatorSeparator: (baseStyles) => ({
		...baseStyles,
		display: 'none',
	}),
};

const CustomOption = ({ data, innerProps }) => {
	const { t } = useTranslation();
	return (
		<div {...innerProps} className="d-flex py-1">
			<Hexagon className="d-block d-40 flex-shrink-0 ms-3">
				<img src={getAvatar(data.user.avatar)} className="img-fluid" alt="Avatar" />
			</Hexagon>
			<div className="d-flex justify-content-between w-100 align-items-center mx-3 overflow-hidden">
				<strong className="font-size-sm text-truncate">{(data.user.isFriend || data.user.isOperator) && (<FaUserFriends className="mr-2" />)} {data.label}</strong>
				<div className="d-flex align-items-center ms-3">
					<Badge color={data.user.isConnected ? 'success' : 'second'} className="position-relative badge-circle ml-1" style={{ lineHeight: '15px' }}>
						{t('Share.ItemFriend.online')}
					</Badge>
					<span className="ml-2 content-light-50 font-size-sm text-nowrap">{data.user.isConnected ? t('Share.ItemFriend.online') : t('Share.ItemFriend.offline')}</span>
				</div>
			</div>
		</div>
	);
};

CustomOption.propTypes = {
	data: PropTypes.shape({
		label: PropTypes.string.isRequired,
		user: PropTypes.shape({
			avatar: PropTypes.string,
			isConnected: PropTypes.bool,
			isFriend: PropTypes.bool,
			isOperator: PropTypes.bool,
		}).isRequired,
	}).isRequired,
	innerProps: PropTypes.shape({}).isRequired,
};

export const ShareTabUsers = ({
	alreadySharedUsers,
	defaultMessage,
	onShare,
	roles,
	selectedRole,
	setSelectedRole,
	shareButtonLabel,
	studio,
}) => {
	const { t } = useTranslation();

	const [formData, setFormData] = useState(getInitialData(defaultMessage));
	const [searchValue, setSearchValue] = useState('');
	const { profile } = useProfile();

	const { data: suggestions, isLoading: isSuggesting } = useFetchUserSuggestions(
		searchValue,
		{
			operatorsOnly: selectedRole === ResourceAccessRole.OPERATOR,
		},
	);

	const searchLabel = selectedRole === ResourceAccessRole.OPERATOR
		? t('Share.Tab.Users.searchOperator')
		: t('Share.Tab.Users.searchForAnyBeeYouUser');

	const filteredSuggestions = useMemo(() => {
		let suggestionsToFilter = suggestions || [];

		// Remove already shared users
		const alreadySharedUsersIds = alreadySharedUsers.map((p) => {
			if (p.type === ShareRecipientType.USER || p.type === ShareRecipientType.USERS) {
				return p.user._id;
			}
			return null;
		});
		suggestionsToFilter = suggestionsToFilter
			.filter((user) => !alreadySharedUsersIds.includes(user.id));

		// Remove current user (could be an operator) and studio owner and return
		return suggestionsToFilter
			.filter((suggestion) => suggestion.id !== studio?.owner._id && suggestion.id !== profile._id)
			.sort((a, b) => {
				// eslint-disable-next-line no-nested-ternary
				a = a.isOperator ? 2 : a.isFriend ? 1 : 0;
				// eslint-disable-next-line no-nested-ternary
				b = b.isOperator ? 2 : b.isFriend ? 1 : 0;
				return b - a;
			})
			.map((suggestion) => ({
				label: suggestion.label,
				user: {
					...suggestion.user,
					isFriend: suggestion.isFriend,
					isOperator: suggestion.isOperator,
				},
				value: suggestion.id,
			}));
	}, [suggestions, alreadySharedUsers, studio?.owner._id, profile._id]);

	const clear = () => setFormData((s) => ({ ...s, users: [] }));

	const handleSubmitAsync = useAsyncCallback(async () => {
		const participants = formData.users.map((data) => ({
			user: data.user,
			message: formData.message,
			type: ShareRecipientType.USER,
		}));
		await onShare(participants, selectedRole);
		clear();
	});

	useEffect(() => {
		if (handleSubmitAsync.status === 'success' || handleSubmitAsync.error) {
			const id = setTimeout(() => handleSubmitAsync.reset(), 3000);
			return () => clearTimeout(id);
		}
		return undefined;
	}, [handleSubmitAsync]);

	const handleClear = () => { clear(); };

	useEffect(() => {
		clear();
	}, [selectedRole]);

	const isEmpty = !(formData.users?.length > 0);
	const isActionDisabled = isEmpty || handleSubmitAsync.loading;

	return (
		<Form
			className="ShareTabUsers"
			data={formData}
			onSubmit={handleSubmitAsync.execute}
			schema={formSchema}
		>
			<div className="d-flex mb-3">
				<ButtonPillOutline
					className="mr-2"
					color="dark"
					disabled={isActionDisabled}
					onClick={handleClear}
				>
					{t('Share.Buttons.clear')}
				</ButtonPillOutline>
				<FormSubmit
					className="btn-pill shadow-none ml-auto"
					disabled={isActionDisabled}
					loading={handleSubmitAsync.loading}
					title={t('Share.Tab.Users.sendInvitationToUsers')}
				>
					{t(shareButtonLabel)}
				</FormSubmit>
			</div>
			{handleSubmitAsync.status === 'success' && (
				<Alert color="success">
					{t('Share.Tab.Users.usersAddedSuccessfully')}
				</Alert>
			)}
			{handleSubmitAsync.error && (
				<Alert color="danger">
					{t('Global.error')}
				</Alert>
			)}
			<ShareRoleInput
				value={selectedRole}
				onChange={(role) => setSelectedRole(role)}
				roles={roles}
				shareTabType={ShareTabType.USERS}
			/>
			<FormGroup>
				<FormLabel>{t('Share.sendTo')}</FormLabel>
				<Select
					captureMenuScroll={false}
					classNamePrefix="react-select-dark"
					components={{ Option: CustomOption }}
					hideSelectedOptions
					isLoading={isSuggesting}
					isMulti
					isSearchable
					name="users"
					noOptionsMessage={() => null}
					onChange={(users) => setFormData({ ...formData, users })}
					onInputChange={(value) => setSearchValue(value)}
					options={filteredSuggestions}
					placeholder={searchLabel}
					styles={customDropdownStyles}
					value={formData.users}
				/>
				<FieldError
					name="users"
					errorMessages={{
						minItems: () => t('Share.Tab.Users.pleaseAddAtLeastOneUser'),
					}}
				/>
			</FormGroup>
			<FormGroup>
				<FormLabel>{t('Share.Message.message')}</FormLabel>
				<Field
					className="Share_InviteMessage bg-dark content-dark rounded-1 border-darker"
					component={Input}
					name="message"
					onChange={(e) => setFormData({ ...formData, message: e.target.value })}
					placeholder={t('Share.Message.addCustomMessage')}
					rows="3"
					type="textarea"
					value={formData.message}
				/>
				<FieldError
					errorMessages={{
						maxLength: () => t('Share.Message.messageIsTooLong'),
					}}
					name="message"
				/>
			</FormGroup>
		</Form>
	);
};

ShareTabUsers.propTypes = {
	alreadySharedUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	defaultMessage: PropTypes.string,
	onShare: PropTypes.func.isRequired,
	roles: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(ResourceAccessRole))).isRequired,
	selectedRole: PropTypes.oneOf(Object.keys(ResourceAccessRole)).isRequired,
	setSelectedRole: PropTypes.func.isRequired,
	shareButtonLabel: PropTypes.string.isRequired,
	studio: PropTypes.shape({
		owner: PropTypes.shape({
			_id: PropTypes.string,
		}),
	}),
};

ShareTabUsers.defaultProps = {
	defaultMessage: '',
	studio: { owner: { _id: undefined } },
};
