import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import { FaCrop, FaEnvelope, FaExpand, FaPaintBrush } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import { VoteView } from '../../components/Vote/View';
import { DrawTab } from '../../components/DrawTab/DrawTab';
import { FloatingPiP } from '../../components/FloatingPiP/FloatingPiP';
import ReallyPerfectScrollbar from '../../components/ReallyPerfectScrollbar/ReallyPerfectScrollbar';
import { UserSettingsModal, useUserSettings } from '../../components/UserSettings/Context';
import { ButtonPill } from '../../components/Button';
import { MembershipModalTab } from '../../components/UserSettings/MembershipModalTab';
import { Crop } from '../../components/Crop/Crop';
import { useAppsTab } from './AppsTabProvider';

const UpgradeMembership = () => {
	const { t } = useTranslation();
	const { openModal } = useUserSettings();
	return (
		<div>
			<p>{t('Controlroom.Apps.needUpgrade')}</p>
			<ButtonPill
				className="w-100"
				onClick={() => openModal(
					UserSettingsModal.MEMBERSHIP,
					MembershipModalTab.PRICING,
				)}
				size="sm"
			>
				{t('Controlroom.Apps.updgrade')}
			</ButtonPill>
		</div>
	);
};

export const Apps = ({ hashtag, connectedUsers, shouldTabProvider }) => {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState('votes');
	const { currentTab } = useAppsTab();

	useEffect(() => {
		if (!shouldTabProvider) return;
		setActiveTab(currentTab);
	}, [currentTab, shouldTabProvider]);

	/* Enable every feature for any membership
	const { studio } = useStudio();
	const { error, result: features } = useAsync(async () => {
		const { data } = await fetchStudioFeaturesAPI(studio._id);
		return data;
	}, [studio._id]);

	useAsyncErrorLog({ error });

	const pollsEnabled = features?.pollsEnabled || false;
	const drawingEnabled = features?.drawingEnabled || false;
	const floatingPipEnabled = features?.floatingPipEnabled || false;
	*/

	const pollsEnabled = true;
	const drawingEnabled = true;
	const floatingPipEnabled = true;

	return (
		<div className="MediaTabs Apps h-100 d-flex flex-column">
			<div className="mx-2 mx-md-3 mt-2 flex-shrink-0">
				<div className="nav-line-dark w-100">
					<ReallyPerfectScrollbar>
						<Nav className="w-100 d-flex nav-line flex-nowrap overflow-hidden">
							<NavItem className="flex-grow-1">
								<NavLink
									className={clsx({ active: activeTab === 'votes' }, 'd-flex flex-column')}
									onClick={() => { setActiveTab('votes'); }}
								>
									<Button
										className={clsx({ active: activeTab === 'votes' }, 'btn-pill d-30 p-0 ')}
										color="neutral-secondary"
										title={t('Controlroom.Apps.poll')}
									>
										<span className="btn-wrapper--icon">
											<FaEnvelope />
										</span>
									</Button>
									<small>{t('Controlroom.Apps.vote')}</small>
								</NavLink>
							</NavItem>
							<NavItem className="flex-grow-1">
								<NavLink
									className={clsx({ active: activeTab === 'draw' }, 'd-flex flex-column')}
									onClick={() => { setActiveTab('draw'); }}
								>
									<Button
										className={clsx({ active: activeTab === 'draw' }, 'btn-pill d-30 p-0 ')}
										color="neutral-secondary"
										title={t('Controlroom.Apps.draw')}
									>
										<span className="btn-wrapper--icon">
											<FaPaintBrush />
										</span>
									</Button>
									<small>{t('Controlroom.Apps.draw')}</small>
								</NavLink>
							</NavItem>
							<NavItem className="flex-grow-1">
								<NavLink
									className={clsx({ active: activeTab === 'floatingpips' }, 'd-flex flex-column')}
									onClick={() => { setActiveTab('floatingpips'); }}
								>
									<Button
										className={clsx({ active: activeTab === 'floatingpips' }, 'btn-pill d-30 p-0 ')}
										color="neutral-secondary"
										title={t('Controlroom.Apps.floatingPip')}
									>
										<span className="btn-wrapper--icon">
											<FaExpand />
										</span>
									</Button>
									<small>{t('Controlroom.Apps.pip')}</small>
								</NavLink>
							</NavItem>
							<NavItem className="flex-grow-1">
								<NavLink
									className={clsx({ active: activeTab === 'cropLive' }, 'd-flex flex-column')}
									onClick={() => { setActiveTab('cropLive'); }}
								>
									<Button
										className={clsx({ active: activeTab === 'cropLive' }, 'btn-pill d-30 p-0 ')}
										color="neutral-secondary"
										title={t('Controlroom.Apps.zoom')}
									>
										<span className="btn-wrapper--icon">
											<FaCrop />
										</span>
									</Button>
									<small>{t('Controlroom.Apps.zoom')}</small>
								</NavLink>
							</NavItem>
						</Nav>
					</ReallyPerfectScrollbar>
				</div>
			</div>
			<TabContent activeTab={activeTab} className="TabContent flex-grow-1 overflow-hidden h-100 d-flex flex-column">
				<ReallyPerfectScrollbar>
					<div className="p-2 py-md-2 px-md-3 min-h-100 d-flex flex-column">
						{activeTab === 'votes' && (
							pollsEnabled ? <VoteView hashtag={hashtag} /> : <UpgradeMembership />
						)}
						{activeTab === 'draw' && (
							drawingEnabled ? <DrawTab connectedUsers={connectedUsers} /> : <UpgradeMembership />
						)}
						{activeTab === 'floatingpips' && (
							floatingPipEnabled ? <FloatingPiP /> : <UpgradeMembership />
						)}
						{activeTab === 'cropLive' && (
							<Crop />
						)}
					</div>
				</ReallyPerfectScrollbar>
			</TabContent>
		</div>
	);
};

Apps.propTypes = {
	hashtag: PropTypes.string.isRequired,
	connectedUsers: PropTypes.arrayOf(PropTypes.shape({})),
	shouldTabProvider: PropTypes.bool,
};

Apps.defaultProps = {
	connectedUsers: [],
	shouldTabProvider: false,
};
