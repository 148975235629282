import React, { useEffect } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useCrop, useMixing } from '@technomiam/react-video';
import { useTranslation } from 'react-i18next';

import { ButtonPillOutline } from '../Button';

export const Crop = () => {
	const { t } = useTranslation();
	const {
		isCropEnabled,
		setIsCropEnabled,
		/*, toggleCoefficient, isCoefficientEnabled */
	} = useCrop();
	const { notifyCrop } = useMixing();

	const handleClickReset = () => {
		notifyCrop(undefined);
	};

	const toggle = () => {
		if (!isCropEnabled) {
			handleClickReset();
		}
		setIsCropEnabled(!isCropEnabled);
	};

	/*
	const handleClickCoef = () => {
		toggleCoefficient();
	};
	*/

	useEffect(() => () => {
		setIsCropEnabled(false);
	}, [setIsCropEnabled]);

	return (
		<section className="Crop h-100 d-flex flex-column justify-content-between overflow-hidden flex-grow-1">
			<div className="w-100">
				<p>{t('Crop.Crop.selectArea')}</p>
				<ButtonPillOutline color="primary" className="d-inline mr-2" onClick={toggle}>
					{isCropEnabled ? t('Crop.Crop.disable') : t('Crop.Crop.enable')}
				</ButtonPillOutline>
				<ButtonPillOutline color="secondary" className="d-inline" onClick={handleClickReset}>
					{t('Crop.Crop.reset')}
				</ButtonPillOutline>

				{/* <FormGroup check className="mt-2">
					<Label check>
						<Input
							type="checkbox"
							checked={isCoefficientEnabled}
							onChange={handleClickCoef}
						/>
						Keep aspect ratio
					</Label>
				</FormGroup> */}
			</div>
		</section>
	);
};
