import React from 'react';
import 'react-multi-email/style.css';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';
import { Button, Modal } from 'reactstrap';

import * as shareApi from '../../../api/share';

import { ResourceAccessRole } from '../../../lib/ResourceAccessRole';
import { Share } from '../../Share/Share';
import { ShareTabType } from '../../Share/Share.helper';
import { getShareType } from './helpers';

export const ChannelShareModal = ({
	isOpen,
	channel,
	roles,
	shareTabTypes,
	studio,
	toggle,
	video,
	post,
	container,
}) => {
	const handleShares = async (data, role) => {
		const shares = data.map((share) => ({
			about: {
				channel: !video ? channel._id : undefined,
				role,
				studio: studio ? studio._id : undefined,
				type: getShareType({ channel, studio, video, post }),
				video: video ? video._id : undefined,
				post: post ? post._id : undefined,
			},
			recipient: {
				type: share.type,
				email: share.email || undefined,
				user: share.user?._id || undefined,
				phoneNumber: share.phoneNumber || undefined,
				group: share.group?._id || undefined,
			},
			message: share.message || '',
		}));
		await shareApi.share(shares);
	};

	return (
		<Modal
			centered
			contentClassName="border-0 shadow-lg rounded overflow-hidden bg-darker m-auto"
			isOpen={isOpen}
			toggle={toggle}
			zIndex={2000}
			container={container}
		>
			<div className="rounded overflow-hidden">
				<Button
					color="neutral-secondary"
					className="SettingModal_close position-absolute card-badges shadow btn-pill d-30 p-0 m-2"
					onClick={toggle}
				>
					<span className="btn-wrapper--icon">
						<FaTimes />
					</span>
				</Button>
			</div>
			<div className="p-5 pb-4">
				<Share
					channel={channel}
					onShare={handleShares}
					post={post}
					roles={roles}
					shareTabTypes={shareTabTypes}
					studio={studio}
					video={video}
				/>
			</div>
		</Modal>
	);
};

ChannelShareModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	channel: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		hashtag: PropTypes.string.isRequired,
		currentShow: PropTypes.shape({}),
	}).isRequired,
	roles: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(ResourceAccessRole))),
	studio: PropTypes.shape({
		_id: PropTypes.string.isRequired,
	}),
	toggle: PropTypes.func.isRequired,
	video: PropTypes.shape({
		_id: PropTypes.string.isRequired,
	}),
	post: PropTypes.shape({
		_id: PropTypes.string.isRequired,
	}),
	shareTabTypes: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(ShareTabType))).isRequired,
	container: PropTypes.string,
};

ChannelShareModal.defaultProps = {
	roles: [],
	studio: undefined,
	video: undefined,
	post: undefined,
	container: 'body',
};
