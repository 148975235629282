export const LayerBounds = {
	width: 1920,
	height: 1080,
};

export const Layout = {
	DEFAULT_MODE: 'DEFAULT_MODE',
	HALF_RATIO: 'HALF_RATIO',
	HALF_THIRD_THIRD: 'HALF_THIRD_THIRD',
	CENTER_ALL_BOTTOM: 'CENTER_ALL_BOTTOM',
	CENTER_ALL_RIGHT: 'CENTER_ALL_RIGHT',
	MOSAIC_QUAD: 'MOSAIC_QUAD',
	MOSAIC_SIX: 'MOSAIC_SIX',
	MOSAIC_TWELVE: 'MOSAIC_TWELVE',
	MOSAIC_SIXTEEN: 'MOSAIC_SIXTEEN',
	FULL_CANVAS: 'FULL_CANVAS',
};

const calculateMosaic = (
	videoSize,
	rows,
	cols,
	padding = 10,
) => {
	const mosaic = [];
	const divider = rows > cols ? rows : cols;
	for (let row = 0; row < rows; row += 1) {
		for (let col = 0; col < cols; col += 1) {
			let x = col * (videoSize.width / divider);
			let y = row * ((videoSize.width / divider) * (9 / 16));
			const width = videoSize.width / divider;
			const height = (videoSize.width / divider) * (9 / 16);
			if (cols * width < videoSize.width) {
				x += (videoSize.width - cols * width) / 2;
			}
			if (rows * height < videoSize.height) {
				y += (videoSize.height - rows * height) / 2;
			}
			mosaic.push({
				x,
				y,
				width: width - padding,
				height: height - padding,
			});
		}
	}
	return mosaic;
};

const getPipDefaultDimensions = (mergerWidth, mergerHeight) => ({
	width: Math.floor(mergerWidth * (1 / 4)),
	height: Math.floor(mergerHeight * (1 / 4)),
});

const getDefaultPip1Position = (
	pipWidth,
	pipHeight,
	mergerWidth,
	mergerHeight,
) => {
	const spacingX = Math.floor(mergerWidth * (5 / 100));
	const spacingY = Math.floor(mergerHeight * (5 / 100));

	return {
		x: spacingX,
		y: mergerHeight - pipHeight - spacingY,
	};
};

const getDefaultPip2Position = (
	pipWidth,
	pipHeight,
	mergerWidth,
	mergerHeight,
) => {
	const spacingX = Math.floor(mergerWidth * (5 / 100));
	const spacingY = Math.floor(mergerHeight * (5 / 100));

	return {
		x: mergerWidth - pipWidth - spacingX,
		y: mergerHeight - pipHeight - spacingY,
	};
};

/**
 * @returns {Object.<string, {
 * 	automaticSwitchAllowed: boolean,
 * 	layers: Array<{ x: integer, y: integer, width: integer, height: integer}>,
 * }>}
 */
export const getLayoutsDefaults = (
	/** @type {{
	 * 	width: integer,
	 * 	height: integer,
	 * }}
	 */
	videoSize,
) => {
	const { width, height } = videoSize;

	const defaultPipDimension = getPipDefaultDimensions(width, height);
	const defaultPip1Position = getDefaultPip1Position(
		defaultPipDimension.width,
		defaultPipDimension.height,
		width,
		height,
	);
	const defaultPip2Position = getDefaultPip2Position(
		defaultPipDimension.width,
		defaultPipDimension.height,
		width,
		height,
	);

	const centerAllRightPadding = (height - 4 * ((width * 0.22) * (9 / 16))) / 5;

	return {
		DEFAULT_MODE: {
			autoFill: false,
			automaticSwitchAllowed: true,
			layers: [
				{
					x: 0,
					y: 0,
					width,
					height,
				},
				{
					...defaultPip1Position,
					...defaultPipDimension,
				},
				{
					...defaultPip2Position,
					...defaultPipDimension,
				},
			],
		},
		HALF_RATIO: {
			autoFill: false,
			automaticSwitchAllowed: true,
			layers: [
				{
					x: 0,
					y: (height - ((width / 2 - 10) * (9 / 16))) / 2,
					width: width / 2 - 10,
					height: (width / 2 - 10) * (9 / 16),
				},
				{
					x: width - (width / 2 - 10),
					y: (height - ((width / 2 - 10) * (9 / 16))) / 2,
					width: width / 2 - 10,
					height: (width / 2 - 10) * (9 / 16),
				},
			],
		},
		HALF_THIRD_THIRD: {
			autoFill: false,
			automaticSwitchAllowed: true,
			layers: [
				{
					x: 0,
					y: 0,
					width: width / 2 - 10,
					height,
				},
				{
					x: width - (width / 2 - 10),
					y: 0,
					width: width / 2 - 10,
					height: (width / 2 - 10) * (9 / 16),
				},
				{
					x: width - (width / 2 - 10),
					y: height - ((width / 2 - 10) * (9 / 16)),
					width: width / 2 - 10,
					height: (width / 2 - 10) * (9 / 16),
				},
			],
		},
		CENTER_ALL_BOTTOM: {
			autoFill: false,
			automaticSwitchAllowed: true,
			layers: [
				{
					x: (width - ((height * 0.75) * (16 / 9))) / 2,
					y: 0,
					width: (height * 0.75) * (16 / 9),
					height: height * 0.75,
				},
				{
					x: 0,
					y: height * 0.75 + 10,
					width: width / 5 - 10,
					height: (width / 5 - 10) * (9 / 16),
				},
				{
					x: (width - 4 * (height * 0.2) * (16 / 9)),
					y: height * 0.75 + 10,
					width: width / 5 - 10,
					height: (width / 5 - 10) * (9 / 16),
				},
				{
					x: (width - 3 * (height * 0.2) * (16 / 9)),
					y: height * 0.75 + 10,
					width: width / 5 - 10,
					height: (width / 5 - 10) * (9 / 16),
				},
				{
					x: (width - 2 * (height * 0.2) * (16 / 9)),
					y: height * 0.75 + 10,
					width: width / 5 - 10,
					height: (width / 5 - 10) * (9 / 16),
				},
				{
					x: (width - 1 * (height * 0.2) * (16 / 9)),
					y: height * 0.75 + 10,
					width: width / 5 - 10,
					height: (width / 5 - 10) * (9 / 16),
				},
			],
		},
		CENTER_ALL_RIGHT: {
			autoFill: false,
			automaticSwitchAllowed: true,
			layers: [
				{
					x: 0,
					y: (height - ((width * 0.75 - 10) * (9 / 16))) / 2,
					width: width * 0.75 - 10,
					height: (width * 0.75 - 10) * (9 / 16),
				},
				{
					x: width * 0.75,
					y: centerAllRightPadding,
					width: width * 0.2,
					height: (width * 0.22) * (9 / 16),
				},
				{
					x: width * 0.75,
					y: height - 3 * ((width * 0.22) * (9 / 16)) - 3 * centerAllRightPadding,
					width: width * 0.2,
					height: (width * 0.22) * (9 / 16),
				},
				{
					x: width * 0.75,
					y: height - 2 * ((width * 0.22) * (9 / 16)) - 2 * centerAllRightPadding,
					width: width * 0.2,
					height: (width * 0.22) * (9 / 16),
				},
				{
					x: width * 0.75,
					y: height - 1 * ((width * 0.22) * (9 / 16)) - centerAllRightPadding,
					width: width * 0.2,
					height: (width * 0.22) * (9 / 16),
				},
			],
		},
		MOSAIC_QUAD: {
			autoFill: true,
			automaticSwitchAllowed: true,
			layers: calculateMosaic(videoSize, 2, 2),
		},
		MOSAIC_SIX: {
			autoFill: true,
			automaticSwitchAllowed: false,
			layers: calculateMosaic(videoSize, 2, 3),
		},
		MOSAIC_TWELVE: {
			autoFill: true,
			automaticSwitchAllowed: false,
			layers: calculateMosaic(videoSize, 3, 4),
		},
		MOSAIC_SIXTEEN: {
			autoFill: true,
			automaticSwitchAllowed: false,
			layers: calculateMosaic(videoSize, 4, 4),
		},
		FULL_CANVAS: {
			autoFill: false,
			automaticSwitchAllowed: true,
			layers: [
				{
					x: 0,
					y: 0,
					width,
					height,
				},
			],
		},
	};
};
