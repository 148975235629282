import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldError, Form as SchemaForm } from 'react-jsonschema-form-validation';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useCreateContact, useUpdateContact } from '../../api-hooks/contacts/contacts';
import { FormPhone } from '../Form/Phone';
import FormSubmit from '../Form/Submit';
import { useContactsModal } from './Provider';
import { schema } from './contacts.schema';

export const ContactsModal = () => {
	const { isOpen, closeModal, contactToEdit } = useContactsModal();
	const { t } = useTranslation();
	const { mutate: saveContact } = useCreateContact();
	const { mutate: updateContact } = useUpdateContact();
	const [contactData, setContactData] = useState({
		name: '',
		tel: '',
		address: '',
		email: '',
	});

	const handleChange = useCallback((e) => {
		setContactData((prevState) => ({ ...prevState, ...e }));
	}, []);

	const handleSubmit = useCallback(() => {
		if (contactToEdit?._id) {
			updateContact({ ...contactData, _id: contactToEdit?._id }, {
				onSuccess: () => closeModal(),
			});
		} else {
			saveContact({ contact: contactData }, {
				onSuccess: () => closeModal(),
			});
		}
	}, [closeModal, contactData, contactToEdit, saveContact, updateContact]);

	useEffect(() => {
		if (contactToEdit) {
			setContactData({
				name: contactToEdit.name || '',
				tel: contactToEdit.tel || '',
				address: contactToEdit.address || '',
				email: contactToEdit.email || '',
			});
		}
	}, [contactToEdit]);

	return (
		<Modal
			isOpen={isOpen}
			contentClassName="bg-light"
			toggle={closeModal}
			zIndex={2000}
		>
			<ModalHeader toggle={closeModal}>
				<strong> {t('ContactModal.Header')} </strong>
			</ModalHeader>
			<ModalBody>
				<SchemaForm
					className="h-100 d-flex flex-column"
					data={contactData}
					onChange={handleChange}
					onSubmit={handleSubmit}
					schema={schema}
				>
					<FormGroup>
						<Label for="name" className="text-secondary font-size-sm">
							<strong>{t('ContactModal.Form.Label.Name')}</strong>
						</Label>
						<Field
							className="bg-dark content-dark rounded-1 border-darker"
							component={Input}
							type="text"
							name="name"
							placeholder={t('ContactModal.Form.Placeholder.Name')}
							value={contactData.name || ''}
						/>
						<FieldError name="name" />
					</FormGroup>

					<FormGroup>
						<Label for="tel" className="text-secondary font-size-sm">
							<strong>{t('ContactModal.Form.Label.Phone')}</strong>
						</Label>
						<Field
							component={FormPhone}
							name="tel"
							placeholder={t('ContactModal.Form.Placeholder.Phone')}
							onChange={(e) => handleChange({ tel: e })}
							value={contactData.tel || ''}
							className="ShareTabSms_PhoneInput bg-dark content-dark px-2 border-dark"
						/>
						<FieldError name="phone" />
					</FormGroup>

					<FormGroup>
						<Label for="address" className="text-secondary font-size-sm">
							<strong>{t('ContactModal.Form.Label.Address')}</strong>
						</Label>
						<Field
							className="bg-dark content-dark rounded-1 border-darker"
							component={Input}
							type="text"
							name="address"
							placeholder={t('ContactModal.Form.Placeholder.Address')}
							value={contactData.address || ''}
						/>
						<FieldError name="address" />
					</FormGroup>

					<FormGroup>
						<Label for="email" className="text-secondary font-size-sm">
							<strong>{t('ContactModal.Form.Label.Email')}</strong>
						</Label>
						<Field
							className="bg-dark content-dark rounded-1 border-darker"
							component={Input}
							type="email"
							name="email"
							placeholder={t('ContactModal.Form.Placeholder.Email')}
							value={contactData.email || ''}
						/>
						<FieldError name="email" />
					</FormGroup>

					<footer className="d-flex mt-4 justify-content-end">
						<Button
							color="neutral-secondary"
							className="btn-pill mr-2"
							onClick={closeModal}
						>
							{t('ContactModal.Form.Cancel')}
						</Button>
						<FormSubmit>
							{t('ContactModal.Form.Submit')}
						</FormSubmit>
					</footer>
				</SchemaForm>
			</ModalBody>
		</Modal>
	);
};
