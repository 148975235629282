import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';
import { MdScreenShare } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import {
	useMediaScreenshare,
} from '@technomiam/react-video';

import { MediaErrorPopover } from './MediaErrorPopover';

export const LocalScreenShare = ({ className }) => {
	const { t } = useTranslation();
	const {
		screenshareActive,
		screenshareRequestError,
		toggleScreenshare,
	} = useMediaScreenshare();

	let color = 'secondary';
	if (screenshareRequestError) {
		color = 'neutral-danger';
	} else if (!screenshareActive) {
		color = 'neutral-secondary';
	}

	const animatedIconColor = screenshareRequestError ? 'danger' : 'secondary';
	// eslint-disable-next-line no-nested-ternary
	const iconColor = screenshareRequestError ? 'text-danger'
		: screenshareActive ? 'content-secondary' : 'text-secondary';
	const title = screenshareActive ? t('LocalUserMedia.LocalScreenShare.stop') : t('LocalUserMedia.LocalScreenShare.shareScreen');

	return (
		<>
			<Button
				id="LocalScreenShare"
				tag="span"
				className={clsx('LocalScreenShare btn-animated-icon', `btn-animated-icon--${animatedIconColor}`, className)}
				color={color}
				onClick={toggleScreenshare}
				title={title}
			>
				<span className="btn-wrapper--icon d-inline-block">
					<MdScreenShare className={iconColor} size={30} />
				</span>
			</Button>
			<MediaErrorPopover error={screenshareRequestError} target="LocalScreenShare" type="screen" />
		</>
	);
};

LocalScreenShare.propTypes = {
	className: PropTypes.string,
};

LocalScreenShare.defaultProps = {
	className: '',
};
