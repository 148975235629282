import {
	ADD_STREAM_TRACK,
	REMOVE_STREAM_TRACK,
	REMOVE_CHANNEL_STREAMS,
	UPDATE_STREAM,
} from '../actions/channelStreams';
import { getIdFromPublication } from '../lib/helpers';

export { STATE_KEY } from '../selectors/channelStreams';

export default function channelStreamsReducer(state = [], action) {
	switch (action.type) {
	case ADD_STREAM_TRACK: {
		const {
			appData,
			hashtag,
			mediaStreamTrack,
			own,
			peerId,
			producerId,
		} = action.payload;
		const {
			streamType,
			user,
		} = appData;
		const { id: trackId } = mediaStreamTrack;
		let streamIndex = -1;

		const id = getIdFromPublication(action.payload);

		let stream = state.find((s, i) => {
			if (
				s.id === id
			) {
				streamIndex = i;
				return true;
			}
			return false;
		});

		stream = stream ?? {
			hashtag,
			id,
			own,
			peerId,
			producers: [],
			streamType,
			tracks: [],
			user,
		};

		if (!stream.tracks.includes(trackId)) {
			stream = {
				...stream,
				tracks: [...stream.tracks, trackId],
			};
		}

		if (!stream.producers.includes(producerId)) {
			stream = {
				...stream,
				producers: [...stream.producers, producerId],
			};
		}

		if (streamIndex > -1) {
			state = [
				...state.slice(0, streamIndex),
				stream,
				...state.slice(streamIndex + 1),
			];
		} else {
			state = [...state, stream];
		}

		return state;
	}
	case REMOVE_STREAM_TRACK: {
		const { hashtag, mediaStreamTrack, producerId } = action.payload;
		const trackId = mediaStreamTrack.id;
		let streamIndex;

		let stream = state.find((s, i) => {
			if (
				s.hashtag === hashtag
				&& s.tracks.includes(trackId)
			) {
				streamIndex = i;
				return true;
			}
			return false;
		});

		if (!stream) return state;

		stream = {
			...stream,
			producers: stream.producers.filter((id) => id !== producerId),
			tracks: stream.tracks.filter((id) => id !== trackId),
		};

		if (stream.tracks.length < 1) {
			state = [
				...state.slice(0, streamIndex),
				...state.slice(streamIndex + 1),
			];
		} else {
			state = [
				...state.slice(0, streamIndex),
				stream,
				...state.slice(streamIndex + 1),
			];
		}

		return state;
	}
	case REMOVE_CHANNEL_STREAMS: {
		const { hashtag } = action.payload;
		return state.filter((stream) => stream.hashtag !== hashtag);
	}
	case UPDATE_STREAM: {
		return state;
	}
	default:
		return state;
	}
}
