import { createSelector } from 'reselect';

import { createChannelSelector } from '../lib/selector';
import { STREAM_TYPE_LIVE, STREAM_TYPE_PREVIEW } from '../../lib/stream';

export const STATE_KEY = 'channelStreams';

/**
 * selectors
 */
export const select = (state) => state[STATE_KEY];

export const selectChannelStreams = createChannelSelector(
	select,
	(_, { hashtag }) => hashtag,
	(channelsStreams, hashtag) => channelsStreams.filter(
		(channelStream) => channelStream.hashtag === hashtag,
	),
);

export const selectChannelLiveStream = createSelector(
	selectChannelStreams,
	(channelStreams) => channelStreams.find(({ streamType }) => streamType === STREAM_TYPE_LIVE),
);

export const selectChannelParticipantsStreams = createSelector(
	selectChannelStreams,
	(channelStreams) => channelStreams.filter(({ streamType }) => streamType === STREAM_TYPE_PREVIEW),
);

export const selectChannelOwnStreams = createSelector(
	selectChannelStreams,
	(channelStreams) => channelStreams.filter(({ own }) => !!own),
);

export const selectChannelOtherStreams = createSelector(
	selectChannelStreams,
	(channelStreams) => channelStreams.filter(({ own }) => !own),
);

export const selectIsPublicationChannelStreamStored = createSelector(
	select,
	(_, publication) => publication,
	(channelStreams = [], publication) => channelStreams
		.find(({ producers }) => (
			!!producers.includes(publication.producerId)
		)),
);
