import { authenticatedApi } from '../api';

export const API_CHANNEL_IMAGES_PATH = '/channel/images';

export const createImage = async ({ channelId, image }) => authenticatedApi.post(
	`${API_CHANNEL_IMAGES_PATH}/toChannelById/${channelId}`,
	image,
);

export const fetchImages = (
	channelId,
	mode,
	itemsPerPage,
	currentPage,
) => authenticatedApi.get(
	`${API_CHANNEL_IMAGES_PATH}`,
	{
		params: {
			channelId,
			mode,
			itemsPerPage,
			currentPage,
		},
	},
);

export const updateImage = async (image) => authenticatedApi.put(
	API_CHANNEL_IMAGES_PATH,
	image,
);

export const deleteImage = async (imageId) => authenticatedApi.delete(
	`${API_CHANNEL_IMAGES_PATH}/${imageId}`,
);
