import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { Path, getLink } from '../../RoutePath';
import { useFetchHomePageVod } from '../../api-hooks/home';
import { ChannelListHexagon } from '../../components/Channel/ChannelListHexagon/ChannelListHexagon';
import { ListLoading } from '../../components/Channel/ItemList/Loading';
import { ChannelSlider } from '../../components/Channel/Slider/Slider';
import { VideoListSlider } from '../../components/Channel/VideoListSlider/VideoListSlider';
import ReallyPerfectScrollbar from '../../components/ReallyPerfectScrollbar/ReallyPerfectScrollbar';
import { SHOW_COMPLETE_PROFILE_MODAL_KEY } from '../../components/Signup/Form';
import { useUserPreferences } from '../../components/UserPreferences/Context';
import { MyAccountModalTab, UserSettingsModal, useUserSettings } from '../../components/UserSettings/Context';
import { useAsyncErrorLog } from '../../lib/hooks';
import { Footer } from '../Footer/Footer';
import './Home.scss';

const MEET_THE_BEES_LIMIT = 50;
const VIDEOS_LIMIT = 16;

const ViewHome = () => {
	const { t } = useTranslation();
	const matchSuggested = useRouteMatch({
		path: getLink(Path.HOME_ANCHOR, { anchor: 'suggested' }),
		strict: true,
		sensitive: true,
	});
	const matchFollowed = useRouteMatch({
		path: getLink(Path.HOME_ANCHOR, { anchor: 'followed' }),
		strict: true,
		sensitive: true,
	});
	const matchTop = useRouteMatch({
		path: getLink(Path.HOME_ANCHOR, { anchor: 'watch-videos' }),
		strict: true,
		sensitive: true,
	});
	const matchCominUpLive = useRouteMatch({
		path: getLink(Path.HOME_ANCHOR, { anchor: 'coming-up-live' }),
		strict: true,
		sensitive: true,
	});
	const matchMeetTheBees = useRouteMatch({
		path: getLink(Path.HOME_ANCHOR, { anchor: 'meet-the-bees' }),
		strict: true,
		sensitive: true,
	});

	const followedRef = useRef();
	const scrollbar = useRef();
	const suggestedRef = useRef();
	const topRef = useRef();
	const comingUpLiveRef = useRef();
	const meetTheBeesRef = useRef();

	const {
		data: vodData,
		error: homePageVodError,
		refetch: fetchHomePageVod,
		isLoading,
	} = useFetchHomePageVod();
	useAsyncErrorLog({ error: homePageVodError });

	const { openModal } = useUserSettings();

	const { getUserPreference, setUserPreferences } = useUserPreferences();

	const showUserProfileModal = useMemo(
		() => getUserPreference(SHOW_COMPLETE_PROFILE_MODAL_KEY),
		[getUserPreference],
	);

	useEffect(() => {
		if (showUserProfileModal) {
			openModal(UserSettingsModal.ACCOUNT, MyAccountModalTab.DETAILS);
			setUserPreferences(
				(s) => ({ ...s, [SHOW_COMPLETE_PROFILE_MODAL_KEY]: false }),
			);
		}
	}, [openModal, showUserProfileModal, setUserPreferences]);

	useEffect(() => {
		if (matchFollowed && followedRef.current?.offsetTop) {
			scrollbar.current.scrollTop = (followedRef.current.offsetTop - 100);
		}
		if (matchSuggested && suggestedRef.current?.offsetTop) {
			scrollbar.current.scrollTop = (suggestedRef.current.offsetTop - 100);
		}
		if (matchTop && topRef.current?.offsetTop) {
			scrollbar.current.scrollTop = (topRef.current.offsetTop - 80);
		}
		if (matchCominUpLive && comingUpLiveRef.current?.offsetTop) {
			scrollbar.current.scrollTop = (comingUpLiveRef.current.offsetTop - 100);
		}
		if (matchMeetTheBees && meetTheBeesRef.current?.offsetTop) {
			scrollbar.current.scrollTop = (meetTheBeesRef.current.offsetTop - 100);
		}
	});

	return (
		<div className="ViewHome app-inner-content-layout--main">
			<ReallyPerfectScrollbar ref={scrollbar}>
				<div className="app-content--inner--spacing p-0 overflow-hidden pt-4">{/* overflow-hidden because of slider next-stick */}
					{(vodData?.live?.length > 0) && (
						<ChannelSlider
							className="mw-100"
							forwardedRef={comingUpLiveRef}
							refetch={fetchHomePageVod}
							title={t('Home.comingUpLive')}
							lives={vodData?.live}
						/>
					)}
					<ChannelListHexagon
						channels={vodData?.channels}
						forwardedRef={meetTheBeesRef}
						placeholderLength={MEET_THE_BEES_LIMIT}
						title={t('Home.meetThebees')}
					/>
					<VideoListSlider
						videos={vodData?.top}
						forwardedRef={meetTheBeesRef}
						placeholderLength={VIDEOS_LIMIT}
						title={t('Home.top')}
					/>
					{vodData?.followed ? (
						<VideoListSlider
							videos={vodData.followed}
							ref={followedRef}
							title={t('Home.followed')}
						/>
					) : null}
					<div className="pl-md-5 mt-5">
						{vodData?.suggested ? (
							<VideoListSlider
								videos={vodData.suggested}
								ref={suggestedRef}
								title={t('Home.suggested')}
							/>
						) : null}
					</div>
					{isLoading ? <ListLoading /> : null}
					<Footer />
				</div>
			</ReallyPerfectScrollbar>
		</div>
	);
};

ViewHome.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			tags: PropTypes.string,
		}),
	}),
};

ViewHome.defaultProps = {
	match: null,
};

export default React.memo(ViewHome);
