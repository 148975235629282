import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldError } from 'react-jsonschema-form-validation';
import { FormGroup } from 'reactstrap';
import PhoneInput from 'react-phone-number-input';
import countries from 'i18n-iso-countries';
import { useTranslation } from 'react-i18next';
import { InputVerification, InputVerificationOrientation } from '../../Input/Verification';
import { PhoneInputInput } from '../../Phone/PhoneInputInput';
import { VerificationType } from '../../../lib/VerificationType';

export const VerifiedPhoneInput = ({ phoneNumber, country, onChange, orientation }) => {
	const { t } = useTranslation();

	return (
		<FormGroup>
			<InputVerification
				value={phoneNumber}
				type={VerificationType.PHONE}
				input={(
					<Field
						component={PhoneInput}
						inputComponent={PhoneInputInput}
						defaultCountry={countries.isValid(country) ? country : 'US'}
						international
						name="phoneNumber"
						placeholder={t('Input.Verified.phone')}
						onChange={onChange}
						value={phoneNumber}
					/>
				)}
				orientation={orientation}
			/>
			<FieldError name="phoneNumber" />
		</FormGroup>
	);
};

VerifiedPhoneInput.propTypes = {
	phoneNumber: PropTypes.string,
	country: PropTypes.string,
	onChange: PropTypes.func,
	orientation: PropTypes.oneOf(Object.values(InputVerificationOrientation)),
};

VerifiedPhoneInput.defaultProps = {
	phoneNumber: undefined,
	country: undefined,
	onChange: undefined,
	orientation: InputVerificationOrientation.VERTICAL,
};
