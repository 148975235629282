import React, { useState, useEffect } from 'react';
import { Input, Modal, ModalHeader, ModalBody, Label, FormGroup } from 'reactstrap';
import { FaExclamationTriangle } from 'react-icons/fa';
import shuffle from 'lodash.shuffle';
import { Trans, useTranslation } from 'react-i18next';

import {
	browser,
	isAndroid,
	isIOS,
	isMacOS,
} from '../../lib/userAgent';
import { supportedBrowsers } from './compatibilityList';
import { ButtonPillOutline } from '../Button';

const { parsedResult } = browser;

let currentOs = 'desktop';
if (isAndroid) currentOs = 'android';
if (isIOS) currentOs = 'iOS';
if (isMacOS) currentOs = 'macOS';

const supportedBrowsersOnCurrentOs = supportedBrowsers.filter(({ os }) => os.includes(currentOs));

const currentBrowserName = parsedResult.browser.name;

const currentOsName = parsedResult.os.name;

const currentBrowserVersionStr = parsedResult.browser.version;
const currentBrowserVersion = {
	major: parseInt(currentBrowserVersionStr.slice(0, currentBrowserVersionStr.indexOf('.')), 10),
	minor: parseInt(currentBrowserVersionStr.slice(currentBrowserVersionStr.indexOf('.') + 1), 10),
};

const currentBrowserSupported = supportedBrowsersOnCurrentOs.find(
	(b) => b.name === currentBrowserName,
);

const isBrowserSupported = !!currentBrowserSupported;

const getIsVersionSupported = (currentVersion, supportedVersion) => {
	if (currentVersion.major < supportedVersion.major) return false;
	if (
		currentVersion.major === supportedVersion.major
		&& currentVersion.minor < supportedVersion.minor
	) return false;
	return true;
};

const isVersionSupported = currentBrowserSupported
	&& getIsVersionSupported(currentBrowserVersion, currentBrowserSupported.minBrowserVersion);

const shuffledBrowsers = shuffle(supportedBrowsersOnCurrentOs || []);

const LOCAL_STORAGE_KEY = 'beeyou_hideCompatibilityModal';

const SupportedBrowserList = () => (
	<ul>
		{shuffledBrowsers.map((supportedBrowser) => (
			<li key={supportedBrowser.name}>
				<a
					href={supportedBrowser.linkInstall}
					rel="noopener noreferrer"
					target="_blank"
				>
					{supportedBrowser.fullname}
				</a>
			</li>
		))}
	</ul>
);

const shouldHideCompatibilityModal = localStorage.getItem(LOCAL_STORAGE_KEY) === 'true';

export const BrowserCompatibility = () => {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const [hideCompatibilityModal, setHideCompatibilityModal] = useState(false);

	const handleChange = ({ target }) => {
		setHideCompatibilityModal(target.checked);
	};

	const handleClose = () => {
		if (hideCompatibilityModal) localStorage.setItem(LOCAL_STORAGE_KEY, true);
		setIsOpen(false);
	};

	useEffect(() => {
		if (shouldHideCompatibilityModal) return;
		if (!isVersionSupported) setIsOpen(true);
	}, []);

	return (
		<Modal
			centered
			contentClassName="border-0 shadow-lg rounded overflow-hidden bg-transparent m-auto"
			isOpen={isOpen}
			zIndex={2000}
		>
			<ModalHeader className="bg-dark border-darker">
				<FaExclamationTriangle className="text-primary" />
				{' '}
				{!isBrowserSupported
					? t('BrowserCompatibility.browserNotFullySupported')
					: t('BrowserCompatibility.requiresLatestVersion')}
			</ModalHeader>
			<ModalBody className="bg-light">
				<p>
					<Trans i18nKey="BrowserCompatibility.beeyouAim">
						At BeeYou.tv, we aim at delivering complex functionalities,
						such as live streaming and video conferencing, over simple mediums,
						such as Web browsers.
					</Trans>
				</p>
				<p>
					<Trans i18nKey="BrowserCompatibility.navigatorVersion">
						Although we work daily to ensure compatibility with as many Web browsers
						and versions as possible, at the moment, we can guarantee that, while
						using {{ currentOsName }}, BeeYou.tv user experience is maximised by also using
						the latest version of
					</Trans>
					{!isBrowserSupported
						? t('BrowserCompatibility.oneOf')
						: `${currentBrowserName}.`}
				</p>
				{!isBrowserSupported
					? <SupportedBrowserList />
					: (
						<p>
							{t('BrowserCompatibility.moreInformations')}{' '}
							<a href={currentBrowserSupported.linkUpdate} rel="noopener noreferrer" target="_blank">
								{t('BrowserCompatibility.here')}
							</a>.
						</p>
					)}
				<div className="p-2 bg-dark border-radius">
					<FormGroup check>
						<Label className="mb-0">
							<Input
								checked={hideCompatibilityModal}
								name="hideCompatibilityModal"
								type="checkbox"
								value="true"
								onChange={handleChange}
							/>
							{t('BrowserCompatibility.noMoreAlert')}
						</Label>
					</FormGroup>
				</div>
				<footer className="d-flex mt-3">
					<ButtonPillOutline className="mx-auto" onClick={handleClose}>{t('BrowserCompatibility.close')}</ButtonPillOutline>
				</footer>
			</ModalBody>
		</Modal>
	);
};
