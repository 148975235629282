const code = {
	type: 'string',
	pattern: '^[0-9]{6}$',
};

export const properties = {
	code,
};

export const schema = {
	type: 'object',
	additionalProperties: false,
	properties,
	required: [
		'code',
	],
};
