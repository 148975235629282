import thunkMiddleware from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import {
	createStore,
	applyMiddleware,
} from 'redux';
import { createLogger } from 'redux-logger';

import reducers from './reducers';

const middlewares = [
	thunkMiddleware,
	promiseMiddleware,
];

if (import.meta.env.NODE_ENV !== 'production') {
	const logger = createLogger({
		predicate: () => true,
		collapsed: () => true,
	});
	middlewares.push(logger);
}

const store = createStore(
	reducers,
	applyMiddleware(...middlewares),
);

export default store;
