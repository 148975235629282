import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import {
	Button,
} from 'reactstrap';
import { FaHandPaper } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

export const GuestHandRaising = ({ onClickRaiseHand, isHandRaised, className }) => {
	const { t } = useTranslation();
	return (
		<Button
			id="GuestHandRaising"
			className={clsx('GuestHandRaising ', className)}
			color="neutral-secondary"
			onClick={onClickRaiseHand}
			disabled={isHandRaised}
			title={t('Guest.HandRaising.raiseHand')}
		>
			<span className="btn-wrapper--icon d-inline-block">
				<FaHandPaper />
			</span>
		</Button>
	);
};

GuestHandRaising.propTypes = {
	className: PropTypes.string,
	onClickRaiseHand: PropTypes.func.isRequired,
	isHandRaised: PropTypes.bool,
};

GuestHandRaising.defaultProps = {
	className: '',
	isHandRaised: false,
};
