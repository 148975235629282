import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useCallback } from 'react';
import { axiosQueryWrapper, axiosMutationWrapper } from '../utils/axios-wrapper';
import * as commentsApi from '../../api/channel/comments';
import { useHandleUpdatedPost } from './posts';

const COMMENT_QUERY_KEYS = {
	fetchCommentsByPost: (postId) => ['api', 'channel', 'posts', 'comments', postId],
};

export const useFetchPostComments = (postId) => useQuery(
	COMMENT_QUERY_KEYS.fetchCommentsByPost(postId),
	axiosQueryWrapper(commentsApi.fetchPostComments, postId),
);

export const useHandleNewPostComment = (hashtag) => {
	const queryClient = useQueryClient();
	const handleUpdatedPost = useHandleUpdatedPost(hashtag);

	return useCallback((comment) => {
		queryClient.setQueryData(
			COMMENT_QUERY_KEYS.fetchCommentsByPost(comment.post._id),
			(comments) => (comments?.length ? [comment, ...comments] : [comment]),
		);

		handleUpdatedPost(comment.post);
	}, [handleUpdatedPost, queryClient]);
};

export const useHandleUpdatePostComment = () => {
	const queryClient = useQueryClient();

	return useCallback((updatedComment) => {
		queryClient.setQueryData(
			COMMENT_QUERY_KEYS.fetchCommentsByPost(updatedComment.post._id),
			(comments) => comments
				?.map((comment) => (
					comment._id === updatedComment._id
						? {
							...updatedComment,
							liked: comment.liked,
						}
						: comment
				)),
		);
	}, [queryClient]);
};

export const useHandleDeletePostComment = (hashtag) => {
	const queryClient = useQueryClient();
	const handleUpdatedPost = useHandleUpdatedPost(hashtag);

	return useCallback((deletedComment) => {
		queryClient.setQueryData(
			COMMENT_QUERY_KEYS.fetchCommentsByPost(deletedComment.post._id),
			(comments) => comments
				?.filter((comment) => (comment._id !== deletedComment._id)),
		);

		handleUpdatedPost(deletedComment.post);
	}, [handleUpdatedPost, queryClient]);
};

export const useHandlePostCommentLikedChanged = () => {
	const queryClient = useQueryClient();

	return useCallback((likedComment, liked) => {
		queryClient.setQueryData(
			COMMENT_QUERY_KEYS.fetchCommentsByPost(likedComment.post._id),
			(comments) => comments
				?.map((comment) => (comment._id === likedComment._id ? {
					...comment,
					liked,
				} : comment)),
		);
	}, [queryClient]);
};

export const useHandlePostCommentLikesChange = () => {
	const queryClient = useQueryClient();

	return useCallback((likedComment) => {
		queryClient.setQueryData(
			COMMENT_QUERY_KEYS.fetchCommentsByPost(likedComment.post),
			(comments) => comments
				?.map((comment) => (comment._id === likedComment._id
					? {
						...comment,
						numberOfLikes: likedComment.numberOfLikes,
					}
					: comment
				)),
		);
	}, [queryClient]);
};

export const useCreatePostComment = () => useMutation(
	axiosMutationWrapper(commentsApi.createPostComment),
);

export const useUpdatePostComment = () => useMutation(
	async ({ id, text, images, videos }) => axiosMutationWrapper(
		commentsApi.updatePostComment,
	)({ id, text, images, videos }),
);

export const useDeletePostComment = () => useMutation(
	axiosMutationWrapper(commentsApi.deletePostComment),
);

export const useLikePostComment = () => useMutation(
	axiosMutationWrapper(commentsApi.likePostComment),
);

export const useUnlikePostComment = () => useMutation(
	axiosMutationWrapper(commentsApi.unLikePostComment),
);
