import React from 'react';
import { createRoot } from 'react-dom/client';

import './lib/shims';
import './lib/session';
import './lib/fixViewportHeight';
import App from './App';
import './i18n';

const container = document.getElementById('root');
const root = createRoot(container);
//Wait reacstrap fix findDOMNode to enable StrictMode
root.render(
	<App />
	,
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

const {
	VITE_BUILD_ID,
	VITE_VERSION,
} = import.meta.env;

const { NODE_ENV } = process.env;
// eslint-disable-next-line no-console
console.info(`ℹ️ v${VITE_VERSION}/${VITE_BUILD_ID}`);
if (process.env.NODE_ENV !== 'production') {
	// eslint-disable-next-line no-console
	console.info(`ℹ️ NODE_ENV: ${NODE_ENV}`);
}
