import React from 'react';
import PropTypes from 'prop-types';
import { SurveyTemplateProvider } from './SurveyTemplateProvider';
import { SurveyProvider } from './SurveyProvider';
import { SurveyModals } from '../Modals';

export const SurveyProviderWrapper = ({ children }) => (
	<SurveyTemplateProvider>
		<SurveyProvider>
			{children}
			<SurveyModals />
		</SurveyProvider>
	</SurveyTemplateProvider>
);

SurveyProviderWrapper.propTypes = {
	children: PropTypes.node,
};

SurveyProviderWrapper.defaultProps = {
	children: undefined,
};
