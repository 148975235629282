import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal, Row } from 'reactstrap';
import { FaHandHoldingUsd, FaTimes } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import { ButtonPill } from '../Button';
import { UserSettingsModal, useUserSettings } from '../UserSettings/Context';
import { MembershipModalTab } from '../UserSettings/MembershipModalTab';
import './CompleteModal.scss';

export const CompleteProfileModal = ({
	isOpen,
	close,
}) => {
	const { t } = useTranslation();
	const { openModal } = useUserSettings();

	const handleCompleteProfile = useCallback(() => {
		close();
		openModal(UserSettingsModal.ACCOUNT);
	}, [close, openModal]);

	const handleBuyPoints = useCallback(() => {
		close();
		openModal(UserSettingsModal.MEMBERSHIP, MembershipModalTab.BUY_POINTS);
	}, [close, openModal]);

	return (
		<Modal
			centered
			contentClassName="CompleteProfileModal border-0 shadow-lg rounded overflow-hidden bg-transparent m-auto p-5"
			isOpen={isOpen}
			toggle={close}
			zIndex={2000}
			size="lg"
		>
			{isOpen ? (
				<div className="rounded overflow-hidden position-relative">
					<Button
						onClick={close}
						color="transparent shadow-none"
						className="position-absolute top-0 right-0 mt-2 mr-1 cursor-pointer"
					>
						<FaTimes className="content-primary" />
					</Button>
					<Row className="g-0 h-100 w-100 bg-primary d-flex justify-content-center align-items-center">
						<Col xs="10" className="d-flex flex-column py-5 mt-4">
							<div className="w-100 d-flex justify-content-center">
								<FaHandHoldingUsd size="4em" className="text-center" />
							</div>
							<h4 className="text-center font-weight-bold mt-5 text-dark">
								{t('Profile.CompleteModal.completeToWin')}
							</h4>
							<div className="CompleteProfileModalButton d-flex justify-content-center w-100 mt-3 px-5 mb-5">
								<ButtonPill
									onClick={handleCompleteProfile}
									color="white"
									className="btn-pill mx-1 CompleteProfileModal-Button"
								>
									<span className="btn-wrapper--label ">{t('Profile.CompleteModal.completeProfile')}</span>
								</ButtonPill>
								<ButtonPill
									onClick={handleBuyPoints}
									color="white"
									className="btn-pill mx-1 CompleteProfileModal-Button"
								>
									<span className="btn-wrapper--label">{t('Profile.CompleteModal.buyPoints')}</span>
								</ButtonPill>
							</div>
						</Col>
					</Row>
				</div>
			) : null}
		</Modal>
	);
};

CompleteProfileModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	close: PropTypes.func.isRequired,
};
