import React, { useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ChannelShareContext } from './Context';
import { ChannelShareModal } from './Modal';
import { ResourceAccessRole } from '../../../lib/ResourceAccessRole';
import { ShareTabType } from '../../Share/Share.helper';

export const ChannelShare = ({
	channel,
	children,
	post,
	roles,
	shareTabTypes,
	studio,
	video,
}) => {
	const [isShareModalOpen, setIsShareModalOpen] = useState(false);
	const [container, setContainer] = useState('body');
	const [elementForFullScreen, setElementForFullScreen] = useState(null);

	const isVideoFullScreen = useCallback(() => {
		let srcType = null;
		const sizeBody = document.body.getBoundingClientRect();
		if (document.querySelector(`#source-${video?._id}`) || document.querySelector(`#vod-${video?._id}`)) {
			if (document.querySelector(`#source-${video?._id}`)) srcType = 'source';
			if (document.querySelector(`#vod-${video?._id}`)) srcType = 'vod';
			const sizeVideo = document.querySelector(`#${srcType}-${video?._id}`).getBoundingClientRect();
			setElementForFullScreen(srcType);
			return sizeBody.width === sizeVideo.width && sizeBody.height === sizeVideo.height;
		}
		return false;
	}, [video]);

	useEffect(() => {
		const toggleFullscreen = () => {
			if (!document.fullscreenElement && isShareModalOpen) {
				setIsShareModalOpen(false);
				setContainer('body');
				setTimeout(() => setIsShareModalOpen(true), 500);
			}
		};
		document.addEventListener('fullscreenchange', toggleFullscreen, false);
		document.addEventListener('webkitfullscreenchange', toggleFullscreen, false);
		return () => {
			document.removeEventListener('fullscreenchange', toggleFullscreen, false);
			document.removeEventListener('webkitfullscreenchange', toggleFullscreen, false);
		};
	}, [container, isShareModalOpen]);

	const context = useMemo(() => ({
		isShareModalOpen,
		setIsShareModalOpen,
		toggle: () => {
			setContainer((isVideoFullScreen() && elementForFullScreen) ? `#${elementForFullScreen}-${video?._id}` : 'body');
			setIsShareModalOpen((s) => !s);
		},
		container,
	}), [
		isShareModalOpen,
		setIsShareModalOpen,
		isVideoFullScreen,
		video?._id,
		container,
		elementForFullScreen,
	]);

	return (
		<ChannelShareContext.Provider value={context}>
			{children}
			{isShareModalOpen ? (
				<ChannelShareModal
					channel={channel}
					isOpen={isShareModalOpen}
					roles={roles}
					studio={studio}
					shareTabTypes={shareTabTypes}
					toggle={context.toggle}
					video={video}
					post={post}
					container={context.container}
				/>
			) : null}
		</ChannelShareContext.Provider>
	);
};

ChannelShare.propTypes = {
	channel: PropTypes.shape({}),
	children: PropTypes.node.isRequired,
	roles: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(ResourceAccessRole))),
	shareTabTypes: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(ShareTabType))).isRequired,
	studio: PropTypes.shape({}),
	video: PropTypes.shape({}),
	post: PropTypes.shape({}),
};

ChannelShare.defaultProps = {
	channel: undefined,
	roles: [],
	studio: undefined,
	video: undefined,
	post: undefined,
};
