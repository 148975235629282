import axios from 'axios';
import axiosBetterStacktrace from 'axios-better-stacktrace';

import { rollbar } from './rollbar';

let xSessionId;

const sessionInterceptor = (config) => {
	config = {
		...config,
		headers: {
			...config.headers,
			'x-session-id': xSessionId,
		},
	};
	return config;
};

const reportedErrorStatusCodes = [422, 500];

const rollbarRequestErrorInterceptor = (error) => {
	const request = {
		method: error.config?.method,
		url: `${error.config?.baseURL}${error.config?.url}`,
	};

	rollbar.error(
		'A network request error occured',
		error,
		{
			request,
			errorRequestData: error.request?.data,
		},
	);

	return Promise.reject(error);
};

const rollbarErrorInterceptor = (error) => {
	if (!error.response || reportedErrorStatusCodes.includes(error.response?.status)) {
		const request = {
			method: error.config?.method,
			url: `${error.config?.baseURL}${error.config?.url}`,
		};
		rollbar.error(
			`A ${error.response?.status || 'network'} server error occured`,
			error,
			{
				request,
				errorResponseData: error.response?.data,
			},
		);
	}
	return Promise.reject(error);
};

export const createAxiosInstance = (...args) => {
	const axiosInstance = axios.create(...args);

	axiosBetterStacktrace(axiosInstance);
	// Interceptor to modify axiosBetterStacktrace
	// in order to have the error stack trace with the correct source maps
	axiosInstance.interceptors.response.use(null, (error) => {
		try {
			if (error.originalStack) { // from axiosBetterStacktrace
				const errorName = error.originalStack.substr(0, error.originalStack.indexOf('\n'));
				error.stack = error.stack.replace(
					`${error.originalStack}`,
					'',
				);
				error.stack = error.stack.replace(
					'\nError: Axios Better Stacktrace',
					errorName,
				);
			}
			throw error;
		} catch (err) {
			console.error(err);
			throw error;
		}
	});

	axiosInstance.interceptors.request.use(sessionInterceptor);
	axiosInstance.interceptors.request.use(null, rollbarRequestErrorInterceptor);
	axiosInstance.interceptors.response.use(null, rollbarErrorInterceptor);
	return axiosInstance;
};

export const setSessionId = (sessionId) => {
	xSessionId = sessionId;
};
