import React, { useMemo } from 'react';
import './ChevronIcon.scss';
import clsx from 'clsx';
import PropTypes from 'prop-types';

export const ChevronIconDirection = {
	LEFT: 'LEFT',
	RIGHT: 'RIGHT',
};

export const ChevronIcon = ({ className, direction }) => {
	const directionClassname = useMemo(() => (direction === ChevronIconDirection.LEFT ? 'arrow-left' : 'arrow-right'), [direction]);

	return <div className={clsx('chevron', className, directionClassname)} />;
};

ChevronIcon.propTypes = {
	direction: PropTypes.string,
	className: PropTypes.string,
};

ChevronIcon.defaultProps = {
	direction: ChevronIconDirection.LEFT,
	className: '',
};
