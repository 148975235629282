import { authenticatedApi, publicApi } from './api';

const API_VERIFICATION_PATH = '/verification';

export const verificationBegin = async ({ email, phoneNumber }) => authenticatedApi.post(
	`${API_VERIFICATION_PATH}/begin`,
	{ email, phoneNumber },
);

export const verificationConfirm = async ({ verification_token, code }) => authenticatedApi.post(
	`${API_VERIFICATION_PATH}/confirm`,
	{ verification_token, code },
);

export const verificationResend = async (verificationToken) => publicApi.post(
	`${API_VERIFICATION_PATH}/resend`,
	{
		verification_token: verificationToken,
	},
);
