import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label, Alert } from 'reactstrap';
import { Form, Field, FieldError } from 'react-jsonschema-form-validation';
import { useAsyncCallback } from 'react-async-hook';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import { useAsyncErrorLog, useQueryParams } from '../../lib/hooks';
import FormSubmit from '../Form/Submit';
import { ButtonPillOutline } from '../Button/Pill';
import * as resourceAccessApi from '../../api/resourceAccess';
import { ResourceAccessCard } from './Card';
import { ResourceAccessRole } from '../../lib/ResourceAccessRole';
import { getLink, Path } from '../../RoutePath';

const schema = {
	type: 'object',
	additionalProperties: false,
	properties: {
		password: {
			type: 'string',
			minLength: 6,
		},
	},
	required: [
		'password',
	],
};

const cleanPassword = (text) => text.replace(/[^0-9]/g, '');

export const ResourceAccessEnterPassword = ({
	resourceType,
	resourceId,
	role,
	onSuccess,
}) => {
	const { t } = useTranslation();
	const [data, setData] = useState({ password: '' });

	const handleSubmitAsync = useAsyncCallback(async (password) => {
		await resourceAccessApi.verifyPassword(resourceType, resourceId, role, password);
		return onSuccess();
	});

	const query = useQueryParams();
	const pwd = query.get('pwd');

	const handleSubmitAsyncExecuteRef = useRef(handleSubmitAsync.execute);
	useEffect(() => {
		handleSubmitAsyncExecuteRef.current = handleSubmitAsync.execute;
	});

	useEffect(() => {
		if (!pwd) return;
		setData({ password: pwd });
		handleSubmitAsyncExecuteRef.current(pwd);
	}, [pwd]);

	const { error } = handleSubmitAsync;
	const unexpectedErrorMsg = t('Global.error');

	useAsyncErrorLog({ error });

	const submitErrorMessage = error && (error.response?.data?.message || unexpectedErrorMsg);

	let label = '';
	if (resourceType === 'STUDIO') {
		label = t('RessourceAccess.EnterPassword.label');
	} else if (resourceType === 'VIDEO') {
		label = t('RessourceAccess.EnterPassword.labelVideo');
	}

	return (
		<ResourceAccessCard>
			<h3>
				<Trans i18nKey="RessourceAccess.EnterPassword.private" label={label}>
					This {{ label }} requires a password.
				</Trans>
			</h3>
			<p>
				{t('RessourceAccess.EnterPassword.typePassword')}
			</p>
			<Form
				className="text-left"
				data={data}
				schema={schema}
				onSubmit={() => handleSubmitAsync.execute(data.password)}
			>
				<FormGroup>
					<Label className="text-secondary">{t('RessourceAccess.EnterPassword.password')}</Label>
					<Field
						component={Input}
						name="password"
						value={data.password}
						onChange={(e) => setData({ password: cleanPassword(e.target.value) })}
						type="text"
						maxLength="6"
						placeholder={`${label} password`}
					/>
					<FieldError name="password" />
				</FormGroup>
				{submitErrorMessage && (
					<Alert color="danger">
						{submitErrorMessage}
					</Alert>
				)}
				<footer className="mt-4 mx-auto d-flex">
					<ButtonPillOutline
						color="secondary"
						className="mr-2"
						tag={Link}
						to={getLink(Path.ROOT)}
					>
						{t('RessourceAccess.EnterPassword.cancel')}
					</ButtonPillOutline>
					<FormSubmit
						className="btn-pill shadow-none btn btn-primary ml-auto"
						loading={handleSubmitAsync.loading}
						disabled={handleSubmitAsync.loading}
					>
						{t('RessourceAccess.EnterPassword.verify')}
					</FormSubmit>
				</footer>
			</Form>
		</ResourceAccessCard>
	);
};

ResourceAccessEnterPassword.propTypes = {
	onSuccess: PropTypes.func.isRequired,
	resourceId: PropTypes.string.isRequired,
	resourceType: PropTypes.string.isRequired,
	role: PropTypes.oneOf(Object.values(ResourceAccessRole)).isRequired,
};
