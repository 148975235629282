import { authenticatedApi } from './api';

const API_RESOURCE_ACCESS_PATH = '/resourceAccess';

export const buy = (
	resourceType,
	resourceId,
	role,
	amounts,
) => authenticatedApi.post(
	`${API_RESOURCE_ACCESS_PATH}/buy`,
	{
		resourceType,
		resourceId,
		role,
		amounts,
	},
);

export const verifyPassword = (
	resourceType,
	resourceId,
	role,
	password,
) => authenticatedApi.post(
	`${API_RESOURCE_ACCESS_PATH}/verifyPassword`,
	{
		password,
		resourceType,
		resourceId,
		role,
	},
);
