import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa';
import { Button, ListGroup, ListGroupItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import ReallyPerfectScrollbar from '../../ReallyPerfectScrollbar/ReallyPerfectScrollbar';
import { useVote } from '../Provider';
import { TemplateHistoryItem } from './HistoryItem';
import { ListSortingSelect } from '../../ListSorting/Select';
import { useListSorting, ListSortingType } from '../../ListSorting/Provider';

export const TemplateHistory = ({
	onTemplatePreviewClick,
	onCreateTemplateClick,
	onDeleteTemplateClick,
	currentTemplate,
	onDuplicateClick,
	minimal,
}) => {
	const { t } = useTranslation();
	const { voteTemplates, fetchVoteTemplates } = useVote();
	const { currentSort } = useListSorting();

	const [extendedCategory, setExtendedCategory] = useState(null);
	const extendCategory = useCallback((category) => () => {
		setExtendedCategory((oldSelected) => (oldSelected === category ? null : category));
	}, []);

	const categories = useMemo(() => {
		const categoryMap = voteTemplates?.data?.reduce((acc, cur) => {
			if (!acc[cur.category || 'Uncategorized']) {
				acc[cur.category || 'Uncategorized'] = [];
			}

			acc[cur.category || 'Uncategorized'].push(cur);
			return acc;
		}, {}) ?? {};

		return Object.keys(categoryMap)
			.map((category) => ({ category, templates: categoryMap[category] }))
			.map((entry) => ({
				category: entry.category,
				templates: entry.templates.toSorted(
					(a, b) => (currentSort === ListSortingType.OLDEST_TO_NEWEST
						? new Date(a.updatedAt) - new Date(b.updatedAt)
						: new Date(b.updatedAt) - new Date(a.updatedAt)),
				),
			}))
			.toSorted((cat1, cat2) => (currentSort === ListSortingType.OLDEST_TO_NEWEST
				? new Date(cat1.templates[cat1.templates.length - 1].updatedAt)
					- new Date(cat2.templates[cat2.templates.length - 1].updatedAt)
				: new Date(cat2.templates[0].updatedAt) - new Date(cat1.templates[0].updatedAt)));
	}, [currentSort, voteTemplates?.data]);

	useEffect(() => {
		if (!minimal) {
			fetchVoteTemplates();
		}
	}, [fetchVoteTemplates, minimal]);

	useEffect(() => {
		if (currentTemplate) {
			setExtendedCategory(currentTemplate.category);
		}
	}, [currentTemplate, setExtendedCategory]);

	return (
		<>
			<ListSortingSelect color="dark" />
			<section className="flex-grow-1 overflow-hidden">
				{categories.length ? (
					<ReallyPerfectScrollbar style={{ overflowX: 'hidden' }}>
						<ListGroup>
							{categories.map((entry) => (
								<Fragment key={`category-${entry.category}`}>
									<ListGroupItem
										className={`rounded-0 d-flex justyfiy-content-between align-items-center px-3 bg-dark content-dark ${!minimal && 'mt-2'}`}
									>
										<div className="w-100 content-dark-50">{entry.category}</div>
										<div>
											<Button
												className="btn-pill d-40 p-0 mr-1"
												color="neutral-secondary"
												onClick={extendCategory(entry.category)}
											>
												<span className="btn-wrapper--icon d-40">
													{entry.category === extendedCategory
														? (
															<FaAngleDown
																size={20}
																className="content-dark-50"
															/>
														)
														: (
															<FaAngleRight
																size={20}
																className="content-dark-50"
															/>
														)}
												</span>
											</Button>
										</div>
									</ListGroupItem>
									{entry.category === extendedCategory ? (
										<div className="px-2 pb-2 bg-dark content-dark">
											<ListGroup className="py-0">
												{entry.templates.map((template) => (
													<TemplateHistoryItem
														key={`template-${template._id}`}
														template={template}
														onTemplatePreviewClick={onTemplatePreviewClick}
														onDeleteTemplateClick={onDeleteTemplateClick}
														onDuplicateClick={onDuplicateClick}
														minimal={minimal}
													/>
												))}
											</ListGroup>
										</div>
									) : null}
								</Fragment>
							))}
						</ListGroup>
					</ReallyPerfectScrollbar>
				) : (
					<p className={`text-center ${minimal && 'mt-2'}`}>{t('Templates.History.noTemplates')}</p>
				)}

				{!minimal && (
					<div className="w-100 mt-2">
						<Button color="primary" className="btn-pill w-100" onClick={onCreateTemplateClick}>
							{t('Templates.History.create')}
						</Button>
					</div>
				)}
			</section>
		</>
	);
};

TemplateHistory.propTypes = {
	onTemplatePreviewClick: PropTypes.func,
	onCreateTemplateClick: PropTypes.func.isRequired,
	onDeleteTemplateClick: PropTypes.func.isRequired,
	onDuplicateClick: PropTypes.func.isRequired,
	currentTemplate: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		defaultPosition: PropTypes.string.isRequired,
		defaultDuration: PropTypes.number.isRequired,
		category: PropTypes.string.isRequired,
		options: PropTypes.arrayOf(PropTypes.shape({
			_id: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
		})).isRequired,
	}),
	minimal: PropTypes.bool,
};

TemplateHistory.defaultProps = {
	currentTemplate: null,
	minimal: false,
	onTemplatePreviewClick: undefined,
};
