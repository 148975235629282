import { createAxiosInstance } from '../lib/axios';
import { identity } from '../components/Authentication/Authentication';

const { VITE_API_URL } = import.meta.env;

export const publicApi = createAxiosInstance({
	baseURL: VITE_API_URL,
});

export const authenticatedApi = createAxiosInstance({
	baseURL: VITE_API_URL,
});

authenticatedApi.interceptors.request.use(async (config) => {
	try {
		const { token } = await identity.getAccessToken();
		return {
			...config,
			headers: {
				...config.headers,
				authorization: `Bearer ${token}`,
			},
		};
	} catch (error) {
		console.warn(error);
		return config;
	}
});

authenticatedApi.interceptors.response.use((response) => response, async (error) => {
	if (error.response?.status === 401) {
		await identity.logout();
		return { data: undefined };
	}
	throw error;
});
