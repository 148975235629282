import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaBell } from 'react-icons/fa';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import addPwa from '../../assets/images/add-ios-pwa.svg';
import forward from '../../assets/images/forward.svg';
import { isApple } from '../../lib/userAgent';
import { ButtonPill, ButtonPillOutline } from '../Button';
import { usePwa } from './Provider';

export const PwaModal = () => {
	const { t } = useTranslation();
	const { isOpen, closeModal, handleInstallApp } = usePwa();

	return (
		<Modal
			isOpen={isOpen}
			toggle={closeModal}
			zIndex={1800}
			className={`position-absolute mr-3 mb-3 ${isApple ? 'bottom' : 'top'}-0 right-0 text-black`}
		>
			<ModalHeader toggle={closeModal}>
				<strong>{t('PWA.Modal.title')}</strong>
			</ModalHeader>
			<ModalBody>
				<p>{t('PWA.Modal.body')}
				</p>
				{isApple
					? (
						<div>
							<div className="d-flex align-items-center mb-3">
								<img className="mr-2" src={forward} />
								<small className="font-weight-bold">{t('PWA.Modal.IOS.stepOne')}</small>

							</div>
							<div className="divider" />
							<div className="d-flex align-items-center mt-3 mb-3">
								<img className="mr-2" src={addPwa} />
								<small className="font-weight-bold">{t('PWA.Modal.IOS.stepTow')}</small>
							</div>
							<div className="divider" />
							<div className="d-flex align-items-center mt-3">
								<FaBell size={60} />
								<small className="font-weight-bold ml-3">{t('PWA.Modal.IOS.stepThree')}</small>
							</div>
						</div>
					)
					: (
						<div className="d-flex justify-content-end">
							<ButtonPill
								className="CookiesApproval_Icon_Button ml-2 mb-1 flex-shrink-0"
								color="primary"
								title="Install"
								onClick={handleInstallApp}
							>
								{t('PWA.Modal.installButton')}
							</ButtonPill>
							<ButtonPillOutline
								className="CookiesApproval_Icon_Button ml-2 mb-1 flex-shrink-0"
								color="secondary"
								title="Cancel"
								onClick={closeModal}
							>
								{t('PWA.Modal.cancelButton')}
							</ButtonPillOutline>
						</div>
					)}
			</ModalBody>
		</Modal>
	);
};
