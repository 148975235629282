import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Sidebar, Header } from '../../layout-components';
import { ModeScreenLayout, useScreenMode } from '../../components/Studio/ScreenMode/Provider';
import { useSidebar } from '../../layout-components/Sidebar/Provider';

const LeftSidebar = ({
	children,
	isMobileLayout,
	isStudioRoute,
	noSearch,
	over,
}) => {
	const { toggleMobile } = useSidebar();
	const { currentModeScreen } = useScreenMode();

	return (
		<div className={clsx('app-wrapper content-darker',
			{ 'app-sidebar-fixed app-header-fixed': !isStudioRoute },
			{ 'app-sidebar-mobile-open': toggleMobile },
			{ 'toggle-sidebar-mobile': isMobileLayout },
			{ over })}
		>
			<div className={clsx({ 'layout-fullscreen': currentModeScreen === ModeScreenLayout.FULLSCREEN })}>
				<Sidebar isStudioRoute={isStudioRoute} />
			</div>
			<div className={clsx('app-main',
				{ 'overflow-hidden': currentModeScreen === ModeScreenLayout.FULLSCREEN })}
			>
				<Header
					search={!noSearch}
					isMobileLayout={isMobileLayout}
					isStudioRoute={isStudioRoute}
				/>
				<div className={clsx('app-content', { 'pl-0': over, 'pt-0': currentModeScreen === ModeScreenLayout.FULLSCREEN })}>
					<div className="app-content--inner">
						<div className="app-content--inner__wrapper">{children}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

LeftSidebar.propTypes = {
	children: PropTypes.node,
	isMobileLayout: PropTypes.bool,
	isStudioRoute: PropTypes.bool,
	noSearch: PropTypes.bool,
	over: PropTypes.bool,
};

LeftSidebar.defaultProps = {
	children: null,
	isMobileLayout: false,
	isStudioRoute: false,
	noSearch: false,
	over: false,
};

export default LeftSidebar;
