import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useAsyncCallback } from 'react-async-hook';
import {
	Button,
	Input,
	FormGroup,
	Label,
	Alert,
} from 'reactstrap';
import { Form, Field, FieldError } from 'react-jsonschema-form-validation';
import { useTranslation } from 'react-i18next';

import { createDonation } from '../../../api/channel/donation';
import { FormModal } from '../../Form/Modal';
import FormSubmit from '../../Form/Submit';
import { ButtonPill } from '../../Button';
import { UserSettingsModal, useUserSettings } from '../../UserSettings/Context';
import { MembershipModalTab } from '../../UserSettings/MembershipModalTab';
import { Currency } from '../../../lib/currency';

const schema = {
	type: 'object',
	additionalProperties: false,
	properties: {
		amounts: {
			piPoints: {
				type: 'number',
				minimum: 0,
			},
			chips: {
				type: 'number',
				minimum: 0,
			},
		},
	},
	required: [
		'amounts',
	],
};

const DonateModal = ({
	isOpen,
	channel,
	toggle,
}) => {
	const [currencies, setCurrencies] = useState([Currency.PI_POINTS]);
	const { t } = useTranslation();
	const [formData, setFormData] = useState({
		amounts: {
			chips: 0,
			piPoints: 0,
		},
	});
	const { openModal } = useUserSettings();

	const handleChange = useCallback((data) => {
		setFormData(data);
	}, []);

	const onSubmit = async (amounts) => {
		await createDonation(channel._id, { amounts });
	};

	const handleSubmitAsync = useAsyncCallback(async () => onSubmit(formData.amounts));
	const { error, reset } = handleSubmitAsync;
	if (error) console.error(error);

	const isSuccess = handleSubmitAsync.status === 'success';
	const unexpectedErrorMsg = t('Donate.Modal.error');
	const errorMessage = error && (error.response?.data?.message || unexpectedErrorMsg);
	const errorReason = error?.response?.data?.reason;
	const isInsufficientFoundsError = errorReason === 'INSUFFICIENT_FUNDS';
	const isGeneralError = error && !isInsufficientFoundsError;

	const handleCurrencyChange = useCallback((e) => {
		const { target } = e;

		setCurrencies((prevCurrencies) => (
			target.checked
				? [...prevCurrencies, target.value]
				: prevCurrencies.filter((currency) => currency !== target.value)));

		if (!target.checked) {
			setFormData((prevFormData) => ({
				...prevFormData,
				amounts: {
					...prevFormData.amounts,
					[target.name]: 0,
				},
			}));
		}
	}, []);

	return (
		<FormModal
			contentClassName="form-dark"
			isOpen={isOpen}
			showCloseButton={!handleSubmitAsync.loading}
			toggle={toggle}
		>
			<h1 className="display-4 mb-5 font-weight-bold">{t('Donate.Modal.donateTo')} {channel.nickname}</h1>
			<Form
				data={formData}
				onChange={handleChange}
				onSubmit={handleSubmitAsync.execute}
				schema={schema}
			>
				<FormGroup>
					{currencies.includes(Currency.PI_POINTS) && (
						<FormGroup>
							<Label for="fullname">{t('Donate.Modal.piAmount')}</Label>
							<Field
								component={Input}
								type="number"
								name="amounts.piPoints"
								id="piAmount"
								placeholder="20 pi"
								min={1}
								value={formData.amounts.piPoints}
							/>
							<FieldError name="piAmount" />
						</FormGroup>
					)}
					{currencies.includes(Currency.CHIPS) && (
						<FormGroup className="mt-2">
							<Label for="fullname">{t('Donate.Modal.chipsAmount')}</Label>
							<Field
								component={Input}
								type="number"
								name="amounts.chips"
								id="chipsAmount"
								placeholder="20 chips"
								min={1}
								value={formData.amounts.chips}
							/>
							<FieldError name="chipsAmount" />
						</FormGroup>
					)}
					<Label className="mt-3" for="currency">{t('Donate.Modal.currencies')}</Label>
					<FormGroup className="ml-2" check>
						<Field
							component={Input}
							type="checkbox"
							name="piPoints"
							id="piPoints"
							value={Currency.PI_POINTS}
							onChange={(e) => {
								reset();
								handleCurrencyChange(e);
							}}
							checked={currencies.includes(Currency.PI_POINTS)}
						/>
						<Label check for="piPoints" className="blue">
							{t('Donate.Modal.piPoints')}
						</Label>
					</FormGroup>
					<FormGroup className="ml-2" check>
						<Field
							component={Input}
							type="checkbox"
							name="chips"
							id="chips"
							value={Currency.CHIPS}
							onChange={(e) => {
								reset();
								handleCurrencyChange(e);
							}}
							checked={currencies.includes(Currency.CHIPS)}
						/>
						<Label check for="chips" className="blue">
							Chips
						</Label>
					</FormGroup>
				</FormGroup>
				<footer className="mt-5">
					{isGeneralError && <Alert color="danger">{errorMessage}</Alert>}
					{isInsufficientFoundsError && (
						<Alert color="danger">
							<strong className="d-block mb-2">{t('Donate.Modal.insufficientFunds')}</strong>
							<ButtonPill
								color="danger"
								disabled={handleSubmitAsync.loading}
								onClick={() => {
									toggle();
									openModal(UserSettingsModal.MEMBERSHIP, MembershipModalTab.BUY_POINTS);
								}}
								size="sm"
							>
								{t('Donate.Modal.buy')}
							</ButtonPill>
						</Alert>
					)}
					{isSuccess && (
						<Alert color="success">
							{t('Donate.Modal.donationSuccessfull')} {channel.nickname}
						</Alert>
					)}
					<div className="d-flex">
						{!isSuccess && (
							<>
								<Button
									color="link"
									disabled={handleSubmitAsync.loading}
									onClick={toggle}
								>
									{t('Donate.Modal.cancel')}
								</Button>
								<FormSubmit className="ml-auto" loading={handleSubmitAsync.loading}>
									{t('Donate.Modal.donate')}
								</FormSubmit>
							</>
						)}
						{isSuccess && (
							<ButtonPill
								className="ml-auto"
								color="info"
								onClick={toggle}
							>
								{t('Donate.Modal.close')}
							</ButtonPill>
						)}
					</div>
				</footer>
			</Form>
		</FormModal>
	);
};

DonateModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	channel: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		nickname: PropTypes.string.isRequired,
	}).isRequired,
	toggle: PropTypes.func.isRequired,
};

export default DonateModal;
