const ACTION_KEY = 'channelStreams';

/**
 * actions
 */
export const ADD_STREAM_TRACK = `${ACTION_KEY}/addStreamTrack`;
export const REMOVE_STREAM_TRACK = `${ACTION_KEY}/removeStreamTrack`;
export const REMOVE_CHANNEL_STREAMS = `${ACTION_KEY}/removeChannelStreams`;
export const UPDATE_STREAM = `${ACTION_KEY}/updateStream`;

export const addStreamTrackAction = (subscription) => ({
	type: ADD_STREAM_TRACK,
	payload: subscription,
});

export const removeStreamTrackAction = (subscription) => ({
	type: REMOVE_STREAM_TRACK,
	payload: subscription,
});

export const removeChannelStreamsAction = (channel) => ({
	type: REMOVE_CHANNEL_STREAMS,
	payload: channel,
});

export const updateStreamAction = (publication) => ({
	type: UPDATE_STREAM,
	payload: publication,
});
