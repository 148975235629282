import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Input } from 'reactstrap';

import { ListSortingType, useListSorting } from './Provider';
import { usePagination } from '../Pagination/Provider';

export const ListSortingSelect = ({
	className,
	color,
}) => {
	const { t } = useTranslation();
	const {
		currentSort,
		setCurrentSort,
	} = useListSorting();

	const {
		goFirstPage,
	} = usePagination();

	const handleSortingTypeChange = useCallback((e) => {
		setCurrentSort(e.target.value);
		if (goFirstPage) goFirstPage();
	}, [goFirstPage, setCurrentSort]);

	return (
		<div className="form-light">
			<Input
				className={`bg-${color} content-${color} shadow-none ${className} w-auto`}
				onChange={handleSortingTypeChange}
				type="select"
				value={currentSort}
			>
				{Object.values(ListSortingType).map((sortType) => (
					<option
						key={`vote-sorting-${sortType}`}
						value={sortType}
					>
						{t(`ListSorting.Select.${sortType}`)}
					</option>
				))}
			</Input>
		</div>
	);
};

ListSortingSelect.propTypes = {
	className: PropTypes.string,
	color: PropTypes.string,
};

ListSortingSelect.defaultProps = {
	className: '',
	color: 'light',
};
