import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StudioGoLiveModal } from './Modal';
import { ButtonPill } from '../../Button';
import { StudioGoLiveStopModal } from './StopModal';

export const StudioGoLiveButton = () => {
	const { t } = useTranslation();
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<>
			{isModalOpen && (
				<StudioGoLiveModal
					isOpen={isModalOpen}
					toggle={() => setIsModalOpen(false)}
				/>
			)}
			<ButtonPill
				className="shadow-none mr-2"
				onClick={() => setIsModalOpen(true)}
				size="sm"
				title={t('GoLiveBUtton.startPublic')}
			>
				{t('GoLiveBUtton.start')}&nbsp;<span className="d-none d-sm-inline">{t('GoLiveBUtton.publicOrLive')}&nbsp;</span>{t('GoLiveBUtton.liveOrPublic')}
			</ButtonPill>
		</>
	);
};

StudioGoLiveButton.propTypes = {

};

export const StudioGoLiveStopButton = () => {
	const { t } = useTranslation();
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<>
			{isModalOpen && (
				<StudioGoLiveStopModal
					isOpen={isModalOpen}
					toggle={() => setIsModalOpen(false)}
				/>
			)}
			<ButtonPill
				className="shadow-none mr-2"
				color="danger"
				onClick={() => setIsModalOpen(true)}
				size="sm"
				title={t('GoLiveBUtton.stopPublicLive')}
			>
				{t('GoLiveBUtton.stop')}&nbsp;<span className="d-none d-sm-inline">{t('GoLiveBUtton.publicOrLive')}&nbsp;</span>{t('GoLiveBUtton.liveOrPublic')}
			</ButtonPill>
		</>
	);
};

StudioGoLiveStopButton.propTypes = {

};
