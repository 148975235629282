import React from 'react';
import { FaCircle, FaEraser, FaFillDrip, FaMinus, FaPen, FaSquare, FaTrashAlt } from 'react-icons/fa';
import {
	Button,
	ButtonGroup,
	UncontrolledPopover,
	PopoverBody,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
	useMixing,
	useDrawer,
} from '@technomiam/react-video';

import { ReactComponent as ThicknessSvg } from './thickness.svg';
import './LocalDrawer.scss';

const colors = [
	'rgba(0, 0, 0, 0)',
	'#FEC82E',
	'#FFFFFF',
	'#000000',
	'#B80000',
	'#DB3E00',
	'#FCCB00',
	'#008B02',
	'#006B76',
	'#1273DE',
	'#004DCF',
	'#5300EB',
	'#EB9694',
	'#FAD0C3',
	'#FEF3BD',
	'#C1E1C5',
	'#BEDADC',
	'#C4DEF6',
	'#BED3F3',
	'#D4C4FB',
];

const ColorPickerOption = ({ color, ...props }) => (
	<button
		className="LocalDrawer_ColorPickerOption"
		style={{ backgroundColor: color }}
		type="button"
		{...props}
	/>
);

ColorPickerOption.propTypes = {
	color: PropTypes.oneOf(colors).isRequired,
};

const ColorPicker = ({ onChange, value }) => (
	<div className="LocalDrawer_ColorPicker">
		{colors.map((color) => (
			<ColorPickerOption
				color={color}
				data-color={color}
				disabled={value === color}
				key={color}
				onClick={() => onChange(color)}
			/>
		))}
	</div>
);

ColorPicker.propTypes = {
	onChange: PropTypes.func.isRequired,
	value: PropTypes.oneOf(colors),
};

ColorPicker.defaultProps = {
	value: undefined,
};

const ShapePickerOption = ({
	icon,
	onSelect,
	value,
	...props
}) => {
	const handleClick = () => { onSelect(value); };

	return (
		<button
			className="LocalDrawer_ShapePickerOption"
			onClick={handleClick}
			type="button"
			{...props}
		>
			{icon}
		</button>
	);
};

ShapePickerOption.propTypes = {
	icon: PropTypes.node.isRequired,
	onSelect: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
};

const ShapePicker = ({ onChange, value }) => (
	<div className="LocalDrawer_ShapePicker">
		<ShapePickerOption
			disabled={value === 'pen'}
			icon={<FaPen />}
			onSelect={onChange}
			value="pen"
		/>
		<ShapePickerOption
			disabled={value === 'shape.rect'}
			icon={<FaSquare />}
			onSelect={onChange}
			value="shape.rect"
		/>
		<ShapePickerOption
			disabled={value === 'shape.circle'}
			icon={<FaCircle />}
			onSelect={onChange}
			value="shape.circle"
		/>
		<ShapePickerOption
			disabled={value === 'eraser'}
			icon={<FaEraser />}
			onSelect={onChange}
			value="eraser"
		/>
	</div>
);

ShapePicker.propTypes = {
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string,
};

ShapePicker.defaultProps = {
	value: undefined,
};

const ThicknessPicker = ({ onChange, value }) => (
	<div className="LocalDrawer_ShapePicker">
		<ShapePickerOption
			disabled={value === '2'}
			icon={<FaMinus style={{ fontSize: '.8em' }} />}
			onSelect={onChange}
			value="2"
		/>
		<ShapePickerOption
			disabled={value === '10'}
			icon={<FaMinus style={{ fontSize: '1.2em' }} />}
			onSelect={onChange}
			value="10"
		/>
		<ShapePickerOption
			disabled={value === '20'}
			icon={<FaMinus style={{ fontSize: '2em' }} />}
			onSelect={onChange}
			value="20"
		/>
	</div>
);

ThicknessPicker.propTypes = {
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string,
};

ThicknessPicker.defaultProps = {
	value: undefined,
};

export const LocalDrawer = () => {
	const { t } = useTranslation();
	const {
		config,
		setPenWidth,
		setPrimaryColor,
		setSecondaryColor,
		setDrawMode,
		drawMode,
	} = useDrawer();
	const { notifyClearDrawing } = useMixing();

	const handleStrokeColorChange = (value) => {
		setPrimaryColor(value);
	};

	const handleFillColorChange = (value) => {
		setSecondaryColor(value);
	};

	return (
		<div className="LocalDrawer">
			<ButtonGroup className="btn-transition-none">
				<Button
					className="d-40 p-0"
					color="neutral-secondary"
					id="popoverShapes"
					tag="span"
				>
					<span className="btn-wrapper--icon d-inline-block">
						{drawMode === 'pen' && (<FaPen />)}
						{drawMode === 'shape.rect' && (<FaSquare />)}
						{drawMode === 'shape.circle' && (<FaCircle />)}
						{drawMode === 'eraser' && (<FaEraser />)}
					</span>
				</Button>
				<UncontrolledPopover
					trigger="hover"
					placement="bottom"
					target="popoverShapes"
				>
					<PopoverBody className="p-1">
						<ShapePicker onChange={(v) => setDrawMode(v)} value={drawMode} />
					</PopoverBody>
				</UncontrolledPopover>
				<Button
					className="d-flex flex-column align-items-center justify-content-center d-40 p-0"
					color="neutral-secondary"
					id="popoverPaletteStroke"
					tag="span"
				>
					<FaPen />
					<span className="LocalDrawer_colorPreview" style={{ backgroundColor: config.colorPrimary }} />
				</Button>
				<UncontrolledPopover
					trigger="hover"
					placement="bottom"
					target="popoverPaletteStroke"
				>
					<PopoverBody className="p-1">
						<ColorPicker onChange={handleStrokeColorChange} value={config.colorPrimary} />
					</PopoverBody>
				</UncontrolledPopover>
				<Button
					className="d-flex flex-column align-items-center justify-content-center d-40 p-0"
					color="neutral-secondary"
					id="popoverPaletteFill"
					tag="span"
				>
					<FaFillDrip />
					<span className="LocalDrawer_colorPreview" style={{ backgroundColor: config.colorSecondary }} />
				</Button>
				<UncontrolledPopover
					trigger="hover"
					placement="bottom"
					target="popoverPaletteFill"
				>
					<PopoverBody className="p-1">
						<ColorPicker onChange={handleFillColorChange} value={config.colorSecondary} />
					</PopoverBody>
				</UncontrolledPopover>
				<Button
					tag="span"
					className="d-40 p-0 d-flex align-items-center justify-content-center"
					color="neutral-secondary"
					id="popoverThickness"
				>
					<ThicknessSvg style={{ height: '1.4em', width: '1.4em' }} />
				</Button>
				<UncontrolledPopover
					trigger="hover"
					placement="bottom"
					target="popoverThickness"
				>
					<PopoverBody className="p-1">
						<ThicknessPicker onChange={(v) => setPenWidth(v)} value={config.penWidth} />
					</PopoverBody>
				</UncontrolledPopover>
				<Button
					className="d-40 p-0"
					color="neutral-secondary"
					onClick={notifyClearDrawing}
					title={t('LocalUserMedia.LocalDrawer.clear')}
					tag="span"
				>
					<span className="btn-wrapper--icon d-inline-block">
						<FaTrashAlt />
					</span>
				</Button>
			</ButtonGroup>
		</div>
	);
};
