import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { MediaKeyProvider, useMediaKey } from './Provider';
import { useMediaUser } from '../User';
import { useMediaKeyPerformer } from './useMediaKeyPerformer';

const MediaKeyConfigPerformer = ({
	configId,
}) => {
	const {
		addKeyVideoTrack,
		config,
		configOverride,
		removeKeyVideoTrackByTrack,
	} = useMediaKey();

	const { userVideoActiveTracks } = useMediaUser();

	const videoTrack = userVideoActiveTracks.find((track) => track.configId === configId);

	const { keyVideoTrack } = useMediaKeyPerformer({
		config,
		configOverride,
		videoSourceTrack: videoTrack,
	});

	useEffect(() => {
		if (keyVideoTrack && videoTrack) {
			keyVideoTrack.configId = videoTrack.configId;
			addKeyVideoTrack(keyVideoTrack);
			return () => {
				removeKeyVideoTrackByTrack(keyVideoTrack);
			};
		}
		return undefined;
	}, [
		addKeyVideoTrack,
		keyVideoTrack,
		removeKeyVideoTrackByTrack,
		videoTrack,
	]);

	return null;
};

MediaKeyConfigPerformer.propTypes = {
	configId: PropTypes.number,
};

MediaKeyConfigPerformer.defaultProps = {
	configId: 0,
};

export const MediaKey = ({ children, isHost }) => (
	<MediaKeyProvider
		isHost={isHost}
	>
		<MediaKeyConfigPerformer configId={0} />
		{children}
	</MediaKeyProvider>
);

MediaKey.propTypes = {
	children: PropTypes.node,
	isHost: PropTypes.bool,
};

MediaKey.defaultProps = {
	children: undefined,
	isHost: false,
};
