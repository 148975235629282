export const ShareAboutType = {
	CHANNEL: 'CHANNEL',
	STUDIO: 'STUDIO',
	VIDEO: 'VIDEO',
	SURVEY: 'SURVEY',
	POST: 'POST',
};

export const ShareRecipientType = {
	EMAIL: 'EMAIL',
	GROUP: 'GROUP',
	SMS: 'SMS',
	USER: 'USER',
	USERS: 'USERS',
};
