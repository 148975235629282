import { IdentificationField } from '../Signup/signup.schema';

export const properties = {
	identificationField: { type: 'string', enum: Object.values(IdentificationField) },
	email: { type: 'string', format: 'email' },
	phoneNumber: { type: 'string', phone: true },
	password: { type: 'string' },
};

export const schema = {
	type: 'object',
	additionalProperties: false,
	properties,
	required: [
		'identificationField',
		'password',
	],
};
