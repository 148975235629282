import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { lazy, useCallback, useEffect, useState } from 'react';
import { NavItem, NavLink } from 'reactstrap';

import { useTranslation } from 'react-i18next';
import { ModalScreenHeader, ModalScreenHeaderNav } from '../Modal/Screen/Header';
import { ModalScreen } from '../Modal/Screen/Screen';
import { useStudioSummary } from './Context';
import { fetchStudioSummary as fetchStudioSummaryAPI } from '../../api/studio/studio';
import { ModalLazyContent } from '../Modal/LazyContent';

const StudioSummaryModalContent = lazy(() => import('./StudioSummaryModalContent'));

export const StudioSummaryModal = ({ isOpen, studioId }) => {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState('summary');
	const { closeModal, activeStudioId } = useStudioSummary();
	const [sessionSummary, setSessionSummary] = useState(null);
	const [title, setTitle] = useState('');

	const fetchStudioSummary = useCallback(async () => {
		const { data } = await fetchStudioSummaryAPI(studioId || activeStudioId);

		if (data) {
			setSessionSummary(data);
			setTitle(data.subject);
		}
	}, [studioId, activeStudioId]);

	useEffect(() => {
		fetchStudioSummary();
	}, [fetchStudioSummary]);

	return (
		<ModalScreen
			bodyClassName="p-0"
			header={(
				<ModalScreenHeader
					onClose={closeModal}
					title={title}
				>
					<ModalScreenHeaderNav className="mt-3">
						<NavItem>
							<NavLink
								className={clsx('text-center', { active: activeTab === 'summary' })}
								onClick={() => { setActiveTab('summary'); }}
							>
								{t('StudioSummary.StudioSummaryModal.summary')}
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="mx-4 flex-grow-0">
							<NavLink
								className={clsx('justify-content-center', { active: activeTab === 'invitations' })}
								onClick={() => { setActiveTab('invitations'); }}
							>
								{t('StudioSummary.StudioSummaryModal.invitations')}
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="mx-4 flex-grow-0">
							<NavLink
								className={clsx('justify-content-center', { active: activeTab === 'participants' })}
								onClick={() => { setActiveTab('participants'); }}
							>
								{t('StudioSummary.StudioSummaryModal.people')}
								<div className="divider" />
							</NavLink>
						</NavItem>
						{sessionSummary?.isPublic && (
							<NavItem className="mx-4 flex-grow-0">
								<NavLink
									className={clsx('justify-content-center', { active: activeTab === 'viewers' })}
									onClick={() => { setActiveTab('viewers'); }}
								>
									{t('StudioSummary.StudioSummaryModal.viewers')}
									<div className="divider" />
								</NavLink>
							</NavItem>
						)}
						<NavItem className="mx-4 flex-grow-0">
							<NavLink
								className={clsx('justify-content-center', { active: activeTab === 'messages' })}
								onClick={() => { setActiveTab('messages'); }}
							>
								{t('StudioSummary.StudioSummaryModal.messages')}
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="mx-4 flex-grow-0">
							<NavLink
								className={clsx('justify-content-center', { active: activeTab === 'voteHistory' })}
								onClick={() => { setActiveTab('voteHistory'); }}
							>
								{t('StudioSummary.StudioSummaryModal.voting')}
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="mx-4 flex-grow-0">
							<NavLink
								className={clsx('justify-content-center', { active: activeTab === 'recordings' })}
								onClick={() => { setActiveTab('recordings'); }}
							>
								{t('StudioSummary.StudioSummaryModal.recordings')}
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="mx-4 flex-grow-0">
							<NavLink
								className={clsx('justify-content-center', { active: activeTab === 'pricing' })}
								onClick={() => { setActiveTab('pricing'); }}
							>
								{t('StudioSummary.StudioSummaryModal.pricing')}
								<div className="divider" />
							</NavLink>
						</NavItem>
					</ModalScreenHeaderNav>
				</ModalScreenHeader>
			)}
			isOpen={isOpen}
			onClose={closeModal}
		>
			<ModalLazyContent>
				<StudioSummaryModalContent
					activeTab={activeTab}
					sessionSummary={sessionSummary}
					setActiveTab={setActiveTab}
				/>
			</ModalLazyContent>
		</ModalScreen>
	);
};

StudioSummaryModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	studioId: PropTypes.string,
};

StudioSummaryModal.defaultProps = {
	studioId: undefined,
};
