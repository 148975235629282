import React from 'react';
import 'react-multi-email/style.css';
import PropTypes from 'prop-types';
import {
	Alert,
	Col,
	Modal,
	Row,
} from 'reactstrap';
import { useAsyncCallback } from 'react-async-hook';
import { useTranslation } from 'react-i18next';

import { useAsyncErrorLog } from '../../../lib/hooks';
import { useStudio } from '../Context';
import { ButtonLoading, ButtonPill, ButtonPillOutline } from '../../Button';
import { useActiveStudio } from '../Active/Context';

export const StudioGoLiveStopModal = ({
	isOpen,
	toggle,
}) => {
	const { t } = useTranslation();
	const {	stoplive } = useStudio();
	const { updateActiveStudio } = useActiveStudio();

	const asyncStopLive = useAsyncCallback(async () => {
		const updatedStudio = await stoplive();
		updateActiveStudio(updatedStudio);
		toggle();
	});

	useAsyncErrorLog(asyncStopLive);

	return (
		<Modal
			centered
			contentClassName="border-0 rounded overflow-hidden bg-transparent align-self-center"
			isOpen={isOpen}
			toggle={toggle}
			zIndex={2000}
		>
			<div className="bg-dark shadow-lg">
				<Row className="no-gutters">
					<Col xs="12" lg="8" className="d-flex mx-auto rounded overflow-hidden p-relative">
						<div className="py-5 mx-auto">
							<h4 className="mb-4">{t('GoLive.StopModal.stopPublicLive')}</h4>
							{asyncStopLive.error && (
								<Alert color="danger">
									{t('Global.error')}
								</Alert>
							)}
							<div className="d-flex justify-content-between w-100">
								<ButtonPillOutline
									onClick={toggle}
									color="secondary"
									className=""
								>
									{t('GoLive.StopModal.cancel')}
								</ButtonPillOutline>
								<ButtonLoading
									color="danger"
									component={ButtonPill}
									loading={asyncStopLive.loading}
									onClick={asyncStopLive.execute}
								>
									{t('GoLive.StopModal.stopLive')}
								</ButtonLoading>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</Modal>
	);
};

StudioGoLiveStopModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
};
