import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as friendsApi from '../../api/channel/friends';
import { useProfile } from '../../components/Profile/ProfileContext';
import { Sound, useSound } from '../../components/Sound/Provider';
import { axiosMutationWrapper, axiosQueryWrapper } from '../utils/axios-wrapper';
import { GRAPH_GLOBALS_QUERY_KEYS } from '../graph/globals';

export const FRIENDS_QUERY_KEYS = {
	fetchFriends: () => ['api', 'channel', 'friends', 'fetch'],
	fetchFriendsHistory: () => ['api', 'channel', 'friends', 'history', 'fetch'],
	fetchFriendStatustByChannelId: (channelId) => ['api', 'channel', 'friendRequest', channelId],
};

export const useFetchFriends = () => useQuery(
	FRIENDS_QUERY_KEYS.fetchFriends(),
	axiosQueryWrapper(friendsApi.getFriendsChannels),
);

export const useFetchFriendsHistory = () => useQuery(
	FRIENDS_QUERY_KEYS.fetchFriendsHistory(),
	axiosQueryWrapper(friendsApi.fetchFriendsHistory),
);

export const useFetchFriendStatus = (channelId) => useQuery(
	FRIENDS_QUERY_KEYS.fetchFriendStatustByChannelId(channelId),
	axiosQueryWrapper(friendsApi.fetchFriendStatus, channelId),
	{ enabled: !!channelId },
);

export const useHandleFriendRequestNew = () => {
	const queryClient = useQueryClient();
	const { playSound } = useSound();
	const { profile } = useProfile();

	return useCallback((newFriendRequest) => {
		queryClient.invalidateQueries(
			FRIENDS_QUERY_KEYS.fetchFriendStatustByChannelId(newFriendRequest.sender._id),
		);

		queryClient.invalidateQueries(
			FRIENDS_QUERY_KEYS.fetchFriendsHistory(),
		);
		queryClient.setQueryData(
			GRAPH_GLOBALS_QUERY_KEYS.fetchGraphGlobals(profile?._id),
			(prevData) => (
				{
					...prevData,
					friendRequests: [
						...prevData.friendRequests.filter(
							(friendRequest) => friendRequest._id !== newFriendRequest._id,
						),
						newFriendRequest,
					],
				}
			),
		);
		playSound(Sound.NEW_MESSAGE);
	}, [playSound, queryClient, profile?._id]);
};

export const useHandleFriendRequestChange = () => {
	const queryClient = useQueryClient();
	const { profile } = useProfile();

	return useCallback((newFriendRequest) => {
		queryClient.invalidateQueries(
			FRIENDS_QUERY_KEYS.fetchFriendStatustByChannelId(
				profile._id === newFriendRequest.receiver._id
					? newFriendRequest.sender._id
					: newFriendRequest.receiver._id,
			),
		);

		queryClient.invalidateQueries(
			FRIENDS_QUERY_KEYS.fetchFriendsHistory(),
		);
		queryClient.setQueryData(
			GRAPH_GLOBALS_QUERY_KEYS.fetchGraphGlobals(profile?._id),
			(prevData) => (
				{
					...prevData,
					friendRequests: [
						...prevData.friendRequests.filter(
							(friendRequest) => friendRequest._id !== newFriendRequest._id,
						),
						newFriendRequest,
					],
				}
			),
		);
	}, [profile?._id, queryClient]);
};

export const useSendFriendRequest = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(friendsApi.sendFriendRequest),
		{
			onSuccess: (data) => {
				queryClient.setQueryData(
					FRIENDS_QUERY_KEYS.fetchFriendStatustByChannelId(data.receiver._id),
					(prevStatus) => (prevStatus
						? { ...prevStatus, friendRequest: data, isFriend: false }
						: { friendRequest: data, isFriend: false }),
				);
				queryClient.invalidateQueries(
					FRIENDS_QUERY_KEYS.fetchFriendsHistory(),
				);
				queryClient.setQueryData(
					GRAPH_GLOBALS_QUERY_KEYS.fetchGraphGlobals(data.sender._id),
					(prevData) => (
						{
							...prevData,
							friendRequests: [
								...prevData.friendRequests.filter(
									(friendRequest) => friendRequest._id !== data._id,
								),
								data,
							],
						}
					),
				);
			},
		},
	);
};

export const useAcceptFriendRequest = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(friendsApi.acceptFriendRequest),
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries(
					FRIENDS_QUERY_KEYS.fetchFriendStatustByChannelId(data.sender._id),
				);
				queryClient.invalidateQueries(
					FRIENDS_QUERY_KEYS.fetchFriendsHistory(),
				);
				queryClient.setQueryData(
					GRAPH_GLOBALS_QUERY_KEYS.fetchGraphGlobals(data.receiver._id),
					(prevData) => (
						{
							...prevData,
							friendRequests: [
								...prevData.friendRequests.filter(
									(friendRequest) => friendRequest._id !== data._id,
								),
								data,
							],
						}
					),
				);
			},
		},
	);
};

export const useDeclineFriendRequest = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(friendsApi.declineFriendRequest),
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries(
					FRIENDS_QUERY_KEYS.fetchFriendStatustByChannelId(data.sender._id),
				);
				queryClient.invalidateQueries(
					FRIENDS_QUERY_KEYS.fetchFriendsHistory(),
				);
				queryClient.setQueryData(
					GRAPH_GLOBALS_QUERY_KEYS.fetchGraphGlobals(data.receiver._id),
					(prevData) => (
						{
							...prevData,
							friendRequests: [
								...prevData.friendRequests.filter(
									(friendRequest) => friendRequest._id !== data._id,
								),
								data,
							],
						}
					),
				);
			},
		},
	);
};

export const useCancelFriendRequest = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(friendsApi.cancelFriendRequest),
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries(
					FRIENDS_QUERY_KEYS.fetchFriendStatustByChannelId(data.receiver._id),
				);
				queryClient.invalidateQueries(
					FRIENDS_QUERY_KEYS.fetchFriendsHistory(),
				);
				queryClient.setQueryData(
					GRAPH_GLOBALS_QUERY_KEYS.fetchGraphGlobals(data.sender._id),
					(prevData) => (
						{
							...prevData,
							friendRequests: [
								...prevData.friendRequests.filter(
									(friendRequest) => friendRequest._id !== data._id,
								),
								data,
							],
						}
					),
				);
			},
		},
	);
};

export const useUnfriendChannel = () => useMutation(
	axiosMutationWrapper(friendsApi.unfriendChannel),
);
