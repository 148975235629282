import { useCallback, useMemo } from 'react';

import { startRecordingAction, stopRecordingAction } from '../../store/actions/channelMixing';
import { useDispatch } from '../ReactVideo/Provider';
import { useMixing } from '../ReactVideo/Mixing';

export const useRecording = ({ hashtag }) => {
	const dispatch = useDispatch();
	const { channelMixerStatus } = useMixing();
	const isRecording = !!channelMixerStatus?.isRecording;

	const startRecording = useCallback((duration) => {
		dispatch(startRecordingAction(hashtag, duration));
	}, [dispatch, hashtag]);

	const stopRecording = useCallback(() => {
		dispatch(stopRecordingAction(hashtag));
	}, [dispatch, hashtag]);

	return useMemo(() => ({
		isRecording,
		startRecording,
		stopRecording,
	}), [
		isRecording,
		startRecording,
		stopRecording,
	]);
}
