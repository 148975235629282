import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as publicChannelApi from '../../api/public/channel';

export const ChannelContext = createContext();

export const useChannel = () => useContext(ChannelContext);

export const ChannelProvider = ({ channel: channelProp, children }) => {
	const [channel, setChannel] = useState(channelProp);

	const fetchOneChannelByHashtag = useCallback(async (hashtag) => {
		const { data } = await publicChannelApi.fetchOneChannelByHashtag(hashtag);
		setChannel(data);
	}, []);

	useEffect(() => {
		setChannel(channelProp);
	}, [channelProp]);

	const value = useMemo(() => ({
		channel,
		fetchOneChannelByHashtag,
		setChannel,
	}), [
		channel,
		fetchOneChannelByHashtag,
		setChannel,
	]);

	return (
		<ChannelContext.Provider value={value}>
			{children}
		</ChannelContext.Provider>
	);
};

ChannelProvider.propTypes = {
	channel: PropTypes.shape({}),
	children: PropTypes.node.isRequired,
};

ChannelProvider.defaultProps = {
	channel: undefined,
};
