import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

export const ButtonLoading = ({
	children,
	className,
	component: Component,
	loading,
	...props

}) => {
	const { t } = useTranslation();
	return (
		<Component
			className={clsx('position-relative', { 'btn-spinner': loading }, className)}
			disabled={loading}
			{...props}
		>
			<div className={clsx({ 'opacity-2': loading })}>{children}</div>
			{loading && (
				<div className="position-absolute w-100 h-100 top-0 left-0 d-flex align-items-center">
					<span
						className="btn-wrapper--icon spinner-border spinner-border-sm ml-1 mr-auto"
						role="status"
						aria-hidden="true"
					/>
					<span className="visually-hidden">{t('Button.loading')}</span>
				</div>
			)}
		</Component>
	);
};

ButtonLoading.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	component: PropTypes.elementType,
	loading: PropTypes.bool,
};

ButtonLoading.defaultProps = {
	children: null,
	className: '',
	component: Button,
	loading: false,
};

export const ButtonLoadingIcon = ({
	children,
	className,
	component: Component,
	loading,
	...props
}) => (
	<Component
		className={clsx('position-relative', { 'btn-spinner': loading }, className)}
		disabled={loading}
		{...props}
	>
		{loading ? (
			<div className="w-100 h-100 align-items-center">
				<span
					className="btn-wrapper--icon spinner-border spinner-border-sm"
					role="status"
					aria-hidden="true"
				/>
			</div>
		) : (
			<div className={clsx({ 'opacity-2': loading })}>{children}</div>
		)}
	</Component>
);

ButtonLoadingIcon.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	component: PropTypes.elementType,
	loading: PropTypes.bool,
};

ButtonLoadingIcon.defaultProps = {
	children: null,
	className: '',
	component: Button,
	loading: false,
};
