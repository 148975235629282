import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import defaultAvatar from '../../../images/default-avatar.png';
import { getFileUrl, CHANNEL_THUMBNAIL_WIDTH } from '../../../lib/file';
import { getChannelLink } from '../../../RoutePath';
import Hexagon from '../../Hexagon/Hexagon';
import './ChannelThumbnail.scss';

const ChannelThumbnail = ({
	channel,
	className,
}) => {
	const getAvatarUrl = (avatar) => (avatar ? getFileUrl(
		{ name: avatar },
		CHANNEL_THUMBNAIL_WIDTH,
	) : defaultAvatar);

	return (
		<Hexagon className={classnames('ChannelThumbnail', className)} size="none">
			<Link
				to={getChannelLink(channel.hashtag)}
				style={{
					backgroundImage: `url(${getAvatarUrl(channel.avatar)})`,
				}}
			>
				<article className="ChannelThumbnail_overlay">
					<p className="ChannelCard_info">
						<strong>{channel.nickname}</strong>
					</p>
					<p className={classnames('ChannelThumbnail_isLive', { isLive: channel.isLive })}>
						<span className="icon-viewers" />
					</p>
					<p className="ChannelThumbnail_viewers">
						{channel.viewers}
					</p>
				</article>
			</Link>
		</Hexagon>
	);
};

ChannelThumbnail.propTypes = {
	channel: PropTypes.shape({
		nickname: PropTypes.string,
		hashtag: PropTypes.string,
		avatar: PropTypes.string,
		isLive: PropTypes.bool,
		viewers: PropTypes.number,
	}).isRequired,
	className: PropTypes.string,
};

ChannelThumbnail.defaultProps = {
	className: '',
};

export default ChannelThumbnail;
