import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FaExclamation } from 'react-icons/fa';

import { getLink, Path } from '../../RoutePath';

const ViewError = ({ error }) => {
	const { t } = useTranslation();
	const handleReload = () => {
		window.location.reload(true);
	};

	return (
		<div className="h-100 w-100 position-absolute d-flex align-items-center justify-content-center">
			<div className="ViewError d-flex flex-column justify-content-center align-items-center text-center">
				<FaExclamation size="5em" className="text-primary" />
				<h2 className="mt-5">{t('Error.Error.errorOccurred')}</h2>
				<p>
					{error && error.toString()}
				</p>
				<p>
					<Button outline color="primary" onClick={handleReload} tag="a" size="lg">{t('Error.Error.reload')}</Button>
				</p>
				<p>
					<Button color="primary" tag="a" href={getLink(Path.ROOT)} size="lg">{t('Error.Error.returnToHome')}</Button>
				</p>
			</div>
		</div>
	);
};

ViewError.propTypes = {
	error: PropTypes.instanceOf(Error),
	errorInfo: PropTypes.shape({}),
};

ViewError.defaultProps = {
	error: null,
	errorInfo: null,
};

export default ViewError;
