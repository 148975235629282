import { authenticatedApi } from '../api';

export const API_CHANNEL_FRIENDS_PATH = '/channel/friends';

export const getFriendsChannels = () => authenticatedApi.get(`${API_CHANNEL_FRIENDS_PATH}/friends`);

export const getFollowingChannels = () => authenticatedApi.get(`${API_CHANNEL_FRIENDS_PATH}/following`);

export const getFollowersChannels = () => authenticatedApi.get(`${API_CHANNEL_FRIENDS_PATH}/followers`);

export const followChannel = (channelId, origin) => authenticatedApi.post(
	`${API_CHANNEL_FRIENDS_PATH}/follow/byid/${channelId}`,
	{ origin },
);

export const unfollowChannel = (channelId, origin) => authenticatedApi.post(
	`${API_CHANNEL_FRIENDS_PATH}/unfollow/byid/${channelId}`,
	{ origin },
);

export const updateStatusDisclosed = (statusDisclosed) => (
	authenticatedApi.put(
		`${API_CHANNEL_FRIENDS_PATH}/statusdisclosed`,
		{ statusDisclosed },
	)
);

export const sendFriendRequest = ({ channelId, origin }) => authenticatedApi.post(
	`${API_CHANNEL_FRIENDS_PATH}/request/byChannelId/${channelId}`,
	{ origin },
);

export const acceptFriendRequest = (friendRequestId) => authenticatedApi.post(
	`${API_CHANNEL_FRIENDS_PATH}/request/accept/${friendRequestId}`,
);

export const declineFriendRequest = (friendRequestId) => authenticatedApi.post(
	`${API_CHANNEL_FRIENDS_PATH}/request/decline/${friendRequestId}`,
);

export const cancelFriendRequest = (friendRequestId) => authenticatedApi.post(
	`${API_CHANNEL_FRIENDS_PATH}/request/cancel/${friendRequestId}`,
);

export const unfriendChannel = (channelId) => authenticatedApi.post(
	`${API_CHANNEL_FRIENDS_PATH}/unfriend/byChannelId/${channelId}`,
);

export const fetchFriendsHistory = () => authenticatedApi.get(
	`${API_CHANNEL_FRIENDS_PATH}/history`,
);

export const fetchFriendStatus = (channelId) => authenticatedApi.get(
	`${API_CHANNEL_FRIENDS_PATH}/status/byChannelId/${channelId}`,
);
