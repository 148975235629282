import React, {
	useMemo,
	useState,
	createContext,
	useContext,
} from 'react';
import PropTypes from 'prop-types';

export const DEFAULT_MODE = 'DEFAULT_MODE';

export const PipLayoutContext = createContext();

export const usePipLayout = () => useContext(PipLayoutContext);

export const PipLayoutProvider = ({ children }) => {
	const [displayMode, setDisplayMode] = useState(DEFAULT_MODE);

	const context = useMemo(() => ({
		displayMode,
		setDisplayMode,
	}), [
		displayMode,
		setDisplayMode,
	]);

	return (
		<PipLayoutContext.Provider value={context}>
			{children}
		</PipLayoutContext.Provider>
	);
};

PipLayoutProvider.propTypes = {
	children: PropTypes.node,
};

PipLayoutProvider.defaultProps = {
	children: undefined,
};
