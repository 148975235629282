import { authenticatedApi } from '../api';
import { upload } from '../upload';

const API_STUDIO_SCHEDULE_PATH = '/studios/schedule';

export const fetchStudioSchedules = async (
	channelId, state, itemsPerPage, currentPage,
) => authenticatedApi.get(
	`${API_STUDIO_SCHEDULE_PATH}/byChannelId/${channelId}`, { params: { state, itemsPerPage, currentPage } },
);

export const fetchStudioScheduleById = async (id) => authenticatedApi.get(
	`${API_STUDIO_SCHEDULE_PATH}/${id}`,
);

export const fetchCalendarStudioSchedule = async (
	channelId,
	startDate,
	endDate,
) => authenticatedApi.get(
	`${API_STUDIO_SCHEDULE_PATH}/calendar/byChannelId/${channelId}`,
	{
		params: { startDate, endDate },
	},
);

export const fetchManyStudioScheduleSoon = async () => authenticatedApi.get(
	`${API_STUDIO_SCHEDULE_PATH}/soon`,
);

const uploadBanner = async (studioSchedule) => {
	if (studioSchedule.banner === null) studioSchedule.banner = ''; // remove banner only if null (not undefined)

	let { banner } = studioSchedule;
	if (banner?.blob) {
		banner = await upload(banner.blob);
	}

	const studioScheduleWithBanner = {
		...studioSchedule,
		banner,
	};

	return studioScheduleWithBanner;
};

export const createStudioSchedule = async ({ channelId, studioSchedule }) => {
	const studioScheduleWithBanner = await uploadBanner(studioSchedule);
	return authenticatedApi.post(
		`${API_STUDIO_SCHEDULE_PATH}/toChannelById/${channelId}`,
		studioScheduleWithBanner,
	);
};

export const updateStudioSchedule = async ({ id, studioSchedule }) => {
	const studioScheduleWithBanner = await uploadBanner(studioSchedule);
	return authenticatedApi.put(
		`${API_STUDIO_SCHEDULE_PATH}/${id}`,
		studioScheduleWithBanner,
	);
};

export const cancelStudioSchedule = async (id) => authenticatedApi.post(
	`${API_STUDIO_SCHEDULE_PATH}/cancel/${id}`,
);

export const updateStudioScheduleTime = async ({
	studioId,
	startAt,
	plannedDuration,
}) => authenticatedApi.put(
	`${API_STUDIO_SCHEDULE_PATH}/${studioId}/time`,
	{ plannedDuration, startAt },
);
