import { authenticatedApi } from '../api';

export const API_VOTE_PATH = '/channel/vote';
export const API_VOTE_TEMPLATE_PATH = '/channel/vote/template';

export const fetchChannelVotesByStudioId = (studioId) => authenticatedApi.get(
	`${API_VOTE_PATH}/history/${studioId}`,
);

export const fetchReport = (studioId, audienceType) => authenticatedApi.get(
	`${API_VOTE_PATH}/report/${studioId}`,
	{
		params: {
			audienceType,
		},
	},
);

export const fetchReportCSV = (studioId, audienceType) => authenticatedApi.get(
	`${API_VOTE_PATH}/report/${audienceType}/${studioId}/csv`,
	{
		params: {
			audienceType,
		},
	},
);

export const createVote = (vote, studioId) => authenticatedApi.post(
	`${API_VOTE_PATH}/${studioId}`,
	vote,
);

export const endVote = (voteId) => authenticatedApi.put(
	`${API_VOTE_PATH}/${voteId}/end`,
);

export const voteOnVote = (voteId, answerId) => authenticatedApi.put(
	`${API_VOTE_PATH}/${voteId}/vote/${answerId}`,
);

export const publishVote = (voteId) => authenticatedApi.put(
	`${API_VOTE_PATH}/${voteId}/publish`,
);

export const endPublishVote = (voteId) => authenticatedApi.delete(
	`${API_VOTE_PATH}/${voteId}/publish/end`,
);

export const repositionVote = (voteId, position) => authenticatedApi.put(
	`${API_VOTE_PATH}/${voteId}/reposition`,
	{ position },
);

export const fetchChannelVoteTemplates = (
	isArchived,
	itemsPerPage,
	currentPage,
	sortValue,
) => authenticatedApi.get(
	`${API_VOTE_TEMPLATE_PATH}`,
	{
		params: {
			isArchived,
			itemsPerPage,
			currentPage,
			sortValue,
		},
	},
);

export const createVoteTemplate = (voteTemplate) => authenticatedApi.post(
	`${API_VOTE_TEMPLATE_PATH}`,
	voteTemplate,
);

export const deleteVoteTemplate = (voteTemplateId) => authenticatedApi.delete(`${API_VOTE_TEMPLATE_PATH}/${voteTemplateId}`);

export const restoreVoteTemplate = (voteTemplateId) => authenticatedApi.put(`${API_VOTE_TEMPLATE_PATH}/restore/${voteTemplateId}`);

export const sendReportThroughMail = (studioId, audienceType) => authenticatedApi.post(
	`${API_VOTE_PATH}/report/${studioId}/email`,
	{
		params: {
			audienceType,
		},
	},
);
