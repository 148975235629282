import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { useCallback } from 'react';
import * as notificationApi from '../../api/notification/notification';
import { axiosMutationWrapper, axiosQueryWrapper } from '../utils/axios-wrapper';
import { Sound, useSound } from '../../components/Sound/Provider';

const MESSAGES_PER_PAGE_LIMIT = 20;

export const NOTIFICATION_QUERY_KEYS = {
	fetchNotifications: () => ['api', 'notification', 'fetch'],
	fetchNotificationsUnreadCount: () => ['api', 'notification', 'unreadCount', 'fetch'],
};

export const useFetchNotifications = () => useInfiniteQuery(
	NOTIFICATION_QUERY_KEYS.fetchNotifications(),
	({ pageParam }) => axiosQueryWrapper(
		notificationApi.fetchNotifications, pageParam,
	)(),
	{
		getNextPageParam: (lastPage) => {
			if (
				lastPage?.length && lastPage[0]?.createdAt && lastPage.length >= MESSAGES_PER_PAGE_LIMIT
			) {
				return lastPage[lastPage.length - 1].createdAt;
			}

			return undefined;
		},
		select: (data) => ({
			pages: [...data.pages],
			pageParams: [...data.pageParams],
		}),
	},
);

export const useFetchNotificationUnreadCount = (conversationId) => useQuery(
	NOTIFICATION_QUERY_KEYS.fetchNotificationsUnreadCount(conversationId),
	axiosQueryWrapper(notificationApi.fetchNotificationsUnreadCount),
);

export const useMarkNotificationBoardRead = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(notificationApi.markNotificationBoardRead), {
			onSuccess: () => {
				queryClient.invalidateQueries(NOTIFICATION_QUERY_KEYS.fetchNotificationsUnreadCount());
			},
		},
	);
};

export const useHandleNotificationNew = () => {
	const queryClient = useQueryClient();
	const { playSound } = useSound();

	return useCallback(() => {
		queryClient.invalidateQueries(NOTIFICATION_QUERY_KEYS.fetchNotifications());
		queryClient.invalidateQueries(NOTIFICATION_QUERY_KEYS.fetchNotificationsUnreadCount());

		playSound(Sound.NEW_MESSAGE);
	}, [playSound, queryClient]);
};
