import { IdentificationField } from '../Signup/signup.schema';

export const properties = {
	identificationField: { type: 'string', enum: Object.values(IdentificationField) },
	email: {
		type: 'string',
		format: 'email',
		minLength: 3,
		maxLength: 255,
	},
	phoneNumber: { type: 'string', phone: true },
};

export const schema = {
	type: 'object',
	additionalProperties: false,
	properties,
	required: [
		'identificationField',
	],
};
