import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

export const ListLoading = () => {
	const { t } = useTranslation();
	return (
		<div className="Loading">
			<span className="mr-2">
				<FaSpinner className="icon-spin" />
			</span>
			<strong>{t('ItemList.Loading')}</strong>
		</div>
	);
};
