import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

export const ContactsModalContext = createContext({});

export const useContactsModal = () => useContext(ContactsModalContext);

export const ContactsModalProvider = ({ children }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [contactToEdit, setContactToEdit] = useState(null);

	const openModal = useCallback((contact) => {
		if (contact) setContactToEdit(contact);
		setIsOpen(true);
	}, []);

	const closeModal = useCallback(() => {
		setContactToEdit(null);
		setIsOpen(false);
	}, []);

	const value = useMemo(() => ({
		isOpen,
		openModal,
		closeModal,
		contactToEdit,

	}), [closeModal, isOpen, openModal, contactToEdit]);

	return (
		<ContactsModalContext.Provider value={value}>
			{children}
		</ContactsModalContext.Provider>
	);
};

ContactsModalProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
