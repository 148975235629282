import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { isInIframe } from '../../lib/isInFrame';
import { useAuthentication } from '../Authentication/Authentication';

export const SHOW_WEB_PUSH_MODAL = 2;
export const LOCAL_STORAGE_WEB_PUSH_KEY = 'beeyou_web_push_modal';

export const WebPushContext = React.createContext({});

export const WebPushProvider = ({ children }) => {
	const [isOpen, setIsOpen] = useState(false);
	const { isLoggedIn } = useAuthentication();
	const isIFrame = isInIframe();

	const openModal = useCallback(() => {
		if (isIFrame) return;
		setIsOpen(true);
	}, [isIFrame]);

	const closeModal = useCallback(() => {
		setIsOpen(false);
	}, []);

	useEffect(() => {
		if (isLoggedIn) {
			const webPushModalLS = parseInt(localStorage.getItem(LOCAL_STORAGE_WEB_PUSH_KEY), 10) || 0;
			if (webPushModalLS <= SHOW_WEB_PUSH_MODAL) {
				openModal();
				localStorage.setItem(LOCAL_STORAGE_WEB_PUSH_KEY, webPushModalLS + 1);
			}
		}
	}, [isLoggedIn, openModal]);

	const value = useMemo(() => ({
		isOpen,
		openModal,
		closeModal,
	}), [closeModal, isOpen, openModal]);

	return (
		<WebPushContext.Provider value={value}>
			{children}
		</WebPushContext.Provider>
	);
};

export const useWebPush = () => useContext(WebPushContext);

WebPushProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
