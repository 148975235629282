import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaPaperPlane } from 'react-icons/fa';

import { ChannelShareButton } from '../../../../../components/Channel/Share/Button';
import { ChannelShare } from '../../../../../components/Channel/Share/Share';
import { useProfile } from '../../../../../components/Profile/ProfileContext';
import { ShareTabType } from '../../../../../components/Share/Share.helper';
import { ResourceAccessRole } from '../../../../../lib/ResourceAccessRole';

export const ShareVideoButton = ({
	video,
	miniPlayer,
}) => {
	const { t } = useTranslation();
	const { profile } = useProfile();

	return (
		<div id={`SettingsLibraryVideosListActions_${video._id}`}>
			<ChannelShare
				channel={profile}
				roles={[ResourceAccessRole.PUBLIC]}
				shareTabTypes={[
					ShareTabType.USERS,
					ShareTabType.EMAIL,
					ShareTabType.LINK,
					ShareTabType.SMS,
					ShareTabType.POST,
				]}
				video={video}
			>
				<ChannelShareButton
					title={t('Videos.ListActions.share')}
					className={`p-0 btn-no-focus ${miniPlayer ? 'd-25' : 'mx-1 d-40'}`}
					color="neutral-secondary"
				>
					<span className="btn-wrapper--icon">
						<FaPaperPlane />
					</span>
				</ChannelShareButton>
			</ChannelShare>
		</div>
	);
};

ShareVideoButton.propTypes = {
	video: PropTypes.shape({
		assetId: PropTypes.string,
		_id: PropTypes.string,
	}).isRequired,
	miniPlayer: PropTypes.bool,
};

ShareVideoButton.defaultProps = {
	miniPlayer: false,
};
