import React, {
	useCallback,
	useMemo,
	useState,
	useContext,
} from 'react';
import PropTypes from 'prop-types';

import * as surveyApi from '../../../api/survey';
import * as shareApi from '../../../api/share';
import { ShareAboutType } from '../../Notification/Notification.helper';
import { ResourceAccessRole } from '../../../lib/ResourceAccessRole';
import { Tabs } from '../Modals/SurveyTemplateModalTabs';
import { ListSortingType } from '../../ListSorting/Provider';

const findAndReplace = (templates, replaceTemplate) => templates.map((template) => {
	if (template._id === replaceTemplate._id) {
		return { ...template, ...replaceTemplate };
	}
	return { ...template };
});

const convertToUpdateDto = (surveyTemplate) => ({
	title: surveyTemplate.title,
	description: surveyTemplate.description,
	questions: surveyTemplate.questions,
	completed: surveyTemplate.completed,
});

const SurveyTemplateContext = React.createContext();
export const useSurveyTemplate = () => useContext(SurveyTemplateContext);

export const SurveyTemplateProvider = ({ children }) => {
	const [surveyTemplates, setSurveyTemplates] = useState([]);
	const [focusSurveyTemplate, setFocusSurveyTemplate] = useState(null);
	const [studioId, setStudioId] = useState('');

	const [isInviteOpen, setInviteOpen] = useState(false);
	const [isOpenSurveyTemplateModal, setOpenSurveyTemplateModal] = useState(false);
	const [initialTab, setInitialTab] = useState(Tabs.SURVEY_TEMPLATE);

	const handleSendInvites = useCallback(async (surveyTemplateId, recipients, message) => {
		const shares = recipients.map((recipient) => ({
			about: {
				type: ShareAboutType.SURVEY,
				surveyTemplate: surveyTemplateId,
				role: ResourceAccessRole.PUBLIC,
			},
			recipient,
			message,
		}));

		await shareApi.share(shares);

		setOpenSurveyTemplateModal(false);
		setStudioId('');
	}, []);

	const handleOpenSurveyTemplateModal = useCallback(async (
		focusTemplateId,
		copy,
		focusTab,
	) => {
		if (focusTab) {
			setInitialTab(focusTab);
		}
		const foundSuerveyTemplate = surveyTemplates.data
			.find((template) => template._id === focusTemplateId);

		if (foundSuerveyTemplate && !copy) {
			setFocusSurveyTemplate(foundSuerveyTemplate);
			setOpenSurveyTemplateModal(true);
			return;
		}

		const { data } = await surveyApi.createSurveyTemplate(copy
			? {
				title: foundSuerveyTemplate.title,
				description: foundSuerveyTemplate.description,
				questions: foundSuerveyTemplate.questions.map(
					(question) => ({ ...question, _id: undefined }),
				),
			}
			: {});
		if (data) {
			setFocusSurveyTemplate({ ...data, participantsCount: 0 });
			setSurveyTemplates({
				data: [...surveyTemplates.data, { ...data, participantsCount: 0 }],
				totalItemsCount: surveyTemplates.totalItemsCount + 1,
			});
			setOpenSurveyTemplateModal(true);
		}
	}, [surveyTemplates, setOpenSurveyTemplateModal, setFocusSurveyTemplate, setSurveyTemplates]);

	const handleCloseSurveyTemplateModal = useCallback(() => {
		setOpenSurveyTemplateModal(false);
		setFocusSurveyTemplate(null);
		setInitialTab(Tabs.SURVEY_TEMPLATE);
	}, [setOpenSurveyTemplateModal]);

	const fetchSurveyTemplates = useCallback(async (
		isArchived,
		itemsPerPage = -1,
		currentPage = -1,
		currentSort = ListSortingType.OLDEST_TO_NEWEST,
	) => {
		const sortValue = currentSort === ListSortingType.OLDEST_TO_NEWEST ? 1 : -1;
		const { data } = await surveyApi.fetchSurveyTemplates(
			isArchived,
			itemsPerPage,
			currentPage,
			sortValue,
		);
		setSurveyTemplates(data);
		return data;
	}, []);

	const handleOpenInvite = useCallback((newStudiId) => {
		if (newStudiId) {
			fetchSurveyTemplates();
			setStudioId(newStudiId);
		}
		setInviteOpen(true);
	}, [setInviteOpen, setStudioId, fetchSurveyTemplates]);

	const handleCloseInvite = useCallback(() => {
		setStudioId('');
		setInviteOpen(false);
	}, [setInviteOpen, setStudioId]);

	const createSurveyTemplate = useCallback(async (surveyTemplate) => {
		const { data } = await surveyApi.createSurveyTemplate(surveyTemplate);
		return data;
	}, []);

	const handleCreateSurveyTemplate = useCallback(async (surveyTemplate) => {
		const newSurveyTemplate = await createSurveyTemplate(surveyTemplate);
		if (newSurveyTemplate) {
			setSurveyTemplates((templates) => ({
				totalItemsCount: templates.totalItemsCount + 1,
				data: [...templates.data, newSurveyTemplate],
			}));
			handleCloseSurveyTemplateModal();
		}
	}, [createSurveyTemplate, handleCloseSurveyTemplateModal]);

	const updateSurveyTemplate = useCallback(async (surveyTemplateId, surveyTemplate) => {
		const { data } = await surveyApi.updateSurveyTemplate(surveyTemplateId, surveyTemplate);
		return data;
	}, []);

	const handleUpdateSurveyTemplate = useCallback(async (surveyTemplateData) => {
		const data = await updateSurveyTemplate(
			surveyTemplateData._id,
			convertToUpdateDto(surveyTemplateData),
		);
		if (data) {
			setSurveyTemplates((surveys) => ({
				...surveys,
				data: findAndReplace(surveys.data, { ...data, participantsCount: 0 }),
			}));
			setFocusSurveyTemplate({ ...data, participantsCount: 0 });
		}
	}, [updateSurveyTemplate]);

	const deleteSurveyTemplate = useCallback(async (surveyTemplateId) => {
		const { data } = await surveyApi.deleteSurveyTemplate(surveyTemplateId);
		return data;
	}, []);

	const handleDeleteSurveyTemplate = useCallback(async (surveyTemplateId) => {
		const data = await deleteSurveyTemplate(surveyTemplateId);
		if (data) {
			setSurveyTemplates((surveys) => ({
				totalItemsCount: surveys.totalItemsCount - 1,
				data: surveys.data.filter((currentSurvey) => surveyTemplateId !== currentSurvey._id),
			}));
		}
	}, [deleteSurveyTemplate]);

	const restoreSurveyTemplate = useCallback(async (surveyTemplateId) => {
		const { data } = await surveyApi.restoreSurveyTemplate(surveyTemplateId);
		return data;
	}, []);

	const handleRestoreSurveyTemplate = useCallback(async (surveyTemplateId) => {
		const data = await restoreSurveyTemplate(surveyTemplateId);

		if (data) {
			setSurveyTemplates((surveys) => ({
				totalItemsCount: surveys.totalItemsCount - 1,
				data: surveys.data.filter((currentSurvey) => surveyTemplateId !== currentSurvey._id),
			}));
		}
	}, [restoreSurveyTemplate]);

	const handlePublishSurveyTemplate = useCallback(async (surveyTemplateData) => {
		const data = await updateSurveyTemplate(
			surveyTemplateData._id,
			{ ...convertToUpdateDto(surveyTemplateData), completed: true },
		);
		if (data) {
			setSurveyTemplates((surveys) => ({
				...surveys,
				data: findAndReplace(surveys.data, { ...data, participantsCount: 0 }),
			}));
			setFocusSurveyTemplate({ ...data, participantsCount: 0 });
		}
	}, [updateSurveyTemplate]);

	const getReport = useCallback(async (surveyTemplateId) => {
		const { data } = await surveyApi.getReport(surveyTemplateId);
		return data;
	}, []);

	const getPeople = useCallback(async (surveyTemplateId) => {
		const { data } = await surveyApi.getSurvetTemplateParticipants(surveyTemplateId);
		return data;
	}, []);

	const context = useMemo(() => ({
		surveyTemplates,
		fetchSurveyTemplates,
		handleCreateSurveyTemplate,
		handleUpdateSurveyTemplate,
		handleDeleteSurveyTemplate,
		handleRestoreSurveyTemplate,
		updateSurveyTemplate,
		isOpenSurveyTemplateModal,
		handleOpenSurveyTemplateModal,
		handleCloseSurveyTemplateModal,
		focusSurveyTemplate,
		handlePublishSurveyTemplate,
		handleOpenInvite,
		handleCloseInvite,
		handleSendInvites,
		getReport,
		getPeople,
		studioId,
		isInviteOpen,
		initialTab,
		setInitialTab,
	}), [
		surveyTemplates,
		fetchSurveyTemplates,
		handleCreateSurveyTemplate,
		handleUpdateSurveyTemplate,
		handleDeleteSurveyTemplate,
		handleRestoreSurveyTemplate,
		updateSurveyTemplate,
		isOpenSurveyTemplateModal,
		handleOpenSurveyTemplateModal,
		handleCloseSurveyTemplateModal,
		focusSurveyTemplate,
		handlePublishSurveyTemplate,
		handleOpenInvite,
		handleCloseInvite,
		handleSendInvites,
		getReport,
		getPeople,
		studioId,
		isInviteOpen,
		initialTab,
		setInitialTab,
	]);

	return (
		<SurveyTemplateContext.Provider value={context}>
			{children}
		</SurveyTemplateContext.Provider>
	);
};

SurveyTemplateProvider.propTypes = {
	children: PropTypes.node,
};

SurveyTemplateProvider.defaultProps = {
	children: undefined,
};
