import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { getLayoutsDefaults, LayerBounds, Layout } from '../../lib/studio';
import { useMixing } from '../ReactVideo/Mixing';

const isPipLayer = (layer) => layer === 'main' || /^pip\d$/.test(layer);

export const PipLayout = ({
	layersOverrides,
	layout,
	showLabels,
}) => {
	const formattedLayers = useMemo(() => {
		const layoutsDefaults = getLayoutsDefaults({
			width: LayerBounds.width,
			height: LayerBounds.height,
		});

		const { layers } = layoutsDefaults[layout];

		return layers.map((layer, index) => ({
			layer: index === 0 ? 'main' : `pip${index}`,
			layerConfig: {
				x: layer.x,
				y: layer.y,
				width: layer.width,
				height: layer.height,
			},
		}));
	}, [layout]);

	const layersWidthOverrides = formattedLayers.map((layer) => {
		const layerOverride = layersOverrides.find((l) => l.layer === layer.layer);
		if (layerOverride) {
			return layerOverride;
		}
		return layer;
	});

	const layersPositionAsPercentage = layersWidthOverrides.map((layer) => {
		const { x, y, width, height } = layer.layerConfig;
		return {
			...layer,
			x: (x / LayerBounds.width) * 100,
			y: (y / LayerBounds.height) * 100,
			width: (width / LayerBounds.width) * 100,
			height: (height / LayerBounds.height) * 100,
		};
	});

	return (
		<div
			className="PipLayout position-relative w-100"
			style={{
				aspectRatio: '16/9',
			}}
		>
			{layersPositionAsPercentage.map((layer) => (
				<div
					className="position-absolute opacity-8 bg-second content-second d-flex justify-content-center align-items-center border border-dark rounded rounded-1"
					key={layer.layer}
					style={{
						left: `${layer.x}%`,
						top: `${layer.y}%`,
						width: `${layer.width}%`,
						height: `${layer.height}%`,
					}}
				>
					{showLabels && layer.layer}
				</div>
			))}
		</div>
	);
};

PipLayout.propTypes = {
	layersOverrides: PropTypes.arrayOf(PropTypes.shape({
		layer: PropTypes.string.isRequired,
		layerConfig: PropTypes.shape({
			x: PropTypes.number.isRequired,
			y: PropTypes.number.isRequired,
			width: PropTypes.number.isRequired,
			height: PropTypes.number.isRequired,
		}).isRequired,
	})),
	layout: PropTypes.string.isRequired,
	showLabels: PropTypes.bool,
};

PipLayout.defaultProps = {
	layersOverrides: [],
	showLabels: false,
};

export const PipLayoutConnected = (props) => {
	const { channelMixerStatus } = useMixing();
	const layout = channelMixerStatus?.displayMode ?? Layout.DEFAULT_MODE;

	const layersOverrides = useMemo(
		() => {
			const layoutsDefaults = getLayoutsDefaults({
				width: LayerBounds.width,
				height: LayerBounds.height,
			});

			const { layers } = layoutsDefaults[layout];

			const formattedLayers = layers.map((layer, index) => ({
				layer: index === 0 ? 'main' : `pip${index}`,
				layerConfig: {
					x: layer.x,
					y: layer.y,
					width: layer.width,
					height: layer.height,
				},
			}));

			const currentLayersOverrides = (Object.entries(channelMixerStatus?.layersConfig || {}))
				.filter(([layer]) => isPipLayer(layer))
				.map(([layer, layerConfig]) => ({
					layer,
					layerConfig,
				}))
				.filter((layer) => {
					const defaultlayer = formattedLayers.find((l) => l.layer === layer.layer);
					if (!defaultlayer) return false;
					if (
						defaultlayer.layerConfig.x !== layer.layerConfig.x
						|| defaultlayer.layerConfig.y !== layer.layerConfig.y
						|| defaultlayer.layerConfig.width !== layer.layerConfig.width
						|| defaultlayer.layerConfig.height !== layer.layerConfig.height
					) return true;
					return false;
				});

			const result = formattedLayers.map((layer) => {
				const layerOverride = currentLayersOverrides.find((l) => l.layer === layer.layer)
					|| undefined;
				return layerOverride;
			}).filter(Boolean);

			return result;
		},
		[
			channelMixerStatus?.layersConfig,
			layout,
		],
	);

	return (
		<PipLayout
			{...props}
			layersOverrides={layersOverrides}
			layout={layout}
		/>
	);
};
