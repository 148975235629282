import React from 'react';
import 'react-multi-email/style.css';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import {
	Alert,
	Button,
	Col,
	Modal,
	Row,
} from 'reactstrap';
import { useAsyncCallback } from 'react-async-hook';
import { useTranslation } from 'react-i18next';

import { ButtonLoading } from '../Button';
import { useStudio } from './Context';

export const StudioModalTerminate = ({
	isOpen,
	stopActiveStudio,
	toggle,
}) => {
	const { t } = useTranslation();
	const { setOpenSurveyModalOnStop } = useStudio();

	const onClickTerminate = useAsyncCallback(async () => {
		ReactGA.event({ category: 'studio', action: 'terminate', label: 'User clicked on "End" button in Studio' });
		await stopActiveStudio();

		toggle();
	});

	const onClickTerminateAndSurvey = useAsyncCallback(async () => {
		ReactGA.event({ category: 'studio', action: 'terminate', label: 'User clicked on "End & Survey" button in Studio' });
		await stopActiveStudio();
		setOpenSurveyModalOnStop(true);
		toggle();
	});

	return (
		<Modal
			centered
			contentClassName="border-0 rounded overflow-hidden bg-transparent align-self-center"
			isOpen={isOpen}
			toggle={toggle}
			zIndex={2000}
		>
			<div className="bg-dark shadow-lg">
				<Row className="g-0">
					<Col xs="12" lg="8" className="d-flex mx-auto rounded overflow-hidden p-relative">
						<div className="py-5 mx-auto">
							<h4 className="mb-4 text-center">{t('Studio.ModalTerminate.closeForEveryone')}</h4>
							{onClickTerminate.error && (
								<Alert color="danger">
									{t('Global.error')}
								</Alert>
							)}
							<div className="d-flex justify-content-between w-100">
								<Button
									onClick={toggle}
									color="link"
									className=""
								>
									{t('Studio.ModalTerminate.cancel')}
								</Button>
								<div className="d-flex flex-column">
									<ButtonLoading
										className="text-white"
										color="secondary"
										disabled={onClickTerminate.loading}
										loading={onClickTerminateAndSurvey.loading}
										onClick={onClickTerminateAndSurvey.execute}
									>
										{t('Studio.ModalTerminate.terminateAndSurvey')}
									</ButtonLoading>
									<ButtonLoading
										className="mt-2"
										color="danger"
										disabled={onClickTerminateAndSurvey.loading}
										loading={onClickTerminate.loading}
										onClick={onClickTerminate.execute}
									>
										{t('Studio.ModalTerminate.terminate')}
									</ButtonLoading>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</Modal>
	);
};

StudioModalTerminate.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	stopActiveStudio: PropTypes.func.isRequired,
	toggle: PropTypes.func.isRequired,
};
