import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
	FormGroup,
	Input,
} from 'reactstrap';
import { Field, FieldError } from 'react-jsonschema-form-validation';
import { useTranslation } from 'react-i18next';

export const removeNonNumeric = (text) => text.replace(/[^0-9]/g, '');

export const NO_PASSWORD = 'no';
export const AUTO_PASSWORD = 'auto';
export const MANUAL_PASSWORD = 'manual';

export const StudioScheduleSettingsFormPasswordInput = ({
	onChange,
	password,
}) => {
	const { t } = useTranslation();
	// const [showPassword, setShowPassword] = useState(password ? MANUAL_PASSWORD : NO_PASSWORD);
	// Password is now mandatory !
	const [showPassword, setShowPassword] = useState(MANUAL_PASSWORD);

	// useEffect(() => {
	// 	if (showPassword === MANUAL_PASSWORD) {
	// 		passwordRef.current.focus();
	// 	}
	// }, [showPassword]);

	return (
		<FormGroup tag="fieldset">
			{/* <FormGroup check>
				<Field
					component={Input}
					type="radio"
					name="showPassword"
					id="password1"
					onChange={() => {
						setShowPassword(NO_PASSWORD);
						onChange({ password: '' });
					}}
					checked={showPassword === NO_PASSWORD}
				/>
				<Label check for="password1" className="blue">No password</Label>
			</FormGroup> */}
			{/* <FormGroup check>
				<Field
					component={Input}
					type="radio"
					name="showPassword"
					id="password2"
					onChange={() => {
						setShowPassword(AUTO_PASSWORD);
						onChange({ password: generatePassword(6) });
					}}
					checked={showPassword === AUTO_PASSWORD}
				/>
				<Label check for="password2" className="blue">Auto-generate</Label>
			</FormGroup>
			<FormGroup check>
				<Field
					component={Input}
					type="radio"
					name="showPassword"
					id="password3"
					onChange={() => {
						setShowPassword(MANUAL_PASSWORD);
						onChange({ password: '' });
					}}
					checked={showPassword === MANUAL_PASSWORD}
				/>
				<Label check for="password3" className="blue">Choose</Label>
			</FormGroup> */}
			{showPassword !== NO_PASSWORD && (
				<FormGroup className="mt-1">
					<Field
						component={Input}
						type="text"
						name="password"
						onChange={(e) => {
							setShowPassword(MANUAL_PASSWORD);
							onChange({ password: removeNonNumeric(e.target.value) });
						}}
						value={password}
						maxLength="6"
						placeholder={t('SettingsForm.PasswordInput.type')}
					/>
					<FieldError name="password" />
				</FormGroup>
			)}
		</FormGroup>
	);
};

StudioScheduleSettingsFormPasswordInput.propTypes = {
	onChange: PropTypes.func.isRequired,
	password: PropTypes.string.isRequired,
};
