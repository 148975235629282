import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary as RollbarErrorBoundary } from '@rollbar/react';

import ViewError from '../../views/Error/Error';

export const ErrorBoundary = ({
	children,
	fallbackUI,
	...props
}) => (
	<RollbarErrorBoundary fallbackUI={fallbackUI} {...props}>
		{children}
	</RollbarErrorBoundary>
);

ErrorBoundary.propTypes = {
	children: PropTypes.node.isRequired,
	fallbackUI: PropTypes.elementType,
};

ErrorBoundary.defaultProps = {
	fallbackUI: ViewError,
};
