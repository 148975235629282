const ACTION_KEY = 'tracks';

/**
 * actions
 */
export const ADD_TRACK = `${ACTION_KEY}/addTrack`;
export const CLEAR_TRACKS = `${ACTION_KEY}/clearTracks`;
export const REMOVE_TRACK = `${ACTION_KEY}/removeTrack`;

export const addTrackAction = (track) => ({
	type: ADD_TRACK,
	track,
});

export const clearTracksAction = () => ({
	type: CLEAR_TRACKS,
});

export const removeTrackAction = (track) => ({
	type: REMOVE_TRACK,
	track,
});
