import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { FaChevronCircleLeft, FaChevronDown } from 'react-icons/fa';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem } from 'reactstrap';

import { useTranslation } from 'react-i18next';
import { ModalScreenHeaderCloseButton } from '../../../Modal/Screen/Header';
import { ProfileAvatar } from '../../../Profile/Avatar';
import { DropdownMode } from './ModalDropdownMode';

import './Header.scss';

export const ChannelDropdownHeader = ({
	avatar,
	onClose,
	onBack,
	noBorder,
	activeMode,
	setActiveMode,
	children,
	activeChannel,
	channels,
	setActiveChannel,
	buttons,
}) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const { t } = useTranslation();

	const handleChannelChange = useCallback((mode) => {
		setActiveMode(mode);
		setDropdownOpen(false);
	}, [setDropdownOpen, setActiveMode]);

	const handleChangeSharingChannel = useCallback((channel) => {
		setDropdownOpen(false);
		setActiveChannel(channel);
		handleChannelChange(DropdownMode.CHANNEL_STUDIOS);
	}, [setDropdownOpen, setActiveChannel, handleChannelChange]);

	return (
		<header className={clsx('p-3 bg-light content-light', { 'border-bottom': !noBorder })}>
			{!!onBack && (
				<span className="btn-wrapper--icon mr-2 cursor-pointer text-secondary font-size-lg" onClick={onBack}>
					<FaChevronCircleLeft />
				</span>
			)}
			<div className={`d-flex align-items-center w-100 mr-md-4 cursor-pointer ${onBack ? 'ml-md-2' : 'ml-md-3'}`}>
				<div className="d-flex justify-content-between w-100">
					<Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
						<DropdownToggle className="ChannelDropdownHeader_toggle p-0 m-0 text-primary hover-none border-0 shadow-none bg-light content-light">
							<div className="d-flex align-items-center">
								<ProfileAvatar
									avatarUrl={activeMode === DropdownMode.MY_STUDIOS
										? avatar
										: activeChannel.avatar}
								/>
								<h4 className="m-0">
									{activeMode === DropdownMode.CHANNEL_STUDIOS
										? t('StudioManager.Header.someoneStudio', { nickname: activeChannel.nickname })
										: t('StudioManager.Header.myStudios')}
								</h4>
								<span className="btn-wrapper--icon ml-2 text-secondary font-size-md" onClick={onBack}>
									<FaChevronDown />
								</span>
							</div>
						</DropdownToggle>
						<DropdownMenu className="dropdown-menu-lg p-3 bg-dark">
							<Nav className="nav-neutral-secondary flex-column">
								<NavItem
									onClick={() => handleChannelChange(DropdownMode.MY_STUDIOS)}
									className={
										`ChannelDropdownHeader_item px-3 py-2 text-secondary text-small ${activeMode === DropdownMode.MY_STUDIOS && 'active'}`
									}
								>
									{t('StudioManager.Header.myStudios')}
								</NavItem>
								<DropdownItem className="border-secondary" divider />
								<p className="text-uppercase text-small text-secondary m-0">{t('StudioManager.Header.sharedStudios')}</p>
								{channels.map((channel) => (
									<NavItem
										key={`dropdowm-menu-item-${channel._id}`}
										className={`ChannelDropdownHeader_item d-flex align-items-center mt-2 text-small text-secondary ${activeMode === DropdownMode.CHANNEL_STUDIOS && activeChannel._id === channel.channel._id && 'active'}`}
										onClick={() => handleChangeSharingChannel(channel.channel)}
									>
										<ProfileAvatar avatarUrl={channel.channel.avatar} />
										<p className="m-0">{channel.channel.nickname}</p>
									</NavItem>
								))}
							</Nav>
						</DropdownMenu>
					</Dropdown>
					<div className="d-flex">
						{!!buttons && (
							<div className="d-flex align-items-center align-self-start mt-2">
								{buttons}
							</div>
						)}
						{!!onClose && (
							<ModalScreenHeaderCloseButton
								className="ml-3 align-self-start flex-shrink-0"
								onClose={onClose}
							/>
						)}
					</div>
				</div>
			</div>
			{children}

		</header>
	);
};

ChannelDropdownHeader.defaultProps = {
	avatar: '',
};

ChannelDropdownHeader.defaultProps = {
	avatar: undefined,
	children: undefined,
	noBorder: false,
	onClose: undefined,
	onBack: undefined,
	activeChannel: {
		_id: undefined,
		avatar: undefined,
		nickname: undefined,
	},
	buttons: undefined,
};

ChannelDropdownHeader.propTypes = {
	activeMode: PropTypes.string.isRequired,
	onBack: PropTypes.func,
	avatar: PropTypes.string,
	children: PropTypes.node,
	noBorder: PropTypes.bool,
	onClose: PropTypes.func,
	setActiveMode: PropTypes.func.isRequired,
	activeChannel: PropTypes.shape({
		_id: PropTypes.string,
		avatar: PropTypes.string,
		nickname: PropTypes.string,
	}),
	channels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	setActiveChannel: PropTypes.func.isRequired,
	buttons: PropTypes.node,
};
