import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import fr from 'javascript-time-ago/locale/fr';
import { useTranslation } from 'react-i18next';
import { getLanguageKey } from '../i18n';

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(fr);

const TimeAgoLocalesInstances = {};

TimeAgoLocalesInstances.default = new TimeAgo();
TimeAgoLocalesInstances.en = TimeAgoLocalesInstances.default;
TimeAgoLocalesInstances.fr = new TimeAgo('fr-FR');

export const useLocalizedTimeAgo = () => {
	const { i18n } = useTranslation();

	const locale = getLanguageKey(i18n.language);

	return TimeAgoLocalesInstances[locale] || TimeAgoLocalesInstances.default;
};
