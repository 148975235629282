import { combineReducers } from 'redux';

import channelStreamsReducer, { STATE_KEY as CHANNEL_STREAMS_STATE_KEY } from './channelStreams';
import publicationsReducer, { STATE_KEY as PUBLICATIONS_STATE_KEY } from './publications';
import tracksReducer, { STATE_KEY as TRACKS_STATE_KEY } from './tracks';

/**
 * reducers
 */
const videosReducers = combineReducers({
	[CHANNEL_STREAMS_STATE_KEY]: channelStreamsReducer,
	[PUBLICATIONS_STATE_KEY]: publicationsReducer,
	[TRACKS_STATE_KEY]: tracksReducer,
});

export default videosReducers;
