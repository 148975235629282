import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { v4 as uuidv4 } from 'uuid';
import { KEY_CODES } from './keyCodes';

// import ChannelDescription from '../Channel/Description/Description';
import { useScreenMode, ModeScreenLayout } from '../Studio/ScreenMode/Provider';
import * as apiChannelVideos from '../../api/channel/videos';
import { useTrackWatchTime } from './useTrackWatchTime';
import { VideoTimeline } from './VideoTimeline/VideoTimeline';
import { ShareVideoButton } from '../../views/Controlroom/Settings/Library/Videos/ShareVideoButton';
// import { ShareVideoButton } from './ShareVideoButton';
import './HLSPlayer.scss';
import { ChannelButtonsPanel } from '../Channel/ChannelButtonsPanel/ChannelButtonsPanel';

const playerOptions = {
	preload: 'auto',
	fill: true,
	controlBar: false,
};

export const HLSPlayerVideoType = {
	LIVE: 'LIVE',
	VOD: 'VOD',
};

export const HLSPlayer = ({
	autoPlay,
	centerChildren,
	className,
	controls,
	currentTime,
	isPreview,
	isRequestingPlay,
	miniPlayer,
	noFullscreen,
	onEnded,
	onError,
	onVideoPlaying,
	playerSizeRef,
	source,
	src,
	toggleDonateModal,
	videoType,
	vodId,
}) => {
	const [videoSpeed, setVideoSpeed] = useState(1);
	const { currentModeScreen, toggleFullscreen } = useScreenMode();
	const viewedRef = useRef(false);

	const playerRef = useRef();
	const videoJsNode = useRef();
	const watchId = useMemo(() => uuidv4(), []);
	const [progressTime, setProgressTime] = useState(0);
	const [bufferedPercent, setBufferedPercent] = useState(0);
	const [volume, setVolume] = useState(50);
	const [isMuted, setIsMuted] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);

	const requestAnimationRef = useRef();

	useEffect(() => {
		if (isPlaying && onVideoPlaying) onVideoPlaying();
	}, [isPlaying, onVideoPlaying]);

	useEffect(() => {
		if (isRequestingPlay && playerRef.current) {
			playerRef.current.play();
		}
	}, [isRequestingPlay]);

	const handleVideoJsRef = useCallback((node) => {
		if (node) {
			videoJsNode.current = node;
			if (playerSizeRef) playerSizeRef.current = node;
		}
	}, [playerSizeRef]);

	const handleChangeProgressTime = useCallback(
		(newProgressTime) => {
			if (playerRef.current) {
				playerRef.current.currentTime(newProgressTime);
				setProgressTime(newProgressTime);
			}
		},
		[setProgressTime],
	);

	const handleTogglePlaying = useCallback(() => {
		if (!playerRef.current) return;
		if (isPlaying) {
			playerRef.current.pause();
		} else {
			playerRef.current.play();
		}
		setIsPlaying(!isPlaying);
	}, [isPlaying]);

	const handleChangeVolume = useCallback(
		(value) => {
			setVolume(parseInt(value, 10));
			if (isMuted) {
				setIsMuted(false);
			}
		},
		[isMuted],
	);

	const handleToggleSound = () => {
		setIsMuted(!isMuted);
	};

	const viewVideo = useCallback(async () => {
		if (videoType !== HLSPlayerVideoType.VOD) return;
		try {
			if (vodId) await apiChannelVideos.viewVideo(vodId, watchId);
		} catch (e) {
			console.error(e);
		}
	}, [videoType, vodId, watchId]);

	const sendVideoWatchTime = useCallback(async (playStartTime, playEndTime) => {
		if (videoType !== HLSPlayerVideoType.VOD) return;
		try {
			await apiChannelVideos.sendVideoWatchTime(vodId, watchId, playStartTime, playEndTime);
		} catch (e) {
			console.error(e);
		}
	}, [videoType, vodId, watchId]);

	const { onPlay, onPause, onTimeUpdate } = useTrackWatchTime(playerRef, sendVideoWatchTime);

	useEffect(() => {
		// console.log('videoSpeed', videoSpeed);
		if (playerRef.current) {
			playerRef.current.playbackRate(videoSpeed);
		}
	}, [videoSpeed]);

	const handleChangeFullscreen = () => {
		if (noFullscreen) return;
		toggleFullscreen();
	};

	const handleKeyDown = (event) => {
		event.preventDefault();
		// f1 = 112, f2 = 113, f3 = 114 are already used for studio !
		if (event.keyCode === KEY_CODES.KEY_SPACE) handleTogglePlaying();
		if (event.keyCode === KEY_CODES.KEY_UP) handleChangeVolume(volume < 100 ? volume + 5 : volume);
		if (event.keyCode === KEY_CODES.KEY_DOWN) handleChangeVolume(volume > 0 ? volume - 5 : volume);
		if (event.keyCode === KEY_CODES.KEY_M) handleToggleSound();
		if (event.keyCode === KEY_CODES.KEY_END) handleChangeProgressTime(playerRef.current.duration());
		if (event.keyCode === KEY_CODES.KEY_HOME) handleChangeProgressTime(0);
		if (event.keyCode === KEY_CODES.KEY_K) setVideoSpeed(1);
		if (event.keyCode === KEY_CODES.KEY_L) {
			setVideoSpeed(videoSpeed < 8 ? videoSpeed * 2 : videoSpeed);
		}
		if (event.keyCode === KEY_CODES.KEY_J) {
			setVideoSpeed(videoSpeed > 0.5 ? videoSpeed / 2 : videoSpeed);
		}
		if (event.keyCode === KEY_CODES.KEY_RIGHT) {
			handleChangeProgressTime(playerRef.current.currentTime() + 0.5);
		}
		if (event.keyCode === KEY_CODES.KEY_LEFT) {
			handleChangeProgressTime(playerRef.current.currentTime() - 0.5);
		}
		if (event.keyCode === KEY_CODES.KEY_F) handleChangeFullscreen();
		// if ((event.keyCode === KEY_CODES.KEY_ESCAPE)
		//	&& (currentModeScreen === ModeScreenLayout.FULLSCREEN)) toggleFullscreen();
		// if (event.keyCode === KEY_CODES.KEY_C) console.log('c Enable/Disable closed captions');
		// if (event.keyCode === KEY_CODES.KEY_I) console.log('i in');
		// if (event.keyCode === KEY_CODES.KEY_O) console.log('o out');
		// if (event.keyCode === KEY_CODES.KEY_P) console.log('p point');
		// if (event.keyCode === KEY_CODES.KEY_T) console.log('t');
	};

	useEffect(() => {
		if (videoJsNode.current) {
			const element = videoJsNode.current;
			element.addEventListener('keydown', handleKeyDown);
			return () => {
				element.removeEventListener('keydown', handleKeyDown);
			};
		}
		return undefined;
	});

	useEffect(() => {
		if (playerRef.current && Number.isFinite(currentTime?.startTime)) {
			playerRef.current.currentTime(currentTime.startTime);
		}
	}, [currentTime.startTime, playerRef]);

	useEffect(() => {
		if (playerRef.current && Number.isFinite(currentTime?.endTime)) {
			playerRef.current.currentTime(currentTime.endTime);
		}
	}, [currentTime.endTime, playerRef]);

	useEffect(() => (
		// clean player on unmount
		() => {
			const player = playerRef.current;
			if (!player) return;
			// cleanUp(playerRef.current);
			setTimeout(() => { // Using setTimeout to fix:
				// DOMException: Failed to execute 'removeChild' on 'Node':
				// The node to be removed is not a child of this node
				player.dispose();
			}, 0);
		}
	), []);

	useEffect(() => {
		if (!playerRef.current) return;
		playerRef.current.src(src);
	}, [src]);

	const playerInitialized = useRef(false);
	const handlersRef = useRef();

	useEffect(() => {
		handlersRef.current = {
			onEnded,
			onError,
		};
	}, [onEnded, onError]);

	useLayoutEffect(() => {
		if (!playerInitialized.current) {
			playerInitialized.current = true;
			videojs(
				videoJsNode.current,
				{
					...playerOptions,
					controls,
					autoplay: autoPlay,
					// userActions: { hotkeys: true },
					playbackRates: [1, 2, 4, 8],
				},
				function onPlayerReady() {
					playerRef.current = this;
					/* eslint-disable react/no-this-in-sfc */
					this.src(src);
					this.on('play', () => (onPlay && setIsPlaying(true)));
					this.on('pause', () => (onPause && setIsPlaying(false)));

					this.on('ended', () => {
						if (handlersRef.current.onEnded) handlersRef.current.onEnded();
					});
					this.on('error', (error) => {
						if (handlersRef.current.onError) handlersRef.current.onError(error);
					});

					if (playerRef.current && Number.isFinite(currentTime?.startTime)) {
						playerRef.current.currentTime(currentTime.startTime);
					}

					this.on('timeupdate', () => {
						onTimeUpdate();
						setBufferedPercent(this.bufferedPercent());
						if (!viewedRef.current && this.currentTime() > 3) {
							viewedRef.current = true;
							viewVideo();
						}
					});
					/* eslint-enable react/no-this-in-sfc */
				},
			);
		}
	}, [
		controls,
		onEnded,
		onError,
		onPause,
		onPlay,
		onTimeUpdate,
		sendVideoWatchTime,
		src,
		viewVideo,
		vodId,
		autoPlay,
		currentTime,
	]);

	useEffect(() => {
		if (!playerRef.current) return;
		if (isMuted) {
			playerRef.current.volume(0);
		} else {
			playerRef.current.volume(volume / 100);
		}
	}, [volume, isMuted]);

	useEffect(() => {
		if (!playerRef.current) return;
		const FPS = 30;
		let lastTimestamp = 0;
		if (isPlaying) {
			const animateProgressTimeLine = (time) => {
				requestAnimationRef.current = requestAnimationFrame(animateProgressTimeLine);
				if (time - lastTimestamp < 1000 / FPS) return;
				setProgressTime(playerRef.current.currentTime());
				lastTimestamp = time;
			};
			requestAnimationRef.current = requestAnimationFrame(animateProgressTimeLine);
		} else {
			requestAnimationRef.current = cancelAnimationFrame(requestAnimationRef.current);
		}
		// eslint-disable-next-line consistent-return
		return () => {
			cancelAnimationFrame(requestAnimationRef.current);
		};
	}, [isPlaying]);

	return (
		<>
			<video-js
				class={clsx(
					'HLSPlayer video-js vjs-default-skin vjs-big-play-centered w-100 d-flex',
					{ 'h-100': currentModeScreen === ModeScreenLayout.FULLSCREEN },
					{ isPlaying },
					className,
				)}
				controlBar={false}
				playsinline
				ref={handleVideoJsRef}
			/>
			{controls && isPreview && (
				<div className={clsx(
					'ChannelButtonsPanelContainer',
					{ 'layout-fullscreen position-absolute bottom-0 w-100 mw-100 mx-auto m-1': currentModeScreen === ModeScreenLayout.FULLSCREEN },
				)}
				>
					<ChannelButtonsPanel
						className={currentModeScreen === ModeScreenLayout.FULLSCREEN ? 'bg-light' : 'bg-black'}
						shareVideoButton={source && (
							<ShareVideoButton video={source} miniPlayer={miniPlayer} />
						)}
						// channelDescription={(
						// 	<ChannelDescription
						// 		isFullscreen={currentModeScreen === ModeScreenLayout.FULLSCREEN}
						// 		onClickDonate={toggleDonateModal}
						// 	/>
						// )}
						centerChildren={(
							<>
								{videoType === HLSPlayerVideoType.VOD && (
									<VideoTimeline
										bufferedPercent={bufferedPercent}
										currentTime={progressTime}
										duration={playerRef.current?.duration()}
										handleChangeProgressTime={handleChangeProgressTime}
									/>
								)}
								{centerChildren}
							</>
						)}
						handleChangeVolume={handleChangeVolume}
						// handleClickFullScreen={noFullscreen ? undefined : toggleFullscreen}
						handleClickFullScreen={handleChangeFullscreen}
						handleTogglePlaying={handleTogglePlaying}
						handleToggleSound={handleToggleSound}
						isFullscreen={currentModeScreen === ModeScreenLayout.FULLSCREEN}
						isMobilePortrait={false}
						isPlaying={isPlaying}
						isSoundMuted={isMuted}
						isVOD={videoType === HLSPlayerVideoType.VOD}
						isViewer
						volume={volume}
						source={source}
						miniPlayer={miniPlayer}
					/>
				</div>
			)}
		</>
	);
};

HLSPlayer.propTypes = {
	autoPlay: PropTypes.bool,
	centerChildren: PropTypes.node,
	className: PropTypes.string,
	controls: PropTypes.bool,
	currentTime: PropTypes.shape({
		startTime: PropTypes.number,
		endTime: PropTypes.number,
	}),
	isPreview: PropTypes.bool,
	isRequestingPlay: PropTypes.bool,
	miniPlayer: PropTypes.bool,
	noFullscreen: PropTypes.bool,
	onEnded: PropTypes.func,
	onError: PropTypes.func,
	onVideoPlaying: PropTypes.func,
	playerSizeRef: PropTypes.shape({
		current: PropTypes.shape({}),
	}),
	src: PropTypes.string.isRequired,
	toggleDonateModal: PropTypes.func,
	videoType: PropTypes.oneOf(Object.values(HLSPlayerVideoType)),
	vodId: PropTypes.string,
};

HLSPlayer.defaultProps = {
	autoPlay: true,
	centerChildren: null,
	className: '',
	controls: true,
	currentTime: {},
	isPreview: true,
	isRequestingPlay: false,
	miniPlayer: false,
	noFullscreen: false,
	onEnded: undefined,
	onError: undefined,
	onVideoPlaying: undefined,
	playerSizeRef: undefined,
	toggleDonateModal: undefined,
	videoType: HLSPlayerVideoType.VOD,
	vodId: undefined,
};
