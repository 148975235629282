import { publicApi } from '../api';

export const API_PUBLIC_LIVE_PATH = '/public/live';

export const fetchAllComingUpLiveByChannelId = (channelId) => publicApi.get(
	`${API_PUBLIC_LIVE_PATH}/byChannel/${channelId}/comingUp`,
);

export const fetchOneNextLiveByChannelId = (channelId) => publicApi.get(
	`${API_PUBLIC_LIVE_PATH}/byChannel/${channelId}/next`,
);

export const fetchComingUpLive = async () => publicApi.get(
	`${API_PUBLIC_LIVE_PATH}/comingUp`,
);
