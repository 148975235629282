import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FaRegEye } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Badge, Card, Col } from 'reactstrap';
import defaultAvatar from '../../../images/default-avatar.png';
import { AVATAR_SIZE, getFileUrl } from '../../../lib/file';
import { useValidateImage } from '../../../lib/useValidateImage';
import { getVodLink } from '../../../RoutePath';
import Hexagon from '../../Hexagon/Hexagon';
import { VideoThumbnail } from '../../Video/Thumbnail';
import './Item.scss';

export const VideoItem = ({ video, className }) => {
	const userAvatar = video.channelAvatar
		? getFileUrl({ name: video.channelAvatar }, AVATAR_SIZE.width)
		: defaultAvatar;
	const validateImage = useValidateImage(defaultAvatar);

	return (
		<Col className={classnames('VideoItem px-4 mb-4', className)} sm="6" md="6" lg="4" xl="3">
			<Link to={getVodLink(video.channelHashtag, video._id)}>
				<Card
					className={classnames(
						'ChannelCard card-transparent card-box-hover-rise rounded overflow-hidden fix-overflow',
						className,
					)}
				>
					<VideoThumbnail
						video={video}
						className="mx-auto h-100 w-100"
					/>
					<div className="ChannelCard_bottom bg-darker content-darker d-flex p-2 w-100 align-items-center">
						<div className="d-flex overflow-hidden">
							<div className={classnames('ChannelCard_avatar avatar-icon-wrapper')}>
								<Hexagon className="avatar-icon rounded-0 shadow-none">
									<img className="img-fit-container" alt="avatar" src={userAvatar} onError={validateImage} />
								</Hexagon>
							</div>
							<div className="d-flex flex-column justify-content-center overflow-hidden font-size-sm">
								<strong className="ChannelCard_info">
									{video.title}
								</strong>
								<span className="ChannelCard_info">
									{video.channelLabel}
								</span>
							</div>
						</div>
						{video.status === 'ready' && (
							<Badge className="ChannelCard_viewers bg-light content-light content-fill-svg ml-auto mr-1 mb-1" pill>
								<FaRegEye className="mr-1" />
								<span className="content-light">{video.vodViews || 0}</span>
							</Badge>
						)}
					</div>
				</Card>
			</Link>
		</Col>
	);
};

VideoItem.propTypes = {
	video: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		status: PropTypes.string.isRequired,
		playbackId: PropTypes.string,
		thumbnailTimeCode: PropTypes.string,
		filename: PropTypes.string,
		vodViews: PropTypes.number.isRequired,
		title: PropTypes.string.isRequired,
		channelLabel: PropTypes.string.isRequired,
		channelHashtag: PropTypes.string.isRequired,
		channelAvatar: PropTypes.string,
	}).isRequired,
	className: PropTypes.string,
};

VideoItem.defaultProps = {
	className: '',
};
