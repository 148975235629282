import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FaShare } from 'react-icons/fa';

import { Translate } from '../../../i18n';
import { ButtonPill } from '../../Button';
import { useChannelShare } from './Context';

export const ChannelShareButton = ({ className, children, ...props }) => {
	const { toggle } = useChannelShare();

	return (
		<ButtonPill
			className={classnames('shadow-none bg-light', className)}
			color="neutral-secondary"
			onClick={toggle}
			type="button"
			{...props}
		>
			{children}
		</ButtonPill>
	);
};

ChannelShareButton.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

ChannelShareButton.defaultProps = {
	className: '',
	children: (
		<>
			<span className="btn-wrapper--icon"><FaShare /></span>
			<span className="btn-wrapper--label d-none-fullscreen-md">
				<Translate k="Share.Button.share" />
			</span>
		</>
	),
};
