import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Header } from '../../layout-components';
import { useScreenMode, ModeScreenLayout } from '../../components/Studio/ScreenMode/Provider';

const NoSidebar = (props) => {
	const { isStudioRoute, children } = props;
	const { currentModeScreen } = useScreenMode();

	return (
		<div className="app-wrapper bg-darker content-darker">
			<div className={clsx('app-main',
				{ 'overflow-hidden': currentModeScreen === ModeScreenLayout.FULLSCREEN })}
			>
				<Header noSideBar isStudioRoute={isStudioRoute} />
				<div className="app-content">
					<div className="app-content--inner">
						<div className="app-content--inner__wrapper">{children}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

NoSidebar.propTypes = {
	isStudioRoute: PropTypes.bool,
	children: PropTypes.node,
};

NoSidebar.defaultProps = {
	isStudioRoute: false,
	children: null,
};

export default NoSidebar;
