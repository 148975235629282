import React,
{
	useCallback,
	useEffect,
	useRef,
} from 'react';
import PropTypes from 'prop-types';

import { useInputs } from '../Inputs/Context';
import getUserMedia from '../../lib/getUserMedia';
import { MediaUserPermissionStatus } from './UserAccessStatus';
import { Resolution, USER_MAX_FPS } from './utils';

const getUserMediaVideoConstraints = (res) => ({
	height: { ideal: res },
	frameRate: { ideal: USER_MAX_FPS }, // max: USER_MAX_FPS (24) is not supported by Firefox
	aspectRatio: { ideal: 16 / 9 },
});

const DEFAULT_AUDIO_CONSTRAINTS = {
	echoCancellation: { ideal: true },
	noiseSuppression: { ideal: true },
};

const PROMPT_TIMEOUT = 500;

export const MediaUserPerform = ({
	allowAudio,
	addTracks,
	isVideoAllowed,
	inputConfig,
	resolution,
	removeTracksByConfigId,
	userAudioRequestError,
	userVideoRequestError,
	setUserAudioPromptStatus,
	setUserVideoPromptStatus,
	setUserAudioRequestError,
	setUserVideoRequestError,
	stopConfigTracks,
}) => {
	const { getAvailableInputId } = useInputs();
	const requestedAudioDeviceId = useRef();
	const requestedVideoDeviceId = useRef();
	const resolutionRef = useRef(resolution);

	const { audioInputOff, id: inputConfigId, videoInputOff } = inputConfig;
	const enabledAudio = allowAudio && !audioInputOff;
	const enabledVideo = isVideoAllowed && !videoInputOff;

	const stopMediastream = useCallback((cfg) => {
		const stopAudioDeviceId = cfg.audio && requestedAudioDeviceId.current;
		const stopVideoDeviceId = cfg.video && requestedVideoDeviceId.current;
		if (stopAudioDeviceId) {
			requestedAudioDeviceId.current = undefined; // cancel audio request
			stopConfigTracks(inputConfigId, 'audio');
			removeTracksByConfigId(inputConfigId, 'audio');
			// Needs this for firefox because the event
			// "ended" is not handled when track is stopped manually
		}
		if (stopVideoDeviceId) {
			requestedVideoDeviceId.current = undefined; // cancel video request
			stopConfigTracks(inputConfigId, 'video');
			removeTracksByConfigId(inputConfigId, 'video');
		}
	}, [inputConfigId, stopConfigTracks, removeTracksByConfigId]);

	const stopMediastreamRef = useRef(stopMediastream);
	useEffect(() => { stopMediastreamRef.current = stopMediastream; }, [stopMediastream]);

	const requestMediastream = useCallback(async (requestedUserDevices) => {
		if (!requestedUserDevices.audio && !requestedUserDevices.video) return;

		if (requestedUserDevices.audio) {
			requestedAudioDeviceId.current = requestedUserDevices.audio;
			setUserAudioRequestError(undefined, inputConfigId);
		}
		if (requestedUserDevices.video) {
			requestedVideoDeviceId.current = requestedUserDevices.video;
			setUserVideoRequestError(undefined, inputConfigId);
		}

		const audioConstraints = requestedUserDevices.audio
			? { ...DEFAULT_AUDIO_CONSTRAINTS, deviceId: requestedUserDevices.audio }
			: false;
		const videoConstraints = requestedUserDevices.video
			? {
				...getUserMediaVideoConstraints(resolutionRef.current),
				deviceId: requestedUserDevices.video,
			}
			: false;

		let isPrompted = false;
		const promptTimeout = setTimeout(() => {
			isPrompted = true;
			if (requestedUserDevices.audio) setUserAudioPromptStatus(MediaUserPermissionStatus.PROMPT);
			if (requestedUserDevices.video) setUserVideoPromptStatus(MediaUserPermissionStatus.PROMPT);
		}, PROMPT_TIMEOUT);

		let mediastream;

		// const supportedConstraints = navigator.mediaDevices?.getSupportedConstraints
		// 	? navigator.mediaDevices.getSupportedConstraints()
		// 	: {};

		try {
			mediastream = await getUserMedia({
				audio: audioConstraints,
				video: videoConstraints,
			});
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error);
			if (requestedUserDevices.audio) {
				setUserAudioRequestError(error, inputConfigId);
			}
			if (requestedUserDevices.video) {
				setUserVideoRequestError(error, inputConfigId);
			}
			if (requestedUserDevices.audio && isPrompted) {
				setUserAudioPromptStatus(error.name === 'NotAllowedError'
					? MediaUserPermissionStatus.DENIED
					: MediaUserPermissionStatus.GRANTED);
			}
			if (requestedUserDevices.video && isPrompted) {
				setUserVideoPromptStatus(error.name === 'NotAllowedError'
					? MediaUserPermissionStatus.DENIED
					: MediaUserPermissionStatus.GRANTED);
			}
			return;
		} finally {
			clearTimeout(promptTimeout);
		}

		if (requestedUserDevices.audio && isPrompted) {
			setUserAudioPromptStatus(MediaUserPermissionStatus.GRANTED);
		}
		if (requestedUserDevices.video && isPrompted) {
			setUserVideoPromptStatus(MediaUserPermissionStatus.GRANTED);
		}

		// Allow cancellation
		const isStillUserAudioRequested = requestedUserDevices.audio === requestedAudioDeviceId.current;
		const isStillUserVideoRequested = requestedUserDevices.video === requestedVideoDeviceId.current;

		// Possible cancellation while getUserMedia was pending
		const audioTracks = mediastream.getAudioTracks();
		if (!isStillUserAudioRequested) audioTracks.forEach((track) => track.stop());
		else {
			// Avoid track reset when track id switch from "default" to "first" (in safari)
			// requestedAudioDeviceId.current = audioTracks[0]?.getSettings().deviceId
			// 	?? requestedAudioDeviceId.current;
			audioTracks.forEach((t) => {
				t.configId = inputConfigId;
			});
			addTracks(audioTracks, inputConfigId, 'audio');
		}

		const videoTracks = mediastream.getVideoTracks();
		if (!isStillUserVideoRequested) videoTracks.forEach((track) => track.stop());
		else {
			// requestedVideoDeviceId.current = videoTracks[0]?.getSettings().deviceId
			//  	?? requestedVideoDeviceId.current;
			videoTracks.forEach((t) => {
				t.configId = inputConfigId;
			});
			addTracks(videoTracks, inputConfigId, 'video');
		}
	}, [
		addTracks,
		inputConfigId,
		setUserAudioPromptStatus,
		setUserVideoPromptStatus,
		setUserAudioRequestError,
		setUserVideoRequestError,
	]);

	const previousResolution = useRef();
	const availableVideoInputDeviceId = getAvailableInputId(inputConfig.videoInputId, inputConfigId);
	const availableAudioInputDeviceId = getAvailableInputId(inputConfig.audioInputId, inputConfigId);

	useEffect(() => {
		const audioConfigHasChanged = requestedAudioDeviceId.current !== inputConfig.audioInputId;
		const videoConfigHasChanged = (
			requestedVideoDeviceId.current !== availableVideoInputDeviceId
			|| previousResolution.current !== resolution
		);
		previousResolution.current = resolution;

		const shouldStopAudio = requestedAudioDeviceId.current
			&& (!enabledAudio /* Stop */ || audioConfigHasChanged /* Restart */);
		const shouldStopVideo = requestedVideoDeviceId.current
			&& (!enabledVideo || videoConfigHasChanged);

		if (shouldStopAudio || shouldStopVideo) {
			stopMediastreamRef.current({
				audio: shouldStopAudio,
				video: shouldStopVideo,
			});
		}

		const shouldStartAudio = availableAudioInputDeviceId
			&& audioConfigHasChanged
			&& enabledAudio
			&& !userAudioRequestError;

		const shouldStartVideo = availableVideoInputDeviceId
			&& videoConfigHasChanged
			&& enabledVideo
			&& !userVideoRequestError;
			/* If there was an error previously we don't start mediastream automatically.
			For example:
			1. the user denied permission for camera,
			2. then the user starts screenshare and stops it. (=> enabledVideo becomes false then true)
			3. If we would have requested the mediastream automatically,
			the permission would have been requested again.
			The next call to requestMediastream() (for example by clicking on the camera button)
			will reset the error.
			*/

		if (shouldStartAudio || shouldStartVideo) {
			requestMediastream({
				audio: shouldStartAudio && availableAudioInputDeviceId,
				video: shouldStartVideo && availableVideoInputDeviceId,
			});
		}

		/* If there was an error previously we don't start mediastream automatically.

		For example:
		1. the user denied permission for camera,
		2. then the user starts screenshare and stops it. (=> enabledVideo becomes false then true)
		3. If we would have requested the mediastream automatically,
		the permission would have been requested again.

		The next call to requestMediastream() (for example by clicking on the camera button)
		will reset the error.
		*/
	}, [
		availableVideoInputDeviceId,
		availableAudioInputDeviceId,
		resolution,
		enabledAudio,
		enabledVideo,
		inputConfig.audioInputId,
		userAudioRequestError,
		userVideoRequestError,
		requestMediastream,
	]);

	const cleanupFunctionRef = useRef();
	useEffect(() => {
		cleanupFunctionRef.current = () => {
			stopConfigTracks(inputConfigId);
			removeTracksByConfigId(inputConfigId);
			// Needs this for firefox because the event
			// "ended" is not handled when track is stopped manually
		};
	}, [inputConfigId, removeTracksByConfigId, stopConfigTracks]);

	// cleanup
	useEffect(() => () => {
		requestedAudioDeviceId.current = undefined; // cancel audio request
		requestedVideoDeviceId.current = undefined; // cancel video request
		cleanupFunctionRef.current();
	}, []);

	useEffect(() => { resolutionRef.current = resolution; }, [resolution]);

	return null;
};
MediaUserPerform.propTypes = {
	addTracks: PropTypes.func.isRequired,
	resolution: PropTypes.oneOf(Object.values(Resolution)),
	removeTracksByConfigId: PropTypes.func.isRequired,
	inputConfig: PropTypes.shape({
		audioInputId: PropTypes.string,
		videoInputId: PropTypes.string,
		id: PropTypes.number,
		audioInputOff: PropTypes.bool,
		videoInputOff: PropTypes.bool,
	}).isRequired,
	userAudioRequestError: PropTypes.shape({}),
	userVideoRequestError: PropTypes.shape({}),
	setUserAudioPromptStatus: PropTypes.func.isRequired,
	setUserVideoPromptStatus: PropTypes.func.isRequired,
	setUserAudioRequestError: PropTypes.func.isRequired,
	setUserVideoRequestError: PropTypes.func.isRequired,
	stopConfigTracks: PropTypes.func.isRequired,
	allowAudio: PropTypes.bool.isRequired,
	isVideoAllowed: PropTypes.bool.isRequired,
};

MediaUserPerform.defaultProps = {
	resolution: Resolution.P720,
	userAudioRequestError: undefined,
	userVideoRequestError: undefined,
};
