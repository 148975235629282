import React from 'react';
import PropTypes from 'prop-types';
import { TabPane } from 'reactstrap';

const FormModalTabPane = ({
	activeTab,
	children,
	subtitle,
	tabId,
	title,
}) => {
	if (activeTab !== tabId) return null;
	return (
		<TabPane tabId={tabId} className="h-100">
			<article className="h-100 d-flex flex-column">
				<h1 className="display-4 mb-2 font-weight-bold">{title}</h1>
				<p className="font-size-lg mb-5 text-secondary">{subtitle}</p>
				{children}
			</article>
		</TabPane>
	);
};

FormModalTabPane.propTypes = {
	children: PropTypes.node.isRequired,
	activeTab: PropTypes.string.isRequired,
	subtitle: PropTypes.node,
	tabId: PropTypes.string.isRequired,
	title: PropTypes.node,
};

FormModalTabPane.defaultProps = {
	subtitle: undefined,
	title: undefined,
};

export default FormModalTabPane;
