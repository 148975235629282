import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Nav, NavItem } from 'reactstrap';
import { FaChevronDown, FaChevronLeft } from 'react-icons/fa';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ModalScreenHeaderCloseButton } from '../../Modal/Screen/Header';
import { ProfileAvatar } from '../../Profile/Avatar';
import { LibraryModalMode } from './LibraryModalMode';
import './Header.scss';

export const LibraryModalHeader = ({
	avatar,
	onClose,
	onBack,
	noBorder,
	activeMode,
	setActiveMode,
	children,
	activeOperatorForChannel,
	operatorForChannels,
	setActiveOperatorForChannel,
}) => {
	const { t } = useTranslation();
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const handleDropdownClick = useCallback(() => {
		setDropdownOpen((dO) => !dO);
	}, []);

	const handleLibraryModeChange = useCallback((mode) => {
		setActiveMode(mode);
		setDropdownOpen(false);
	}, [setActiveMode]);

	const handleChangeSharingChannel = useCallback((channel) => {
		setDropdownOpen(false);
		setActiveOperatorForChannel(channel);
		handleLibraryModeChange(LibraryModalMode.SHARED_BY_CHANNEL);
	}, [setActiveOperatorForChannel, handleLibraryModeChange]);

	return (
		<header className={clsx('d-flex align-items-center p-3 bg-light content-light', { 'border-bottom': !noBorder })}>
			{!!onBack && (
				<span className="btn-wrapper--icon mr-2 cursor-pointer text-secondary font-size-lg" onClick={onBack}>
					<FaChevronLeft />
				</span>
			)}
			<div className={`d-flex align-items-center w-100 mr-4 cursor-pointer ${onBack ? 'ml-2' : 'ml-3'}`}>
				<div className="d-flex flex-column w-100">
					<Dropdown isOpen={dropdownOpen} toggle={handleDropdownClick}>
						<DropdownToggle className="LibraryModalHeader_toggle p-0 m-0 text-primary hover-none border-0 shadow-none bg-light content-light">
							<div className="d-flex align-items-center">
								<ProfileAvatar
									avatarUrl={activeMode === LibraryModalMode.SHARED_BY_CHANNEL
										? activeOperatorForChannel.avatar
										: avatar}
								/>
								<h4 className="m-0">
									{activeMode === LibraryModalMode.SHARED_BY_CHANNEL
										? `${activeOperatorForChannel.nickname}'s Library`
										: t(activeMode)}
								</h4>
								<span className="btn-wrapper--icon ml-2 text-secondary font-size-md" onClick={onBack}>
									<FaChevronDown />
								</span>
							</div>
						</DropdownToggle>
						<DropdownMenu className="dropdown-menu-lg p-3 bg-dark">
							<Nav className="nav-neutral-secondary flex-column">
								<NavItem
									onClick={() => handleLibraryModeChange(LibraryModalMode.MY_LIBRARY)}
									className={`LibraryModalHeader_item px-3 py-2 text-small text-secondary
									${activeMode === LibraryModalMode.MY_LIBRARY && 'active'}`}
								>
									{t('LibraryModal.Header.myLibrary')}
								</NavItem>
								<DropdownItem divider className="border-secondary" />
								<NavItem
									onClick={() => handleLibraryModeChange(LibraryModalMode.BEEYOU)}
									className={`LibraryModalHeader_item px-3 py-2 text-small text-secondary
									${activeMode === LibraryModalMode.BEEYOU && 'active'}`}
								>
									{t('LibraryModal.Header.beeyouLibrary')}
								</NavItem>
								<DropdownItem divider className="border-secondary" />
								<NavItem
									onClick={() => handleLibraryModeChange(LibraryModalMode.MY_SHARES)}
									className={`LibraryModalHeader_item px-3 py-2 text-small text-secondary ${activeMode === LibraryModalMode.MY_SHARES && 'active'}`}
								>
									{t('LibraryModal.Header.myShares')}
								</NavItem>
								<DropdownItem className="mx-3 border-secondary" divider />
								<p className="text-uppercase text-small text-secondary m-0">{t('LibraryModal.Header.sharedWithMe')}</p>
								{operatorForChannels.map((operator) => (
									<NavItem
										key={`shared-operator-${operator._id}`}
										className={`LibraryModalHeader_item d-flex align-items-center mt-2 text-small text-secondary ${activeMode === LibraryModalMode.SHARED_BY_CHANNEL && activeOperatorForChannel._id === operator.channel._id && 'active'}`}
										onClick={() => handleChangeSharingChannel(operator.channel)}
									>
										<ProfileAvatar avatarUrl={operator.channel.avatar} />
										<p className="m-0">{operator.channel.nickname}</p>
									</NavItem>
								))}
							</Nav>
						</DropdownMenu>
					</Dropdown>
					{children}
				</div>
			</div>
			{!!onClose && (
				<ModalScreenHeaderCloseButton
					className="ml-auto align-self-start flex-shrink-0"
					onClose={onClose}
				/>
			)}
		</header>
	);
};

LibraryModalHeader.defaultProps = {
	avatar: '',
};

LibraryModalHeader.defaultProps = {
	avatar: undefined,
	children: undefined,
	noBorder: false,
	onClose: undefined,
	onBack: undefined,
	activeOperatorForChannel: {
		_id: undefined,
		nickname: undefined,
		avatar: undefined,
	},
};

LibraryModalHeader.propTypes = {
	activeMode: PropTypes.string.isRequired,
	onBack: PropTypes.func,
	avatar: PropTypes.string,
	children: PropTypes.node,
	noBorder: PropTypes.bool,
	onClose: PropTypes.func,
	setActiveMode: PropTypes.func.isRequired,
	activeOperatorForChannel: PropTypes.shape({
		_id: PropTypes.string,
		nickname: PropTypes.string,
		avatar: PropTypes.string,
	}),
	operatorForChannels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	setActiveOperatorForChannel: PropTypes.func.isRequired,
};
