import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ButtonDropdown, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FaEllipsisV } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

export const TemplateMenu = ({
	isOptionsOpen,
	onDelete,
	onDuplicate,
	toggleIsOptionsOpen,
	template,
	isVisible,
}) => {
	const { t } = useTranslation();
	return (
		<ButtonDropdown className={clsx({ visible: isVisible, invisible: !isVisible })} toggle={toggleIsOptionsOpen} isOpen={isOptionsOpen} direction="left">
			<DropdownToggle className="shadow-none border-0 bg-transparent p-2" color="neutral-secondary">
				<FaEllipsisV />
			</DropdownToggle>
			<DropdownMenu className="dropdown-menu-lg overflow-hidden p-2 bg-light">
				<Nav pills className="nav-neutral-secondary flex-column">
					<NavItem>
						<NavLink
							to="#"
							onClick={() => onDuplicate(template)}
							tag={Link}
						>
							{t('Templates.Menu.duplicate')}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							to="#"
							onClick={() => onDelete(template._id)}
							tag={Link}
						>
							{t('Templates.Menu.delete')}
						</NavLink>
					</NavItem>
				</Nav>
			</DropdownMenu>
		</ButtonDropdown>
	);
};

TemplateMenu.propTypes = {
	isOptionsOpen: PropTypes.bool.isRequired,
	toggleIsOptionsOpen: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	onDuplicate: PropTypes.func.isRequired,
	template: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		defaultPosition: PropTypes.string.isRequired,
		defaultDuration: PropTypes.number.isRequired,
		category: PropTypes.string.isRequired,
		options: PropTypes.arrayOf(PropTypes.shape({
			_id: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
		})).isRequired,
	}).isRequired,
	isVisible: PropTypes.bool.isRequired,
};
