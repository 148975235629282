import { useQuery } from 'react-query';
import * as searchApi from '../api/search';
import { axiosQueryWrapper } from './utils/axios-wrapper';

export const SEARCH_QUERY_KEYS = {
	fetchSearchResults: (query, opts) => ['api', 'search', 'results', query, opts],
	fetchSearchSuggestions: (query) => ['api', 'search', 'suggestion', query],
	fetchUserSuggestions: (query, opts) => ['api', 'search', 'userSuggestion', query, opts],
};

export const useFetchSearchResults = (query, opts) => useQuery(
	SEARCH_QUERY_KEYS.fetchSearchResults(encodeURI(query), opts),
	axiosQueryWrapper(searchApi.fetchSearchResults, encodeURI(query), opts),
	{ enabled: !!query },
);

export const useFetchSearchSuggestions = (query) => useQuery(
	SEARCH_QUERY_KEYS.fetchSearchSuggestions(encodeURI(query)),
	axiosQueryWrapper(searchApi.fetchSearchSuggestions, encodeURI(query)),
	{ enabled: !!query },
);

export const useFetchUserSuggestions = (query, opts) => useQuery(
	SEARCH_QUERY_KEYS.fetchUserSuggestions(encodeURI(query), opts),
	axiosQueryWrapper(searchApi.fetchUserSuggestions, encodeURI(query), opts),
	{ enabled: !!query },
);
