import React from 'react';
import PropTypes from 'prop-types';

import './Overlay.scss';

const Overlay = ({ children, onClick }) => (
	<div className="Overlay" onClick={onClick}>{children}</div>
);

Overlay.propTypes = {
	children: PropTypes.node.isRequired,
	onClick: PropTypes.func,
};

Overlay.defaultProps = {
	onClick: () => {},
};

export default Overlay;
