import React from 'react';

import { FaHandHoldingUsd } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

export const ProfileRewardNotice = () => {
	const { t } = useTranslation();

	return (
		<div className="d-inline-block align-items-center p-1">
			<FaHandHoldingUsd size="1.4em" className="text-white text-center mr-3" />
			<p className="d-inline-block font-weight m-0">{t('UserSettings.ProfileRewardNotice.completeYourProfile')}</p>
		</div>
	);
};
