import { publicApi } from '../api';

export const API_PUBLIC_STUDIO_PATH = '/public/studio';

export const fetchOneStudioByCode = (code) => publicApi.get(`${API_PUBLIC_STUDIO_PATH}/bycode/${code}`);

export const requestPublicStudioByCode = async (code, requiredAccess) => publicApi.post(
	`${API_PUBLIC_STUDIO_PATH}/request/bycode/${code}`,
	{ requiredAccess },
);
