import { authenticatedApi } from './api';
import {
	upload as uploadToS3,
	uploadMultipart as uploadMultipartToS3,
} from '../lib/file';

const API_UPLOAD_PATH = '/upload';

export const fetchUploadConfig = async ({ mimeType, fileSize }) => authenticatedApi.post(
	API_UPLOAD_PATH,
	{ mimeType, fileSize },
);

export const upload = async (blob, onUploadProgress) => {
	const { data: { filename, uploadUrl } } = await fetchUploadConfig({
		mimeType: blob.type,
		fileSize: blob.size,
	});
	await uploadToS3(uploadUrl, blob, onUploadProgress);
	return filename;
};

export const createMultipartUpload = async ({
	fileSize,
	mimeType,
	partsCount,
}) => authenticatedApi.post(
	`${API_UPLOAD_PATH}/multipart`,
	{
		fileSize,
		mimeType,
		partsCount,
	},
);

export const completeMultipartUpload = async ({
	filename,
	fileSize,
	mimeType,
	parts,
	uploadId,
}) => authenticatedApi.post(
	`${API_UPLOAD_PATH}/multipart/complete`,
	{
		filename,
		fileSize,
		mimeType,
		parts,
		uploadId,
	},
);

export const uploadMultipart = async (blob, onUploadProgress) => {
	const {
		type: mimeType,
		size: fileSize,
	} = blob;

	const CHUNK_SIZE = 30 * 1024; // Uploads the file in ~30 MB chunks
	const chunkByteSize = CHUNK_SIZE * 1024;
	const totalChunks = Math.ceil(blob.size / chunkByteSize);

	const { data: {
		filename,
		uploadId,
		presignedUrlsParts,
	} } = await createMultipartUpload({
		fileSize,
		mimeType,
		partsCount: totalChunks,
	});

	const parts = await uploadMultipartToS3({
		chunkSize: CHUNK_SIZE,
		file: blob,
		presignedUrlsParts,
		onUploadProgress,
	});

	await completeMultipartUpload({
		filename,
		fileSize,
		mimeType,
		parts,
		uploadId,
	});

	return filename;
};
