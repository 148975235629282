import React from 'react';
import PropTypes from 'prop-types';

import { SoupProvider } from './Soup';
import { MixingProvider } from './Mixing';
import { ReactVideoProvider } from './Provider';
import { DrawerProvider } from '../PlayerLive/PlayerCanvasDrawerProvider';
import { PlayerLivePiPProvider } from '../PlayerLive/PiPProvider';
import { AlertTooltipProvider } from '../Alert/Tooltip';
import { PlayerLiveCropProvider } from '../PlayerLive/CropProvider';
import { NicknameProvider } from '../Nickname/NicknameProvider';
import { PipLayoutProvider } from '../ChannelButtonsPanel/PipLayoutProvider';
import { PlayerModeProvider } from '../PlayerLive/PlayerModeProvider';
import { GfxProvider } from '../Gfx/Provider';

export const ReactVideo = ({
	children,
	control,
	getAvatarUrl,
	getConnectionConfig,
	user,
}) => (
	<ReactVideoProvider
		control={control}
		getAvatarUrl={getAvatarUrl}
		getConnectionConfig={getConnectionConfig}
		user={user}
	>
		<SoupProvider control={control}>
			<PlayerModeProvider control={control}>
				<GfxProvider>
					<DrawerProvider>
						<PipLayoutProvider>
							<PlayerLivePiPProvider>
								<PlayerLiveCropProvider>
									<NicknameProvider>
										<MixingProvider>
											<AlertTooltipProvider>
												{children}
											</AlertTooltipProvider>
										</MixingProvider>
									</NicknameProvider>
								</PlayerLiveCropProvider>
							</PlayerLivePiPProvider>
						</PipLayoutProvider>
					</DrawerProvider>
				</GfxProvider>
			</PlayerModeProvider>
		</SoupProvider>
	</ReactVideoProvider>
);

ReactVideo.propTypes = {
	children: PropTypes.node.isRequired,
	control: PropTypes.bool,
	getAvatarUrl: PropTypes.func.isRequired,
	getConnectionConfig: PropTypes.func.isRequired,
	user: PropTypes.shape({}).isRequired,
};

ReactVideo.defaultProps = {
	control: false,
};
