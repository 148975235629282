import { properties as templateProperties } from '../Templates/createTemplate.schema';

const ObjectId = {
	type: 'string',
	pattern: '^[0-9a-fA-F]{24}$',
};

export const properties = {
	templateId: ObjectId,
	...templateProperties,
};

export const createVoteSchema = {
	type: 'object',
	additionalProperties: false,
	properties,
	required: [
		'title',
		'options',
		'durationMinutes',
		'durationSeconds',
	],
};
