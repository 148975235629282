import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FaCheck } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import RatioContainer from '../RatioContainer/RatioContainer';
import './Video.scss';

const PlayerVideo = ({ className, onRemove, videoId, src, ...props }) => {
	const { t } = useTranslation();

	return (
		<RatioContainer
			className={
				clsx(
					'PlayerVideo',
					className,
				)
			}
		>
			{onRemove && (
				<FaCheck
					className="PlayerVideo_remove position-absolute right-0 p-1 cursor-pointer"
					onClick={() => onRemove(videoId)}
				/>
			)}
			<video alt={t('PlayerVideo.player')} className="PlayerVideo_img" {...props}>
				<source src={src} type="video/mp4" />
			</video>
		</RatioContainer>
	);
};

PlayerVideo.propTypes = {
	className: PropTypes.string,
	videoId: PropTypes.string,
	src: PropTypes.string,
	onRemove: PropTypes.func,
};

PlayerVideo.defaultProps = {
	className: '',
	onRemove: undefined,
	videoId: undefined,
	src: undefined,
};

export default PlayerVideo;
