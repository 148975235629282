import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAsyncCallback } from 'react-async-hook';
import { Form, Field, FieldError } from 'react-jsonschema-form-validation';
import { FormGroup, Input, Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import FormSubmit from '../../../Form/Submit';
import { FormLabel } from '../../../Form/Label';
import { ButtonPillOutline } from '../../../Button';
import { FormPhone } from '../../../Form/Phone';
import { ShareRecipientType } from '../../../Notification/Notification.helper';
import { ResourceAccessRole } from '../../../../lib/ResourceAccessRole';
import { ShareTabType } from '../../Share.helper';
import { ShareRoleInput } from '../../RoleInput';

import './Sms.scss';

const phoneNumberSchema = {
	type: 'object',
	additionalProperties: false,
	properties: {
		phoneNumber: {
			type: 'string',
		},
		message: {
			type: 'string',
			minLength: 1,
		},
	},
	required: [
		'phoneNumber',
		'message',
	],
};

const getInitialData = (defaultMessage) => ({
	phoneNumber: '',
	message: defaultMessage || '',
});

export const ShareTabSms = ({
	alreadySharedUsers,
	defaultMessage,
	onShare,
	roles,
	selectedRole,
	setSelectedRole,
	shareButtonLabel,
}) => {
	const { t } = useTranslation();

	const [data, setData] = useState(
		getInitialData(defaultMessage || t('Share.Tab.Sms.defaultSmsMessage')),
	);

	const clear = () => setData((s) => ({ ...s, phoneNumber: '' }));

	const handleSubmitAsync = useAsyncCallback(async () => {
		const alreadyAddedSmsShares = alreadySharedUsers.reduce((acc, share) => {
			if (share.type !== ShareRecipientType.SMS) return acc;
			return {
				...acc,
				[share.phoneNumber]: share,
			};
		}, {});

		if (alreadyAddedSmsShares[data.phoneNumber]) return;
		await onShare([{
			type: ShareRecipientType.SMS,
			phoneNumber: data.phoneNumber,
			message: data.message,
		}], selectedRole);
		clear();
	});

	useEffect(() => {
		if (handleSubmitAsync.status === 'success' || handleSubmitAsync.error) {
			const id = setTimeout(() => handleSubmitAsync.reset(), 3000);
			return () => clearTimeout(id);
		}
		return undefined;
	}, [handleSubmitAsync]);

	const handleChange = (values) => {
		setData({
			...data,
			...values,
		});
	};

	const isEmpty = !data.phoneNumber;
	const isActionDisabled = isEmpty || handleSubmitAsync.loading;

	return (
		<Form
			className="SmsForm"
			data={data}
			onSubmit={handleSubmitAsync.execute}
			schema={phoneNumberSchema}
		>
			<div className="d-flex mb-3">
				<ButtonPillOutline
					className="mr-2"
					color="dark"
					disabled={isActionDisabled}
					onClick={() => { clear(); }}
				>
					{t('Share.Buttons.clear')}
				</ButtonPillOutline>
				<FormSubmit
					className="btn-pill shadow-none ml-auto"
					disabled={isActionDisabled}
					loading={handleSubmitAsync.loading}
					title={t('Share.Tab.Sms.sendInvitationBySms')}
				>
					{t(shareButtonLabel)}
				</FormSubmit>
			</div>
			{handleSubmitAsync.status === 'success' && (
				<Alert color="success">
					{t('Share.Tab.Sms.phoneAddedSuccessfully')}
				</Alert>
			)}
			{handleSubmitAsync.error && (
				<Alert color="danger">
					{t('Global.error')}
				</Alert>
			)}
			<ShareRoleInput
				value={selectedRole}
				onChange={(role) => setSelectedRole(role)}
				roles={roles}
				shareTabType={ShareTabType.SMS}
			/>
			<FormGroup>
				<FormLabel>{t('Share.Tab.Sms.phone')}</FormLabel>
				<Field
					component={FormPhone}
					name="phoneNumber"
					placeholder={t('Share.Tab.Sms.enterPhoneNumber')}
					className="ShareTabSms_PhoneInput bg-dark content-dark px-2 border-dark"
					onChange={(value) => handleChange({ phoneNumber: value })}
					value={data.phoneNumber}
				/>
				<FieldError name="phoneNumber" />
			</FormGroup>
			<FormGroup>
				<FormLabel>{t('Share.Message.message')}</FormLabel>
				<Field
					className="bg-dark content-dark rounded-1 border-dark"
					component={Input}
					name="message"
					onChange={(e) => handleChange({ message: e.target.value })}
					placeholder={t('Share.Message.addCustomMessage')}
					rows="3"
					type="textarea"
					value={data.message}
				/>
				<FieldError name="message" />
			</FormGroup>
		</Form>
	);
};

ShareTabSms.propTypes = {
	alreadySharedUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	defaultMessage: PropTypes.string,
	onShare: PropTypes.func.isRequired,
	roles: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(ResourceAccessRole))).isRequired,
	selectedRole: PropTypes.oneOf(Object.keys(ResourceAccessRole)).isRequired,
	setSelectedRole: PropTypes.func.isRequired,
	shareButtonLabel: PropTypes.string.isRequired,
};

ShareTabSms.defaultProps = {
	defaultMessage: '',
};
