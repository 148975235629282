const optionInput = {
	type: 'object',
	properties: {
		label: {
			type: 'string',
			maxLength: 50,
			minLength: 1,
		},
		correct: { type: 'boolean' },
	},
	required: [
		'label',
	],
};

export const properties = {
	category: { type: 'string', maxLength: 140 },
	audienceType: { type: 'string' },
	title: { type: 'string', maxLength: 140 },
	options: { type: 'array', items: optionInput, minItems: 2, maxItems: 5 },
	durationMinutes: { type: 'number', minimum: 0, maximum: 60 },
	durationSeconds: { type: 'number', minimum: 0, maximum: 60 },
	position: { type: 'string', enum: ['top', 'bot', 'mid'] },
};

export const createTemplateSchema = {
	type: 'object',
	additionalProperties: false,
	properties,
	required: [
		'title',
		'options',
		'durationMinutes',
		'durationSeconds',
	],
};
