import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import 'react-phone-number-input/style.css';
import Ajv from 'ajv';
import { Field, FieldError } from 'react-jsonschema-form-validation';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { Link } from 'react-router-dom';
import { Alert, Button, Col, Container, FormGroup, Input, Row } from 'reactstrap';
import { useAsyncErrorLog } from '../../lib/hooks';
import { FormSchemaForm } from '../Form/Form';
import { FormLabel } from '../Form/Label';
import FormSubmit from '../Form/Submit';
import { AccountType, AccountTypeLabel, IdentificationField, IdentificationFieldLabel, signupSchema } from './signup.schema';
import { InputVerificationOrientation } from '../Input/Verification';
import { VerifiedPhoneInput } from '../Profile/Settings/VerifiedPhoneInput';
import { VerifiedEmailInput } from '../Profile/Settings/VerifiedEmailInput';
import FormPassword from '../Form/Password';
import { getPasswordValidationMessage } from '../Login/password.schema';
import { useSignup } from '../../api-hooks/profile/signup';
import { useAuthentication } from '../Authentication/Authentication';
import { clearAccount } from '../Login/Modal';
import { useUserPreferences } from '../UserPreferences/Context';

const ajv = new Ajv({
	allErrors: true,
	v5: true,
	$data: true,
});

ajv.addKeyword('phone', {
	schema: false,
	validate: function validatePhone(data) {
		const isValid = isPossiblePhoneNumber(data);
		if (!isValid) validatePhone.errors = [{ message: 'Invalid phone number', keyword: 'phone', params: {} }];
		return isValid;
	},
});

export const SHOW_COMPLETE_PROFILE_MODAL_KEY = 'showCompleteProfileModal';

export const SignupForm = ({ closeModal }) => {
	const { mutate: signup, isLoading, error } = useSignup();
	const { login } = useAuthentication();

	const { setUserPreferences } = useUserPreferences();

	const [signupData, setSignupData] = useState({
		accountType: AccountType.PERSONAL,
		identificationField: IdentificationField.EMAIL,
		email: '',
		phoneNumber: '',
	});

	const canSignup = useMemo(
		() => !!signupData.nickname && (signupData.email || signupData.phoneNumber),
		[signupData],
	);

	const handleChange = useCallback((data) => {
		setSignupData((prevState) => ({ ...prevState, ...data }));
	}, []);

	const handleLoginUser = useCallback(async (loginData) => {
		await login(clearAccount({
			...loginData,
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		}));
	}, [login]);

	const handleSubmit = useCallback((skipProfileComplete = false) => {
		signup(clearAccount(signupData), {
			onSuccess: () => {
				handleLoginUser(clearAccount({
					password: signupData.password,
					email: signupData.email,
					phoneNumber: signupData.phoneNumber,
					identificationField: signupData.identificationField,
				}));

				if (!skipProfileComplete) {
					setUserPreferences(
						(s) => ({ ...s, [SHOW_COMPLETE_PROFILE_MODAL_KEY]: true }),
					);
				}
				closeModal();
			},
		});
	}, [signup, signupData, closeModal, handleLoginUser, setUserPreferences]);
	useAsyncErrorLog({ error: undefined });

	const handlePhoneNumberChange = useCallback((phoneNumber) => {
		handleChange({ phoneNumber });
	}, [handleChange]);

	return (
		<Container fluid>
			<FormSchemaForm
				className="mt-5 mx-0 form-light"
				data={signupData}
				onChange={handleChange}
				onSubmit={() => handleSubmit(false)}
				schema={signupSchema}
				ajv={ajv}
			>
				<Row className="mt-3">
					<Col lg="12">
						<FormGroup>
							<FormLabel>
								Account Type
							</FormLabel>
							<Field
								component={Input}
								name="accountType"
								type="select"
								value={signupData.accountType || AccountType.PERSONAL}
							>
								{Object.keys(AccountType).map((key) => (
									<option
										key={`account-type-${AccountType[key]}`}
										value={AccountType[key]}
									>
										{AccountTypeLabel[key]}
									</option>
								))}
							</Field>
							<FieldError name="accountType" />
						</FormGroup>
					</Col>
				</Row>
				<Row className="mt-3">
					<Col lg="6">
						<FormGroup>
							<FormLabel>
								Public Name
							</FormLabel>
							<Field
								component={Input}
								name="nickname"
								type="text"
								placeholder="e.g. John Doe"
								value={signupData.nickname || ''}
							/>
							<FieldError name="nickname" />
						</FormGroup>
					</Col>
					<Col lg="6">
						<FormGroup>
							<FormLabel>
								Register with
							</FormLabel>
							<Field
								component={Input}
								name="identificationField"
								type="select"
								value={signupData.identificationField || IdentificationField.EMAIL}
							>
								{Object.keys(IdentificationField).map((key) => (
									<option
										key={`identification-field-${IdentificationField[key]}`}
										value={IdentificationField[key]}
									>
										{IdentificationFieldLabel[key]}
									</option>
								))}
							</Field>
							<FieldError name="identificationField" />
						</FormGroup>
					</Col>
				</Row>
				{signupData.identificationField === IdentificationField.EMAIL ? (
					<VerifiedEmailInput
						email={signupData.email || ''}
						orientation={InputVerificationOrientation.HORIZONTAL}
					/>
				) : (
					<VerifiedPhoneInput
						phoneNumber={signupData.phoneNumber || ''}
						country="US"
						orientation={InputVerificationOrientation.HORIZONTAL}
						onChange={handlePhoneNumberChange}
					/>
				)}
				<Row className="align-items-center mt-3 mb-5">
					<Col lg="6">
						<FormGroup>
							<FormLabel>
								Password
							</FormLabel>
							<FormPassword
								name="password"
								value={signupData.password || ''}
							/>
							<FieldError
								name="password"
								errorMessages={{
									pattern: (data) => getPasswordValidationMessage(data.params.pattern)
										|| data.message,
									minLength: () => 'Password must be at least 12 characters long',
								}}
							/>
						</FormGroup>
					</Col>
					<Col lg="6">
						<small className="text-secondary">
							Password must be 12 characters long and contain at least one
							uppercase letter, one digit and one special character
						</small>
					</Col>
				</Row>
				{!!error && (
					<Alert color="danger">
						{error.response.data.message}
					</Alert>
				)}
				<footer className="d-flex flex-column align-items-center mt-5">
					<FormSubmit
						loading={isLoading}
						className="flex-grow-0"
						disabled={!canSignup}
					>
						Sign Up &amp; Complete Profile Now
					</FormSubmit>
					<Button
						className="mt-2"
						color="link"
						onClick={() => handleSubmit(true)}
						disabled={!canSignup}
					>
						Sign Up &amp; Complete Profile Later
					</Button>
					<p className="text-secondary mt-4">
						By clicking the &quot;Sign Up&quot; button, I agree to Beeyou&quot;s
						{' '}<Link target="_blank" to="/legal/terms"><strong><u>Terms of Service and Privacy Policy</u></strong></Link>
					</p>
				</footer>
			</FormSchemaForm>
		</Container>
	);
};

SignupForm.propTypes = {
	closeModal: PropTypes.func.isRequired,
};
