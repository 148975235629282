import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import {
	Button,
	Input,
	Label,
} from 'reactstrap';
import { FaPhotoVideo } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import {
	useMediaVideoshare,
	useMediaScreenshare,
	useLocalVideoStreaming,
} from '@technomiam/react-video';

import { MediaErrorPopover } from './MediaErrorPopover';

export const GuestLocalVideoSharing = ({ className, hashtag }) => {
	const { t } = useTranslation();
	const { screenshareActive } = useMediaScreenshare();
	const {
		requestVideoshare,
		videoshareActive,
		videoshareRequestError,
	} = useMediaVideoshare();

	const {
		toggleVideoshare,
	} = useLocalVideoStreaming({ hashtag });

	let color = 'secondary';
	if (videoshareRequestError) {
		color = 'neutral-danger';
	} else if (!videoshareActive) {
		color = 'neutral-secondary';
	}

	const animatedIconColor = videoshareRequestError ? 'danger' : 'secondary';
	const iconColor = videoshareRequestError ? 'danger' : 'secondary';
	const title = videoshareActive ? t('LocalUserMedia.GuestLocalVideoStreaming.stopVideoSharing') : t('LocalUserMedia.GuestLocalVideoStreaming.shareVideo');

	const handleInput = (event) => {
		const [acceptedFile] = event.target.files;
		requestVideoshare(acceptedFile);
	};

	return (
		<>
			<Button
				tag="span"
				id="GuestLocalVideoSharing"
				className={clsx('LocalVideoSharing btn-animated-icon', `btn-animated-icon--${animatedIconColor}`, className)}
				color={color}
				disabled={screenshareActive}
				onClick={(event) => toggleVideoshare(event)}
				title={title}
			>
				<Label
					className="d-flex flex-column justify-content-center align-items-center w-100 h-100"
					role="button"
				>
					<summary className="btn-wrapper--icon d-inline-block">
						{!videoshareActive && (
							<Input accept="video/*" className="d-none" type="file" onInput={handleInput} />
						)}
						<FaPhotoVideo color={iconColor} />
					</summary>
				</Label>
			</Button>
			<MediaErrorPopover error={videoshareRequestError} target="GuestLocalVideoSharing" type="video" />
		</>
	);
};

GuestLocalVideoSharing.propTypes = {
	className: PropTypes.string,
	hashtag: PropTypes.string.isRequired,
};

GuestLocalVideoSharing.defaultProps = {
	className: '',
};
