import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaAngleDown, FaHeart, FaTag } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import {
	Badge,
	Dropdown,
	DropdownMenu,
	DropdownToggle,
	ListGroup,
	ListGroupItem,
	Nav,
	NavItem,
	NavLink,
} from 'reactstrap';

import { useFetchTotalUnreadCount } from '../../api-hooks/conversation';
import { useFetchGraphGlobalsFriendRequest } from '../../api-hooks/graph/globals';
import { useFetchNotificationUnreadCount } from '../../api-hooks/notification/notification';
import { useFetchProfile } from '../../api-hooks/profile/me';
import { useAuthentication } from '../../components/Authentication/Authentication';
import { FriendRequestStatus } from '../../components/Channel/Description/FriendRequestStatus';
import { useMenu } from '../../components/Drawer/Context';
import Hexagon from '../../components/Hexagon/Hexagon';
import { useLogin } from '../../components/Login/Provider';
import { usePoints } from '../../components/Membership/Points';
import { ScreenThemeLayout, useScreenTheme } from '../../components/Studio/ScreenTheme/Provider';
import { UserSettingsModal, useUserSettings } from '../../components/UserSettings/Context';
import defaultAvatar from '../../images/default-avatar.png';
import { AVATAR_SIZE, getFileUrl } from '../../lib/file';
import { useHandleDeleteDisableSubscription } from '../../lib/serviceWorkerHook';
import {
	AccountIcon,
	AnalyticsAdsIcon,
	DarkThemeToggle,
	FriendsIcon,
	LibraryIcon,
	LightThemeToggle,
	MessagesIcon,
	SignOffIcon,
	SurveysIcon,
} from './icons';

const HeaderUserbox = () => {
	const { t } = useTranslation();
	const { openModal } = useUserSettings();
	const history = useHistory();
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const authentication = useAuthentication();
	const { data: user } = useFetchProfile();
	const { balance, chipBalance } = usePoints();
	const { handleCloseRoutingModal } = useLogin();
	const { currentScreenTheme, handleChangeCurrentScreenTheme } = useScreenTheme();
	const deleteSubscription = useHandleDeleteDisableSubscription();

	const { toggleMenu, setActiveTab } = useMenu();

	const { data: unreadCounts } = useFetchTotalUnreadCount();

	const { data: unreadNotifications } = useFetchNotificationUnreadCount();

	const totalUnreadCount = useMemo(() => (unreadCounts?.total ?? 0)
		+ (unreadNotifications?.count ?? 0),
	[unreadCounts, unreadNotifications]);

	const { data: friendRequests } = useFetchGraphGlobalsFriendRequest();
	const [pendingFriendRequests, setPendingFriendRequests] = useState([]);

	useEffect(() => {
		if (friendRequests) {
			setPendingFriendRequests(
				friendRequests.filter(
					(friendRequest) => friendRequest.receiver._id === user?.sub
						&& friendRequest.status === FriendRequestStatus.PENDING,
				),
			);
		}
	}, [friendRequests, user]);

	const userAvatar = useMemo(() => (user?.avatar
		? getFileUrl({ name: user.avatar }, AVATAR_SIZE.width, AVATAR_SIZE.height)
		: defaultAvatar),
	[user]);

	const toggleDropdown = useCallback(() => setIsDropdownOpen((prevState) => !prevState), []);

	const handleClickLogout = useCallback(async () => {
		await deleteSubscription();
		authentication.logout();
		handleCloseRoutingModal();
		toggleDropdown();
		history.push('/');
	}, [authentication, deleteSubscription, handleCloseRoutingModal, history, toggleDropdown]);

	const openUserSettings = useCallback((open) => () => {
		toggleDropdown();
		open();
	}, [toggleDropdown]);

	return (
		<Dropdown
			className="user-box position-relative ml-2"
			isOpen={isDropdownOpen}
			toggle={toggleDropdown}
		>
			<DropdownToggle
				color="link"
				className="p-0 text-left d-flex align-items-center"
			>
				<div
					className="d-flex align-items-center"
					style={{ pointerEvents: 'none' }} // Prevent dropdown issue that was toggling twice
				>
					<div className="position-relative d-block">
						{totalUnreadCount > 0 || pendingFriendRequests.length ? (
							<Badge
								color="danger"
								className="UserMenuDrawer_badge badge-circle badge-position badge-position--top-left-hexagon shadow-none"
							>
								<span />
							</Badge>
						) : null}
						<Hexagon className="d-block d-50 rounded-sm overflow-hidden">
							<img src={userAvatar} className="h-100 w-auto mw-100 img-fit-container" alt={t('HeaderUserbox.Index.avatar')} />
						</Hexagon>
					</div>
					<div className="ml-1">
						<div className="d-flex align-items-center">
							<strong className="d-none d-xl-block">{user?.nickname}</strong>
							<FaAngleDown
								className="ml-xl-2 opacity-5"
							/>
						</div>
						<span className="text-secondary d-none d-xl-block">
							{balance !== undefined && (
								<>
									{/* //TODO CHECK JSX "&amp;" */}
									{balance} Pi {chipBalance ? `& ${chipBalance} Ch` : ''}
								</>
							)}
						</span>
					</div>
				</div>
			</DropdownToggle>
			<DropdownMenu end className="dropdown-menu-xl overflow-hidden shadow-none border border-secondary py-0 bg-transparent">
				<ListGroup flush className="text-left bg-transparent">
					<ListGroupItem className="rounded-top bg-light border-white-light">
						<Nav pills className="nav-neutral-secondary flex-column">
							<NavItem className="border-bottom border-white-light white">
								<NavLink color="white" onClick={() => { toggleMenu(); setActiveTab('Messages'); }}>
									<div className="position-relative">
										<span className="btn-wrapper--icon mr-1">
											{totalUnreadCount > 0 && (
												<Badge
													color="danger"
													className="UserMenuDrawer_badge badge-circle shadow-none badge-position badge-position--top-left-fa"
												>
													<span className="text-xs">{totalUnreadCount}</span>
												</Badge>
											)}
											<MessagesIcon />
										</span>
										{totalUnreadCount > 0 ? (
											<span>
												{t('HeaderUserbox.Index.messages')} | {totalUnreadCount} {t('HeaderUserbox.Index.unread')}
											</span>
										)
											: <span>{t('HeaderUserbox.Index.messages')}</span>}
									</div>
								</NavLink>
							</NavItem>
							<NavItem className="border-bottom border-white-light">
								<NavLink onClick={() => { toggleMenu(); setActiveTab('Friends'); }}>
									<div className="position-relative">
										<span className="btn-wrapper--icon mr-1">
											{pendingFriendRequests.length > 0 && (
												<Badge
													color="danger"
													className="UserMenuDrawer_badge badge-circle shadow-none badge-position badge-position--top-left-fa"
												>
													<span className="text-xs">{totalUnreadCount}</span>
												</Badge>
											)}
											<FriendsIcon />
										</span>
										{pendingFriendRequests.length > 0 ? (
											<span>
												{t('HeaderUserbox.Index.friends')} | {pendingFriendRequests.length} {t('HeaderUserbox.Index.friendsRequests')}
											</span>
										)
											: <span>{t('HeaderUserbox.Index.friends')} </span>}
									</div>
								</NavLink>
							</NavItem>
							<NavItem className="border-bottom border-white-light">
								<NavLink onClick={() => { toggleMenu(); setActiveTab('FollowingUsers'); }}>
									<span className="btn-wrapper--icon mr-1">
										<FaHeart />
									</span>
									{t('HeaderUserbox.Index.followingFollowers')}
								</NavLink>
							</NavItem>
							<NavItem className="border-bottom border-white-light">
								<NavLink onClick={openUserSettings(() => openModal(UserSettingsModal.ACCOUNT))}>
									<span className="btn-wrapper--icon mr-1">
										<AccountIcon />
									</span>
									{t('HeaderUserbox.Index.account')}
								</NavLink>
							</NavItem>
							<NavItem className="border-bottom border-white-light">
								<NavLink
									onClick={openUserSettings(() => openModal(UserSettingsModal.MEMBERSHIP))}
								>
									<span className="btn-wrapper--icon mr-1">
										<FaTag />
									</span>
									{t('HeaderUserbox.Index.membership')}
								</NavLink>
							</NavItem>
							<NavItem className="border-bottom border-white-light">
								<NavLink
									onClick={openUserSettings(() => openModal(UserSettingsModal.LIBRARY))}
								>
									<span className="btn-wrapper--icon mr-1">
										<LibraryIcon />
									</span>
									{t('HeaderUserbox.Index.library')}
								</NavLink>
							</NavItem>
							<NavItem className="border-bottom border-white-light">
								<NavLink
									onClick={openUserSettings(() => openModal(UserSettingsModal.SURVEY_POLL))}
								>
									<span className="btn-wrapper--icon mr-1">
										<SurveysIcon />
									</span>
									{t('HeaderUserbox.Index.publicOpinionAnalysis')}
								</NavLink>
							</NavItem>
							<NavItem className="border-bottom border-white-light">
								<NavLink
									onClick={() => handleChangeCurrentScreenTheme(
										currentScreenTheme === ScreenThemeLayout.DARK
											? ScreenThemeLayout.LIGHT
											: ScreenThemeLayout.DARK,
									)}
								>
									<span className="btn-wrapper--icon mr-1">
										{currentScreenTheme === ScreenThemeLayout.DARK
											? <DarkThemeToggle />
											: <LightThemeToggle />}
									</span>
									{t('HeaderUserbox.Index.toggleTheme')} {currentScreenTheme === ScreenThemeLayout.DARK ? t('HeaderUserbox.Index.lightTheme') : t('HeaderUserbox.Index.darkTheme')}
								</NavLink>

							</NavItem>
						</Nav>
						<Nav pills className="nav-neutral-secondary flex-column">
							<NavItem>
								<NavLink
									active={false}
									onClick={handleClickLogout}
								>
									<span className="btn-wrapper--icon mr-1">
										<SignOffIcon />
									</span>
									{t('HeaderUserbox.Index.logout')}
								</NavLink>
							</NavItem>
						</Nav>
					</ListGroupItem>
				</ListGroup>
			</DropdownMenu>
		</Dropdown>
	);
};

export default HeaderUserbox;
