import { authenticatedApi } from '../api';

export const API_CHANNEL_GFX_PATH = '/channel/gfx';

export const fetchGfx = (
	channelId,
	mode,
	itemsPerPage,
	currentPage,
) => authenticatedApi.get(
	`${API_CHANNEL_GFX_PATH}`,
	{
		params: {
			channelId,
			mode,
			itemsPerPage,
			currentPage,
		},
	},
);
