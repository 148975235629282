import PropTypes from 'prop-types';
import React, { lazy, useEffect, useState } from 'react';
import { TabContent } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { ModalLazyContent } from '../../../Modal/LazyContent';
import { ModalScreen } from '../../../Modal/Screen/Screen';
import { StudioScheduleModal, useStudioSchedule } from '../../Context';
import { DropdownMode } from '../../Manager/Modal/ModalDropdownMode';
import { ChannelDropdownHeader } from '../../Manager/Modal/Header';
import { useProfile } from '../../../Profile/ProfileContext';
import { useFetchMyHosts } from '../../../../api-hooks/channel/operators';
import { ButtonPill } from '../../../Button';

const StudioScheduleCalendarModalContent = lazy(() => import('./Content'));

export const StudioScheduleCalendarModal = ({
	isOpen,
	studioOwnerId,
	setStudioOwnerId,
	options,
}) => {
	const { t } = useTranslation();
	const { closeModal, openModal } = useStudioSchedule();

	const [activeDropdowMode, setActiveDropdownMode] = useState(DropdownMode.MY_STUDIOS);
	const [activeOperatorForChannel, setActiveOperatorForChannel] = useState();

	const { profile } = useProfile();
	const { data: myHosts } = useFetchMyHosts();

	useEffect(() => {
		const channelId = activeDropdowMode === DropdownMode.MY_STUDIOS
			? profile?._id
			: activeOperatorForChannel._id;

		setStudioOwnerId(channelId);
	}, [activeDropdowMode, activeOperatorForChannel, profile, setStudioOwnerId]);

	return (
		<ModalScreen
			bodyClassName="p-3"
			header={(
				<ChannelDropdownHeader
					activeMode={activeDropdowMode}
					setActiveMode={setActiveDropdownMode}
					avatar={profile.avatar}
					onClose={closeModal}
					activeChannel={activeOperatorForChannel}
					channels={myHosts ?? []}
					setActiveChannel={setActiveOperatorForChannel}
					buttons={(
						<ButtonPill
							className="ml-auto px-2"
							onClick={() => openModal(
								StudioScheduleModal.EDIT,
								undefined,
								undefined,
								{
									openModal: StudioScheduleModal.CALENDAR,
								},
							)}
						>
							{t('Modal.Modal.schedule')}
						</ButtonPill>
					)}
				/>
			)}
			isOpen={isOpen}
			onClose={closeModal}
		>
			<TabContent>
				<ModalLazyContent>
					<StudioScheduleCalendarModalContent
						channelId={studioOwnerId}
						options={options}
					/>
				</ModalLazyContent>
			</TabContent>
		</ModalScreen>
	);
};

StudioScheduleCalendarModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	studioOwnerId: PropTypes.string,
	setStudioOwnerId: PropTypes.func,
	options: PropTypes.shape({
		forceStartImmediately: PropTypes.bool,
		openModal: PropTypes.oneOf(Object.values(StudioScheduleModal)),
		startDate: PropTypes.string,
		endDate: PropTypes.string,
	}),
};

StudioScheduleCalendarModal.defaultProps = {
	studioOwnerId: undefined,
	setStudioOwnerId: undefined,
	options: undefined,
};
