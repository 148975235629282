import { createSelector } from 'reselect';

export const STATE_KEY = 'tracks';

/**
 * selectors
 */
export const selectTracks = (state) => state[STATE_KEY];

export const selectAudioTracks = createSelector(
	selectTracks,
	(tracks = []) => tracks.filter((t) => t.mediaStreamTrack.kind === 'audio'),
);

export const selectVideoTracks = createSelector(
	selectTracks,
	(tracks = []) => tracks.filter((t) => t.mediaStreamTrack.kind === 'video'),
);
