import { Path, getAbsoluteLink } from '../../../RoutePath';
import { ResourceAccessRole } from '../../../lib/ResourceAccessRole';
import { ShareAboutType } from '../../Notification/Notification.helper';

export const getShareType = ({ channel, studio, video, post }) => {
	if (post) return ShareAboutType.POST;
	if (studio) return ShareAboutType.STUDIO;
	if (video) return ShareAboutType.VIDEO;
	if (channel) return ShareAboutType.CHANNEL;
	throw new TypeError('Incorrect share');
};

export const getShareLink = ({ channel, studio, video, role, post }) => {
	if (!channel) throw new TypeError('Incorrect share');
	const { hashtag } = channel;
	if (post) return getAbsoluteLink(Path.POST, { hashtag, slug: post.slug });
	if (studio && role === ResourceAccessRole.VIEWER) {
		return getAbsoluteLink(Path.STUDIO_VIEWER, { hashtag, code: studio.code });
	}
	if (studio && role === ResourceAccessRole.PARTICIPANT) {
		return `${getAbsoluteLink(Path.STUDIO_PARTICIPANT, { hashtag, code: studio.code })}?pwd=${studio.password}`;
	}
	if (video) return getAbsoluteLink(Path.VOD, { hashtag, id: video._id });
	return getAbsoluteLink(Path.CHANNEL, { hashtag });
};
