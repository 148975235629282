/**
 * !!! IMPORTANT :
 * every route path that is not related to a channel
 * must start with a 4-chararacters long maximum root path in order
 * to avoid conflicts with channel paths (channel hashtags are 5 characters long minimum)
 */

export const BytvPathPrefix = '/bytv';

export const Path = {
	CHANNEL: '/:hashtag',
	CHECKOUT: `${BytvPathPrefix}/checkout`,
	DONATE: `${BytvPathPrefix}/donate`,
	HELP: `${BytvPathPrefix}/help`,
	HELP_USERGUIDE: `${BytvPathPrefix}/help/userGuide`,
	HELP_ANCHOR: `${BytvPathPrefix}/help/:anchor`,
	HOME: `${BytvPathPrefix}`,
	HOME_ANCHOR: `${BytvPathPrefix}/:anchor`,
	HOME_WATCH_VIDEOS: `${BytvPathPrefix}/watch`,
	HOME_MEET_BEES: `${BytvPathPrefix}/meet`,
	LEGAL_COOKIES: `${BytvPathPrefix}/legal/cookies`,
	LEGAL_TERMS: `${BytvPathPrefix}/legal/terms`,
	MEDIATEST: `${BytvPathPrefix}/mediatest`,
	// PI_SUCCESS: '/pi-success',
	POST: '/:hashtag/post/:slug',
	ROOT: '/',
	SEARCH: `${BytvPathPrefix}/search/:tags`,
	SLACK: `${BytvPathPrefix}/slack`,
	SLACK_CONNECT: `${BytvPathPrefix}/slack/connect`,
	STUDIO: `${BytvPathPrefix}/studio`,
	STUDIO_HOST: '/:hashtag/studio/:code',
	STUDIO_HOST_ELEMENTS: '/:hashtag/studio/:code/elements',
	STUDIO_ACTIVITY: '/:hashtag/studio/:code/activity',
	STUDIO_CHAT: '/:hashtag/studio/:code/chat',
	STUDIO_TOOLS: '/:hashtag/studio/:code/tools',
	STUDIO_PARTICIPANTS: '/:hashtag/studio/:code/participants',
	STUDIO_TEMPLATES: '/:hashtag/studio/:code/templates',
	STUDIO_OPERATOR: '/:hashtag/o/:code',
	STUDIO_PARTICIPANT: '/:hashtag/p/:code',
	STUDIO_VIEWER: '/:hashtag/v/:code',
	SURVEY: `${BytvPathPrefix}/survey/:surveyCode`,
	SURVEY_STUDIO_ATTENDANCE: `${BytvPathPrefix}/survey/studio/attendance`,
	VOD: '/:hashtag/vod/:id',
	WEBRTCTEST: `${BytvPathPrefix}/webrtctest`,
};

export const AdminPath = {
	ADMIN_ROOT: '/a/admin',
};

export const DevPath = {
	DEV_STUDIO: '/dev/studio',
	DEV_STUDIO_PARTICIPANT: '/dev/p/:hashtag',
};

export function getLink(path, pathParams = {}) {
	const pathParamsKeys = Object.keys(pathParams);
	const pathParamsCount = (path.match(/:/g) || []).length;

	if (pathParamsCount !== pathParamsKeys.length) {
		// eslint-disable-next-line no-console
		console.warn(`Path params count mismatch for path ${path}`);
	}

	return pathParamsKeys.reduce((acc, key) => {
		const value = pathParams[key];
		return acc.replace(`:${key}`, value);
	}, path);
}

export const getChannelLink = (hashtag) => getLink(Path.CHANNEL, { hashtag });

export const getStudioViewerLink = (hashtag, code) => getLink(
	Path.STUDIO_VIEWER,
	{ hashtag, code },
);

export const getVodLink = (hashtag, id) => getLink(
	Path.VOD,
	{ hashtag, id },
);

export const Redirects = [
	{ from: '/c/:hashtag/o/:code', to: '/:hashtag/o/:code' },
	{ from: '/c/:hashtag/p/:code', to: '/:hashtag/p/:code' },
	{ from: '/c/:hashtag/studio/:code', to: '/:hashtag/studio/:code' },
	{ from: '/c/:hashtag/studio/:code/elements', to: '/:hashtag/studio/:code/elements' },
	{ from: '/c/:hashtag/studio/:code/activity', to: '/:hashtag/studio/:code/activity' },
	{ from: '/c/:hashtag/studio/:code/chat', to: '/:hashtag/studio/:code/chat' },
	{ from: '/c/:hashtag/studio/:code/tools', to: '/:hashtag/studio/:code/tools' },
	{ from: '/c/:hashtag/studio/:code/participants', to: '/:hashtag/studio/:code/participants' },
	{ from: '/c/:hashtag/studio/:code/templates', to: '/:hashtag/studio/:code/templates' },
	{ from: '/c/:hashtag/v/:code', to: '/:hashtag/v/:code' },
	{ from: '/c/:hashtag/vod/:id', to: '/:hashtag/vod/:id' },
	{ from: '/c/:hashtag/post/:id', to: '/:hashtag/post/:id' },
	{ from: '/c/:hashtag', to: '/:hashtag/channel' },
];

export const RedirectsFromPath = Redirects.map(({ from }) => from);

export const getRedirectToLink = (fromPath, fromParams) => {
	const redirect = Redirects.find(({ from }) => from === fromPath);
	return getLink(redirect.to, fromParams);
};

export const getAbsoluteLink = (path, pathParams) => {
	const link = getLink(path, pathParams);
	return `${window.origin}${link}`;
};
