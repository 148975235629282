import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button } from 'reactstrap';

export const ButtonPill = ({ className, ...props }) => (
	<Button
		className={clsx('btn-pill font-weight-bold', className)}
		{...props}
	/>
);

ButtonPill.propTypes = {
	className: PropTypes.string,
	color: PropTypes.string,
};

ButtonPill.defaultProps = {
	className: '',
	color: 'primary',
};

export const ButtonPillOutline = ({ className, color, textColor, ...props }) => (
	<ButtonPill
		className={clsx('text-inherit', className)}
		color={`outline-${color}`}
		{...props}
	/>
);

ButtonPillOutline.propTypes = {
	className: PropTypes.string,
	color: PropTypes.string,
	textColor: PropTypes.string,
};

ButtonPillOutline.defaultProps = {
	className: '',
	color: 'primary',
	textColor: 'dark',
};
