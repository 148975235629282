import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ButtonGroup } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useCurrentStudio } from '../../components/Studio/useCurrentStudio';
import { ReactComponent as BeeyouRedLogo } from '../../images/beeyou-red.svg';
import { ReactComponent as BeeyouLogo } from '../../images/beeyou.svg';
import { getLink, Path } from '../../RoutePath';
import { StudioButtonActive } from '../../components/Studio/Button';
import { useStudio } from '../../components/Studio/Context';

const SidebarHeader = ({ handleClick, isStudioRoute, sideBar }) => {
	const { t } = useTranslation();
	const { currentStudio,
		isCurrentStudioStarted,
		isCurrentStudioMine,
		isCurrentStudioOperatedByMe } = useCurrentStudio();
	const isHomeRoute = window.location.pathname === '/';

	const isControlledStudioStarted = isCurrentStudioStarted
		&& (isCurrentStudioMine || isCurrentStudioOperatedByMe);

	const { controlroomActive } = useStudio();

	return (
		<div className="app-sidebar--header px-0 w-100">
			{!isCurrentStudioStarted && sideBar}
			<div className="nav-logo d-flex align-items-center flex-fill mw-100">
				{!isStudioRoute && (
					<Link
						to={isCurrentStudioStarted && isCurrentStudioMine && !isStudioRoute ? '' : getLink(Path.ROOT)}
						title={t('SidebarHeader.Index.homepage')}
						onClick={isHomeRoute ? handleClick : null}
					>
						{isCurrentStudioStarted && isCurrentStudioMine
							? <BeeyouRedLogo className="mr-2" height="40px" />
							: <BeeyouLogo className="mr-2" height="40px" />}
						{!isStudioRoute && (
							<div className="d-none d-md-block position-relative">
								<span className="content-darker">
									beeyou.tv
								</span>
								{isHomeRoute && (
									<span className="position-absolute d-block small text-primary right-0 line-height-crop">
										beta
									</span>
								)}
							</div>
						)}
					</Link>
				)}
				<ButtonGroup className={clsx('StudioButtonGroup', { 'studio-started': isControlledStudioStarted })}>
					{controlroomActive && <StudioButtonActive />}
				</ButtonGroup>
				{isStudioRoute && isCurrentStudioStarted && currentStudio?.subject && (
					<div className="SubjectContainer mw-100 d-flex align-items-center ml-2">
						<span className="d-none d-md-block text-truncate">
							{currentStudio.subject}
						</span>
					</div>
				)}
			</div>
		</div>
	);
};

SidebarHeader.propTypes = {
	handleClick: PropTypes.func,
	isStudioRoute: PropTypes.bool,
	sideBar: PropTypes.node,
};

SidebarHeader.defaultProps = {
	handleClick: undefined,
	isStudioRoute: false,
	sideBar: null,
};

export default SidebarHeader;
