import PropTypes from 'prop-types';
import React, { lazy, useState } from 'react';
import { NavItem, NavLink, TabContent } from 'reactstrap';

import { useTranslation } from 'react-i18next';
import { ModalLazyContent } from '../Modal/LazyContent';
import { ModalScreenHeader, ModalScreenHeaderNav } from '../Modal/Screen/Header';
import { ModalScreen } from '../Modal/Screen/Screen';
import { InfoCenterModalTab, useUserSettings } from './Context';

const InfoCenterModalContent = lazy(() => import('./InfoCenterModalContent'));

export const InfoCenterModal = ({ isOpen }) => {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState(InfoCenterModalTab.TERMS_AND_PRIVACY);
	const { closeModal } = useUserSettings();

	return (
		<ModalScreen
			bodyClassName="bg-gray py-0"
			header={(
				<ModalScreenHeader
					onClose={closeModal}
					title={t('UserSettings.InfoCenterModal.infoCenter')}
				>
					<ModalScreenHeaderNav className="mt-3">
						<NavItem className="mr-3">
							<NavLink
								title={t('UserSettings.InfoCenterModal.termsAndPrivacy')}
								onClick={() => setActiveTab(InfoCenterModalTab.TERMS_AND_PRIVACY)}
								active={activeTab === InfoCenterModalTab.TERMS_AND_PRIVACY}
							>
								<span>{t('UserSettings.InfoCenterModal.termsAndPrivacy')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="mx-3">
							<NavLink
								title={t('UserSettings.InfoCenterModal.cookiePolicy')}
								onClick={() => setActiveTab(InfoCenterModalTab.COOKIE_POLICY)}
								active={activeTab === InfoCenterModalTab.COOKIE_POLICY}
							>
								<span>{t('UserSettings.InfoCenterModal.cookiePolicy')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="ml-3">
							<NavLink
								title={t('UserSettings.InfoCenterModal.help')}
								onClick={() => setActiveTab(InfoCenterModalTab.HELP)}
								active={activeTab === InfoCenterModalTab.HELP}
							>
								<span>{t('UserSettings.InfoCenterModal.help')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
					</ModalScreenHeaderNav>
				</ModalScreenHeader>
			)}
			isOpen={isOpen}
			onClose={closeModal}
		>
			<TabContent activeTab={activeTab}>
				<ModalLazyContent>
					<InfoCenterModalContent activeTab={activeTab} />
				</ModalLazyContent>
			</TabContent>
		</ModalScreen>
	);
};

InfoCenterModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
};
