import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFileInvoice, FaQuestionCircle } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { Path, getChannelLink, getLink } from '../../RoutePath';
import { useFetchTotalUnreadCount } from '../../api-hooks/conversation';
import { useAuthentication } from '../../components/Authentication/Authentication';
import { useMenu } from '../../components/Drawer/Context';
import { useProfile } from '../../components/Profile/ProfileContext';
import ReallyPerfectScrollbar from '../../components/ReallyPerfectScrollbar/ReallyPerfectScrollbar';
import { SidebarMenuFollowing } from './Following/Following';
import { MenuCollapse } from './MenuCollapse/MenuCollapse';
import { SidebarMenuStudio } from './Studio/Studio';
import {
	LiveIcon,
	MeetIcon,
	MyChannelIcon,
	MyMessagesIcon,
	WatchIcon,
} from './icons';
import { usePwa } from '../../components/Pwa/Provider';

const SidebarMenu = ({ toggleSidebarMobile, isStudioRoute, hideSidebarMenu }) => {
	const { t } = useTranslation();
	const { user } = useAuthentication();
	const { profile } = useProfile();

	const { openModalCustom } = usePwa();
	const { data: unreadCounts } = useFetchTotalUnreadCount();
	const totalUnreadCount = unreadCounts?.total ?? 0;
	const { toggleMenu, setActiveTab } = useMenu();
	const history = useHistory();

	const [activeLink, setActiveLink] = useState('');

	const handleLinkClick = (link, url) => {
		if (isStudioRoute && url) {
			window.open(url, '_blank', 'location=yes,height=700,width=1080px');
		} else {
			setActiveLink(link);
		}
	};

	const toggleSidePanelBreakpoint = 991.98;
	const isCurrentLocationHome = /\/|\/home\/|\/help\//.test(history.location.pathname);
	const isWidthToggleSidebarMobile = window.innerWidth < toggleSidePanelBreakpoint;
	const sidebarMenuContent = [
		user && profile?.hashtag && (
			{
				label: t('SidebarMenu.Index.myChannel'),
				icon: <MyChannelIcon className="icon" />,
				to: getChannelLink(profile.hashtag),
				hideSidebarOnClick: true,
			}
		),

		user && profile?.hashtag && (
			{
				label: t('SidebarMenu.Index.myMessages'),
				badge:
	<div
		className="position-absolute sibebar-menu-badge"
	>
		<span className="btn-wrapper--icon mr-1">
			{totalUnreadCount > 0 && (
				<Badge
					color="danger"
					className="UserMenuDrawer_badge badge-circle shadow-none badge-position badge-position--top-left-sidebar-menu"
				>
					<span className="text-xs">{totalUnreadCount}</span>
				</Badge>
			)}
		</span>
	</div>,
				icon: <MyMessagesIcon className="icon" />,
				to: window.location.pathname,
				action: () => {
					toggleMenu();
					setActiveTab('Messages');
				},
				toggleSidebarOnClick: isWidthToggleSidebarMobile,
			}
		),
	];

	const sidebarSecondaryMenuContent = [
		{
			label: t('SidebarMenu.Index.live'),
			icon: <LiveIcon className="icon" />,
			to: getLink(Path.HOME_ANCHOR, { anchor: 'coming-up-live' }),
			toggleSidebarOnClick: !isCurrentLocationHome,
		},
		{
			label: t('SidebarMenu.Index.meetTheBees'),
			icon: <MeetIcon className="icon" />,
			to: getLink(Path.HOME_MEET_BEES),
			toggleSidebarOnClick: false,
		},
		{
			label: t('SidebarMenu.Index.watchVideos'),
			icon: <WatchIcon className="icon" />,
			to: getLink(Path.HOME_WATCH_VIDEOS),
			toggleSidebarOnClick: false,
		},
		profile && {
			label: t('SidebarMenu.Index.posts'),
			icon: <FaFileInvoice />,
			to: `/${profile.hashtag}?tab=POSTS`,
			toggleSidebarOnClick: false,
		},
	];
	const helpCenterMenu = [
		{ label: t('SidebarMenu.Index.gettingStarted'), to: getLink(Path.HELP_ANCHOR, { anchor: 'gettingStarted' }) },
		{ label: t('SidebarMenu.Index.userGuide'), to: getLink(Path.HELP_USERGUIDE) },
		{ label: t('SidebarMenu.Index.faqs'), to: getLink(Path.HELP_ANCHOR, { anchor: 'faq' }) },
		{ label: t('SidebarMenu.Index.troubleshooting'), to: getLink(Path.HELP_ANCHOR, { anchor: 'troubleshooting' }) },
		{ label: t('SidebarMenu.Index.InstallApp'), onClick: openModalCustom },
	];

	const helpMenu = {
		label: 'Help',
		icon: <FaQuestionCircle className="icon" />,
	};

	return (
		<ReallyPerfectScrollbar>
			<div className="sidebar-navigation">
				<ul className="pt-3">
					{!!user && (
						<SidebarMenuStudio
							activeLink={activeLink}
							isCurrentLocationHome={isCurrentLocationHome}
							hideSidebarMenu={hideSidebarMenu}
						/>
					)}
					{!!user && sidebarMenuContent.map((menuItem) => menuItem && (
						<li key={menuItem.label}>
							<Link
								to={menuItem.to}
								className={clsx('sidebar-navigation-item mx-2', { active: activeLink === menuItem.label })}
								onClick={() => {
									if (menuItem.action) {
										menuItem.action();
									}
									if (menuItem.toggleSidebarOnClick) {
										toggleSidebarMobile();
									}
									if (menuItem.hideSidebarOnClick) {
										hideSidebarMenu();
									}
									handleLinkClick(menuItem.label);
								}}
							>
								{menuItem.badge}
								<i className="sidebar-icon">
									{menuItem.icon && (
										menuItem.icon
									)}
								</i>
								{menuItem.label}
							</Link>
						</li>
					))}
				</ul>
				{!!user && <div className="divider bg-secondary opacity-25 mb-3" />}
				<ul>
					{sidebarSecondaryMenuContent.map((menuItem) => menuItem && (
						<li key={menuItem.label}>
							<Link
								to={menuItem.to}
								className={clsx('sidebar-navigation-item mx-2', { active: activeLink === menuItem.label })}
								onClick={() => {
									if (menuItem.action) {
										menuItem.action();
									}
									if (menuItem.toggleSidebarOnClick) {
										toggleSidebarMobile();
									}
									handleLinkClick(menuItem.label);
								}}
							>
								{menuItem.badge}
								<i className="sidebar-icon">
									{menuItem.icon && (
										menuItem.icon
									)}
								</i>
								{menuItem.label}
							</Link>
						</li>
					))}
				</ul>
				{!!user && (
					<>
						<div className="divider bg-secondary opacity-25" />
						<div className="pt-2 pb-1">
							<span className="font-size-xs mb-0 ml-2 text-uppercase text-secondary ml-4">{t('SidebarMenu.Index.channelsYouFollow')}</span>
						</div>
						<ul>
							<SidebarMenuFollowing
								activeLink={activeLink}
								isCurrentLocationHome={isCurrentLocationHome}
							/>
						</ul>
					</>
				)}
				<div className="divider bg-secondary opacity-25" />
				<ul className="pt-3">
					<MenuCollapse parentItem={helpMenu} childItems={helpCenterMenu} />
				</ul>
			</div>
		</ReallyPerfectScrollbar>
	);
};
SidebarMenu.propTypes = {
	toggleSidebarMobile: PropTypes.func,
	isStudioRoute: PropTypes.bool,
	hideSidebarMenu: PropTypes.func,
};
SidebarMenu.defaultProps = {
	toggleSidebarMobile: undefined,
	isStudioRoute: false,
	hideSidebarMenu: undefined,
};
export default SidebarMenu;
