import PropTypes from 'prop-types';
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../../lib/hooks';
import { FriendsMenuLazyFallback } from '../FriendsMenu/LazyFallback';
import { MenuContext } from './Context';
import Overlay from './Overlay';
import MenuContainerForPush, { AppContainerForPush } from './Push';
import { AppContainerForReveal, MenuContainerForReveal } from './Reveal';
import MenuContainerForSlide from './Slide';

const MenuProvider = (props) => {
	const {
		openByDefault,
		MenuComponent,
		children,
		width,
		minWidth,
		direction,
		animation,
	} = props;
	const [isMenuOpen, setIsMenuOpen] = useState(openByDefault || false);
	const [menuIsClosing, setMenuIsClosing] = useState(true);
	const [activeTab, setActiveTab] = useState('');
	const [menuProps, _setMenuProps] = useState({});
	const location = useQueryParams();
	const history = useHistory();

	const openMenu = useCallback(() => {
		setIsMenuOpen(true);
		setMenuIsClosing(false);
	}, []);

	const closeMenu = useCallback(() => {
		setMenuIsClosing(true);
		// TODO
		if (location.get('new_messages')) {
			location.delete('new_messages');
			history.replace({
				search: location.toString(),
			});
		}
	}, [history, location]);

	const toggleMenu = useCallback(() => {
		if (isMenuOpen) {
			closeMenu();
		} else {
			openMenu();
		}
	}, [isMenuOpen, openMenu, closeMenu]);

	useEffect(() => {
		if (location.get('new_messages') === 'open') {
			openMenu();
		}
	}, [location, openMenu]);

	const setMenuProps = useCallback((newMenuProps) => {
		if (JSON.stringify(menuProps) !== JSON.stringify(newMenuProps)) _setMenuProps(newMenuProps);
	}, [menuProps]);

	const renderContents = () => {
		switch (animation) {
		case 'push':
			return (
				<>
					{isMenuOpen && (
						<MenuContainerForPush
							direction={direction}
							menuIsClosing={menuIsClosing}
							setIsMenuOpen={setIsMenuOpen}
							width={width}
						>
							<Suspense fallback={<FriendsMenuLazyFallback />}>
								<MenuComponent {...menuProps} />
							</Suspense>
						</MenuContainerForPush>
					)}
					<AppContainerForPush
						direction={direction}
						width={width}
						menuIsClosing={menuIsClosing}
					>
						{children}
					</AppContainerForPush>
				</>
			);
		case 'reveal':
			return (
				<>
					{isMenuOpen && (
						<MenuContainerForReveal
							direction={direction}
							menuIsClosing={menuIsClosing}
							width={width}
							// isMenuOpen={isMenuOpen}
						>
							<Suspense fallback={<FriendsMenuLazyFallback />}>
								<MenuComponent {...menuProps} />
							</Suspense>
						</MenuContainerForReveal>
					)}
					<AppContainerForReveal
						direction={direction}
						width={width}
						menuIsClosing={menuIsClosing}
						setIsMenuOpen={setIsMenuOpen}
					>
						{children}
					</AppContainerForReveal>
				</>
			);
		default:
			return (
				<>
					{isMenuOpen && (
						<Overlay onClick={closeMenu}>
							<MenuContainerForSlide
								direction={direction}
								menuIsClosing={menuIsClosing}
								minWidth={minWidth}
								setIsMenuOpen={setIsMenuOpen}
								width={width}
							>
								<Suspense fallback={<FriendsMenuLazyFallback />}>
									<MenuComponent {...menuProps} />
								</Suspense>
							</MenuContainerForSlide>
						</Overlay>
					)}
					{children}
				</>
			);
		}
	};

	const value = useMemo(() => ({
		activeTab,
		closeMenu,
		openMenu,
		setActiveTab,
		setMenuProps,
		toggleMenu,
	}), [
		activeTab,
		closeMenu,
		openMenu,
		setActiveTab,
		setMenuProps,
		toggleMenu,
	]);

	return (
		<MenuContext.Provider
			value={value}
		>
			{renderContents()}
		</MenuContext.Provider>
	);
};
MenuProvider.propTypes = {
	/**
	 * Set's the initial state of the Menu i.e. Open or Close
	 */
	openByDefault: PropTypes.bool,
	direction: PropTypes.oneOf(['left', 'right']),
	animation: PropTypes.oneOf(['slide', 'push', 'reveal']),
	MenuComponent: PropTypes.elementType.isRequired,
	children: PropTypes.node.isRequired,
	width: PropTypes.string,
	minWidth: PropTypes.string,
};

MenuProvider.defaultProps = {
	openByDefault: false,
	width: '275px',
	minWidth: window.innerWidth - 42,
	direction: 'left',
	animation: 'slide',
};

export default MenuProvider;
