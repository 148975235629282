import React, { useCallback, useEffect, useState } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { ConfirmationModal } from '../../layout-components/ConfirmationModal';
import { useVote } from './Provider';
import { TemplateCreate } from './Templates/Create';
import { TemplateHistory } from './Templates/History';
import { VoteAudienceType, VoteCreate } from './Votes/Create';
import { VoteHistory } from './Votes/History';
import { VotePreview } from './Votes/Preview';
import { ReportModal } from './Votes/Report/Modal';
import { useStudio } from '../Studio/Context';
import { useCurrentStudio } from '../Studio/useCurrentStudio';
import { ListSortingProvider } from '../ListSorting/Provider';

export const VoteView = () => {
	const { t } = useTranslation();
	const {
		activeVote,
		createVote,
		endVote,
		publishVote,
		endVotePublish,
		createVoteTemplate,
		deleteVoteTemplate,
	} = useVote();

	const { studio } = useStudio();

	const [activeView, setActiveView] = useState('template/history');
	const [currentVote, setCurrentVote] = useState(null);
	const [deletingTemplate, setDeletingTemplate] = useState(null);

	const { currentStudio } = useCurrentStudio();

	const [currentTemplate, setCurrentTemplate] = useState(null);

	const [isReportModalOpen, setIsReportModalOpen] = useState(false);
	const toggleReportModalOpen = useCallback(() => { setIsReportModalOpen((open) => !open); }, []);

	const handleCreateVote = useCallback(async (voteData) => {
		await createVote(voteData, studio._id);
	}, [createVote, studio]);

	const handleQuicCreateVoteCancel = useCallback(() => {
		setCurrentVote(null);
		setActiveView('template/history');
	}, [setCurrentVote, setActiveView]);

	const handleEndVote = useCallback(async (voteId) => {
		await endVote(voteId);
	}, [endVote]);

	const handleVotePreview = useCallback((vote) => {
		setCurrentVote(vote);
		setActiveView('preview');
	}, [setCurrentVote, setActiveView]);

	const handleCreateVoteRedirect = useCallback(() => {
		setCurrentVote(null);
		setActiveView('new');
	}, [setActiveView]);

	const handleCreateVoteCancel = useCallback(() => {
		setCurrentVote(null);
		setActiveView('history');
	}, [setActiveView]);

	const handleVoteClose = useCallback(() => {
		setCurrentVote(null);
		setActiveView('history');
	}, [setCurrentVote]);

	const handleCreateTemplate = useCallback(async (templateData) => {
		if (currentStudio) {
			await createVoteTemplate(templateData);

			setCurrentTemplate(null);
			setActiveView('template/history');
		}
	}, [createVoteTemplate, currentStudio]);

	const handleCreateTemplateCancel = useCallback(() => {
		setCurrentTemplate(null);
		setActiveView('template/history');
	}, [setActiveView]);

	const handleDeleteTemplate = useCallback(async () => {
		await deleteVoteTemplate(deletingTemplate);

		setDeletingTemplate(null);
	}, [deleteVoteTemplate, deletingTemplate]);

	const handleDeleteTemplateClick = useCallback((templateId) => {
		setDeletingTemplate(templateId);
	}, []);

	const handleDeleteTemplateCancel = useCallback(() => {
		setDeletingTemplate(null);
	}, []);

	const handleTemplatePreview = useCallback((template) => {
		setCurrentVote({
			title: template.title,
			options: template.options,
			duration: template.defaultDuration,
			position: template.defaultPosition,
			category: template.category,
			audienceType: template.audienceType,
			template: template._id,
		});
		setCurrentTemplate(template);
		setActiveView('template/quick');
	}, [setCurrentVote, setCurrentTemplate, setActiveView]);

	const handleCreateTemplateRedirect = useCallback(() => {
		setCurrentTemplate(null);
		setActiveView('template/new');
	}, []);

	const handleDuplicateTemplate = useCallback((template) => {
		setCurrentVote(template);
		setActiveView('template/new');
	}, [setCurrentVote, setActiveView]);

	const handleTemplateClick = useCallback(() => {
		setActiveView('template/history');
		setCurrentVote(null);
		setCurrentTemplate(null);
	}, [setActiveView, setCurrentTemplate, setCurrentVote]);

	const handleHistoryClick = useCallback(() => {
		setActiveView('history');
		setCurrentVote(null);
		setCurrentTemplate(null);
	}, [setActiveView, setCurrentTemplate, setCurrentVote]);

	useEffect(() => {
		if (activeVote) {
			handleVotePreview(activeVote);
		} else {
			// if current vote exists force re-render
			setCurrentVote((cV) => (cV && cV.audienceType === VoteAudienceType.PARTICIPANT
				? { ...cV, endedAt: new Date().toISOString() }
				: cV
			));
		}
	}, [activeVote, handleVotePreview]);

	return (
		<div className="h-100 d-flex flex-column justify-content-between overflow-hidden flex-grow-1">
			<div className="nav-line-dark w-100 px-3">
				{!activeVote && (
					<Nav justified className="nav-line w-100">
						<NavItem>
							<NavLink
								active={activeView === 'template/history'}
								onClick={handleTemplateClick}
							>
								<small className="mx-auto">{t('Vote.View.templates')}</small>
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								active={activeView === 'history'}
								onClick={handleHistoryClick}
							>
								<small className="mx-auto">{t('Vote.View.history')}</small>
							</NavLink>
						</NavItem>
					</Nav>
				)}
			</div>
			{activeView === 'new' && (
				<VoteCreate
					onSubmit={handleCreateVote}
					onCancel={handleCreateVoteCancel}
					currentVote={currentVote}
				/>
			)}
			{activeView === 'template/new' && (
				<TemplateCreate
					currentTemplate={currentVote}
					onSubmit={handleCreateTemplate}
					onCancel={handleCreateTemplateCancel}
				/>
			)}
			{activeView === 'history' && (
				<ListSortingProvider>
					<VoteHistory
						onVotePreviewClick={handleVotePreview}
						onCreateVoteClick={handleCreateVoteRedirect}
						openReportModal={toggleReportModalOpen}
					/>
				</ListSortingProvider>
			)}
			{activeView === 'template/history' && (
				<ListSortingProvider>
					<TemplateHistory
						onTemplatePreviewClick={handleTemplatePreview}
						onCreateTemplateClick={handleCreateTemplateRedirect}
						onDeleteTemplateClick={handleDeleteTemplateClick}
						onDuplicateClick={handleDuplicateTemplate}
						currentTemplate={currentTemplate}
					/>
				</ListSortingProvider>
			)}
			{activeView === 'preview' && (
				<VotePreview
					vote={currentVote}
					onClose={handleVoteClose}
					onEnd={handleEndVote}
					onPublish={publishVote}
					onEndPublish={endVotePublish}
				/>
			)}
			{activeView === 'template/quick' && (
				<VoteCreate
					onSubmit={handleCreateVote}
					onCancel={handleQuicCreateVoteCancel}
					currentVote={currentVote}
				/>
			)}
			<ConfirmationModal
				isOpen={!!deletingTemplate}
				onConfirm={handleDeleteTemplate}
				onDeny={handleDeleteTemplateCancel}
				message={t('Vote.View.deleteConfirm')}
			/>
			<ReportModal
				toggle={toggleReportModalOpen}
				isOpen={isReportModalOpen}
			/>
		</div>
	);
};
