import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { NavCountContext } from './Context';

const NavCount = ({ children }) => {
	const [count, setCount] = useState(0);

	useLayoutEffect(() => {
		const handleClick = () => {
			setCount((s) => s + 1);
		};

		document.addEventListener('click', handleClick, true);

		return () => {
			document.removeEventListener('click', handleClick, true);
		};
	});

	return (
		<NavCountContext.Provider value={count}>
			{children}
		</NavCountContext.Provider>
	);
};

NavCount.propTypes = {
	children: PropTypes.node,
};

NavCount.defaultProps = {
	children: null,
};

export default NavCount;
