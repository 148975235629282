import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
	Label,
	FormGroup,
	Input,
	Alert,
	Collapse,
} from 'reactstrap';
import clsx from 'clsx';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import {
	DEFAULT_DEVICES,
	KeyDetectionMode,
	useInputs,
	useMediaKey,
} from '@technomiam/react-video';

import { LocalMic } from '../../Channel/ChannelButtonsPanel/LocalUserMedia/LocalMic';
import { LocalCam } from '../../Channel/ChannelButtonsPanel/LocalUserMedia/LocalCam';
import { MediaKeyForm } from '../Key/Form';

export const InputsSelector = ({
	config,
	size,
	onKeySelectBackground,
}) => {
	const { t } = useTranslation();
	const {
		audioInputDevices,
		changeAudioInputDevice,
		changeVideoInputDevice,
		currentlyEnabledDefaultDevices,
		videoInputDevices,
		inputsConfig,
	} = useInputs();
	const configId = config.id;
	const {
		config: keyConfig,
		isKeyForced,
		isReplacementConfigOverriden,
	} = useMediaKey();
	const [keySettingsOpen, setKeySettingsOpen] = useState(false);

	const handleAudioSourceChange = (event) => {
		const { value } = event.target;
		const label = value === DEFAULT_DEVICES.mic.deviceId
			? DEFAULT_DEVICES.mic.label
			: audioInputDevices.find(({ deviceId }) => deviceId === value)?.label;
		changeAudioInputDevice(
			configId,
			value || undefined,
			label,
		);
	};

	const handleVideoSourceChange = (event) => {
		const { value } = event.target;
		const label = value === DEFAULT_DEVICES.cam.deviceId
			? DEFAULT_DEVICES.cam.label
			: videoInputDevices.find(({ deviceId }) => deviceId === value)?.label;
		changeVideoInputDevice(
			configId,
			value || undefined,
			label,
		);
	};

	const otherInputConfigs = inputsConfig.filter((cfg) => cfg.id !== configId);

	const availableAudioDevices = (audioInputDevices || []).filter(
		({ deviceId }) => !otherInputConfigs.find(({ audioInputId }) => audioInputId === deviceId),
	);
	const availableVideoDevices = (videoInputDevices || []).filter(
		({ deviceId }) => !otherInputConfigs.find(({ videoInputId }) => videoInputId === deviceId),
	);
	const audioDeviceFound = configId === 0
		? audioInputDevices[0]
		: audioInputDevices.find(({ deviceId }) => (
			deviceId === config?.audioInputId
		));
	const videoDeviceFound = configId === 0
		? videoInputDevices[0]
		: videoInputDevices.find(({ deviceId }) => (
			deviceId === config?.videoInputId
		));

	const isDeviceBusy = (inputId) => configId !== 0
		&& currentlyEnabledDefaultDevices.find(({ deviceId }) => (
			deviceId === inputId
		));

	const isAudioEnabled = !!audioDeviceFound && !isDeviceBusy(config.audioInputId);
	const isVideoEnabled = !!videoDeviceFound && !isDeviceBusy(config.videoInputId);

	const showDeviceNotFound = audioInputDevices?.length < 1 || videoInputDevices?.length < 1;
	const isKeyEnabled = keyConfig?.detection?.mode
		&& keyConfig.detection.mode !== KeyDetectionMode.DISABLED;

	return (
		<div>
			{showDeviceNotFound && (
				<Alert color="danger">
					{t('Inputs.Selector.noDevices')}
					<br />
					{t('Inputs.Selector.seeOur')} <a href="/help/troubleshooting" target="_blank" rel="noopener noreferrer">{t('Inputs.Selector.troobleShootingGuide')} <FaExternalLinkAlt /></a>.
				</Alert>
			)}
			<FormGroup className={clsx({ 'mb-2': size === 'sm' })}>
				<Label className={clsx({ 'mb-1 p-0': size === 'sm' })} htmlFor="videoSource" size={size}>{t('Inputs.Selector.selectVideoSource')}</Label>
				<div className="d-flex align-items-center">
					<LocalCam configId={config.id} className="d-40 p-0 mr-1 flex-shrink-0" title={t('Inputs.Selector.camera')} disabled={!isVideoEnabled} />
					<Input
						disabled={videoInputDevices.length < 1}
						id="videoSource"
						name="videoSource"
						onChange={handleVideoSourceChange}
						bsSize={size}
						type="select"
						value={config.videoInputId || ''}
					>
						{(config.id !== 0 || videoInputDevices.length < 1) && (
							<option value="">{t('Inputs.Selector.none')}</option>
						)}
						{(config.id === 0) && (
							<option value={DEFAULT_DEVICES.cam.deviceId}>
								{DEFAULT_DEVICES.cam.label}
								{videoDeviceFound && ` (${videoDeviceFound.label})`}
							</option>
						)}
						{availableVideoDevices.map((device) => (
							<option
								key={device.deviceId}
								value={device.deviceId}
							>
								{isDeviceBusy(device.deviceId) && `[${t('Inputs.Selector.busy')}] `}
								{device.label}
							</option>
						))}
						{(
							!!config.videoInputId
							&& !videoDeviceFound
						) && (
							<option
								key={config.videoInputId}
								value={config.videoInputId}
							>
								[{t('Inputs.Selector.disconnected')}] {config.videoDeviceLabel}
							</option>
						)}
					</Input>
				</div>
			</FormGroup>
			{config.id === 0 && (
				<section>
					<header>
						<button
							className="btn btn-dark d-flex w-100 justify-content-center shadow-none"
							disabled={isKeyForced}
							type="button"
							onClick={() => setKeySettingsOpen((s) => !s)}
						>
							{isKeyForced
								? 'Key config overriden by current scene settings'
								: (
									<>
										{t('Inputs.Selector.keySettings', { context: keySettingsOpen ? 'hide' : 'show' })}
										{' ('}{t('Global.Status', { context: isKeyEnabled ? 'enabled' : 'disabled' })})
									</>
								)}
						</button>
					</header>
					<Collapse isOpen={!isKeyForced && keySettingsOpen}>
						<div className="p-3 bg-dark">
							{keySettingsOpen && (
								<MediaKeyForm
									initialValue={keyConfig}
									isReplacementConfigOverriden={isReplacementConfigOverriden}
									onKeySelectBackground={onKeySelectBackground}
								/>
							)}
						</div>
					</Collapse>
				</section>
			)}
			<FormGroup className={clsx('mt-2', { 'mb-2': size === 'sm' })}>
				<Label className={clsx({ 'mb-1 p-0': size === 'sm' })} htmlFor="audioSource" size={size}>{t('Inputs.Selector.selectAudioSource')}</Label>
				<div className="d-flex align-items-center">
					<LocalMic configId={config.id} className="d-40 p-0 mr-1 flex-shrink-0" title={t('Inputs.Selector.mic')} disabled={!isAudioEnabled} />
					<Input
						disabled={audioInputDevices.length < 1}
						id="audioSource"
						name="audioSource"
						onChange={handleAudioSourceChange}
						bsSize={size}
						type="select"
						value={config.audioInputId || ''}
					>
						{(config.id !== 0 || audioInputDevices.length < 1) && (
							<option value="">{t('Inputs.Selector.none')}</option>
						)}
						{(config.id === 0) && (
							<option value={DEFAULT_DEVICES.mic.deviceId}>
								{DEFAULT_DEVICES.mic.label}
								{audioDeviceFound && ` (${audioDeviceFound.label})`}
							</option>
						)}
						{availableAudioDevices.map((device) => (
							<option
								key={device.deviceId}
								value={device.deviceId}
							>
								{isDeviceBusy(device.deviceId) && `[${t('Inputs.Selector.busy')}] `}
								{device.label}
							</option>
						))}
						{(
							!!config.audioInputId
							&& !audioDeviceFound
						) && (
							<option
								key={config.audioInputId}
								value={config.audioInputId}
							>
								[{t('Inputs.Selector.disconnected')}] {config.audioDeviceLabel}
							</option>
						)}
					</Input>
				</div>
			</FormGroup>
		</div>
	);
};

InputsSelector.propTypes = {
	config: PropTypes.shape({
		audioInputId: PropTypes.string,
		audioDeviceLabel: PropTypes.string,
		id: PropTypes.number,
		videoInputId: PropTypes.string,
		videoDeviceLabel: PropTypes.string,
	}),
	size: PropTypes.string,
	onKeySelectBackground: PropTypes.func,
};

InputsSelector.defaultProps = {
	config: {},
	size: undefined,
	onKeySelectBackground: undefined,
};
