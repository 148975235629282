import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { FaEllipsisV } from 'react-icons/fa';

import { useAuthentication } from '../../components/Authentication/Authentication';
import { useScreenMode, ModeScreenLayout } from '../../components/Studio/ScreenMode/Provider';
import { StudioHeader } from '../../components/Studio/Header';
import HeaderAnonymousBox from '../HeaderAnonymousBox';
import HeaderUserbox from '../HeaderUserbox';
import SidebarHeader from '../SidebarHeader';
import { SearchInput } from '../../components/SearchSuggestion/SearchInput';
import { useSidebar } from '../Sidebar/Provider';

const Header = ({
	handleClick,
	isMobileLayout,
	isStudioRoute,
	noSideBar,
	search,
}) => {
	const authentication = useAuthentication();
	const { toggleSidebarMobile } = useSidebar();
	const { currentModeScreen } = useScreenMode();

	const [isSearchOpen, setSearchOpen] = useState(false);

	const toggleSidebarMobileWrapper = useCallback(() => {
		if (handleClick) {
			handleClick();
		} else {
			toggleSidebarMobile();
		}
	}, [handleClick, toggleSidebarMobile]);

	return (
		<div
			className={clsx('d-block', { 'position-absolute w-100 app-main layout-fullscreen d-none': currentModeScreen === ModeScreenLayout.FULLSCREEN })}
			id="Header"
		>
			<div className="app-header app-header--shadow d-flex">
				<div
					className={clsx('LogoBeeyouContainer d-flex align-items-center flex-shrink-0',
						{ 'search-menu-open': isSearchOpen },
						{ 'search-menu-close': !isSearchOpen },
						{ 'inStudio w-auto mw-50': isStudioRoute })}
				>
					<SidebarHeader
						isStudioRoute={isStudioRoute}
						isSearchOpen={isSearchOpen}
						handleClick={toggleSidebarMobileWrapper}
						sideBar={
							!noSideBar && (
								<span
									className={clsx('pr-2', { 'd-lg-none': !isMobileLayout })}
									onClick={toggleSidebarMobileWrapper}
								>
									<FaEllipsisV className="text-second" />
								</span>
							)
						}
					/>
				</div>
				{search && (
					<div
						className={clsx(
							'd-flex align-items-center flex-grow-1 flex-shrink-1 min-w-0',
							{
								'mr-2': !isSearchOpen,
								'mr-md-2': isSearchOpen,
							},
						)}
					>
						<SearchInput
							isSearchOpen={isSearchOpen}
							handleSearchOpen={setSearchOpen}
						/>
					</div>
				)}
				<StudioHeader
					isSearchOpen={isSearchOpen}
					isStudioRoute={isStudioRoute}
				/>
				<div
					className={clsx('flex-shrink-0 flex-wrap justify-content-center',
						{ 'search-menu-open': isSearchOpen },
						{ 'search-menu-close': !isSearchOpen })}
				>
					{authentication.isLoggedIn
						? <HeaderUserbox />
						: <HeaderAnonymousBox />}
				</div>
			</div>
		</div>
	);
};

Header.propTypes = {
	handleClick: PropTypes.func,
	isMobileLayout: PropTypes.bool,
	isStudioRoute: PropTypes.bool,
	noSideBar: PropTypes.bool,
	search: PropTypes.bool,
};

Header.defaultProps = {
	handleClick: undefined,
	isMobileLayout: false,
	isStudioRoute: false,
	noSideBar: false,
	search: false,
};

export default Header;
