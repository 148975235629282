const regCamOrMicStreamId = /(?:cam|mic):(\d+)$/;
const regCamStreamId = /(?:cam):(\d+)$/;

export const isCamStreamId = (sourceId) => regCamStreamId.test(sourceId);

export const replaceCamOrMicByParticipant = (sourceId) => sourceId.replace(regCamOrMicStreamId, 'participant:$1');

export const getIdFromPublication = (publication) => {
	const {
		appData,
		hashtag,
		peerId,
	} = publication;
	const {
		streamId,
	} = appData;

	const mergedParticipantStreamId = replaceCamOrMicByParticipant(streamId);

	return `${hashtag}/${peerId}/${mergedParticipantStreamId}`;
};

const regParticipantSourceId = /participant:(\d+)$/;

export const replaceParticipantSourceIdByMic = (sourceId) => sourceId.replace(regParticipantSourceId, 'mic:$1');
export const replaceParticipantSourceIdByCam = (sourceId) => sourceId.replace(regParticipantSourceId, 'cam:$1');

export const isParticipantSourceId = (sourceId) => regParticipantSourceId.test(sourceId);

const regParticipantDefaultSourceId = /participant:0$/;
export const isParticipantDefaultSourceId = (sourceId) => (
	regParticipantDefaultSourceId.test(sourceId)
);

const regVideoshareSourceId = /video:0$/;
export const isVideoshareSourceId = (sourceId) => regVideoshareSourceId.test(sourceId);

const regScreenshareSourceId = /screen:0$/;
export const isScreenshareSourceId = (sourceId) => regScreenshareSourceId.test(sourceId);

const layerIndexRegex = /(?:pip)(\d+)$/;
export const getLayerIndex = (layer) => {
	if (layer === 'main') return 0;
	const regLayer = layerIndexRegex.exec(layer);
	return regLayer?.length === 2 ? parseInt(regLayer[1], 10) : null;
};
