import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import PropTypes from 'prop-types';
import { initReactI18next, useTranslation } from 'react-i18next';

import englishTranslation from './translations/en/en.json';
import frenchTranslation from './translations/fr/fr.json';

const { NODE_ENV } = process.env;
const isDev = NODE_ENV === 'development';

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		// we init with resources
		resources: {
			fr: frenchTranslation,
			en: englishTranslation,
		},
		fallbackLng: 'en',
		debug: isDev,

		// have a common namespace used around the full app
		ns: ['translations'],
		defaultNS: 'translations',

		keySeparator: false, // we use content as keys

		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;

export const getLanguageKey = (language) => (
	i18n.services.languageUtils.getLanguagePartFromCode(language)
);

export const Translate = ({ k }) => {
	const { t } = useTranslation();
	return t(k);
};

Translate.propTypes = {
	k: PropTypes.string.isRequired,
};
