import React from 'react';
import { useTranslation } from 'react-i18next';

export const ModalLoadingTabPane = () => {
	const { t } = useTranslation();
	return (
		<div
			className="p-4 d-flex align-items-center justify-content-center font-size-sm text-secondary"
			style={{ minHeight: '100px' }}
		>
			{t('LoadingTabPane.loading')}
		</div>
	);
};
