import { authenticatedApi } from '../api';

const API_CONTACT_PATH = '/contacts';

export const createContacts = async (data) => authenticatedApi.post(`${API_CONTACT_PATH}/list`, data);

export const fetchContactsList = async () => authenticatedApi.get(
	`${API_CONTACT_PATH}/list`,
);

export const createContact = async (data) => authenticatedApi.post(`${API_CONTACT_PATH}/add`, data);

export const removeContact = async (contactId) => authenticatedApi.delete(`${API_CONTACT_PATH}/delete/${contactId}`);

export const updateContact = async (data) => authenticatedApi.put(`${API_CONTACT_PATH}/${data._id}`, { contact: data });
