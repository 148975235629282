export default class Track {
	constructor(mediaStreamTrack, id) {
		this.id = id || mediaStreamTrack.id;
		this.mediaStreamTrack = mediaStreamTrack;
	}

	clone() {
		return new Track(
			this.mediaStreamTrack.clone(),
			this.id,
		);
	}
}
