import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

export const ShareItem = ({
	children,
	isAdded,
	label,
	onToggle,
	isDisabled,
}) => {
	const { t } = useTranslation();
	return (
		<div className="w-100 rounded-sm p-2 my-1 d-flex align-items-center justify-content-between shadow-sm-dark bg-dark content-dark">
			<div className="d-flex align-items-center overflow-hidden">
				{children}
			</div>
			<div>
				<div className="d-flex justify-content-end align-items-center">
					<Button
						className="font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-30 btn-transition-none m-1"
						color={`neutral-${isAdded ? 'warning' : 'info'}`}
						onClick={onToggle}
						title={`${isAdded ? t('Share.Item.remove') : t('Share.Item.add')} ${label} ${t('Share.Item.fromParticipants')}`}
						disabled={isDisabled}
					>
						{isAdded ? <FaTimes /> : <FaPlus />}
					</Button>
				</div>
			</div>
		</div>
	);
};

ShareItem.propTypes = {
	children: PropTypes.node.isRequired,
	isAdded: PropTypes.bool,
	label: PropTypes.string.isRequired,
	onToggle: PropTypes.func.isRequired,
	isDisabled: PropTypes.bool,
};

ShareItem.defaultProps = {
	isAdded: false,
	isDisabled: false,
};
