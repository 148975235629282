import React from 'react';
import PropTypes from 'prop-types';

import {
	MediaKeyPreviewSource,
	MediaKeyPreviewOutput,
} from '@technomiam/react-video';

export const MediaKeyPreviewDouble = ({
	config,
	eyeDropperEnabled,
	onPickColor,
	videoTrack,
}) => (
	<div className="d-flex">
		{videoTrack && (
			<>
				<MediaKeyPreviewSource
					className="w-50 mr-1"
					eyeDropperEnabled={eyeDropperEnabled}
					onPickColor={onPickColor}
					videoTrack={videoTrack}
				/>
				<MediaKeyPreviewOutput
					className="w-50 ml-1"
					config={config}
					videoTrack={videoTrack}
				/>
			</>
		)}
	</div>
);

MediaKeyPreviewDouble.propTypes = {
	config: PropTypes.shape({}),
	eyeDropperEnabled: PropTypes.bool,
	onPickColor: PropTypes.func,
	videoTrack: PropTypes.instanceOf(MediaStreamTrack),
};

MediaKeyPreviewDouble.defaultProps = {
	config: undefined,
	eyeDropperEnabled: false,
	onPickColor: undefined,
	videoTrack: undefined,
};

MediaKeyPreviewDouble.propTypes = {

};
