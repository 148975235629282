import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { Field, FieldError, Form } from 'react-jsonschema-form-validation';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import FormSubmit from '../../Form/Submit';
import ReallyPerfectScrollbar from '../../ReallyPerfectScrollbar/ReallyPerfectScrollbar';
import { OptionInput } from '../common/OptionInput';
import { VoteAudienceType } from '../Votes/Create';
import './Create.scss';
import { createTemplateSchema } from './createTemplate.schema';

export const TemplateCreate = ({ currentTemplate, onSubmit, onCancel }) => {
	const { t } = useTranslation();
	const [template, setTemplate] = useState({
		title: '',
		options: [{ label: '' }, { label: '' }],
		durationMinutes: 1,
		durationSeconds: 0,
		position: 'top',
		category: '',
		audienceType: VoteAudienceType.PARTICIPANT,
	});

	useEffect(() => {
		if (currentTemplate) {
			setTemplate({
				title: currentTemplate.title,
				category: currentTemplate.category,
				durationMinutes: Math.ceil(currentTemplate.defaultDuration / 60),
				durationSeconds: currentTemplate.defaultDuration % 60,
				position: currentTemplate.defaultPosition,
				options: currentTemplate.options,
				audienceType: currentTemplate.audienceType,
			});
		}
	}, [currentTemplate, setTemplate]);

	const handleSubmitAsync = useAsyncCallback(() => onSubmit({
		title: template.title,
		options: template.options.map((option) => ({ label: option.label, correct: option.correct })),
		duration: template.durationMinutes * 60 + template.durationSeconds,
		position: template.position,
		category: template.category,
		audienceType: template.audienceType,
	}));

	const handleChoiceChange = useCallback((options) => {
		setTemplate((cp) => ({ ...cp, options }));
	}, [setTemplate]);

	const handleChange = useCallback((data) => {
		handleSubmitAsync.reset();
		setTemplate(data);
	}, [handleSubmitAsync]);

	return (
		<Form
			className="TemplateCreate h-75 flex-grow-1 d-flex flex-column justify-content-between form-light"
			data={template}
			onChange={handleChange}
			onSubmit={handleSubmitAsync.execute}
			schema={createTemplateSchema}
		>
			<ReallyPerfectScrollbar>
				<section className="flex-grow-1 overflow-hidden">
					<FormGroup>
						<Label className="text-secondary">{t('Templates.Create.category')}<i>({t('Templates.Create.optional')})</i></Label>
						<Field
							component={Input}
							name="category"
							placeholder={t('Templates.Create.egCategory')}
							type="text"
							value={template.category || ''}
						/>
						<FieldError name="category" />
					</FormGroup>
					<FormGroup>
						<Label className="text-secondary">{t('Templates.Create.title')}</Label>
						<Field
							component={Input}
							name="title"
							placeholder={t('Templates.Create.egTitle')}
							type="textarea"
							value={template.title}
						/>
						<FieldError name="title" />
					</FormGroup>
					<Label className="text-secondary">{t('Templates.Create.audience')}</Label>
					<FormGroup check>
						<Field
							component={Input}
							type="radio"
							name="audienceType"
							id="participantsAudience"
							value={VoteAudienceType.PARTICIPANT}
							checked={template.audienceType === VoteAudienceType.PARTICIPANT}
						/>
						<Label check for="participantsAudience" className="blue">
							{t('Templates.Create.participants')}
						</Label>
					</FormGroup>
					<FormGroup check>
						<Field
							component={Input}
							type="radio"
							name="audienceType"
							id="viewersAudience"
							value={VoteAudienceType.VIEWER}
							checked={template.audienceType === VoteAudienceType.VIEWER}
						/>
						<Label check for="viewersAudience" className="blue">
							{t('Templates.Create.viewers')}
						</Label>
					</FormGroup>
					<FormGroup className="mt-2">
						<Label className="text-secondary">{t('Templates.Create.options')}</Label>
						<OptionInput
							onChange={handleChoiceChange}
							value={template.options}
							disabled={false}
						/>
						<FieldError name="options" />
					</FormGroup>
					<FormGroup>
						<Label className="text-secondary">{t('Templates.Create.defaultDuration')}</Label>
						<Row className="align-items-center">
							<Col sm={3}>
								<Field
									component={Input}
									className="no-arrows"
									name="durationMinutes"
									type="number"
									min={1}
									max={60}
									value={template.durationMinutes}
								/>
							</Col>
							:
							<Col sm={3}>
								<Field
									component={Input}
									className="no-arrows"
									name="durationSeconds"
									type="number"
									min={0}
									max={60}
									value={template.durationSeconds}
								/>
							</Col>
						</Row>
						<FieldError name="durationMinutes" />
						<FieldError name="durationSeconds" />
					</FormGroup>
					<FormGroup tag="fieldset">
						<Label className="text-secondary">{t('Templates.Create.defaultPosition')}</Label>
						<Row>
							<Col sm={3}>
								<FormGroup check>
									<Field
										component={Input}
										name="position"
										id="positionRadioTop"
										type="radio"
										label={t('Templates.Create.top')}
										value="top"
										checked={template.position === 'top'}
									/>
									<Label check for="positionRadioTop">
										{t('Templates.Create.top')}
									</Label>
								</FormGroup>
							</Col>
							<Col sm={3}>
								<FormGroup check>
									<Field
										component={Input}
										name="position"
										id="positionRadioMid"
										type="radio"
										label={t('Templates.Create.mid')}
										value="mid"
										checked={template.position === 'mid'}
									/>
									<Label check for="positionRadioMid">
										{t('Templates.Create.mid')}
									</Label>
								</FormGroup>
							</Col>
							<Col sm={3}>
								<FormGroup check>
									<Field
										component={Input}
										name="position"
										id="positionRadioBot"
										type="radio"
										label={t('Templates.Create.bot')}
										value="bot"
										checked={template.position === 'bot'}
									/>
									<Label check for="positionRadioBot">
										{t('Templates.Create.bot')}
									</Label>
								</FormGroup>
							</Col>
						</Row>
					</FormGroup>
					{handleSubmitAsync.error && (
						<Alert color="danger">
							{t('Global.error')}
						</Alert>
					)}
				</section>
			</ReallyPerfectScrollbar>

			<footer className="d-flex w-100 align-items-center">
				<Button
					color="neutral-secondary"
					className="btn-pill mr-2"
					disabled={handleSubmitAsync.loading}
					onClick={onCancel}
				>
					{t('Templates.Create.cancel')}
				</Button>
				<FormSubmit
					color="primary"
					className="btn-pill"
					loading={handleSubmitAsync.loading}
				>
					{t('Templates.Create.create')}
				</FormSubmit>
			</footer>
		</Form>
	);
};

TemplateCreate.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	currentTemplate: PropTypes.shape({
		title: PropTypes.string.isRequired,
		defaultPosition: PropTypes.string.isRequired,
		defaultDuration: PropTypes.number.isRequired,
		category: PropTypes.string.isRequired,
		options: PropTypes.arrayOf(PropTypes.shape({
			_id: PropTypes.string,
			label: PropTypes.string.isRequired,
		})).isRequired,
		audienceType: PropTypes.string.isRequired,
	}),
};

TemplateCreate.defaultProps = {
	currentTemplate: null,
};
