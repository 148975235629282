import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTranslation } from 'react-i18next';

import RatioContainer from '../RatioContainer/RatioContainer';
import { minutes, seconds } from '../Player/helpers';
import { getThumbnail } from '../../lib/file';
import './Thumbnail.scss';
import { useValidateImage } from '../../lib/useValidateImage';

export const VideoThumbnail = ({ className, duration, video }) => {
	const { t } = useTranslation();
	const [videoDuration, setVideoDuration] = useState();
	const validateImage = useValidateImage();

	const handleLoadedMetadata = useCallback((event) => {
		setVideoDuration(event.target.duration);
	}, []);

	const durationValue = Math.floor(duration ?? video?.duration ?? videoDuration ?? 0);
	const src = getThumbnail(video);
	const isVideo = video.status !== 'ready';

	return (
		<RatioContainer
			className={
				clsx(
					'VideoThumbnail',
					className,
				)
			}
		>
			<div className="VideoThumbnail_container">
				{isVideo ? (
					<video
						onLoadedMetadata={handleLoadedMetadata}
						muted
						src={src}
					/>
				) : (
					<LazyLoadImage
						alt={t('Video.Thumbnail.videoThumbnail')}
						src={src}
						onError={validateImage}
					/>
				)}
				{
					!Number.isNaN(durationValue)
					&& Number.isFinite(durationValue)
					&& durationValue > 0
					&& (
						<span className="Player_duration">
							{minutes(durationValue)}:{seconds(durationValue)}
						</span>
					)
				}
			</div>
		</RatioContainer>
	);
};

VideoThumbnail.propTypes = {
	className: PropTypes.string,
	video: PropTypes.shape({
		duration: PropTypes.number,
		status: PropTypes.string,
		playbackId: PropTypes.string,
		thumbnailTimeCode: PropTypes.number,
		filename: PropTypes.string,
	}).isRequired,
	duration: PropTypes.number,
};

VideoThumbnail.defaultProps = {
	className: '',
	duration: undefined,
};
