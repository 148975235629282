import { authenticatedApi } from '../api';

const API_MODERATION_BAN_PATH = '/moderation/ban';

export const fetchManyByChannelId = async (channelId) => authenticatedApi.get(
	`${API_MODERATION_BAN_PATH}/byChannelId/${channelId}`,
);

export const banUserFromStudio = async (studioId, userId, until) => authenticatedApi.post(
	`${API_MODERATION_BAN_PATH}/studio/${studioId}`,
	{
		until,
		userId,
	},
);

export const unbanUser = async (userId, studioId) => authenticatedApi.delete(
	`${API_MODERATION_BAN_PATH}/studio/${studioId}`,
	{
		data: {
			userId,
		},
	},
);
