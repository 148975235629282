import { createSelector } from 'reselect';
import { STREAM_TYPE_LIVE, STREAM_TYPE_PREVIEW } from '../../lib/stream';

export const STATE_KEY = 'publications';

export const select = (state) => state[STATE_KEY];

export const selectIsPublicationStored = createSelector(
	select,
	(_, publication) => publication,
	(publicationsStreams = [], publication) => publicationsStreams
		.find(({ publications }) => (
			!!publications.find(({ producerId }) => publication.producerId === producerId)
		)),
);

export const selectLiveStreamPublication = createSelector(
	select,
	(publicationsStreams = []) => publicationsStreams
		.find(({ streamType }) => streamType === STREAM_TYPE_LIVE),
);

export const selectParticipantStreamPublications = createSelector(
	select,
	(publicationsStreams = []) => publicationsStreams
		.filter(({ streamType }) => streamType === STREAM_TYPE_PREVIEW),
);
