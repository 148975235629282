import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as studioSchedulesApi from '../../api/studio/schedule';
import { axiosMutationWrapper, axiosQueryWrapper } from '../utils/axios-wrapper';

export const STUDIO_SCHEDULES_QUERY_KEYS = {
	fetchSudioSchedules: ({ channelId, state, itemsPerPage, currentPage }) => ['api', 'studio', 'schedules', 'fetch', channelId, state, itemsPerPage, currentPage].filter((item) => !!item),
	fetchStudioScheduleById: (id) => ['api', 'studio', 'schedule', 'fetch', id],
	fetchManyStudioScheduleSoon: () => ['api', 'studio', 'schedules', 'soon', 'fetch'],
	fetchCalendarSchedule: ({ channelId, startDate, endDate }) => ['api', 'studio', 'schedules', 'calendar', channelId, startDate, endDate],
};

export const useFetchStudioSchedules = (channelId, state, itemsPerPage, currentPage) => useQuery(
	STUDIO_SCHEDULES_QUERY_KEYS.fetchSudioSchedules({ channelId, state, itemsPerPage, currentPage }),
	axiosQueryWrapper(
		studioSchedulesApi.fetchStudioSchedules, channelId, state, itemsPerPage, currentPage,
	),
	{
		enabled: !!channelId,
	},
);

export const useFetchStudioScheduleById = (id) => useQuery(
	STUDIO_SCHEDULES_QUERY_KEYS.fetchStudioScheduleById(id),
	axiosQueryWrapper(studioSchedulesApi.fetchStudioScheduleById, id),
	{
		enabled: !!id,
	},
);

export const useFetchCalendarStudioSchedule = (channelId, startDate, endDate) => useQuery(
	STUDIO_SCHEDULES_QUERY_KEYS.fetchCalendarSchedule({ channelId, startDate, endDate }),
	axiosQueryWrapper(studioSchedulesApi.fetchCalendarStudioSchedule, channelId, startDate, endDate),
	{
		enabled: !!channelId && !!startDate && !!endDate,
		staleTime: 0,
	},
);

export const useFetchManyStudioScheduleSoon = () => useQuery(
	STUDIO_SCHEDULES_QUERY_KEYS.fetchManyStudioScheduleSoon(),
	axiosQueryWrapper(studioSchedulesApi.fetchManyStudioScheduleSoon),
);

export const useCancelStudioSchedule = () => {
	const queryClient = useQueryClient();
	return useMutation(
		axiosMutationWrapper(studioSchedulesApi.cancelStudioSchedule),
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries(
					STUDIO_SCHEDULES_QUERY_KEYS.fetchSudioSchedules(
						{ channelId: data.owner._id },
					),
				);
			},
		},
	);
};

export const useUpdateStudioSchedule = () => {
	const queryClient = useQueryClient();
	return useMutation(
		axiosMutationWrapper(studioSchedulesApi.updateStudioSchedule), {
			onSuccess: (data) => {
				queryClient.invalidateQueries(STUDIO_SCHEDULES_QUERY_KEYS.fetchSudioSchedules(
					{ channelId: data.owner._id },
				));
				queryClient.invalidateQueries(STUDIO_SCHEDULES_QUERY_KEYS
					.fetchStudioScheduleById(data._id));
			},
		},
	);
};

export const useCreateStudioSchedule = () => {
	const queryClient = useQueryClient();
	return useMutation(
		axiosMutationWrapper(studioSchedulesApi.createStudioSchedule), {
			onSuccess: (data) => {
				queryClient.invalidateQueries(STUDIO_SCHEDULES_QUERY_KEYS.fetchSudioSchedules(
					{ channelId: data.owner._id },
				));
			},
		},
	);
};

export const useUpdateStudioScheduleTime = () => useMutation(
	axiosMutationWrapper(studioSchedulesApi.updateStudioScheduleTime),
);
