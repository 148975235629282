import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import ViewForbidden from '../../views/Forbidden/Forbidden';
import { useAuthentication } from './Authentication';

const RouteIfAuthenticated = ({
	component: Component,
	...routeProps
}) => {
	const authentication = useAuthentication();

	const render = (props) => {
		if (authentication.isPending) return null; // wait for first getUser
		if (!authentication.isLoggedIn) {
			return (
				<ViewForbidden />
			);
		}
		// eslint-disable-next-line react/jsx-props-no-spreading
		return <Component {...props} />;
	};

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <Route {...routeProps} render={render} />;
};

RouteIfAuthenticated.propTypes = {
	component: PropTypes.elementType.isRequired,
};

export default RouteIfAuthenticated;
