import React from 'react';
import { useTranslation } from 'react-i18next';
import { Nickname } from '../Channel/Nickname/Nickname';

export const FloatingPiP = () => {
	const { t } = useTranslation();

	return (
		<section className="FloatingPiP h-100 d-flex flex-column overflow-hidden flex-grow-1">
			<div className="w-100 mt-4">
				<p>{t('FloatingPiP.FloatingPiP.showNicknames')}</p>
				<Nickname />
			</div>
		</section>
	);
};
