import PropTypes from 'prop-types';
import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { usePagination } from './Provider';

export const PaginationPageLine = ({ disabled }) => {
	const {
		currentPage,
		pageCount,
		canNextPage,
		canPrevPage,
		goNextPage,
		goPrevPage,
		goFirstPage,
		goLastPage,
	} = usePagination();

	return pageCount ? (
		<Pagination>
			<PaginationItem disabled={disabled || !canPrevPage}>
				<PaginationLink first onClick={goFirstPage} className="bg-light content-light border-0" />
			</PaginationItem>
			<PaginationItem disabled={disabled || !canPrevPage}>
				<PaginationLink previous onClick={goPrevPage} className="bg-light content-light border-0 me-0" />
			</PaginationItem>
			<PaginationItem className="d-flex align-items-center text-secondary px-2">
				{currentPage} / {pageCount}
			</PaginationItem>
			<PaginationItem disabled={disabled || !canNextPage}>
				<PaginationLink next onClick={goNextPage} className="bg-light content-light border-0" />
			</PaginationItem>
			<PaginationItem disabled={disabled || !canNextPage}>
				<PaginationLink last onClick={goLastPage} className="bg-light content-light border-0" />
			</PaginationItem>
		</Pagination>
	) : null;
};

PaginationPageLine.propTypes = {
	disabled: PropTypes.bool,
};

PaginationPageLine.defaultProps = {
	disabled: false,
};
