import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
	FormGroup,
	Input,
	Label,
} from 'reactstrap';
import Slider from 'rc-slider';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-unresolved
import { useDrawer, useMixing } from '@technomiam/react-video';
import './DrawTab.scss';
import { ButtonPillOutline } from '../Button';
import { LocalDrawer } from '../Channel/ChannelButtonsPanel/LocalUserMedia/LocalDrawer';

export const DrawTab = ({ connectedUsers }) => {
	const { t } = useTranslation();
	const { setShowCanvas } = useDrawer();
	const { channelMixerStatus } = useMixing();
	const currentAllowedUser = channelMixerStatus?.allowedUserToDraw || '';
	const {
		isConnected,
		notifyToggleDrawings,
		notifyClearAllDrawings,
		notifyToggleDrawingsBackground,
	} = useMixing();
	const [allowedUserToDraw, setAllowedUserToDraw] = useState(currentAllowedUser);
	const [backgroundColor, setBackgroundColor] = useState('#FEC82E');
	const [backgroundAlpha, setBackgroundAlpha] = useState(50);
	const [showBackground, setShowBackground] = useState(false);

	const handleClickClearAll = () => {
		if (isConnected) notifyClearAllDrawings();
	};

	const handleSelectGuest = (e) => {
		const user = e.target.value;
		setAllowedUserToDraw(user);
		if (isConnected) notifyToggleDrawings(user);
	};

	useEffect(() => {
		if (!isConnected) return;
		notifyToggleDrawingsBackground({
			backgroundColor,
			backgroundAlpha: backgroundAlpha / 100,
			isEnabled: showBackground,
		});
	}, [
		isConnected,
		notifyToggleDrawingsBackground,
		backgroundColor,
		backgroundAlpha,
		showBackground,
	]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleChangeBackgroundColor = useCallback(debounce((hexColor) => {
		setBackgroundColor(hexColor);
	}, 500), []);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleChangeBackgroundAlpha = useCallback(debounce((alphaValue) => {
		setBackgroundAlpha(alphaValue);
	}, 500), []);

	const handleToggleBackground = () => {
		setShowBackground((prevState) => !prevState);
	};

	useEffect(() => {
		setAllowedUserToDraw(currentAllowedUser);
	}, [currentAllowedUser]);

	useEffect(() => {
		setShowCanvas(true);
		return () => { setShowCanvas(false); };
	}, [setShowCanvas]);

	return (
		<section className="DrawTab h-100 d-flex flex-column justify-content-between overflow-hidden flex-grow-1">
			<div className="w-100">
				<LocalDrawer />
				<div className="mt-4">
					<FormGroup>
						<Label for="select" className="text-secondary font-size-sm"><strong>{t('DrawTab.DrawTab.participants')}</strong></Label>
						<Input
							value={allowedUserToDraw}
							onChange={handleSelectGuest}
							type="select"
							id="selectGuests"
						>
							<option value="">{t('DrawTab.DrawTab.none')}</option>
							<option value="all">{t('DrawTab.DrawTab.all')}</option>
							{connectedUsers.length > 0 && connectedUsers.map((user) => (
								<option key={user._id} value={user._id}>{user.nickname}</option>
							))}
						</Input>
					</FormGroup>
				</div>
				<div className="mt-4">
					<strong className="text-secondary font-size-sm d-block">{t('DrawTab.DrawTab.background')}</strong>
					<div className="d-flex align-items-stretch font-size-sm text-secondary mt-2">
						<FormGroup className="">
							<Label for="enableBackground">
								{t('DrawTab.DrawTab.enable')}<br />
								<Input id="enableBackground" className="m-0 mt-2" onChange={handleToggleBackground} type="checkbox" checked={showBackground} />
							</Label>
						</FormGroup>
						<FormGroup className="ml-3 pl-3 border-left border-light">
							<Label className="d-block" for="selectColor">{t('DrawTab.DrawTab.color')}</Label>
							<input
								className="DrawTab_backgroundColor"
								value={backgroundColor}
								onChange={(e) => handleChangeBackgroundColor(e.target.value)}
								type="color"
								id="selectColor"
							/>
						</FormGroup>
						<FormGroup className="ml-3 pl-3 border-left border-light">
							<Label className="d-block" for="selectAlpha">{t('DrawTab.DrawTab.transparency')}</Label>
							<Slider
								className="rc-slider-primary ml-auto mt-3 flex-shrink-0"
								onChange={handleChangeBackgroundAlpha}
								step={1}
								max={100}
								min={0}
								startPoint={backgroundAlpha}
								trackStyle={{ opacity: 0 }}
							/>
						</FormGroup>
					</div>
				</div>
				<footer className="mt-3">
					<ButtonPillOutline color="secondary" className="" onClick={handleClickClearAll}>
						{t('DrawTab.DrawTab.clear')}
					</ButtonPillOutline>
				</footer>
			</div>
		</section>
	);
};

DrawTab.propTypes = {
	connectedUsers: PropTypes.arrayOf(PropTypes.shape({})),
};

DrawTab.defaultProps = {
	connectedUsers: [],
};
