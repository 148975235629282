import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button } from 'reactstrap';
import { FaCheck } from 'react-icons/fa';
import Switch from 'rc-switch';
import { useTranslation } from 'react-i18next';
import {
	useMixing,
	usePipLayout,
} from '@technomiam/react-video';

import './ChannelAutomaticSwitch.scss';

const LOCAL_STORAGE_AUTOMATIC_SWITCH_KEY = 'beeyou.automaticSwitch';

const getSavedAutomaticSwitch = () => {
	const stringValue = localStorage.getItem(LOCAL_STORAGE_AUTOMATIC_SWITCH_KEY);
	switch (stringValue) {
	case 'true': return true;
	case 'false': return false;
	default: return undefined;
	}
};

const saveAutomaticSwitch = (automaticSwitch) => localStorage.setItem(
	LOCAL_STORAGE_AUTOMATIC_SWITCH_KEY,
	automaticSwitch,
);

const DEFAULT_AUTOMATIC_SWITCH = true;

const ChannelAutomaticSwitchOption = ({
	checked,
	label,
	onChange,
	value,
}) => (
	<div role="menuitem">
		<a
			className={clsx('dropdown-item d-flex align-items-center', { checked })}
			href="#/"
			onClick={() => {
				onChange({ target: { value } });
			}}
		>
			{checked && (
				<FaCheck className="mr-1 text-primary" size={12} style={{ marginLeft: '-1rem' }} />
			)}
			{label}
		</a>
	</div>
);

ChannelAutomaticSwitchOption.propTypes = {
	checked: PropTypes.bool,
	label: PropTypes.node.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.bool.isRequired,
};

ChannelAutomaticSwitchOption.defaultProps = {
	checked: false,
};

export const ChannelAutomaticSwitch = ({ className }) => {
	const { t } = useTranslation();
	const {
		channelMixerStatus,
		isConnected,
		notifyAutomaticSwitchChange,
	} = useMixing();
	const { displayMode } = usePipLayout();

	const channelMixerStatusAutomaticSwitch = channelMixerStatus?.automaticSwitch;

	const [isEnabled, setIsEnabled] = useState(() => {
		const savedAutomaticSwitch = getSavedAutomaticSwitch();

		if (typeof savedAutomaticSwitch === 'boolean') {
			return savedAutomaticSwitch;
		}

		if (typeof channelMixerStatusAutomaticSwitch === 'boolean') {
			return channelMixerStatusAutomaticSwitch;
		}

		return DEFAULT_AUTOMATIC_SWITCH;
	});

	useEffect(() => {
		if (typeof channelMixerStatusAutomaticSwitch !== 'boolean') return;
		setIsEnabled(channelMixerStatusAutomaticSwitch);
	}, [channelMixerStatusAutomaticSwitch]);

	const handleClick = (value) => {
		setIsEnabled(value);
		notifyAutomaticSwitchChange(value);
		saveAutomaticSwitch(value);
	};

	const calledOnce = useRef(false);
	// Initialize once channel mode with saved value in localstorage
	useEffect(() => {
		if (calledOnce.current) return;
		if (!isConnected || typeof channelMixerStatusAutomaticSwitch !== 'boolean') return;
		calledOnce.current = true;
		const savedAutomaticSwitch = getSavedAutomaticSwitch();
		if (typeof savedAutomaticSwitch !== 'boolean') return;
		if (channelMixerStatusAutomaticSwitch !== savedAutomaticSwitch) {
			notifyAutomaticSwitchChange(savedAutomaticSwitch);
		}
	}, [channelMixerStatusAutomaticSwitch, isConnected, notifyAutomaticSwitchChange]);

	const disableAutomaticSwitch = ['MOSAIC_SIX', 'MOSAIC_TWELVE', 'MOSAIC_SIXTEEN'].includes(displayMode);

	return (
		<Button
			className="ChannelAutomaticSwitch d-flex flex-shrink-0 btn-transition-none align-items-center bg-transparent"
			color={className}
			onClick={() => handleClick(!isEnabled)}
			tag="span"
			title={isEnabled ? t('ChannelAutomaticSwitch.autoEnabledDescription')
				: t('ChannelAutomaticSwitch.autoDisabledDescription')}
			style={{
				width: '85px',
			}}
			disabled={disableAutomaticSwitch}
		>
			<Switch
				className="switch-small switch-very-small rotate-90"
				checked={isEnabled}
			/>
			<div
				className="d-flex flex-column justify-content-center align-items-start flex-grow-1"
				style={{ transform: 'translateX(-10%)' }}
			>
				{isEnabled && !disableAutomaticSwitch ? (
					<>
						<span className="font-weight-bold">MODE</span>
						<span className="channelSwitchMode font-weight-bold">{t('ChannelAutomaticSwitch.auto')}</span>
					</>
				)
					: (
						<>
							<span className="channelSwitchMode font-weight-bold">{t('ChannelAutomaticSwitch.manual')}</span>
							<span className="font-weight-bold">MODE</span>
						</>
					)}
			</div>
		</Button>
	);
};

ChannelAutomaticSwitch.propTypes = {
	className: PropTypes.string,
};

ChannelAutomaticSwitch.defaultProps = {
	className: '',
};
