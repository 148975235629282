import { batch } from 'react-redux';

import {
	startRecording,
	stopRecording,
} from '../../api/ws/mixing';
import {
	soupSession,
	publishTrack,
	unpublishTrack,
	updateTrack,
} from '../../api/soup';
import Track from '../../lib/track';
import {
	addTrackAction,
	removeTrackAction,
} from './tracks';
import {
	addStreamTrackAction,
	removeStreamTrackAction,
	removeChannelStreamsAction,
	updateStreamAction,
} from './channelStreams';
import {
	addStreamPublicationAction,
	clearStreamPublicationAction,
	removeStreamPublicationAction,
	unsubscribePublicationsAction,
} from './publications';
import { selectChannelOtherStreams, selectIsPublicationChannelStreamStored } from '../selectors/channelStreams';
import { selectIsPublicationStored } from '../selectors/publications';

const ACTION_KEY = 'channelMixing';

/**
 * actions
 */
const START_RECORDING = `${ACTION_KEY}/startRecording`;
const STOP_RECORDING = `${ACTION_KEY}/stopRecording`;
const PUBLISH_TRACK = `${ACTION_KEY}/publishTrack`;
const UNPUBLISH_TRACK = `${ACTION_KEY}/unpublishTrack`;
const UPDATE_TRACK = `${ACTION_KEY}/updateTrack`;

const shouldSkipPublication = (publication, peerId) => {
	const { appData = {} } = publication;
	const { peerExceptions, peerReceiver } = appData;

	// The publication is dedicated to a single different peer
	if (peerReceiver && peerReceiver !== peerId) return true;

	// The publication should not be subscribed by this peer
	if (peerExceptions && peerExceptions.includes(peerId)) return true;

	return false;
};

export const addSoupListenersAction = () => (dispatch, getState) => {
	const soup = soupSession();

	const onTrackPublished = (publication) => {
		const { peerId } = publication;

		if (shouldSkipPublication(publication, soup.peerId)) return;

		if (peerId !== soup.peerId) {
			dispatch(addStreamPublicationAction(publication));
		} else {
			// For own publication, we don't add the publication
			// but we still add a "fake" streamTrack to the store
			// with producer ids instead of track ids.
			// It allows to add the stream in guests sources
			dispatch(addStreamTrackAction({
				...publication,
				own: true,
				mediaStreamTrack: { id: publication.producerId },
			}));
		}
	};

	const onTrackUnpublished = (publication) => {
		const { peerId } = publication;

		if (peerId === soup.peerId) {
			// For own publication, we just remove the "fake" streamTrack
			// with producer ids instead of track ids.
			// It allows to remove the stream from guests sources
			dispatch(removeStreamTrackAction({
				...publication,
				mediaStreamTrack: { id: publication.producerId },
			}));
		} else {
			dispatch(removeStreamPublicationAction(publication));
		}
	};

	const onTrackSubscribed = (subscription) => {
		const { mediaStreamTrack } = subscription;
		const track = new Track(mediaStreamTrack);
		dispatch(addTrackAction(track));
		dispatch(addStreamTrackAction(subscription));
	};

	const onTrackUnsubscribed = (subscription) => {
		const { mediaStreamTrack } = subscription;
		const track = new Track(mediaStreamTrack);
		dispatch(removeTrackAction(track));
		dispatch(removeStreamTrackAction(subscription));
	};

	const onTrackUpdated = (publication) => {
		if (shouldSkipPublication(publication, soup.peerId)) {
			/* If the appData changed, maybe it's time to unsubscribe a previously
			subscribed publication */
			const isSubscribed = !!soup.consumptions.get(publication.producerId);
			if (isSubscribed) {
				dispatch(unsubscribePublicationsAction([publication]));
			}

			const isPublicationStored = selectIsPublicationStored(getState(), publication);
			if (isPublicationStored) {
				onTrackUnpublished(publication);
			}
		} else {
			onTrackPublished(publication);
		}

		const isPublicationChannelStreamStored = selectIsPublicationChannelStreamStored(
			getState(),
			publication,
		);
		if (isPublicationChannelStreamStored) {
			dispatch(updateStreamAction(publication));
		}
	};

	const onConnected = async () => {
		dispatch(clearStreamPublicationAction());

		batch(() => {
			soup.publications.forEach((publication) => {
				onTrackPublished(publication);
			});
		});
	};

	const onDisconnected = async () => {
		const channelStreams = selectChannelOtherStreams(getState(), { hashtag: soup.hashtag });
		channelStreams.forEach((stream) => {
			stream.tracks.forEach((trackId) => {
				const track = new Track({ id: trackId });
				dispatch(removeTrackAction(track));
			});
		});
		dispatch(clearStreamPublicationAction());
		dispatch(removeChannelStreamsAction({ hashtag: soup.hashtag }));
	};

	soup.on('trackPublished', onTrackPublished);
	soup.on('trackUnpublished', onTrackUnpublished);
	soup.on('trackSubscribed', onTrackSubscribed);
	soup.on('trackUnsubscribed', onTrackUnsubscribed);
	soup.on('trackUpdated', onTrackUpdated);
	soup.on('connected', onConnected);
	soup.on('disconnected', onDisconnected);

	if (soup.connected) onConnected();

	return function removeSoupListeners() {
		onDisconnected();
		soup.off('trackPublished', onTrackPublished);
		soup.off('trackUnpublished', onTrackUnpublished);
		soup.off('trackSubscribed', onTrackSubscribed);
		soup.off('trackUnsubscribed', onTrackUnsubscribed);
		soup.off('trackUpdated', onTrackUpdated);
		soup.off('connected', onConnected);
		soup.off('disconnected', onDisconnected);
	};
};

export const publishTrackAction = (
	track,
	streamId,
	user,
	preventLarsens = false,
	alphaColor,
	trackEncodings,
) => ({
	type: PUBLISH_TRACK,
	payload: async () => {
		const { sub: userId, picture: avatar, nickname } = user;
		return publishTrack(
			track,
			{ preventLarsens, streamId, user: { avatar, nickname, userId }, alphaColor },
			trackEncodings,
		);
	},
});

export const unpublishTrackAction = (track) => ({
	type: UNPUBLISH_TRACK,
	payload: unpublishTrack(track),
});

export const startRecordingAction = (hashtag, durationMinutes) => ({
	type: START_RECORDING,
	payload: startRecording(hashtag, durationMinutes),
});

export const stopRecordingAction = (hashtag) => ({
	type: STOP_RECORDING,
	payload: stopRecording(hashtag),
});

export const updateTrackAction = (track, appdata) => ({
	type: UPDATE_TRACK,
	payload: updateTrack(track, appdata),
});
