import React from 'react';
import PropTypes from 'prop-types';
import { PaginationItemLine } from './ItemLine';
import { PaginationPageLine } from './PageLine';

export const Pagination = ({ breakpoint, className, isDisabled, minimal }) => (
	<div className={`d-flex align-items-center justify-content-center justify-content-${breakpoint}-between flex-wrap ${minimal ? 'text-dark' : ''} ${className}`}>
		<div className="d-flex align-items-center">
			<PaginationItemLine isDisabled={isDisabled} />
		</div>
		<div className="d-flex align-items-center ms-lg-4 ms-0">
			<PaginationPageLine isDisabled={isDisabled} />
		</div>
	</div>
);

Pagination.propTypes = {
	breakpoint: PropTypes.string,
	className: PropTypes.string,
	isDisabled: PropTypes.bool,
	minimal: PropTypes.bool,
};

Pagination.defaultProps = {
	breakpoint: 'lg',
	className: '',
	isDisabled: false,
	minimal: false,
};
