import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import * as publicLivesApi from '../../api/public/live';
import { StudioStatus } from '../../components/StudioSchedule/helper';
import { axiosQueryWrapper } from '../utils/axios-wrapper';

const PUBLIC_LIVE_QUERY_KEYS = {
	fetchLives: (channelId) => ['api', 'public', 'lives', 'channel', 'fetch', { channelId }],
	fetchOneNextLive: (channelId) => ['api', 'public', 'nextLive', 'channel', 'fetch', { channelId }],
	fetchComingUp: () => ['api', 'public', 'lives', 'comingUp'],
};

const hours = (n = 1) => 60 * 60 * 1000 * n;

export const useFetchPublicLivesByChannelId = (channelId) => useQuery(
	PUBLIC_LIVE_QUERY_KEYS.fetchLives(channelId),
	axiosQueryWrapper(publicLivesApi.fetchAllComingUpLiveByChannelId, channelId),
	{
		enabled: !!channelId,
		select: (lives) => {
			if (!lives) return [];
			const now = new Date();
			const time = now.getTime();
			const timeLess24h = time - hours(24);
			const sortedLives = ([...lives]).sort((a, b) => {
				const aTime = new Date(a.startAt).getTime();
				const bTime = new Date(b.startAt).getTime();

				// Running lives are first
				const aIsRunning = a.status === StudioStatus.RUNNING ? 1 : 0;
				const bIsRunning = b.status === StudioStatus.RUNNING ? 1 : 0;
				if ((bIsRunning - aIsRunning) !== 0) {
					return bIsRunning - aIsRunning;
				}

				// lives before last 24h are last
				const aIsAfterLast4h = aTime > timeLess24h ? 1 : 0;
				const bIsAfterLast4h = bTime > timeLess24h ? 1 : 0;
				if ((bIsAfterLast4h - aIsAfterLast4h) !== 0) {
					return bIsAfterLast4h - aIsAfterLast4h;
				}

				// sort lives before last 24h descending
				if (!aIsAfterLast4h) {
					return bTime - aTime;
				}

				const aTimeFromNow = Math.abs(aTime - time);
				const bTimeFromNow = Math.abs(bTime - time);

				// sort lives after last 24h from closest to farest
				return aTimeFromNow - bTimeFromNow;
			});
			return sortedLives;
		},
	},
);

export const useFetchOneNextLiveByChannelId = (channelId) => useQuery(
	PUBLIC_LIVE_QUERY_KEYS.fetchOneNextLive(channelId),
	axiosQueryWrapper(publicLivesApi.fetchOneNextLiveByChannelId, channelId),
	{ enabled: !!channelId },
);

export const useInvalidateNextLiveByChannelId = (channelId) => {
	const queryClient = useQueryClient();
	return useCallback(() => {
		queryClient.invalidateQueries(PUBLIC_LIVE_QUERY_KEYS.fetchOneNextLive(channelId));
	}, [queryClient, channelId]);
};

export const useFetchComingUpLive = () => useQuery(
	PUBLIC_LIVE_QUERY_KEYS.fetchComingUp(),
	axiosQueryWrapper(publicLivesApi.fetchComingUpLive),
	{ refetchInterval: 1000 * 60 * 10 /* 10 minutes */ },
);

export const useHandleLiveStatusChange = () => {
	const queryClient = useQueryClient();

	return useCallback(() => {
		queryClient.invalidateQueries(PUBLIC_LIVE_QUERY_KEYS.fetchComingUp);
	}, [queryClient]);
};
