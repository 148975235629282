import { authenticatedApi } from './api';

export const API_SURVEYS_PATH = '/surveys';

export const fetchSurveyTemplates = (
	isArchived,
	itemsPerPage,
	currentPage,
	sortValue,
) => authenticatedApi.get(
	`${API_SURVEYS_PATH}`,
	{
		params: {
			isArchived,
			itemsPerPage,
			currentPage,
			sortValue,
		},
	},
);

export const focusSurveyTemplate = (surveyTemplateId) => authenticatedApi.post(`${API_SURVEYS_PATH}/focus/${surveyTemplateId}`);

export const createSurveyTemplate = (surveyTemplateData) => authenticatedApi.post(`${API_SURVEYS_PATH}`, surveyTemplateData);

export const updateSurveyTemplate = (surveyTemplateId, surveyTemplateData) => authenticatedApi.put(`${API_SURVEYS_PATH}/${surveyTemplateId}`, surveyTemplateData);

export const deleteSurveyTemplate = (surveyTemplateId) => authenticatedApi.delete(`${API_SURVEYS_PATH}/${surveyTemplateId}`);

export const restoreSurveyTemplate = (surveyTemplateId) => authenticatedApi.put(`${API_SURVEYS_PATH}/${surveyTemplateId}/restore`);

export const getSurveyBySurveyTemplateAndChannel = (templateId, channelId) => authenticatedApi.get(`${API_SURVEYS_PATH}/overview/${templateId}/${channelId}`);

export const beginSurvey = (surveyTemplateCode) => authenticatedApi.post(`${API_SURVEYS_PATH}/begin/${surveyTemplateCode}`);

export const answerQuestion = (surveyId, questionId, answer) => authenticatedApi.put(`${API_SURVEYS_PATH}/${surveyId}/answer/${questionId}`, answer);

export const endSurvey = (surveyId) => authenticatedApi.put(`${API_SURVEYS_PATH}/end/${surveyId}`);

export const getReport = (surveyTemplateId) => authenticatedApi.get(`${API_SURVEYS_PATH}/report/${surveyTemplateId}`);

export const getSurvetTemplateParticipants = (surveyTemplateId) => authenticatedApi.get(`${API_SURVEYS_PATH}/people/${surveyTemplateId}`);

export const getReportCSV = (surveyTemplateId) => authenticatedApi.get(`${API_SURVEYS_PATH}/csv/report/${surveyTemplateId}`);
