import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Input } from 'reactstrap';
import { useVote } from '../Provider';

const SIZE = 25;
export const TemplateSelect = ({ onChange, templateId }) => {
	const { voteTemplates, fetchVoteTemplates } = useVote();

	useEffect(() => { fetchVoteTemplates(); }, [fetchVoteTemplates]);

	return (
		<div className="form-light">
			<Input
				name="template"
				type="select"
				onChange={(e) => onChange(voteTemplates.data.find(
					(template) => template._id === e.target.value,
				))}
				value={templateId || false}
			>
				<option value="">--</option>
				{
					voteTemplates?.data?.map((template) => (
						<option
							key={template._id}
							value={template._id}
						>
							{template.title.length > SIZE ? `${template.title.substring(0, SIZE - 1)}...` : template.title }
						</option>
					))
				}
			</Input>
		</div>
	);
};

TemplateSelect.propTypes = {
	onChange: PropTypes.func.isRequired,
	templateId: PropTypes.string,
};

TemplateSelect.defaultProps = {
	templateId: null,
};
