import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import clsx from 'clsx';

export const ResourceAccessCard = ({
	className,
	children,
}) => (
	<Card className={clsx('text-black', className)}>
		<CardBody className="text-center">
			{children}
		</CardBody>
	</Card>
);

ResourceAccessCard.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
};

ResourceAccessCard.defaultProps = {
	className: '',
};
