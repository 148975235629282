import React, { useMemo, useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSurveyTemplate } from './SurveyTemplateProvider';

import * as surveyApi from '../../../api/survey';

const SurveyContext = React.createContext();
export const useSurvey = () => useContext(SurveyContext);

export const SurveyProvider = ({ children }) => {
	const { focusSurveyTemplate } = useSurveyTemplate();

	const [chosenParticipant, setChosenParticipant] = useState(null);
	const [survey, setSurvey] = useState();
	const [isOverviewSurveyOpen, setOverviewSurveyOpen] = useState(false);

	const getSurveyBySurveyTemplateAndChannel = useCallback(async (focusTemplateId, channelId) => {
		const { data } = await surveyApi.getSurveyBySurveyTemplateAndChannel(
			focusTemplateId, channelId,
		);
		return data;
	}, []);

	const beginSurvey = useCallback(async (surveyTemplateCode) => {
		const { data } = await surveyApi.beginSurvey(surveyTemplateCode);
		if (data) {
			setSurvey(data);
		}
	}, []);

	const answerQuestion = useCallback(async (surveyId, questionId, answer) => {
		const { data } = await surveyApi.answerQuestion(surveyId, questionId, answer);
		return data;
	}, []);

	const endSurvey = useCallback(async (surveyId) => {
		const { data } = await surveyApi.endSurvey(surveyId);
		return data;
	}, []);

	const getReportCSV = useCallback(async (surveyTemplateId) => {
		const { data } = await surveyApi.getReportCSV(surveyTemplateId);
		return data;
	}, []);

	const handleGetSurvey = useCallback(async (channelId) => {
		if (focusSurveyTemplate) {
			const surveyFromResponse = await getSurveyBySurveyTemplateAndChannel(
				focusSurveyTemplate._id, channelId,
			);
			setSurvey(surveyFromResponse);
		}
	}, [focusSurveyTemplate, getSurveyBySurveyTemplateAndChannel, setSurvey]);

	const handleAnswerQuestion = useCallback(async (questionId, answer) => {
		const question = survey.questions.find((q) => q._id === questionId);
		if (question) {
			if (question.type === 'MULTICHOICE') {
				setSurvey({ ...survey,
					questions: survey.questions.map(
						(q) => (q._id === questionId ? { ...q, answer: { optionIndex: answer } } : { ...q }),
					),
				});
				await answerQuestion(survey._id, questionId, { optionIndex: answer });
			} else {
				setSurvey({ ...survey,
					questions: survey.questions.map(
						(q) => (q._id === questionId ? { ...q, answer: { text: answer } } : { ...q }),
					),
				});
				await answerQuestion(survey._id, questionId, { text: answer });
			}
		}
	}, [survey, answerQuestion]);

	const handleEndSurvey = useCallback(async () => {
		setSurvey({ ...survey, completed: true });
		await endSurvey(survey._id);
	}, [survey, endSurvey]);

	const handleOpenOverviewSurvey = useCallback((channel) => {
		handleGetSurvey(channel._id);
		setChosenParticipant(channel);
		setOverviewSurveyOpen(true);
	}, [handleGetSurvey, setOverviewSurveyOpen]);

	const handleCloseOverviewSurvey = useCallback(() => {
		setOverviewSurveyOpen(false);
		setChosenParticipant(null);
		setSurvey(null);
	}, [setSurvey, setOverviewSurveyOpen]);

	const context = useMemo(() => ({
		chosenParticipant,
		isOverviewSurveyOpen,
		handleOpenOverviewSurvey,
		handleCloseOverviewSurvey,
		getReportCSV,
		beginSurvey,
		handleAnswerQuestion,
		handleEndSurvey,
		survey,
		setSurvey,
	}), [
		chosenParticipant,
		isOverviewSurveyOpen,
		handleOpenOverviewSurvey,
		handleCloseOverviewSurvey,
		getReportCSV,
		beginSurvey,
		handleAnswerQuestion,
		handleEndSurvey,
		survey,
		setSurvey,
	]);

	return (
		<SurveyContext.Provider value={context}>
			{children}
		</SurveyContext.Provider>
	);
};

SurveyProvider.propTypes = {
	children: PropTypes.node,
};

SurveyProvider.defaultProps = {
	children: undefined,
};
