import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { FaPlayCircle } from 'react-icons/fa';
import { Button, ListGroupItem } from 'reactstrap';
import { TemplateMenu } from './Menu';

export const TemplateHistoryItem = ({
	template,
	onTemplatePreviewClick,
	onDeleteTemplateClick,
	onDuplicateClick,
	minimal,
}) => {
	const [isHover, setIsHover] = useState(true);
	const handleMouseEnter = useCallback(() => { setIsHover(true); }, [setIsHover]);
	const handleMouseLeave = useCallback(() => { setIsHover(true); }, [setIsHover]);

	const [isOptionsOpen, setIsOptionsOpen] = useState(false);
	const toggleIsOptionsOpen = useCallback(
		() => setIsOptionsOpen((iO) => !iO),
		[setIsOptionsOpen],
	);

	return (
		<ListGroupItem
			className="rounded-0 d-flex justyfiy-content-between align-items-center pl-3 pr-1 bg-light content-light mt-2"
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<div className="w-100">
				<small className="content-light-50">{template.title}</small>
			</div>
			<div className="d-flex align-items-center">
				{!minimal && (
					<Button
						className={`btn-pill d-30 p-0 mr-1 ${isHover ? 'visible' : 'invisible'}`}
						color="neutral-secondary"
						onClick={() => onTemplatePreviewClick(template)}
					>
						<FaPlayCircle size={14} className="content-light-50" />
					</Button>
				)}
				<TemplateMenu
					onDelete={onDeleteTemplateClick}
					onDuplicate={onDuplicateClick}
					template={template}
					isOptionsOpen={isOptionsOpen}
					toggleIsOptionsOpen={toggleIsOptionsOpen}
					isVisible={isHover || isOptionsOpen}
				/>
			</div>
		</ListGroupItem>
	);
};

TemplateHistoryItem.propTypes = {
	template: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
	}).isRequired,
	onTemplatePreviewClick: PropTypes.func,
	onDeleteTemplateClick: PropTypes.func.isRequired,
	onDuplicateClick: PropTypes.func.isRequired,
	minimal: PropTypes.bool,
};

TemplateHistoryItem.defaultProps = {
	onTemplatePreviewClick: undefined,
	minimal: false,
};
