import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
	Alert,
	Row,
	TabPane,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { PostInput } from '../../../Channel/Posts/Input';
import { useCreatePost } from '../../../../api-hooks/channel/posts';
import { VideoThumbnail } from '../../../Video/Thumbnail';
// import { ChannelPost } from '../../../Channel/Posts/ChannelPost';

import './Post.scss';

export const ShareTabPost = ({ video, post }) => {
	const { t } = useTranslation();
	const {
		mutate: createPost,
		isLoading: createPostLoading,
		isSuccess: createPostSuccess,
	} = useCreatePost();

	const handleCreatePost = useCallback((text, images, videos) => {
		createPost({
			text,
			images,
			videos,
			sharedVideo: video ? video._id : undefined,
			share: post ? post._id : undefined,
		});
	}, [createPost, video, post]);

	return (
		<TabPane tabId="Post">
			<Row className="ShareTabPost_PostInput g-0 h-100 w-100 d-flex justify-content-center align-items-center">
				<PostInput
					onSubmit={handleCreatePost}
					isDisabled={createPostLoading}
				/>
				{!!video && (
					<VideoThumbnail
						video={video}
						className="mt-3 mx-auto h-100 w-100"
					/>
				)}
				{/*
				Creates a dependency cycle because post imports mediagallery
				that imports HLSPlayer that imports Share.
				At the moment we cannot share a post from a post, so removing this import to fix the cycle.
				{!!post && (
					<ChannelPost
						post={post}
						minimal
					/>
				)} */}
				{createPostSuccess && (
					<Alert color="success">
						{t('Share.Tab.Post.success')}
					</Alert>
				)}
			</Row>
		</TabPane>
	);
};

ShareTabPost.propTypes = {
	video: PropTypes.shape({
		_id: PropTypes.string.isRequired,
	}),
	post: PropTypes.shape({
		_id: PropTypes.string.isRequired,
	}),
};

ShareTabPost.defaultProps = {
	video: undefined,
	post: undefined,
};
