import React, {
	useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './PlayerCustom.scss';

const { MediaStream } = window;

// eslint-disable-next-line prefer-arrow-callback
export const PlayerCustom = React.forwardRef(function PlayerCustom(
	{
		className,
		src,
		isFullscreen,
	},
	videoRef,
) {
	useLayoutEffect(() => {
		if (!videoRef.current) return;
		videoRef.current.srcObject = src;
	}, [src, videoRef]);

	return (
		<div
			className={clsx(
				'PlayerCustom d-flex position-relative overflow-hidden',
				{
					'PlayerCustom--isFullscreen': !!isFullscreen,
					'h-100 w-100': !isFullscreen,
				},
				className,
			)}
		>
			<div className="d-flex w-100 mw-100 mh-100 overflow-hidden">
				<video
					autoPlay
					playsInline
					className="PlayerCustom_video w-100 h-100 mw-100 mh-100"
					ref={videoRef}
				/>
			</div>
		</div>
	);
});

PlayerCustom.propTypes = {
	className: PropTypes.string,
	src: PropTypes.instanceOf(MediaStream),
	isFullscreen: PropTypes.bool,
};

PlayerCustom.defaultProps = {
	className: '',
	src: null,
	isFullscreen: false,
};
