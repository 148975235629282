import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { ButtonPill, ButtonPillOutline } from '../../components/Button';
import { useLogin } from '../../components/Login/Provider';
import { getLink, Path } from '../../RoutePath';
import './Forbidden.scss';

const ViewForbidden = () => {
	const { openLogin, openSignup } = useLogin();
	const { t } = useTranslation();

	const handleClick = () => {
		openLogin();
	};

	const handleClickOnboarding = () => {
		openSignup();
	};

	return (
		<div className="h-100 w-100">
			<div className="ViewForbidden h-100 w-100">
				<h2>{t('Forbidden.Forbidden.mustBeLogged')}</h2>
				<p>
					<ButtonPillOutline color="white" onClick={handleClick}>{t('Forbidden.Forbidden.signIn')}</ButtonPillOutline>
					<ButtonPill className="ml-2" color="primary" onClick={handleClickOnboarding}>{t('Forbidden.Forbidden.signUp')}!</ButtonPill>
				</p>
				<p>
					<Button color="link" tag={Link} to={getLink(Path.ROOT)}>{t('Forbidden.Forbidden.home')}</Button>
				</p>
			</div>
		</div>
	);
};

export default ViewForbidden;
