import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useAsyncCallback } from 'react-async-hook';
import * as voteApi from '../../api/channel/vote';
import { useStudio } from '../Studio/Provider';
import { ListSortingType } from '../ListSorting/Provider';

const SHOW_COLUMNS = 3;
const SHOW_ROWS = 7;

const VoteContext = createContext({});

export const useVote = () => useContext(VoteContext);

export const VoteProvider = ({ children }) => {
	const [activeVote, setActiveVote] = useState(null);
	const [votes, setVotes] = useState(null);
	const [report, setReport] = useState(null);
	const [voteTemplates, setVoteTemplates] = useState(null);
	const [viewerDelay, setViewerDelay] = useState(0); // In milliseconds
	const { studio } = useStudio();

	const fetchVotes = useCallback(async () => {
		const { data } = await voteApi.fetchChannelVotesByStudioId(studio._id);

		if (data) {
			setVotes(data);
		}
	}, [setVotes, studio]);

	const fetchReport = useCallback(async (studioId, audienceType) => {
		const { data } = await voteApi.fetchReport(studioId, audienceType);

		if (data) {
			setReport(data);
		}
	}, []);

	const fetchReportCSV = useCallback(async (studioId, audienceType) => {
		const { data } = await voteApi.fetchReportCSV(studioId, audienceType);

		return data;
	}, []);

	const createVote = useCallback(async (voteData, studioId) => {
		await voteApi.createVote(voteData, studioId);
	}, []);

	const endVote = useCallback(async (voteId) => {
		await voteApi.endVote(voteId);
	}, []);

	const voteOnVote = useCallback(async (voteId, answerId) => {
		await voteApi.voteOnVote(voteId, answerId);
	}, []);

	const publishVote = useCallback(async (voteId) => {
		await voteApi.publishVote(voteId);
	}, []);

	const endVotePublish = useCallback(async (voteId) => {
		await voteApi.endPublishVote(voteId);
	}, []);

	const fetchVoteTemplates = useCallback(async (
		isArchived,
		itemsPerPage = -1,
		currentPage = -1,
		currentSort = ListSortingType.OLDEST_TO_NEWEST,
	) => {
		const sortValue = currentSort === ListSortingType.OLDEST_TO_NEWEST ? 1 : -1;
		const { data } = await voteApi.fetchChannelVoteTemplates(
			isArchived,
			itemsPerPage,
			currentPage,
			sortValue,
		);
		if (data) {
			setVoteTemplates(data);
		}
	}, [setVoteTemplates]);

	const createVoteTemplate = useCallback(async (voteTemplate) => {
		await voteApi.createVoteTemplate(voteTemplate);
	}, []);

	const deleteVoteTemplate = useCallback(async (voteTemplateId) => {
		await voteApi.deleteVoteTemplate(voteTemplateId);

		setVoteTemplates((templates) => ({
			totalItemsCount: templates.totalItemsCount - 1,
			data: templates.data.filter((template) => template._id !== voteTemplateId),
		}));
	}, []);

	const restoreVoteTemplate = useCallback(async (voteTemplateId) => {
		const { data } = await voteApi.restoreVoteTemplate(voteTemplateId);
		return data;
	}, []);

	const sendReportThroughMail = useCallback(async (studioId, audienceType) => {
		await voteApi.sendReportThroughMail(studioId, audienceType);
	}, []);

	const handleEventVoteActive = useCallback((newActiveVote) => {
		setActiveVote(newActiveVote);
	}, []);

	const [currentIndex, setCurrentIndex] = useState(0);
	const indexes = useMemo(() => {
		if (!report) {
			return [];
		}
		if (report.legend.length < SHOW_COLUMNS) {
			return [...Array(report.legend.length).keys()].map((foo) => currentIndex + foo);
		}
		return [...Array(SHOW_COLUMNS).keys()].map((foo) => currentIndex + foo);
	}, [currentIndex, report]);

	const [currentHeightIndex, setCurrentHeightIndex] = useState(0);
	const heightIndexes = useMemo(() => {
		if (!report) {
			return [];
		}
		if (report.allParticipants.length < SHOW_ROWS) {
			return [...Array(report.allParticipants.length).keys()]
				.map((foo) => currentHeightIndex + foo);
		}
		return [...Array(SHOW_ROWS).keys()].map((foo) => currentHeightIndex + foo);
	}, [currentHeightIndex, report]);

	const [showBack, showNext] = useMemo(
		() => (!report ? [false, false] : [
			currentIndex > 0, indexes[indexes.length - 1] < report.legend.length - 1]),
		[currentIndex, report, indexes],
	);

	const next = useCallback(() => {
		if (showNext) {
			setCurrentIndex(currentIndex + 1);
		}
	}, [currentIndex, setCurrentIndex, showNext]);

	const back = useCallback(() => {
		if (showBack) {
			setCurrentIndex(currentIndex - 1);
		}
	}, [currentIndex, setCurrentIndex, showBack]);

	const focusLegend = useMemo(() => ((report && report.legend)
		? indexes.map((index) => report.legend[index])
		: []), [report, indexes]);

	const focusHeaders = useMemo(() => ((report && report.result)
		? indexes.map((index) => report.result[index])
		: []), [report, indexes]);

	const [showUp, showDown] = useMemo(
		() => (!report ? [false, false] : [
			currentHeightIndex > 0,
			heightIndexes[heightIndexes.length - 1] < report.allParticipants.length - 1]),
		[currentHeightIndex, report, heightIndexes],
	);

	const down = useCallback(() => {
		if (showDown) {
			setCurrentHeightIndex(currentHeightIndex + 1);
		}
	}, [currentHeightIndex, setCurrentHeightIndex, showDown]);

	const up = useCallback(() => {
		if (showUp) {
			setCurrentHeightIndex(currentHeightIndex - 1);
		}
	}, [currentHeightIndex, setCurrentHeightIndex, showUp]);

	const focusRows = useMemo(() => {
		if (report && report.allParticipants) {
			return heightIndexes.map((index) => ({
				participant: report.allParticipants[index],
				results: focusHeaders.reduce((prev, curr) => [
					...prev, { ...curr.participants[index] }], []),
			}));
		}
		return [];
	}, [report, heightIndexes, focusHeaders]);

	const sendReportThroughMailAsync = useAsyncCallback(sendReportThroughMail);

	const fetchReportCSVAsync = useAsyncCallback(fetchReportCSV);

	const value = useMemo(() => ({
		activeVote,
		back,
		createVote,
		createVoteTemplate,
		deleteVoteTemplate,
		down,
		endVote,
		endVotePublish,
		fetchReport,
		fetchReportCSV,
		fetchReportCSVAsync,
		fetchVotes,
		fetchVoteTemplates,
		focusHeaders,
		focusLegend,
		focusRows,
		handleEventVoteActive,
		next,
		publishVote,
		report,
		restoreVoteTemplate,
		sendReportThroughMail,
		sendReportThroughMailAsync,
		setViewerDelay,
		showBack,
		showDown,
		showNext,
		showUp,
		up,
		viewerDelay,
		voteOnVote,
		votes,
		voteTemplates,
	}), [
		activeVote,
		back,
		createVote,
		createVoteTemplate,
		deleteVoteTemplate,
		down,
		endVote,
		endVotePublish,
		fetchReport,
		fetchReportCSV,
		fetchReportCSVAsync,
		fetchVotes,
		fetchVoteTemplates,
		focusHeaders,
		focusLegend,
		focusRows,
		handleEventVoteActive,
		next,
		publishVote,
		report,
		restoreVoteTemplate,
		sendReportThroughMail,
		sendReportThroughMailAsync,
		setViewerDelay,
		showBack,
		showDown,
		showNext,
		showUp,
		up,
		viewerDelay,
		voteOnVote,
		votes,
		voteTemplates,
	]);

	return (
		<VoteContext.Provider value={value}>
			{children}
		</VoteContext.Provider>
	);
};

VoteProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
