import { createSelector } from 'reselect';

import {
	SOURCE_TYPE_PARTICIPANT,
} from '../../lib/stream';
import {
	selectParticipantsMediaStreams,
} from './mediaStreams';
import {
	selectChannelParticipantsStreams,
} from './channelStreams';
import {
	isParticipantDefaultSourceId,
	isParticipantSourceId,
	isScreenshareSourceId,
	isVideoshareSourceId,
} from '../lib/helpers';

export const selectParticipantSources = createSelector(
	selectChannelParticipantsStreams,
	selectParticipantsMediaStreams,
	(
		participantStreams,
		participantsMediaStreams = [],
	) => (
		participantsMediaStreams.map((participantMediaStream) => {
			const participantStream = participantStreams
				.find(({ id }) => id === participantMediaStream.stream.id);

			let src;
			let muted = true;

			if (participantStream.own) {
				src = undefined;
			} else {
				src = participantMediaStream;
				muted = false; /* source will be muted in SourceCard component
				if participant is already in live */
			}

			return {
				sourceId: participantStream.id,
				ownerAvatar: participantStream.user?.avatar,
				label: participantStream.user?.nickname,
				own: participantStream.own,
				muted,
				src,
				type: SOURCE_TYPE_PARTICIPANT,
			};
		})
	),
);

export const selectGuestSources = createSelector(
	selectParticipantSources,
	(participantSources = []) => participantSources.filter(({ own }) => (!own)),
);

export const selectControllerSources = createSelector(
	selectParticipantSources,
	(participantSources = []) => participantSources.filter(
		({ sourceId, own }) => (own && isParticipantSourceId(sourceId)),
	),
);

export const selectControllerParticipantSources = createSelector(
	selectParticipantSources,
	(participantSources = []) => participantSources.find(
		({ sourceId, own }) => (own && isParticipantSourceId(sourceId)),
	),
);

export const selectControllerParticipantDefaultSource = createSelector(
	selectParticipantSources,
	(participantSources = []) => participantSources.find(
		({ sourceId, own }) => (own && isParticipantDefaultSourceId(sourceId)),
	),
);

export const selectControllerVideoSource = createSelector(
	selectParticipantSources,
	(participantSources = []) => participantSources.find(
		({ sourceId, own }) => (own && isVideoshareSourceId(sourceId)),
	),
);

export const selectControllerScreenShareSource = createSelector(
	selectParticipantSources,
	(participantSources = []) => participantSources.find(
		({ sourceId, own }) => (own && isScreenshareSourceId(sourceId)),
	),
);
