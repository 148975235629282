import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { FaCheckCircle, FaCircle, FaTrash } from 'react-icons/fa';
import { Field, FieldError } from 'react-jsonschema-form-validation';
import { Button, Col, Input, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

export const OptionInput = ({ value, onChange, disabled, fullWidth, correctAnswers }) => {
	const { t } = useTranslation();
	const canAddNew = useMemo(
		() => !disabled
			&& value.length < 5
			&& value.find((option) => !option.label) === undefined,
		[value, disabled],
	);

	const handleRemoveChoice = useCallback((index) => {
		onChange(value.filter((_, i) => i !== index));
	}, [value, onChange]);

	const handleAddNewOption = useCallback(() => {
		onChange([...value, { label: '' }]);
	}, [value, onChange]);

	const handleItemChange = useCallback((newVal, index) => {
		onChange(value.map((option, i) => (i === index ? { ...option, label: newVal } : option)));
	}, [value, onChange]);

	const handleItemCorrectChange = useCallback((index) => {
		if (!disabled) {
			onChange(value.map((option, i) => (i === index
				? { ...option, correct: option.correct ? undefined : true }
				: { ...option, correct: undefined }
			)));
		}
	}, [value, disabled, onChange]);

	/* eslint-disable react/no-array-index-key, no-nested-ternary */
	return (
		<>
			{value.map((option, i) => (
				<Row key={`option-input-${i}`} className={`mt-2 ${option.correct ? 'bg-primary content-primary py-1 pl-1 pr-2' : 'pr-1'} ${fullWidth ? 'p-0 m-0' : ''}`}>
					<Col sm={fullWidth ? 1 : 2} xs={fullWidth ? 1 : 12} className={fullWidth ? 'p-0 m-0' : ''}>
						<div
							className={`h-100 d-flex align-items-center ${!disabled && 'cursor-pointer'} ${fullWidth ? 'p-0 m-0 justify-content-start' : 'justify-content-center'}`}
							onClick={() => handleItemCorrectChange(i)}
						>
							{correctAnswers && (option.correct
								? (
									<FaCheckCircle
										size={14}
										className="text-white-50"
									/>
								)
								: (
									<FaCircle
										size={14}
										className="text-white-50"
									/>
								))}
							<div className="ml-1">
								{'ABCDE'[i]}:
							</div>
						</div>
					</Col>
					<Col xs={10} sm={fullWidth ? 10 : 8} className={fullWidth ? 'p-0 m-0' : ''}>
						<Field
							component={Input}
							name={`options.${i}.label`}
							value={option.label}
							onChange={(e) => handleItemChange(e.target.value, i)}
							placeholder={i === 0 ? t('Common.OptionInput.egGood') : (i === 1 ? t('Common.OptionInput.egBad') : undefined)}
							className={disabled ? 'bg-secondary content-secondary' : ''}
							disabled={disabled}
						/>
					</Col>
					<Col xs={fullWidth ? 1 : 2} sm={fullWidth ? 1 : 2} className={fullWidth ? 'p-0 m-0 d-flex justify-content-end' : ''}>
						{value.length > 2 && (
							<Button
								className="btn-pill d-40 p-0"
								color="neutral-secondary"
								onClick={() => handleRemoveChoice(i)}
								disabled={disabled}
							>
								<span className="btn-wrapper--icon">
									<FaTrash size={20} className="text-white-50" />
								</span>
							</Button>
						)}
					</Col>
					<Col sm={fullWidth ? 12 : 10}>
						<FieldError name={`options.${i}.label`} />
					</Col>
				</Row>
			))}
			{canAddNew && (
				<Row className="mt-2">
					<Col sm={fullWidth ? 11 : 10}>
						<Button
							color="neutral-secondary"
							className="w-100"
							onClick={handleAddNewOption}
						>
							{t('Common.OptionInput.addMore')}
						</Button>
					</Col>
				</Row>
			)}
		</>
	);
	/* eslint-enable */
};

OptionInput.propTypes = {
	value: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	correctAnswers: PropTypes.bool,
	fullWidth: PropTypes.bool,
};

OptionInput.defaultProps = {
	disabled: false,
	correctAnswers: true,
	fullWidth: false,
};
