import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Alert, Table, UncontrolledTooltip } from 'reactstrap';
import clsx from 'clsx';
import { FaChevronCircleDown, FaChevronCircleUp, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import useCsvDownloader from 'use-csv-downloader';

import { toPercentage } from '../../../../lib/number';
import { useVote } from '../../Provider';
import { ButtonPillOutline } from '../../../Button';
import { VoteAudienceType } from '../Create';
import './Table.scss';

const getAudienceTypeLabel = (audienceType) => {
	switch (audienceType) {
	case VoteAudienceType.PARTICIPANT: {
		return 'Participant';
	}
	case VoteAudienceType.VIEWER: {
		return 'Viewer';
	}
	default:
		return 'User';
	}
};

export const ReportTable = ({ studioId }) => {
	const { t } = useTranslation();
	const { report, focusHeaders, focusRows, focusLegend,
		showNext, showBack, showUp, showDown, next, back, down, up,
		sendReportThroughMailAsync, fetchReportCSVAsync } = useVote();

	const downloadReportCSV = useCsvDownloader({ header: false });
	const handleSendReportThroughEmail = useCallback(() => {
		sendReportThroughMailAsync.execute(studioId, report.audienceType);
	}, [sendReportThroughMailAsync, studioId, report]);

	const handleDownloadReportCSV = useCallback(async () => {
		const reportData = await fetchReportCSVAsync.execute(studioId, report.audienceType);

		if (reportData) {
			downloadReportCSV(reportData, `${t('Report.Table.CSVName')} (${studioId}).csv`);
		}
	}, [fetchReportCSVAsync, studioId, report, downloadReportCSV, t]);
	return focusHeaders && !focusHeaders.length ? (
		<div className="w-100 h-100 d-flex justify-content-center">{t('Report.Table.noVotes')}</div>
	) : (
		<>
			{sendReportThroughMailAsync.status === 'success' && (
				<Alert color="success" className="mt-4">
					{t('Report.Table.success.sentByEmail')}
				</Alert>
			)}
			{(sendReportThroughMailAsync.error || fetchReportCSVAsync.error) && (
				<Alert color="danger" className="mt-4">
					{t('Global.error')}
				</Alert>
			)}
			<div className="ReportContent">
				<Table className="ReportTable" borderless>
					<thead>
						<tr>
							<th className="text-center bg-transparent">
								{showUp && (
									<span onClick={up} className="cursor-pointer icon-span">
										<FaChevronCircleUp size={13} />
									</span>
								) }
							</th>
							<th className="text-center bg-transparent" />
							{focusHeaders.map((head, index) => (
								<th
									key={`report-header-${head.enumeration}`}
									className={clsx('text-center bg-darker', {
										left: focusHeaders.length !== 1 && index === 0,
										right: focusHeaders.length !== 1 && index === focusHeaders.length - 1,
										both: focusHeaders.length === 1,
									})}
								>
									{ index === 0 && focusHeaders.length !== 1 && showBack && (
										<span onClick={back} className="cursor-pointer" style={{ float: 'left' }}>
											<FaChevronLeft className="font-size-xs ml-2" />
										</span>
									)}
									<span id={`report-header-${head.enumeration}`}>
										&quot;{head.enumeration}&quot;
									</span>
									<UncontrolledTooltip target={`report-header-${head.enumeration}`} z-index={2001}>
										{head.title}
									</UncontrolledTooltip>
									{ index === focusHeaders.length - 1 && focusHeaders.length !== 1 && showNext && (
										<span onClick={next} className="cursor-pointer" style={{ float: 'right' }}>
											<FaChevronRight className="font-size-xs ml-2" />
										</span>
									)}
								</th>
							))}
							<th className="text-center text-secondary bg-transparent">#{t('Report.Table.answered')}</th>
							<th className="text-center text-secondary bg-transparent">#{t('Report.Table.correct')}</th>
						</tr>
					</thead>
					<tbody className="bg-light">
						{focusRows.map((row) => (
							<tr key={`report-row-${row.participant._id}`}>
								<td colSpan={2} className="text-center" style={{ width: '100px' }}>{row.participant.nickname}</td>
								{row.results.map((result, i) => (
									<td
										key={`report-cell-${focusHeaders[i].enumeration}-${result.participant._id}`}
										className="text-center"
									>
										{result.enumeration ? (
											<>
												<span
													id={`report-cell-${focusHeaders[i].enumeration}-${result.participant._id}`}
													// eslint-disable-next-line no-nested-ternary
													className={result.isCorrect !== undefined
														? (result.isCorrect ? 'text-success' : 'text-danger')
														: ''}
												>
													&quot;{result.enumeration}&quot;
												</span>
												<UncontrolledTooltip target={`report-cell-${focusHeaders[i].enumeration}-${result.participant._id}`} z-index={2001}>
													{result.label}
												</UncontrolledTooltip>
											</>
										) : '/'}
									</td>
								))}
								<td className="text-center bg-light">
									<small>
										{row.participant.answeredCount} / {report.legend.length}
										<span className="mx-1">-</span>
										{toPercentage(
											row.participant.answeredCount / report.legend.length,
										)}
									</small>
								</td>
								<td className="text-center bg-light">
									<small>
										{row.participant.correctCount} / {report.ratedCount}
										<span className="mx-1">-</span>
										{toPercentage(row.participant.correctCount / report.ratedCount)}
									</small>
								</td>
							</tr>
						))}
						<tr className="bg-dark">
							<td className="text-center text-white">
								{ showDown
								&& (
									<span onClick={down} className="cursor-pointer icon-span">
										<FaChevronCircleDown size={13} />
									</span>
								)}
							</td>
							<td className="text-center text-secondary border-bottom"><strong><small>{t('Report.Table.answerRatio')}</small></strong></td>
							{focusHeaders.map((head) => (
								<td
									key={`report-cell-${head.enumeration}-answer-count`}
									className="text-center border-bottom"
								>
									<small>
										{head.answeredCount} / {report.totalParticipantCount}
										<span className="mx-1">-</span>
										{toPercentage(head.answeredCount / report.totalParticipantCount)}
									</small>
								</td>
							))}
							<td className="bg-dark" colSpan={2} />
						</tr>
						<tr className="bg-dark">
							<td className="text-secondary" />
							<td className="text-center text-secondary"><strong><small>{t('Report.Table.correctRatio')}</small></strong></td>
							{focusHeaders.map((head) => (
								<td
									key={`report-cell-${head.enumeration}-correct-count`}
									className="text-center"
								>
									{head.isRated ? (
										<small>
											{head.correctCount} / {report.totalParticipantCount}
											<span className="mx-1">-</span>
											{toPercentage(head.correctCount / report.totalParticipantCount)}
										</small>
									) : <small>{t('Report.Table.notRated')}</small>}
								</td>
							))}
							<td className="bg-dark" colSpan={2} />
						</tr>
						<tr className="w-100">
							<td
								colSpan={2}
								className="text-center bg-dark"
							/>
							{focusLegend.map((legend) => (
								<td xs={12} lg={3} key={`report-legend-${legend.enumeration}`} className="my-1 py-1 bg-dark">
									<div className="p-2 pb-4 bg-light rounded">
										<p className="mb-1"><strong>&quot;{legend.enumeration}&quot;</strong>: {legend.title}</p>
										<div className="mb-1">
											{legend.options.map((option) => (
												<div key={option._id}>
													<small>
														<strong>
															&quot;{option.enumeration}&quot;
														</strong>: {option.label} ({toPercentage(option.percentage)})
													</small>
												</div>
											))}
										</div>
									</div>
									<p className="mb-1 text-secondary">
										<small className="font-weight-bold">
											{`${getAudienceTypeLabel(report.audienceType)} ${t('Report.Table.participantCount')}: ${legend.totalParticipantCount}`}
										</small>
									</p>
								</td>
							))}
							<td
								className="bg-dark"
								colSpan={2}
							>
								{studioId && (
									<div className="d-flex flex-column align-items-center justify-content-center">
										<ButtonPillOutline
											className="mr-2 mb-2"
											color="secondary"
											size="sm"
											disabled={fetchReportCSVAsync.loading}
											onClick={handleDownloadReportCSV}
										>
											{t('Report.Table.downloadCSV')}
										</ButtonPillOutline>
										<ButtonPillOutline
											className="mr-2 mb-2"
											color="secondary"
											size="sm"
											disabled={sendReportThroughMailAsync.loading}
											onClick={handleSendReportThroughEmail}
										>
											{t('Report.Table.emailReport')}
										</ButtonPillOutline>
									</div>
								)}
							</td>
						</tr>
					</tbody>
				</Table>
			</div>
		</>
	);
};

ReportTable.propTypes = {
	studioId: PropTypes.string,
};

ReportTable.defaultProps = {
	studioId: undefined,
};
