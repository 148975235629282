import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import aspectRatioImg from './ratio-16x9.png';
import './RatioContainer.scss';

const RatioContainer = forwardRef(({ children, className }, ref) => (
	<div ref={ref} className={clsx('RatioContainer', className)}>
		<img alt="ratio-16x9" className="RatioContainer_ratio" src={aspectRatioImg} />
		<div className="RatioContainer_content">
			{children}
		</div>
	</div>
));

RatioContainer.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

RatioContainer.defaultProps = {
	children: undefined,
	className: undefined,
};

export default RatioContainer;
