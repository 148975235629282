import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Alert,
	Button,
	Input,
	InputGroup,
	Row,
	TabPane,
} from 'reactstrap';

import { ResourceAccessRole } from '../../../lib/ResourceAccessRole';
import { getShareLink } from '../../Channel/Share/helpers';
import { ShareRoleInput } from '../RoleInput';
import { ShareTabType } from '../Share.helper';

export const ShareTabLink = ({
	channel,
	post,
	roles,
	selectedRole,
	setSelectedRole,
	studio,
	video,
}) => {
	const { t } = useTranslation();

	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const link = getShareLink({ channel, studio, video, role: selectedRole, post });

	const handleCopyLink = useCallback(() => {
		navigator.clipboard.writeText(link)
			.then(() => {
				setSuccess(true);
				setTimeout(() => setSuccess(false), 3000);
			}, () => {
				setError(true);
				setTimeout(() => setError(false), 3000);
			});
	}, [link]);

	return (
		<TabPane tabId="Link">
			<Row className="g-0 form-dark h-100 w-100 d-flex justify-content-center align-items-center">
				<h4 className="text-center pt-2 pb-2 font-weight-bold mt-3">
					{t('Share.Tab.Link.youCanCopyLinkAndPasteIt')}
				</h4>
				<ShareRoleInput
					value={selectedRole}
					onChange={(role) => setSelectedRole(role)}
					roles={roles}
					shareTabType={ShareTabType.LINK}
				/>
				<InputGroup className="mb-3">
					<Input
						className="text-truncate border-0 bg-dark"
						type="url"
						name="url"
						id="url"
						value={link}
						readOnly
						onClick={handleCopyLink}
					/>
				</InputGroup>
				{success && (
					<Alert color="success">
						{t('Share.Tab.Link.linkCopied')}
					</Alert>
				)}
				{error && (
					<Alert color="danger">
						{t('Share.Tab.Link.somethingWentWrong')}
					</Alert>
				)}
				<div className="mb-3 d-flex justify-content-between w-100">
					<Button
						color="primary"
						className="btn-pill ml-auto shadow-none font-weight-bold"
						onClick={handleCopyLink}
					>
						{t('Share.Tab.Link.copyLink')}
					</Button>
				</div>
			</Row>
		</TabPane>
	);
};

ShareTabLink.propTypes = {
	channel: PropTypes.shape({
		_id: PropTypes.string.isRequired,
	}),
	roles: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(ResourceAccessRole))),
	studio: PropTypes.shape({
		_id: PropTypes.number.isRequired,
	}),
	video: PropTypes.shape({
		_id: PropTypes.string.isRequired,
	}),
	post: PropTypes.shape({}),
	selectedRole: PropTypes.oneOf(Object.keys(ResourceAccessRole)).isRequired,
	setSelectedRole: PropTypes.func.isRequired,
};

ShareTabLink.defaultProps = {
	channel: undefined,
	roles: [],
	studio: undefined,
	video: undefined,
	post: undefined,
};
