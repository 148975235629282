import React, {
	useCallback,
	useMemo,
	useState,
	createContext,
	useContext,
	useEffect,
} from 'react';
import PropTypes from 'prop-types';

import { useMediaUser } from '../User';
import { defaultForcedKeyConfig, KeyDetectionMode } from './KeyConfig';

export const MediaKeyContext = createContext();

export const useMediaKey = () => useContext(MediaKeyContext);

const LOCAL_KEY_CONFIG_KEY = 'beeyou_localKeyConfig';

const getLocalKeyConfig = () => {
	const localKeyConfig = localStorage.getItem(LOCAL_KEY_CONFIG_KEY);
	return localKeyConfig && JSON.parse(localKeyConfig);
};
const setLocalKeyConfig = (config) => localStorage.setItem(
	LOCAL_KEY_CONFIG_KEY,
	JSON.stringify(config),
);

export const MediaKeyProvider = ({
	children,
	isHost,
}) => {
	const [localConfig, setLocalConfig] = useState(() => getLocalKeyConfig());
	const [keyVideoActiveTracks, setKeyVideoActiveTracks] = useState([]);
	const { userVideoActiveTracks, userMediastreams, userAudioActiveTracks } = useMediaUser();

	const [channelMixerKeyConfig, setChannelMixerKeyConfig] = useState();

	const configOverride = useMemo(() => {
		const replacementOverride = isHost
			? channelMixerKeyConfig?.own?.replacement
			: channelMixerKeyConfig?.all?.replacement;

		if (!replacementOverride) {
			return undefined;
		}

		return {
			...defaultForcedKeyConfig,
			replacement: replacementOverride,
		};
	}, [isHost, channelMixerKeyConfig]);

	const isReplacementConfigOverriden = !!configOverride;

	const isKeyForced = isReplacementConfigOverriden && isHost;

	// The 2-pass key is not available for the host.
	// For the host, the key settings defined in scenes completely
	// replace the his default key settings.
	const config = useMemo(() => {
		if (isKeyForced) return undefined;
		return localConfig;
	}, [isKeyForced, localConfig]);

	const addKeyVideoTrack = useCallback((track) => {
		setKeyVideoActiveTracks((prev) => [...prev, track]);
	}, []);

	const removeKeyVideoTrackByTrack = useCallback((track) => {
		setKeyVideoActiveTracks((prev) => prev.filter(({ configId }) => configId !== track.configId));
	}, []);

	const handleCMSEvents = useCallback((cms) => {
		const { key } = cms;
		setChannelMixerKeyConfig(key);
	}, []);

	const isKeyEnabled = (
		(
			!!config?.detection.mode
			&& config.detection.mode !== KeyDetectionMode.DISABLED
		)
		|| (
			!!configOverride?.detection.mode
			&& configOverride.detection.mode !== KeyDetectionMode.DISABLED
		)
	);

	const keyOrUserVideoActiveTracks = useMemo(() => {
		// ensure user track is removed even if key track is not yet available
		// in order to avoid publish two tracks in a row
		if (isKeyEnabled) {
			return [
				...keyVideoActiveTracks,
				...userVideoActiveTracks.filter(({ configId }) => configId !== 0),
			];
		}
		return userVideoActiveTracks;
	}, [isKeyEnabled, keyVideoActiveTracks, userVideoActiveTracks]);

	const keyOrUserActiveTracks = useMemo(
		() => [
			...keyOrUserVideoActiveTracks,
			...userAudioActiveTracks,
		], [keyOrUserVideoActiveTracks, userAudioActiveTracks],
	);

	const keyOrUserMediastream = useMemo(() => {
		if (keyVideoActiveTracks?.length > 0) {
			const keyMediaStream = new MediaStream();
			keyMediaStream.configId = 0;
			keyMediaStream.addTrack(keyVideoActiveTracks[0]);
			const userMediaStream = (userMediastreams || []).find(({ configId }) => configId === 0);
			if (userMediaStream) {
				const track = userMediaStream.getAudioTracks()[0];
				if (track) keyMediaStream.addTrack(track);
			}

			// Refresh keyMediaStream when tracks change to avoid player image stuck
			return [...(userMediastreams || []).filter(({ configId }) => configId !== 0), keyMediaStream];
		}
		return userMediastreams;
	}, [keyVideoActiveTracks, userMediastreams]);

	useEffect(() => {
		setLocalKeyConfig(localConfig);
	}, [localConfig]);

	const context = useMemo(() => ({
		addKeyVideoTrack,
		config,
		configOverride,
		handleCMSEvents,
		isKeyForced,
		isReplacementConfigOverriden,
		localConfig,
		removeKeyVideoTrackByTrack,
		setLocalConfig,
		setKeyVideoActiveTracks,
		keyVideoActiveTracks,
		keyOrUserVideoActiveTracks,
		keyOrUserActiveTracks,
		keyOrUserMediastream,
	}), [
		addKeyVideoTrack,
		config,
		configOverride,
		handleCMSEvents,
		isKeyForced,
		isReplacementConfigOverriden,
		localConfig,
		removeKeyVideoTrackByTrack,
		keyVideoActiveTracks,
		keyOrUserVideoActiveTracks,
		keyOrUserActiveTracks,
		keyOrUserMediastream,
	]);

	return (
		<MediaKeyContext.Provider value={context}>
			{children}
		</MediaKeyContext.Provider>
	);
};

MediaKeyProvider.propTypes = {
	children: PropTypes.node,
	isHost: PropTypes.bool,
	user: PropTypes.shape({
		sub: PropTypes.string.isRequired,
	}),
};

MediaKeyProvider.defaultProps = {
	children: undefined,
	isHost: false,
	user: undefined,
};
