import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { notifyHandRaised } from '../../../api/handRaising';
import { Sound, useSound } from '../../Sound/Provider';

const RAISE_HAND_TIMEOUT = 1000 * 5;

const GuestHandRaisingContext = createContext({});

export const useHandRaising = () => useContext(GuestHandRaisingContext);

export const GuestHandRaisingProvider = ({ children }) => {
	const [isHandRaised, setIsHandRaised] = useState(false);
	const [guestsHandRaised, setGuestsHandRaised] = useState([]);
	const { playSound } = useSound();

	const timeoutHandRaised = () => {
		setIsHandRaised((prevState) => !prevState);
		setTimeout(() => {
			setIsHandRaised((prevState) => !prevState);
		}, RAISE_HAND_TIMEOUT);
	};

	const raiseHand = useCallback((studio) => {
		timeoutHandRaised();
		notifyHandRaised(studio);
	}, []);

	const setGuestRaisedHand = useCallback((guestId) => {
		playSound(Sound.HAND_RAISED);
		timeoutHandRaised();
		setGuestsHandRaised((prevState) => [...prevState, guestId]);
	}, [playSound]);

	const removeGuestHandRaised = useCallback((guest) => {
		setGuestsHandRaised((prevState) => [...prevState.filter((g) => g !== guest)]);
	}, []);

	const value = useMemo(() => ({
		raiseHand,
		setGuestRaisedHand,
		removeGuestHandRaised,
		isHandRaised,
		guestsHandRaised,
	}), [
		raiseHand,
		setGuestRaisedHand,
		removeGuestHandRaised,
		isHandRaised,
		guestsHandRaised,
	]);

	return (
		<GuestHandRaisingContext.Provider value={value}>
			{children}
		</GuestHandRaisingContext.Provider>
	);
};

GuestHandRaisingProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
