import { getConnect, getDisconnect, getSocket } from '@technomiam/wsapi-client';

const { VITE_WSAPI_URL } = import.meta.env;
const CHANNEL_URL = `${VITE_WSAPI_URL}/channel`;

export const connectChannelSockets = getConnect(CHANNEL_URL);
export const disconnectChannelSockets = getDisconnect(CHANNEL_URL);
export const socket = getSocket(CHANNEL_URL);

export const onEventVoteActive = (listener) => {
	const sock = socket();

	sock.on('event.vote.active', listener);
	return () => {
		sock.off('event.vote.active', listener);
	};
};

export const onEventChatMessage = (listener) => {
	const sock = socket();
	sock.on('event.chat.message', listener);
	return () => {
		sock.off('event.chat.message', listener);
	};
};

export const onEventChatMessageUpdate = (listener) => {
	const sock = socket();
	sock.on('event.chat.message.update', listener);
	return () => {
		sock.off('event.chat.message.update', listener);
	};
};

export const onEventChatMessageDelete = (listener) => {
	const sock = socket();
	sock.on('event.chat.message.delete', listener);
	return () => {
		sock.off('event.chat.message.delete', listener);
	};
};

export const onEventChatMessagesClear = (listener) => {
	const sock = socket();
	sock.on('event.chat.messages.clear', listener);
	return () => {
		sock.off('event.chat.messages.clear');
	};
};

export const onEventChatStatus = (listener) => {
	const sock = socket();
	sock.on('event.chat.status', listener);
	return () => {
		sock.off('event.chat.status', listener);
	};
};

export const onEventConnectionNew = (listener) => {
	const sock = socket();
	sock.on('event.channel.connection.new', listener);
	return () => {
		sock.off('event.channel.connection.new', listener);
	};
};

export const onEventConnectionLeft = (listener) => {
	const sock = socket();
	sock.on('event.channel.connection.left', listener);
	return () => {
		sock.off('event.channel.connection.left', listener);
	};
};

export const onEventConnectionAll = (listener) => {
	const sock = socket();
	sock.on('event.channel.connection.all', listener);
	return () => {
		sock.off('event.channel.connection.all', listener);
	};
};

export const onEventStudioStatus = (listener) => {
	const sock = socket();
	sock.on('event.studio.status', listener);
	return () => {
		sock.off('event.studio.status', listener);
	};
};

export const onEventPostNew = (listener) => {
	const sock = socket();
	sock.on('event.post.new', listener);
	return () => {
		sock.off('event.post.new', listener);
	};
};

export const onEventPostUpdate = (listener) => {
	const sock = socket();
	sock.on('event.post.update', listener);
	return () => {
		sock.off('event.post.update', listener);
	};
};

export const onEventPostDelete = (listener) => {
	const sock = socket();
	sock.on('event.post.delete', listener);
	return () => {
		sock.off('event.post.delete', listener);
	};
};

export const onEventPostLikeChange = (listener) => {
	const sock = socket();
	sock.on('event.post.like.change', listener);
	return () => {
		sock.off('event.post.like.change', listener);
	};
};

export const onEventPostPinChange = (listener) => {
	const sock = socket();
	sock.on('event.post.pin.change', listener);
	return () => {
		sock.off('event.post.pin.change', listener);
	};
};

export const onEventPostCommentNew = (listener) => {
	const sock = socket();
	sock.on('event.post.comment.new', listener);
	return () => {
		sock.off('event.post.comment.new', listener);
	};
};

export const onEventPostCommentDelete = (listener) => {
	const sock = socket();
	sock.on('event.post.comment.delete', listener);
	return () => {
		sock.off('event.post.comment.delete', listener);
	};
};

export const onEventPostCommentUpdate = (listener) => {
	const sock = socket();
	sock.on('event.post.comment.update', listener);
	return () => {
		sock.off('event.post.comment.update', listener);
	};
};

export const onEventPostCommentLikeChange = (listener) => {
	const sock = socket();
	sock.on('event.post.comment.like.change', listener);
	return () => {
		sock.off('event.post.comment.like.change', listener);
	};
};

export const onEventPostCommentLike = (listener) => {
	const sock = socket();
	sock.on('event.post.comment.like', listener);
	return () => {
		sock.off('event.post.comment.like', listener);
	};
};

export const onEventPostCommentDislike = (listener) => {
	const sock = socket();
	sock.on('event.post.comment.dislike', listener);
	return () => {
		sock.off('event.post.comment.dislike', listener);
	};
};

export const onEventHandRaised = (listener) => {
	const sock = socket();
	sock.on('event.hand.raised', listener);
	return () => {
		sock.off('event.hand.raised', listener);
	};
};

export const onEventModerationBan = (listener) => {
	const sock = socket();
	sock.on('event.moderation.ban', listener);
	return () => {
		sock.off('event.moderation.ban', listener);
	};
};

export const onEventModerationUnban = (listener) => {
	const sock = socket();
	sock.on('event.moderation.unban', listener);
	return () => {
		sock.off('event.moderation.unban', listener);
	};
};

export const joinChannel = (
	hashtag,
	role,
	studioId,
) => {
	const sock = socket();

	const join = () => sock.emit(
		'join.channel',
		{ hashtag, role, studioId },
	);

	const onReconnect = () => { join(); };
	sock.on('reconnect', onReconnect);

	join();

	return () => {
		sock.off('reconnect', onReconnect);
		sock.emit('leave.channel', { hashtag, studioId });
	};
};

export const onDisconnect = (listener) => {
	const sock = socket();
	sock.on('disconnect', listener);
	return () => {
		sock.off('disconnect', listener);
	};
};
