import { authenticatedApi } from '../api';

export const API_POSTS_PATH = '/channel/posts';

export const fetchPosts = (hashtag, mode, lastTimestamp) => authenticatedApi.get(
	`${API_POSTS_PATH}/byhashtag/${hashtag}`, {
		params: {
			mode,
			lastTimestamp,
		},
	},
);

export const fetchPost = (slug) => authenticatedApi.get(
	`${API_POSTS_PATH}/byid/${slug}`,
);

export const fetchPinnedPost = (hashtag) => authenticatedApi.get(
	`${API_POSTS_PATH}/pinned/byhashtag/${hashtag}`,
);

export const fetchMentionedPosts = (hashtag) => authenticatedApi.get(
	`${API_POSTS_PATH}/mentions/byhashtag/${hashtag}`,
);

export const fetchPostLikes = (id, page, itemsPerPage) => authenticatedApi.get(
	`${API_POSTS_PATH}/likes/byid/${id}`,
	{
		params: {
			page,
			itemsPerPage,
		},
	},
);

export const createPost = (post) => authenticatedApi.post(
	`${API_POSTS_PATH}`,
	post,
);

export const updatePost = ({ id, text, images, videos, surveyTemplate }) => authenticatedApi.put(
	`${API_POSTS_PATH}/${id}`,
	{ text, images, videos, surveyTemplate },
);

export const deletePost = (id) => authenticatedApi.delete(
	`${API_POSTS_PATH}/${id}`,
);

export const likePost = (id) => authenticatedApi.post(
	`${API_POSTS_PATH}/like`,
	{ postId: id },
);

export const unlikePost = (id) => authenticatedApi.post(
	`${API_POSTS_PATH}/unlike`,
	{ postId: id },
);

export const sharePostInChat = (id) => authenticatedApi.post(
	`${API_POSTS_PATH}/${id}/share_in_chat`,
);

export const sharePost = (id) => authenticatedApi.post(
	`${API_POSTS_PATH}/share/${id}`,
);

export const pinPost = (id) => authenticatedApi.post(
	`${API_POSTS_PATH}/pin/${id}`,
);

export const unpinPost = (id) => authenticatedApi.post(
	`${API_POSTS_PATH}/unpin/${id}`,
);
