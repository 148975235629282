import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
	getSimulcastEncodings,
} from '@technomiam/soup-client';

import {
	connect,
	join as joinSoup,
	disconnect,
	soupSession,
} from '../../api/soup';
import { useDispatch, useReactVideo } from './Provider';
import { addSoupListenersAction, publishTrackAction, unpublishTrackAction, updateTrackAction } from '../../store/actions/channelMixing';
import { useMediaUser } from '../Media/User';
import { useMediaScreenshare } from '../Media/Screenshare';
import { useMediaVideoshare } from '../Media/Videoshare';
import { useMediaKey } from '../Media/MediaKey/Provider';
import { usePrevious } from '../../lib/hooks';
import { KeyReplacementMode } from '../Media/MediaKey/KeyConfig';

//TODO CHECK
const { rollbar } = window;

export const SoupContext = React.createContext({});

export const useSoup = () => useContext(SoupContext);

export const SOUP_CONNECTING_STATE_CONNECTED = 'SOUP_CONNECTING_STATE_CONNECTED';
export const SOUP_CONNECTING_STATE_DISCONNECTED = 'SOUP_CONNECTING_STATE_DISCONNECTED';
export const SOUP_CONNECTING_STATE_PENDING = 'SOUP_CONNECTING_STATE_PENDING';

const TRACK_TYPE_CAM = 'TRACK_TYPE_CAM';
const TRACK_TYPE_MIC = 'TRACK_TYPE_MIC';
const TRACK_TYPE_SCREENSHARE = 'TRACK_TYPE_SCREENSHARE';
const TRACK_TYPE_VIDEOSHARE = 'TRACK_TYPE_VIDEOSHARE';

const STREAM_ID_CAM = 'cam';
const STREAM_ID_MIC = 'mic';
const STREAM_ID_SCREENSHARE = 'screen';
const STREAM_ID_VIDEOSHARE = 'video';

const MODE_CONTROL = 'MODE_CONTROL';
const MODE_GUEST = 'MODE_GUEST';

const trackTypeToStreamIdByModeMap = {
	[MODE_CONTROL]: {
		[TRACK_TYPE_CAM]: STREAM_ID_CAM,
		[TRACK_TYPE_MIC]: STREAM_ID_MIC,
		[TRACK_TYPE_SCREENSHARE]: STREAM_ID_SCREENSHARE,
		[TRACK_TYPE_VIDEOSHARE]: STREAM_ID_VIDEOSHARE,
	},
	[MODE_GUEST]: {
		[TRACK_TYPE_CAM]: STREAM_ID_CAM,
		[TRACK_TYPE_MIC]: STREAM_ID_MIC,
		[TRACK_TYPE_SCREENSHARE]: STREAM_ID_SCREENSHARE,
		[TRACK_TYPE_VIDEOSHARE]: STREAM_ID_VIDEOSHARE,
	},
};

export const SoupProvider = ({
	children,
	control,
}) => {
	const dispatch = useDispatch();
	const { getConnectionConfig, user } = useReactVideo();
	const [hashtag, setHashtag] = useState();
	const [isConnected, setIsConnected] = useState(false);
	const [connectingState, setConnectingState] = useState(SOUP_CONNECTING_STATE_DISCONNECTED);
	const cancelled = useRef(false);

	const getIsConnected = useCallback(() => (
		isConnected && soupSession()?.connected
	), [isConnected]);

	const leave = useCallback(async () => {
		setConnectingState(SOUP_CONNECTING_STATE_DISCONNECTED);
		cancelled.current = true;
		console.log('[leaveSoup]');
		disconnect();
	}, []);

	const join = useCallback(async (h) => {
		try {
			setHashtag(h);
			cancelled.current = false;
			console.log('[joinSoup]');
			setConnectingState(SOUP_CONNECTING_STATE_PENDING);
			const connectionConfig = await getConnectionConfig();
			if (cancelled.current) return;
			await connect(connectionConfig, h);
			if (cancelled.current) return;
			await joinSoup();
			if (cancelled.current) return;
			setConnectingState(SOUP_CONNECTING_STATE_CONNECTED);
		} catch {
			setConnectingState(SOUP_CONNECTING_STATE_DISCONNECTED);
		}
	}, [getConnectionConfig]);

	const { userActiveTracks } = useMediaUser();
	const { screenshareActive, screenshareActiveTracks } = useMediaScreenshare();
	const { videoshareActive, videoshareActiveTracks } = useMediaVideoshare();
	const {
		keyOrUserActiveTracks,
		config: keyConfig,
		configOverride: keyConfigOverride,
	} = useMediaKey();

	const previousKeyConfig = usePrevious(keyConfig);
	const previousKeyConfigOverride = usePrevious(keyConfigOverride);

	const localTracks = useMemo(() => {
		const selectedTracks = keyOrUserActiveTracks
			.filter((track) => !!track) // useful ?
			.map((mediaStreamTrack) => {
				const selectedTrack = { id: mediaStreamTrack.id, mediaStreamTrack };
				if (mediaStreamTrack.isKey && mediaStreamTrack.configId === 0) {
					selectedTrack.isKeyTrack = true;
					selectedTrack.keyConfig = keyConfig;
					selectedTrack.keyConfigOverride = keyConfigOverride;
				}
				return selectedTrack;
			});

		return [
			...selectedTracks,
			...videoshareActiveTracks
				.filter(() => control || !screenshareActive)
				.map((mediaStreamTrack) => ({ id: mediaStreamTrack.id, mediaStreamTrack })),
			...screenshareActiveTracks.map(
				(mediaStreamTrack) => ({ id: mediaStreamTrack.id, mediaStreamTrack }),
			),
		];
	}, [
		control,
		keyConfig,
		keyConfigOverride,
		screenshareActive,
		screenshareActiveTracks,
		keyOrUserActiveTracks,
		videoshareActiveTracks,
	]);

	console.log({
		keyOrUserActiveTracks,
		videoshareActive,
		videoshareActiveTracks,
		userActiveTracks,
		localTracks,
	});

	useEffect(() => {
		if (connectingState === SOUP_CONNECTING_STATE_CONNECTED) {
			const soup = soupSession();
			if (!soup) {
				setIsConnected(false);
				return undefined;
			}

			const onConnected = () => { setIsConnected(true); };
			const onDisconnected = () => { setIsConnected(false); };
			const onTransportFailed = async ({ rtcPeerConnection, transport }) => {
				// eslint-disable-next-line no-underscore-dangle
				const stats = Object.fromEntries(await transport.getStats());
				if (rollbar) {
					rollbar.error(
						'Transport connection failed.',
						{
							// eslint-disable-next-line no-underscore-dangle
							remoteSdpIceCandidates: transport._remoteSdp?._iceCandidates,
							stats,
							transport: {
								id: transport.id,
								direction: transport.direction,
							},
						},
					);
				}
				console.error('Soup transport failed', { rtcPeerConnection, transport, stats });
			};
			soup.on('connected', onConnected);
			soup.on('disconnected', onDisconnected);
			soup.on('transport:failed', onTransportFailed);
			const offSoupListeners = dispatch(addSoupListenersAction());

			setIsConnected(!!soup.connected);

			return () => {
				setIsConnected(false);
				offSoupListeners();
				soup.off('connected', onConnected);
				soup.off('disconnected', onDisconnected);
				soup.off('transport:failed', onTransportFailed);
			};
		}
		return undefined;
	}, [dispatch, connectingState]);

	useEffect(() => {
		if (isConnected && soupSession()?.connected) {
			console.log({ localTracks });
			const currentPublishedLocalTracksIds = Array.from(soupSession().productions.keys());
			const localTracksIds = localTracks.map(({ id }) => id);
			const screenSharingTracksIds = screenshareActiveTracks.map(({ id }) => id);
			const videoSharingTracksIds = videoshareActiveTracks.map(({ id }) => id);
			const tracksToUpdate = localTracks.filter((localTrack) => (
				currentPublishedLocalTracksIds.includes(localTrack.id)
				&& localTrack.isKeyTrack
				&& (
					localTrack.keyConfig !== previousKeyConfig
					|| localTrack.keyConfigOverride !== previousKeyConfigOverride
				)
			));

			console.log({
				currentPublishedLocalTracksIds,
				localTracksIds,
				tracksToUpdate,
				keyConfig,
				previousKeyConfig,
			});

			// publish new ones
			const idsTracksToPublish = localTracksIds.filter(
				(lms) => !currentPublishedLocalTracksIds.includes(lms),
			);

			const getTrackType = (trackId, kind) => {
				if (screenSharingTracksIds.includes(trackId)) return TRACK_TYPE_SCREENSHARE;
				if (videoSharingTracksIds.includes(trackId)) return TRACK_TYPE_VIDEOSHARE;
				if (kind === 'audio') return TRACK_TYPE_MIC;
				return TRACK_TYPE_CAM;
			};

			const getTrackStreamId = (trackType, configId) => {
				const mode = control ? MODE_CONTROL : MODE_GUEST;
				const trackTypeId = trackTypeToStreamIdByModeMap[mode][trackType];
				if ([TRACK_TYPE_MIC, TRACK_TYPE_CAM].includes(trackType)) {
					return `${trackTypeId}:${configId}`;
				}
				return `${trackTypeId}:0`; /* Force screenshare and videoshare to have the same configId
				as the default microphone.
				So the microphone is played in live when screenshare is active */
			};

			const getTrackPreventLarsen = (trackType, kind) => {
				if (kind === 'video') return false;
				if (trackType === TRACK_TYPE_VIDEOSHARE) return false;
				return true;
			};

			const getTrackEncodings = (trackType, track) => {
				if (track.kind !== 'video') return undefined;

				// TODO: test with max bitrate (see below)
				if (trackType === TRACK_TYPE_SCREENSHARE) return undefined;

				// Firefox MediaStreamTrack.getSettings returns an empty object in case of captureStream
				// See Videoshare component for the fix
				const { height } = track.firefoxFixCaptureSettings || track.getSettings();
				const trackEncodings = getSimulcastEncodings(height);
				// TODO: test with max bitrate
				// if (trackType === TRACK_TYPE_SCREENSHARE) {
				// 	// Simulcast disabled for screensharing return only highest encoding
				// 	return trackEncodings.slice(-1);
				// }
				return trackEncodings;
			};
			console.log({ idsTracksToPublish });

			const getTrackAppDataKeyConfig = (kc) => {
				if (
					!kc
					|| kc.replacement.mode !== KeyReplacementMode.TRANSPARENT
				) return null;
				return {
					color: kc.replacement.transparentColor,
					sensitivity: kc.replacement.transparentColorSensitivity,
				};
			};

			idsTracksToPublish.forEach((trackId) => {
				const {
					keyConfig: trackKeyConfig,
					keyConfigOverride: trackKeyConfigOverride,
					mediaStreamTrack,
				} = localTracks.find(({ id }) => id === trackId);
				const trackType = getTrackType(trackId, mediaStreamTrack.kind);
				const trackStreamId = getTrackStreamId(trackType, mediaStreamTrack.configId);
				const trackPreventLarsen = getTrackPreventLarsen(trackType, mediaStreamTrack.kind);
				const trackEncodings = getTrackEncodings(trackType, mediaStreamTrack);
				const alphaColor = getTrackAppDataKeyConfig(trackKeyConfigOverride || trackKeyConfig);
				dispatch(publishTrackAction(
					mediaStreamTrack,
					trackStreamId,
					user,
					trackPreventLarsen,
					alphaColor,
					trackEncodings,
				));
			});

			tracksToUpdate.forEach((track) => {
				const {
					keyConfig: trackKeyConfig,
					keyConfigOverride: trackKeyConfigOverride,
					mediaStreamTrack,
				} = track;
				const trackType = getTrackType(track.id, mediaStreamTrack.kind);
				const trackStreamId = getTrackStreamId(trackType, mediaStreamTrack.configId);
				const trackPreventLarsen = getTrackPreventLarsen(trackType, mediaStreamTrack.kind);
				const alphaColor = getTrackAppDataKeyConfig(trackKeyConfigOverride || trackKeyConfig);

				dispatch(updateTrackAction(mediaStreamTrack, {
					trackStreamId,
					user,
					trackPreventLarsen,
					alphaColor,
				}));
			});

			// unpublish old ones
			const idsTracksToUnpublish = currentPublishedLocalTracksIds.filter(
				(cplt) => !localTracksIds.includes(cplt),
			);
			console.log({ idsTracksToUnpublish });
			idsTracksToUnpublish.forEach((trackId) => {
				dispatch(unpublishTrackAction({ id: trackId }));
			});
		} else if (soupSession()?.productions) {
			const currentPublishedLocalTracksIds = Array.from(soupSession()?.productions.keys());
			console.log({ currentPublishedLocalTracksIds });
			currentPublishedLocalTracksIds.forEach((track) => {
				dispatch(unpublishTrackAction({ track }));
			});
		}
	}, [
		control,
		keyConfig,
		previousKeyConfig,
		previousKeyConfigOverride,
		dispatch,
		isConnected,
		localTracks,
		screenshareActiveTracks,
		user,
		videoshareActiveTracks,
	]);

	const contextValue = useMemo(() => ({
		connectingState,
		getIsConnected,
		hashtag,
		isConnected,
		join,
		leave,
	}), [
		connectingState,
		getIsConnected,
		hashtag,
		isConnected,
		join,
		leave,
	]);

	return (
		<SoupContext.Provider value={contextValue}>
			{children}
		</SoupContext.Provider>
	);
};

SoupProvider.propTypes = {
	children: PropTypes.node.isRequired,
	control: PropTypes.bool,
};

SoupProvider.defaultProps = {
	control: false,
};
