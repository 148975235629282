import { authenticatedApi } from './api';

export const API_SHARE_PATH = '/share';

export const share = (shares, studioOwnerId) => authenticatedApi.post(
	`${API_SHARE_PATH}/`,
	{ shares, studioOwnerId },
);

export const answerStudioAttendance = (token, answer, suggestedDate) => authenticatedApi.post(
	`${API_SHARE_PATH}/studio/attendanceSurvey`,
	{ token, answer, suggestedDate },
);

export const fetchAttendanceReportCSV = (studioId) => authenticatedApi.get(
	`${API_SHARE_PATH}/attendance/report/byStudioId/${studioId}/csv`,
);

export const fetchStudioAttendace = (studioId) => authenticatedApi.get(`${API_SHARE_PATH}/studio/attendance/byStudioId/${studioId}`);
