import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';

import { ProfileContext } from './ProfileContext';
import * as channelFriendsApi from '../../api/channel/friends';
import { useAuthentication } from '../Authentication/Authentication';
import { FRIENDS_QUERY_KEYS } from '../../api-hooks/channel/friends';
import { useFetchGraphGlobalsProfile, useSetGraphGlobalsProfile } from '../../api-hooks/graph/globals';

const getProfileFromUser = (user) => {
	if (!user) return undefined;
	return {
		_id: user.sub,
		avatar: user.picture,
		birthdate: user.birthdate,
		email: user.email,
		firstname: user.given_name,
		hashtag: user.preferred_username,
		nickname: user.nickname,
	};
};

export const ProfileProvider = ({ children }) => {
	const queryClient = useQueryClient();
	const { user } = useAuthentication();

	const defaultProfile = useMemo(() => getProfileFromUser(user), [user]);

	const {
		data: profile = defaultProfile,
		refetch: fetchProfile, // TODO: remove this ?
	} = useFetchGraphGlobalsProfile();
	const setProfile = useSetGraphGlobalsProfile();

	const followChannel = useCallback(async (channelId, origin) => {
		const { data } = await channelFriendsApi.followChannel(channelId, origin);
		setProfile(data);

		queryClient.invalidateQueries(
			FRIENDS_QUERY_KEYS.fetchFriendStatustByChannelId(channelId),
		);
	}, [queryClient, setProfile]);

	const unfollowChannel = useCallback(async (channelId, origin) => {
		const { data } = await channelFriendsApi.unfollowChannel(channelId, origin);
		setProfile(data);
		queryClient.invalidateQueries(
			FRIENDS_QUERY_KEYS.fetchFriendStatustByChannelId(channelId),
		);
	}, [queryClient, setProfile]);

	const updateStatusDisclosed = useCallback(async (statusDisclosed) => {
		const { data } = await channelFriendsApi.updateStatusDisclosed(statusDisclosed);
		setProfile(data);
	}, [setProfile]);

	const contextValue = useMemo(() => ({
		fetchProfile,
		followChannel,
		profile,
		updateStatusDisclosed,
		unfollowChannel,
		setProfile,
	}), [
		fetchProfile,
		followChannel,
		profile,
		updateStatusDisclosed,
		unfollowChannel,
		setProfile,
	]);

	return (
		<ProfileContext.Provider value={contextValue}>
			{children}
		</ProfileContext.Provider>
	);
};

ProfileProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
