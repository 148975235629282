import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { ReactComponent as SearchIcon } from './icons/ic-hp-search.svg';

import { useOutsideClickEvent } from '../../lib/click';
import './TypeaheadInput.scss';

const ENTER_KEYCODE = 13;
const UP_ARROW_KEYCODE = 38;
const DOWN_ARROW_KEYCODE = 40;
const ESCAPE_KEYCODE = 27;

const getOptionId = (option) => (typeof option !== 'object' ? option : option.id);
const getOptionLabel = (option) => (typeof option !== 'object' ? option : option.label);

export const TypeaheadInput = ({
	wrapperClassName,
	areSuggestionsOpened,
	onSuggestionsOpenedChange,
	suggestions,
	areSuggestionsLoading,
	inputValue,
	onInputValueChange,
	onChange,
	placeholder,
	suggestionOnly,
	multiSelect,
}) => {
	const searchRef = useRef();
	const inputRef = useRef();

	const [showSuggestions, setShowSuggestions] = useState(areSuggestionsOpened ?? false);

	const [highlightedOption, setHighlightedOption] = useState(null);
	const [selectedOption, setSelectedOption] = useState(null);
	const [options, setOptions] = useState([]);

	const displayValue = useMemo(
		() => (selectedOption !== null ? getOptionLabel(options[selectedOption]) : inputValue),
		[selectedOption, options, inputValue],
	);

	useEffect(() => {
		if (suggestions) {
			setOptions(
				typeof suggestions[0] === 'string'
					? suggestions.map((value) => ({ id: value, label: value }))
					: suggestions,
			);
		} else if (!inputValue) {
			setOptions([]);
		}
	}, [inputValue, suggestions]);

	useEffect(() => {
		if (typeof areSuggestionsOpened !== 'undefined') {
			setShowSuggestions(areSuggestionsOpened);
		}
	}, [areSuggestionsOpened]);

	const handleSuggestionsOpenChange = useCallback((value) => {
		onSuggestionsOpenedChange(value);
		if (typeof areSuggestionsOpened === 'undefined') {
			setShowSuggestions(value);
		}
	}, [areSuggestionsOpened, onSuggestionsOpenedChange]);

	const handleInputFocus = useCallback(() => {
		handleSuggestionsOpenChange(true);
	}, [handleSuggestionsOpenChange]);

	const handleInputBlur = useCallback(() => {
		handleSuggestionsOpenChange(false);
	}, [handleSuggestionsOpenChange]);

	useOutsideClickEvent(handleInputBlur, searchRef);

	const handleInputChange = useCallback((e) => {
		onInputValueChange(e.target.value);
		setHighlightedOption(null);
		setSelectedOption(null);

		handleSuggestionsOpenChange(true);
	}, [handleSuggestionsOpenChange, onInputValueChange]);

	const handleInputClear = useCallback(() => {
		onInputValueChange('');
		setHighlightedOption(null);
		setSelectedOption(null);

		handleSuggestionsOpenChange(false);
		inputRef.current.focus();
	}, [handleSuggestionsOpenChange, onInputValueChange]);

	const change = useCallback((value) => {
		onChange(typeof value === 'string' ? {
			inputValue: value,
			selectedOption: null,
		} : {
			inputValue: options[value]?.label,
			selectedOption: options[value],
		});

		onInputValueChange(
			// eslint-disable-next-line no-nested-ternary
			multiSelect
				? ''
				: typeof value === 'string' ? value : options[value]?.label ?? '',
		);
		setSelectedOption(null);
		setHighlightedOption(null);

		handleSuggestionsOpenChange(false);
		inputRef.current.blur();
	}, [handleSuggestionsOpenChange, multiSelect, onChange, onInputValueChange, options]);

	const handleChange = useCallback(() => {
		if (!suggestionOnly || selectedOption !== null) {
			change(selectedOption !== null ? selectedOption : displayValue);
		}
	}, [suggestionOnly, selectedOption, change, displayValue]);

	const handleNextSelection = useCallback(() => {
		setHighlightedOption(null);
		setSelectedOption((prevOption) => {
			// eslint-disable-next-line no-nested-ternary
			const nextOption = highlightedOption === null
				? prevOption === null ? options.length - 1 : prevOption - 1
				: highlightedOption - 1;

			return nextOption < 0 ? null : nextOption;
		});
	}, [highlightedOption, options.length]);

	const handlePrevSelection = useCallback(() => {
		setHighlightedOption(null);
		setSelectedOption((prevOption) => {
			// eslint-disable-next-line no-nested-ternary
			const nextOption = highlightedOption === null
				? prevOption === null ? 0 : prevOption + 1
				: highlightedOption + 1;

			return nextOption >= options.length ? null : nextOption;
		});
	}, [highlightedOption, options.length]);

	const handleKeypress = useCallback((e) => {
		if (e.keyCode === ENTER_KEYCODE) {
			e.preventDefault();
			handleChange();
		} else if (e.keyCode === UP_ARROW_KEYCODE) {
			e.preventDefault();
			handleNextSelection();
		} else if (e.keyCode === DOWN_ARROW_KEYCODE) {
			e.preventDefault();
			handlePrevSelection();
		} else if (e.keyCode === ESCAPE_KEYCODE) {
			handleSuggestionsOpenChange(false);
		}
	}, [handleChange, handleNextSelection, handlePrevSelection, handleSuggestionsOpenChange]);

	const rightPadding = areSuggestionsLoading || displayValue;

	return (
		<div
			className={`TypeaheadInput h-100 d-flex justify-content-center position-relative ${wrapperClassName}`}
			ref={searchRef}
		>
			<span className="icon-search h-100 d-flex align-items-center ml-2 p-2 text-secondary">
				<SearchIcon />
			</span>
			<input
				className={clsx(
					'w-100 bg-light content-dark px-5 py-2 rounded border-2 border-light',
					{ 'pr-2': !rightPadding, 'pr-5': rightPadding },
				)}
				value={displayValue}
				placeholder={placeholder}
				onChange={handleInputChange}
				onFocus={handleInputFocus}
				onKeyDown={handleKeypress}
				ref={inputRef}
			/>
			{areSuggestionsLoading && (
				<span className="icon-loading h-100 d-flex align-items-center">
					<span
						className="btn-wrapper--icon spinner-border spinner-border-sm ml-1 mr-auto"
						role="status"
						aria-hidden="true"
					/>
				</span>
			)}
			{displayValue && (
				<span className="icon-clear h-100 d-flex align-items-center mr-2 p-2">
					<FaTimes
						className="cursor-pointer"
						onClick={handleInputClear}
					/>
				</span>
			)}
			<div
				className={clsx('suggest-options w-100 bg-light rounded px-1', {
					'd-none': !showSuggestions || !options.length,
				})}
			>
				{showSuggestions && options.length ? (
					<>
						{options.map((option, idx) => (
							<div
								key={`search-suggestion-${getOptionId(option)}`}
								className={clsx('suggest-option w-100 p-md-3 p-2 my-1 cursor-pointer', {
									'option-highlighted': idx === highlightedOption || (highlightedOption === null && idx === selectedOption),
								})}
								onMouseEnter={() => setHighlightedOption(idx)}
								onMouseLeave={() => setHighlightedOption(null)}
								onClick={() => { change(idx); }}
							>
								{getOptionLabel(option)}
							</div>
						))}
					</>
				) : null}
			</div>
		</div>
	);
};

TypeaheadInput.propTypes = {
	wrapperClassName: PropTypes.string,
	areSuggestionsOpened: PropTypes.bool,
	onSuggestionsOpenedChange: PropTypes.func,
	suggestions: PropTypes.arrayOf(PropTypes.oneOfType([
		PropTypes.string, PropTypes.shape({
			id: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
		}),
	])),
	areSuggestionsLoading: PropTypes.bool,
	inputValue: PropTypes.string.isRequired,
	onInputValueChange: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	suggestionOnly: PropTypes.bool,
	placeholder: PropTypes.string,
	multiSelect: PropTypes.bool,
};

TypeaheadInput.defaultProps = {
	wrapperClassName: '',
	areSuggestionsOpened: undefined,
	onSuggestionsOpenedChange: () => {},
	suggestions: [],
	areSuggestionsLoading: false,
	suggestionOnly: false,
	placeholder: undefined,
	multiSelect: false,
};
