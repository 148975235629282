import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import * as apiModerationBan from '../../../api/moderation/ban';
import * as apiModerationReport from '../../../api/moderation/report';
import { ModerationBanModal } from './BanModal';
import { ModerationContext, ReportType } from './Context';
import { ModerationReportModal } from './ReportModal';
import { ModerationReportDetailsModal } from './ReportDetailsModal';

export const ModerationProvider = ({ children }) => {
	const [isReportModalOpen, setIsReportModalOpen] = useState(false);
	const [isReportDetailsModalOpen, setIsReportDetailsModalOpen] = useState(false);
	const [reportModalData, setReportModalData] = useState();
	const [selectedReport, setSelectedReport] = useState();
	const [selectedReportType, setSelectedReportType] = useState();

	const [isBanModalOpen, setIsBanModalOpen] = useState(false);
	const [banModalData, setBanModalData] = useState();
	const [bans, setBans] = useState([]);

	const history = useHistory();

	const fetchBans = useCallback(async (channelId) => {
		const { data } = await apiModerationBan.fetchManyByChannelId(channelId);
		setBans(data);
	}, []);

	const handleEventModerationBan = useCallback(() => {
		// Reload current page to refetch everything and possibly handle a ban event
		history.go(0);
	}, [history]);

	const handleEventModerationUnban = useCallback(() => {
		// Reload current page to refetch everything and possibly handle a ban event
		history.go(0);
	}, [history]);

	const closeBanModal = useCallback(() => {
		setIsBanModalOpen(false);
		setBanModalData(undefined);
	}, []);

	const closeReportModal = useCallback(() => {
		setIsReportModalOpen(false);
	}, []);

	const closeReportDetailsModal = useCallback(() => {
		setIsReportDetailsModalOpen(false);
	}, []);

	const openBanModal = useCallback((user, duration) => {
		setIsBanModalOpen(true);
		setBanModalData({
			duration,
			user,
		});
	}, []);

	const openChannelReportModal = useCallback((channel) => {
		setIsReportModalOpen(true);
		setReportModalData({
			type: ReportType.CHANNEL,
			channel,
		});
	}, []);

	const openStudioReportModal = useCallback((channel, studio) => {
		setIsReportModalOpen(true);
		setReportModalData({
			type: ReportType.STUDIO,
			channel,
			studio,
		});
	}, []);

	const openVideoReportModal = useCallback((video) => {
		setIsReportModalOpen(true);
		setReportModalData({
			type: ReportType.VIDEO,
			video,
		});
	}, []);

	const openReportDetailsModal = useCallback((report, type) => {
		setSelectedReport(report);
		setSelectedReportType(type);
		setIsReportDetailsModalOpen(true);
	}, []);

	const banUserFromStudio = useCallback(async (studioId, userId, until) => (
		apiModerationBan.banUserFromStudio(studioId, userId, until)
	), []);

	const unbanUser = useCallback(async (userId, studio) => {
		await apiModerationBan.unbanUser(userId, studio._id);
		await fetchBans(studio.owner._id);
	}, [fetchBans]);

	const reportStudio = useCallback(async (
		channelId,
		category,
		reason,
		studioId,
	) => (
		apiModerationReport.reportChannel(channelId, category, reason, studioId)
	), []);

	const reportChannel = useCallback(async (
		channelId,
		category,
		reason,
	) => (
		reportStudio(channelId, category, reason)
	), [reportStudio]);

	const reportVideo = useCallback(async (
		videoId,
		category,
		reason,
	) => (
		apiModerationReport.reportVideo(videoId, category, reason)
	), []);

	const value = useMemo(() => ({
		bans,
		banModalData,
		banUserFromStudio,
		closeBanModal,
		closeReportModal,
		fetchBans,
		handleEventModerationBan,
		handleEventModerationUnban,
		openBanModal,
		openChannelReportModal,
		openStudioReportModal,
		openVideoReportModal,
		reportChannel,
		reportModalData,
		reportStudio,
		reportVideo,
		unbanUser,
		openReportDetailsModal,
		closeReportDetailsModal,
	}), [
		bans,
		banModalData,
		banUserFromStudio,
		closeBanModal,
		closeReportModal,
		fetchBans,
		handleEventModerationBan,
		handleEventModerationUnban,
		openBanModal,
		openChannelReportModal,
		openStudioReportModal,
		openVideoReportModal,
		reportChannel,
		reportModalData,
		reportStudio,
		reportVideo,
		unbanUser,
		openReportDetailsModal,
		closeReportDetailsModal,
	]);

	return (
		<ModerationContext.Provider value={value}>
			{children}
			{isBanModalOpen && <ModerationBanModal />}
			{isReportModalOpen && <ModerationReportModal />}
			{isReportDetailsModalOpen
				&& (
					<ModerationReportDetailsModal
						report={selectedReport}
						type={selectedReportType}
					/>
				)}
		</ModerationContext.Provider>
	);
};

ModerationProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
