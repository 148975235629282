import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal, Row } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';

import { useVote } from '../../Provider';
import { VoteReport } from './Report';
import { useStudio } from '../../../Studio/Context';
import './Modal.scss';

export const ReportModal = ({ toggle, isOpen }) => {
	const { studio } = useStudio();
	const { fetchReport } = useVote();

	useEffect(() => {
		if (isOpen && studio) {
			fetchReport(studio._id);
		}
	}, [fetchReport, isOpen, studio]);

	return (
		<Modal
			centered
			contentClassName="border-0 shadow-lg rounded overflow-hidden bg-dark"
			isOpen={isOpen}
			size="xl"
			toggle={toggle}
			zIndex={1801}
		>
			<Row className="g-0 h-100 w-100">
				<Col xs="12" className="d-flex h-100 rounded overflow-hidden p-relative">
					<div className="w-100 p-5 pb-0 d-flex flex-column ReportModal">
						<VoteReport studioId={studio._id} />
					</div>
					<Button
						color="neutral-secondary"
						className="position-absolute card-badges shadow btn-pill d-30 p-0 m-2"
						onClick={toggle}
					>
						<span className="btn-wrapper--icon">
							<FaTimes />
						</span>
					</Button>
				</Col>
			</Row>
		</Modal>
	);
};

ReportModal.propTypes = {
	toggle: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
};
