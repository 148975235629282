import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { ModalScreen } from '../../../Modal/Screen/Screen';
import { ModalScreenHeader } from '../../../Modal/Screen/Header';

export const ChannelCoverAvatarModal = ({ avatar, isOpen, closeModal }) => (
	<ModalScreen
		bodyClassName="bg-black p-0"
		size="lg"
		isOpen={isOpen}
		onClose={closeModal}
		className="p-0"
		header={(
			<ModalScreenHeader
				onClose={closeModal}
				title={t('Profile.Avatar')}
			/>
		)}
	>
		<img
			alt={t('Cover.Cover.avatar')}
			className="img-fit-container"
			src={avatar}
		/>
	</ModalScreen>
);

ChannelCoverAvatarModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeModal: PropTypes.func.isRequired,
	avatar: PropTypes.string,
};

ChannelCoverAvatarModal.defaultProps = {
	avatar: undefined,
};
