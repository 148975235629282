import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label, Popover, PopoverBody } from 'reactstrap';
import { Field } from 'react-jsonschema-form-validation';
import { useTranslation } from 'react-i18next';
import { FaInfoCircle } from 'react-icons/fa';

import { Currency } from '../../../lib/currency';
import { StudioScheduleSettingsFormIntegerInput } from './IntegerInput';

export const StudioScheduleSettingsFormPriceInput = ({
	label,
	name,
	onChangePrice,
	price,
	currencies,
	onChangeCurrency,
	activeTab,
	presale,
	onChangePresale,
}) => {
	const { t } = useTranslation();
	const [showPopover, setShowPopover] = useState(false);

	return (
		<div>
			{label}
			<FormGroup check>
				<Label check>
					<Field
						component={Input}
						type="radio"
						name={`${name}_showPrice`}
						onChange={() => {
							onChangePrice({
								price: {
									...price,
									[activeTab]: '',
									[`${activeTab}PerMinute`]: '',
								},
							});
						}}
						checked={price[activeTab] === '' && price[`${activeTab}PerMinute`] === ''}
					/>
					{t('SettingsForm.PriceInput.free')}
				</Label>
			</FormGroup>
			<FormGroup check>
				<Label check>
					<Field
						component={Input}
						type="radio"
						name={`${name}_showPrice`}
						onChange={() => {
							onChangePrice({
								price: {
									...price,
									[activeTab]: 0,
									[`${activeTab}PerMinute`]: '',
								},
							});
						}}
						checked={price[activeTab] !== ''}
					/>
					{t('SettingsForm.PriceInput.fixedPrice')}
				</Label>
				{price[activeTab] !== '' && (
					<>
						<StudioScheduleSettingsFormIntegerInput
							value={price[activeTab]}
							onChange={(value) => {
								onChangePrice({
									price: {
										...price,
										[`${activeTab}PerMinute`]: '',
										[activeTab]: value,
									},
								});
							}}
							name={name}
							placeholder={t('SettingsForm.PriceInput.eg')}
							autofocus
						/>
						<FormGroup check>
							<Field
								component={Input}
								type="checkbox"
								name={activeTab}
								id="piPoints"
								value={Currency.PI_POINTS}
								onChange={onChangeCurrency}
								checked={currencies[activeTab]?.includes(Currency.PI_POINTS)}
							/>
							<Label check for="piPoints" className="blue">
								Pi
							</Label>
						</FormGroup>
						<FormGroup check>
							<Field
								component={Input}
								type="checkbox"
								name={activeTab}
								id="chips"
								value={Currency.CHIPS}
								onChange={onChangeCurrency}
								checked={currencies[activeTab]?.includes(Currency.CHIPS)}
							/>
							<Label check for="chips" className="blue">
								Chips
							</Label>
						</FormGroup>
						<FormGroup id="PresaleContainer" className="mt-3 mb-2" check>
							<Field
								component={Input}
								type="checkbox"
								name="presale"
								id="presale"
								value={presale[activeTab]}
								onChange={() => onChangePresale(!presale[activeTab])}
								checked={presale[activeTab]}
							/>
							<Label check for="presale" className="blue">
								{t('SettingsForm.PriceInput.allowPresale')}
								<span
									onMouseEnter={() => setShowPopover(true)}
									onMouseLeave={() => setShowPopover(false)}
									id="InfoIcon"
								>
									<FaInfoCircle
										size={12}
										className="ml-1"
									/>
								</span>
							</Label>
						</FormGroup>
						<Popover
							placement="top"
							isOpen={showPopover}
							target="InfoIcon"
							container="PresaleContainer"
						>
							<PopoverBody
								className="text-black"
							>
								{t('SettingsForm.PriceInput.presaleTicketsExplanations')}
							</PopoverBody>
						</Popover>
					</>
				)}
			</FormGroup>
			<div>
				{label}
				<FormGroup check>
					<Label check>
						<Field
							component={Input}
							type="radio"
							name={name}
							onChange={() => {
								onChangePrice({
									price: {
										...price,
										[activeTab]: '',
										[`${activeTab}PerMinute`]: 0,
									},
								});
							}}
							checked={price[`${activeTab}PerMinute`] !== ''}
						/>
						{t('SettingsForm.PriceInput.chargePerMinutes')}
					</Label>
					{price[`${activeTab}PerMinute`] !== '' && (
						<div>
							<StudioScheduleSettingsFormIntegerInput
								value={price[`${activeTab}PerMinute`]}
								onChange={(value) => {
									onChangePrice({
										price: {
											...price,
											[activeTab]: '',
											[`${activeTab}PerMinute`]: value,
										},
									});
								}}
								name={name}
								placeholder="ex. 5 (Pi)"
								autofocus
							/>
							<FormGroup check>
								<Field
									component={Input}
									type="checkbox"
									name={`${activeTab}PerMinute`}
									id="piPoints"
									value={Currency.PI_POINTS}
									onChange={onChangeCurrency}
									checked={currencies[`${activeTab}PerMinute`]?.includes(Currency.PI_POINTS)}
								/>
								<Label check for="piPoints" className="blue">
									Pi
								</Label>
							</FormGroup>
							<FormGroup check>
								<Field
									component={Input}
									type="checkbox"
									name={`${activeTab}PerMinute`}
									id="chips"
									value={Currency.CHIPS}
									onChange={onChangeCurrency}
									checked={currencies[`${activeTab}PerMinute`]?.includes(Currency.CHIPS)}
								/>
								<Label check for="chips" className="blue">
									Chips
								</Label>
							</FormGroup>
						</div>
					)}
				</FormGroup>
			</div>
		</div>
	);
};

StudioScheduleSettingsFormPriceInput.propTypes = {
	activeTab: PropTypes.string.isRequired,
	currencies: PropTypes.shape({
		participant: PropTypes.arrayOf(PropTypes.string),
		participantPerMinute: PropTypes.arrayOf(PropTypes.string),
		viewer: PropTypes.arrayOf(PropTypes.string),
		viewerPerMinute: PropTypes.arrayOf(PropTypes.string),
	}),
	label: PropTypes.node,
	name: PropTypes.string.isRequired,
	onChangeCurrency: PropTypes.func.isRequired,
	onChangePresale: PropTypes.func.isRequired,
	onChangePrice: PropTypes.func.isRequired,
	presale: PropTypes.shape({
		participant: PropTypes.bool,
		viewer: PropTypes.bool,
	}),
	price: PropTypes.shape({
		participant: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.string,
		]),
		participantPerMinute: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.string,
		]),
		viewer: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.string,
		]),
		viewerPerMinute: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.string,
		]),
	}),
};

StudioScheduleSettingsFormPriceInput.defaultProps = {
	currencies: {
		participant: [],
		participantPerMinute: [],
		viewer: [],
		viewerPerMinute: [],
	},
	label: undefined,
	presale: {
		participant: false,
		viewer: false,
	},
	price: {},
};

export const StudioScheduleSettingsFormRolePriceInput = ({
	label,
	activeTab,
	onChange,
	price,
	currencies,
	presale,
}) => {
	const handleCurrencyChange = useCallback((e) => {
		const { value, checked, name } = e.target;

		onChange({
			currencies: {
				...currencies,
				[name]: checked
					? [...currencies[name], value]
					: currencies[name].filter((currency) => currency !== value),
			},
		});
	}, [currencies, onChange]);

	const handlePresaleChange = useCallback((value) => {
		onChange({
			presale: {
				...presale,
				[activeTab]: value,
			},
		});
	}, [activeTab, onChange, presale]);

	return (
		<FormGroup tag="fieldset">
			{label}
			<StudioScheduleSettingsFormPriceInput
				name={`price.${activeTab}`}
				onChangePrice={onChange}
				price={price}
				currencies={currencies}
				onChangeCurrency={handleCurrencyChange}
				activeTab={activeTab}
				onChangePresale={handlePresaleChange}
				presale={presale}
			/>
		</FormGroup>
	);
};

StudioScheduleSettingsFormRolePriceInput.propTypes = {
	activeTab: PropTypes.string.isRequired,
	currencies: PropTypes.shape({
		participant: PropTypes.arrayOf(PropTypes.string),
		participantPerMinute: PropTypes.arrayOf(PropTypes.string),
		viewer: PropTypes.arrayOf(PropTypes.string),
		viewerPerMinute: PropTypes.arrayOf(PropTypes.string),
	}),
	label: PropTypes.node,
	onChange: PropTypes.func.isRequired,
	presale: PropTypes.shape({
		participant: PropTypes.bool,
		viewer: PropTypes.bool,
	}),
	price: PropTypes.shape({
		participant: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.string,
		]),
		participantPerMinute: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.string,
		]),
		viewer: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.string,
		]),
		viewerPerMinute: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.string,
		]),
	}),
};

StudioScheduleSettingsFormRolePriceInput.defaultProps = {
	currencies: {
		participant: [],
		participantPerMinute: [],
		viewer: [],
		viewerPerMinute: [],
	},
	label: undefined,
	presale: {
		participant: false,
		viewer: false,
	},
	price: {},
};
