import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Label } from 'reactstrap';

export const FormLabel = ({ className, ...props }) => (
	<Label
		className={clsx('font-size-sm font-weight-bold mb-1', className)}
		{...props}
	/>
);

FormLabel.propTypes = {
	className: PropTypes.string,
};

FormLabel.defaultProps = {
	className: '',
};
