import { authenticatedApi, publicApi } from '../api';

const API_PROFILE_PASSWORD_PATH = '/profile/password';

export const resetPasswordBegin = async (account) => publicApi.post(
	`${API_PROFILE_PASSWORD_PATH}/reset/begin`,
	account,
);

export const resetPasswordConfirm = async (account, verificationToken) => {
	await publicApi.post(
		`${API_PROFILE_PASSWORD_PATH}/reset/confirm`,
		{
			...account,
			verification_token: verificationToken,
		},
	);
};

export const changePassword = async ({ currentPassword, newPassword }) => authenticatedApi.post(
	`${API_PROFILE_PASSWORD_PATH}/change`,
	{ currentPassword, newPassword },
);
