import React from 'react';

import PropTypes from 'prop-types';

const PresentationLayout = (props) => {
	const { children } = props;

	return (
		<div className="app-wrapper">
			<div className="app-main">
				{children}
			</div>
		</div>
	);
};

PresentationLayout.propTypes = {
	children: PropTypes.node,
};

PresentationLayout.defaultProps = {
	children: null,
};

export default PresentationLayout;
