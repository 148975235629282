import { publicApi } from '../api';
import { identity } from '../../components/Authentication/Authentication';
import { IdentityError } from '../../lib/Identity';

export const API_PAGEVIEW_PATH = '/stats/pageview';

export const postPageViewData = async (path) => {
	let authorization;
	const browserLanguage = navigator.language;
	const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	try {
		const { token } = await identity.getAccessToken();
		authorization = `Bearer ${token}`;
	} catch (e) {
		if (!(e instanceof IdentityError)) throw e;
		// WARNING: hidden error access_token_not_found
		if (e.type !== 'access_token_not_found') console.warn(e);
	}

	return publicApi.post(
		API_PAGEVIEW_PATH,
		{
			path,
			browserLanguage,
			browserTimezone,
		},
		{
			headers: {
				authorization,
			},
		},
	);
};
