import React from 'react';
import PropTypes from 'prop-types';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ChannelModeSelect } from './ChannelModeSelect';
import { ChannelAutomaticSwitch } from './ChannelAutomaticSwitch';
import { ReactComponent as ChannelModeSelectIcon } from './ChannelModeSelectIcon.svg';
import { ReactComponent as ChannelModeAutomaticSwitchIcon } from './ChannelModeAutomaticSwitchIcon.svg';
import './ChannelMode.scss';

export const ChannelMode = ({
	className,
	setAllMuted,
}) => {
	const { t } = useTranslation();
	return (
		<>
			<UncontrolledDropdown className={clsx('ChannelMode', className)}>
				<DropdownToggle
					color="neutral-secondary d-40 p-0"
					title={t('ChannelMode.ChannelMode.defineWhoCanHear')}
				>
					<ChannelModeSelectIcon />
				</DropdownToggle>
				<DropdownMenu className="bg-dark shadow-none border border-secondary pl-2">
					<strong className="dropdown-header pl-2">{t('ChannelMode.ChannelMode.listening')}</strong>
					<ChannelModeSelect setAllMuted={setAllMuted} />
				</DropdownMenu>
			</UncontrolledDropdown>
			<UncontrolledDropdown className={clsx('ChannelMode', className)}>
				<DropdownToggle
					color="neutral-secondary d-40 p-0"
					title={t('ChannelMode.ChannelMode.usersHeardInTheLive')}
				>
					<ChannelModeAutomaticSwitchIcon />
				</DropdownToggle>
				<DropdownMenu className="bg-dark shadow-none border border-secondary pl-2">
					<strong className="dropdown-header pl-2">{t('ChannelMode.ChannelMode.speakerSwitching')}</strong>
					<ChannelAutomaticSwitch className="bg-transparent" />
				</DropdownMenu>
			</UncontrolledDropdown>
		</>
	);
};

ChannelMode.propTypes = {
	className: PropTypes.string,
	setAllMuted: PropTypes.func.isRequired,
};

ChannelMode.defaultProps = {
	className: undefined,
};

export const ChannelModeListening = ({ className, setAllMuted }) => {
	const { t } = useTranslation();
	return (
		<UncontrolledDropdown className="ChannelModeListening">
			<DropdownToggle
				color={clsx(className)}
				title={t('ChannelMode.ChannelMode.defineWhoCanHear')}
			>
				<ChannelModeSelectIcon width="25" height="25" />
			</DropdownToggle>
			<DropdownMenu className="shadow-none">
				<strong className="dropdown-header content-darker">{t('ChannelMode.ChannelMode.listening')}</strong>
				<ChannelModeSelect setAllMuted={setAllMuted} />
			</DropdownMenu>
		</UncontrolledDropdown>
	);
};

ChannelModeListening.propTypes = {
	className: PropTypes.string,
	setAllMuted: PropTypes.func.isRequired,
};

ChannelModeListening.defaultProps = {
	className: undefined,
};

export const ChannelModeSpeakerSwitch = ({ className }) => (
	<ChannelAutomaticSwitch className={className} />
);

ChannelModeSpeakerSwitch.propTypes = {
	className: PropTypes.string,
};

ChannelModeSpeakerSwitch.defaultProps = {
	className: undefined,
};
