import React, { useState } from 'react';
import {
	Label,
	Input,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ButtonPill } from '../Button';
import { useAuthentication } from '../Authentication/Authentication';
import { getChannelLink, getLink, Path } from '../../RoutePath';

export const LoginNotificationRouting = ({
	hideRoutingModalAndSetDiscard,
	isFirstLogin,
	openIntro,
}) => {
	const { t } = useTranslation();
	const [isDiscard, setIsDiscard] = useState(false);
	const { user } = useAuthentication();

	const isHomeRoute = useRouteMatch({ path: '/', exact: true });

	const handleClick = () => {
		hideRoutingModalAndSetDiscard(isDiscard);
	};

	return (
		<section className="p-3">
			<div className="d-flex flex-column">
				<h6 className="text-center">{isFirstLogin ? t('Login.NotificationRouting.accountCreated') : `${t('Login.NotificationRouting.welcome')} ${user?.nickname}`}</h6>
				<div className="d-flex flex-column">
					<ButtonPill
						className="shadow-none"
						color="white"
						onClick={handleClick}
						tag={Link}
						to={getLink(Path.STUDIO)}
					>
						{t('Login.NotificationRouting.goToStudio')}
					</ButtonPill>
					<ButtonPill
						color="white"
						className="mt-2 shadow-none"
						onClick={handleClick}
						tag={Link}
						to={getChannelLink(user?.preferred_username)}
					>
						{t('Login.NotificationRouting.goToChannel')}
					</ButtonPill>
					{!isHomeRoute ? (
						<ButtonPill
							color="white"
							className="mt-2 shadow-none"
							onClick={handleClick}
							tag={Link}
							to={getLink(Path.ROOT)}
						>
							{t('Login.NotificationRouting.goToHome')}
						</ButtonPill>
					)
						: (
							<ButtonPill
								color="white"
								className="mt-2 shadow-none"
								onClick={() => openIntro()}
							>
								{t('Login.NotificationRouting.seeIntroductionVideo')}
							</ButtonPill>
						)}
					<ButtonPill
						className="mt-2 shadow-none"
						color="white"
						onClick={handleClick}
					>
						{t('Login.NotificationRouting.close')}
					</ButtonPill>
				</div>
				<div className="d-flex justify-content-center mt-2">
					<Label check className="ml-4">
						<Input
							type="checkbox"
							onChange={(event) => setIsDiscard(event.target.checked)}
							checked={isDiscard}
						/>{' '}
						{t('Login.NotificationRouting.dontShowAgain')}
					</Label>
				</div>
			</div>
		</section>
	);
};

LoginNotificationRouting.propTypes = {
	hideRoutingModalAndSetDiscard: PropTypes.func.isRequired,
	isFirstLogin: PropTypes.bool,
	openIntro: PropTypes.func.isRequired,
};

LoginNotificationRouting.defaultProps = {
	isFirstLogin: false,
};
