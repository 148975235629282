export const stopTrack = (track) => {
	track.stop();
	track.dispatchEvent(new Event('ended'));
	// Firefox do not handle this event triggered manually
};

export const Resolution = {
	P1080: 1080,
	P720: 720,
	P480: 480,
};

export const USER_MAX_FPS = 24;
