import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export const ListTitle = ({ className, title }) => (
	<h1 className={classNames('d-flex align-items-center mt-2 mb-3 ml-4 font-size-xl mb-3', className)}>
		<strong className="text-nowrap">{title}</strong>
	</h1>
);

ListTitle.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string.isRequired,
};

ListTitle.defaultProps = {
	className: '',
};
