import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

const PaginationContext = createContext({});

export const usePagination = () => useContext(PaginationContext);

export const PaginationProvider = ({ children, initialItemsPerPage }) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPage || 20);
	const [itemsCount, setItemsCount] = useState(NaN);
	const [pageCount, setPageCount] = useState(NaN);

	const canNextPage = !Number.isNaN(pageCount) && currentPage < pageCount;
	const canPrevPage = !Number.isNaN(pageCount) && currentPage > 1;

	const goNextPage = useCallback(() => {
		if (canNextPage) { setCurrentPage((cP) => cP + 1); }
	}, [canNextPage]);
	const goPrevPage = useCallback(() => {
		if (canPrevPage) { setCurrentPage((cP) => cP - 1); }
	}, [canPrevPage]);
	const goFirstPage = useCallback(() => {
		setCurrentPage(1);
	}, []);
	const goLastPage = useCallback(() => {
		if (pageCount) { setCurrentPage(pageCount); }
	}, [pageCount]);

	const changeItemsPerPage = useCallback((newItemsPerPage) => {
		setItemsPerPage(newItemsPerPage);
		setPageCount(newItemsPerPage !== -1 ? Math.ceil(itemsCount / newItemsPerPage) : 1);
		setCurrentPage(1);
	}, [itemsCount]);

	const updateItemsCount = useCallback((newItemsCount) => {
		setItemsCount(newItemsCount);
		setPageCount(itemsPerPage !== -1 ? Math.ceil(newItemsCount / itemsPerPage) : 1);
	}, [itemsPerPage]);

	const value = useMemo(() => ({
		currentPage,
		itemsPerPage,
		itemsCount,
		pageCount,
		canNextPage,
		canPrevPage,
		goNextPage,
		goPrevPage,
		goFirstPage,
		goLastPage,
		changeItemsPerPage,
		updateItemsCount,
	}), [
		currentPage,
		itemsPerPage,
		itemsCount,
		pageCount,
		canNextPage,
		canPrevPage,
		goNextPage,
		goPrevPage,
		goFirstPage,
		goLastPage,
		changeItemsPerPage,
		updateItemsCount,
	]);

	return (
		<PaginationContext.Provider value={value}>
			{children}
		</PaginationContext.Provider>
	);
};

PaginationProvider.propTypes = {
	children: PropTypes.node.isRequired,
	initialItemsPerPage: PropTypes.number,
};

PaginationProvider.defaultProps = {
	initialItemsPerPage: undefined,
};
