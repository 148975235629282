import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { Tooltip } from 'reactstrap';

export const TooltipTimeout = ({
	onTimeout,
	timeoutTime,
	...props
}) => {
	useEffect(() => {
		const timer = setTimeout(onTimeout, timeoutTime);
		return () => {
			clearTimeout(timer);
		};
	});

	return (
		<Tooltip {...props} />
	);
};

TooltipTimeout.propTypes = {
	onTimeout: PropTypes.func.isRequired,
	timeoutTime: PropTypes.number,
};

TooltipTimeout.defaultProps = {
	timeoutTime: 5000,
};
