import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'react-multi-email/style.css';
import {
	Alert,
	Badge,
	Col,
	Collapse,
	Fade,
	FormGroup,
	Nav,
	NavItem,
	NavLink,
	Row,
	UncontrolledTooltip,
} from 'reactstrap';
import { Form } from 'react-jsonschema-form-validation';
import { useAsyncCallback } from 'react-async-hook';
import { FaCheck, FaSpinner } from 'react-icons/fa';
import 'react-phone-number-input/style.css';

import FormSubmit from '../../../components/Form/Submit';

import * as studioApi from '../../../api/studio/studio';
import { FormLabel } from '../../../components/Form/Label';
import { StudioScheduleSettingsFormPasswordInput } from '../../../components/StudioSchedule/SettingsForm/PasswordInput';
import { StudioScheduleSettingsFormRolePriceInput } from '../../../components/StudioSchedule/SettingsForm/PriceInput';
import { useAsyncErrorLog } from '../../../lib/hooks';
import { useStudioAccess } from './Provider';

export const TooltipDisabledViewers = ({
	target,
	text,
	...props
}) => {
	const { t } = useTranslation();

	return (
		<UncontrolledTooltip
			innerClassName="shadow-none"
			popperClassName="tooltip-secondary"
			placement="bottom"
			target={target}
			trigger="hover"
			{...props}
		>
			<strong className="d-block">{t('Controlroom.AccessSummary.TooltipDisabledViewers.viewerMode')}</strong>
			<p className="mb-0 mt-2">
				{`${text || t('Controlroom.AccessSummary.TooltipDisabledViewers.toInviteViewers')} ${t('Controlroom.AccessSummary.TooltipDisabledViewers.youMustEnable')}`},
				{' '}
				<Badge color="primary">
					<FaCheck />
					{' '}{t('Controlroom.AccessSummary.TooltipDisabledViewers.publicLive')}
				</Badge>
				{' '}{t('Controlroom.AccessSummary.TooltipDisabledViewers.whenYouSchedule')}
			</p>
		</UncontrolledTooltip>
	);
};

TooltipDisabledViewers.propTypes = {
	target: PropTypes.string.isRequired,
	text: PropTypes.string,
};

TooltipDisabledViewers.defaultProps = {
	text: '',
};

export const PARTICIPANTS_TAB = {
	PARTICIPANT: 'participant',
	VIEWER: 'viewer',
};

const studioAccessSchema = {
	type: 'object',
	additionalProperties: false,
	properties: {
		password: {
			type: 'string',
			minLength: 6,
		},
		price: {
			type: 'object',
			additionalProperties: false,
			properties: {
				participant: {
					type: 'number',
					min: 0,
				},
				viewer: {
					type: 'number',
					min: 0,
				},
				participantPerMinute: {
					type: 'number',
					minimum: 0,
				},
				viewerPerMinute: {
					type: 'number',
					minimum: 0,
				},
			},
		},
		currencies: {
			type: 'object',
			additionalProperties: false,
			properties: {
				participant: {
					type: 'array',
					items: { type: 'string' },
				},
				viewer: {
					type: 'array',
					items: { type: 'string' },
				},
				participantPerMinute: {
					type: 'array',
					items: { type: 'string' },
				},
				viewerPerMinute: {
					type: 'array',
					items: { type: 'string' },
				},
			},
		},
	},
	required: [
		'password',
		'price',
	],
};

export const AccessSummary = ({ showAccess }) => {
	const { t } = useTranslation();

	const { data, studio, loading, setData, studioConfig } = useStudioAccess();
	const [activeTab, setActiveTab] = useState(PARTICIPANTS_TAB.PARTICIPANT);

	const handleSubmitAsync = useAsyncCallback(async () => {
		const access = {
			password: data.password,
			price: {
				participant: data.price.participant ? parseInt(data.price.participant, 10) : 0,
				viewer: data.price.viewer ? parseInt(data.price.viewer, 10) : 0,
				participantPerMinute: data.price.participantPerMinute
					? parseInt(data.price.participantPerMinute, 10)
					: 0,
				viewerPerMinute: data.price.viewerPerMinute ? parseInt(data.price.viewerPerMinute, 10) : 0,
			},
			currencies: data.currencies || undefined,
		};

		await studioApi.updateStudioAccess(studio._id, access);
	});
	useAsyncErrorLog(handleSubmitAsync);

	const handleChange = useCallback((change) => {
		handleSubmitAsync.reset();
		setData((state) => ({ ...state, ...change }));
	}, [handleSubmitAsync, setData]);

	return (
		<Collapse
			isOpen={showAccess}
		>
			<Form className="mb-3" schema={studioAccessSchema} data={data} onSubmit={handleSubmitAsync.execute}>
				<Nav className="nav-line justify-content-start text-nowrap text-uppercase">
					<NavItem className="pb-1">
						<NavLink
							title={t('Controlroom.AccessSummary.participants')}
							onClick={() => setActiveTab(PARTICIPANTS_TAB.PARTICIPANT)}
							active={activeTab === PARTICIPANTS_TAB.PARTICIPANT}
							className="pb-2"
						>
							<span>{t('Controlroom.AccessSummary.participants')}</span>
							<div className="divider" />
						</NavLink>
					</NavItem>
					<NavItem className="mr-3 pb-1" id="StudioAccessTab_viewersTab">
						<NavLink
							disabled={!studioConfig?.isPublic}
							title={t('Controlroom.AccessSummary.viewers')}
							onClick={() => setActiveTab(PARTICIPANTS_TAB.VIEWER)}
							active={activeTab === PARTICIPANTS_TAB.VIEWER}
							className="pb-2"
						>
							<span>{t('Controlroom.AccessSummary.viewers')}</span>
							<div className="divider" />
						</NavLink>
						{!studio.isLiveEnabled && (
							<TooltipDisabledViewers
								target="StudioAccessTab_viewersTab"
							/>
						)}
					</NavItem>
				</Nav>
				<Row className="justify-content-between g-0">
					{loading ? (
						<Col>
							<p className="text-center">
								<FaSpinner className="icon-spin" />
							</p>
						</Col>
					) : (
						<>
							<Col lg="5">
								{activeTab === PARTICIPANTS_TAB.PARTICIPANT && (
									<Fade in>
										<FormGroup>
											<FormLabel className="border-bottom border-gray-dark w-100">
												{t('Controlroom.AccessSummary.password')}
											</FormLabel>
											<StudioScheduleSettingsFormPasswordInput
												onChange={handleChange}
												password={data.password}
											/>
										</FormGroup>
									</Fade>
								)}
							</Col>
							<Col lg="5">
								<StudioScheduleSettingsFormRolePriceInput
									label={(
										<FormLabel className="border-bottom border-gray-dark w-100">
											{t('Controlroom.AccessSummary.pricing')}
										</FormLabel>
									)}
									activeTab={activeTab}
									price={data.price}
									onChange={handleChange}
									currencies={data.currencies}
								/>
							</Col>
						</>
					)}
				</Row>
				{(handleSubmitAsync.status === 'success') && (
					<Alert color="success">
						{t('Controlroom.AccessSummary.studioUpdateSuccess')}
					</Alert>
				)}
				{!!handleSubmitAsync.error && (
					<Alert color="danger">
						{t('Global.error')}
					</Alert>
				)}
				<div className="d-flex">
					<FormSubmit
						className="btn-sm btn-pill ml-auto shadow-none"
						disabled={loading}
						title={t('Controlroom.AccessSummary.updateStudio')}
						loading={handleSubmitAsync.loading}
					>
						{t('Controlroom.AccessSummary.update')}
					</FormSubmit>
				</div>
			</Form>
		</Collapse>
	);
};

AccessSummary.propTypes = {
	showAccess: PropTypes.bool,
};

AccessSummary.defaultProps = {
	showAccess: false,
};
