import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Field, FieldError } from 'react-jsonschema-form-validation';
import {
	FormGroup,
	Input,
	Alert,
} from 'reactstrap';
import { useAsyncCallback } from 'react-async-hook';
import { useTranslation } from 'react-i18next';

import { ButtonPillOutline } from '../../../Button';
import FormSubmit from '../../../Form/Submit';
import { FormEmails } from '../../../Form/Emails';
import { FormLabel } from '../../../Form/Label';
import { ShareRecipientType } from '../../../Notification/Notification.helper';
import { ResourceAccessRole } from '../../../../lib/ResourceAccessRole';
import { ShareTabType } from '../../Share.helper';
import { ShareRoleInput } from '../../RoleInput';

import './Email.scss';

const emailSchema = {
	type: 'object',
	additionalProperties: false,
	properties: {
		emails: {
			type: 'array',
			items: {
				type: 'string',
				format: 'email',
			},
			minItems: 1,
		},
		message: {
			type: 'string',
			maxLength: 1000,
		},
	},
};

const getInitialData = (defaultMessage) => ({
	emails: [],
	message: defaultMessage || '',
});

export const ShareTabEmail = ({
	alreadySharedUsers,
	defaultMessage,
	onShare,
	roles,
	selectedRole,
	setSelectedRole,
	shareButtonLabel,
}) => {
	const { t } = useTranslation();

	const [data, setData] = useState(getInitialData(defaultMessage));

	const clear = () => setData((s) => ({ ...s, emails: [] }));

	const handleSubmitAsync = useAsyncCallback(async () => {
		const alreadySharedEmails = alreadySharedUsers.reduce((acc, participant) => {
			if (participant.type !== ShareRecipientType.EMAIL) return acc;
			return {
				...acc,
				[participant.email]: participant,
			};
		}, {});

		const emails = data.emails.map((email) => {
			if (!alreadySharedEmails[email]) {
				return {
					type: ShareRecipientType.EMAIL,
					email,
					message: data.message,
				};
			}
			return null;
		});
		await onShare(emails, selectedRole);
		clear();
	});

	useEffect(() => {
		if (handleSubmitAsync.status === 'success' || handleSubmitAsync.error) {
			const id = setTimeout(() => handleSubmitAsync.reset(), 3000);
			return () => clearTimeout(id);
		}
		return undefined;
	}, [handleSubmitAsync]);

	const handleChange = (values) => {
		setData({
			...data,
			...values,
		});
	};

	const isEmpty = !(data.emails?.length > 0);
	const isActionDisabled = isEmpty || handleSubmitAsync.loading;

	return (
		<Form
			className="ShareTabEmail"
			schema={emailSchema}
			data={data}
			onSubmit={handleSubmitAsync.execute}
		>
			<div className="d-flex mb-3">
				<ButtonPillOutline
					className="mr-2"
					color="dark"
					disabled={isActionDisabled}
					onClick={() => { clear(); }}
				>
					{t('Share.Buttons.clear')}
				</ButtonPillOutline>
				<FormSubmit
					className="btn-pill shadow-none ml-auto"
					disabled={isActionDisabled}
					loading={handleSubmitAsync.loading}
					title={t('Share.Tab.Email.sendInvitationByEmail')}
				>
					{t(shareButtonLabel)}
				</FormSubmit>
			</div>
			{handleSubmitAsync.status === 'success' && (
				<Alert color="success">
					{t('Share.Tab.Email.emailsAddedSuccessfully')}
				</Alert>
			)}
			{handleSubmitAsync.error && (
				<Alert color="danger">
					{t('Global.error')}
				</Alert>
			)}
			<ShareRoleInput
				value={selectedRole}
				onChange={(role) => setSelectedRole(role)}
				roles={roles}
				shareTabType={ShareTabType.EMAIL}
			/>
			<FormGroup>
				<FormLabel htmlFor="emails">{t('Share.sendTo')}</FormLabel>
				<Field
					component={FormEmails}
					name="emails"
					placeholder={t('Share.Tab.Email.emails')}
					emails={data.emails}
					className="bg-dark content-dark rounded-1 border-dark"
					tagClassName="bg-light content-light"
					onChange={(emails) => handleChange({ emails })}
				/>

				<FieldError name="emails" />
			</FormGroup>
			<FormGroup>
				<FormLabel>{t('Share.Message.message')}</FormLabel>
				<Field
					className="bg-dark content-dark rounded-1 border-dark"
					component={Input}
					name="message"
					onChange={(e) => handleChange({ message: e.target.value })}
					placeholder={t('Share.Message.addCustomMessage')}
					rows="3"
					type="textarea"
					value={data.message}
				/>
				<FieldError name="message" />
			</FormGroup>
		</Form>
	);
};

ShareTabEmail.propTypes = {
	alreadySharedUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	defaultMessage: PropTypes.string,
	onShare: PropTypes.func.isRequired,
	roles: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(ResourceAccessRole))).isRequired,
	selectedRole: PropTypes.oneOf(Object.keys(ResourceAccessRole)).isRequired,
	setSelectedRole: PropTypes.func.isRequired,
	shareButtonLabel: PropTypes.string.isRequired,
};

ShareTabEmail.defaultProps = {
	defaultMessage: '',
};
