import React, { useMemo } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BeeyouLogo } from '../../images/beeyou.svg';
import { ScreenThemeLayout, useScreenTheme } from '../../components/Studio/ScreenTheme/Provider';

export const Footer = () => {
	const { t } = useTranslation();

	const { currentScreenTheme, handleChangeCurrentScreenTheme } = useScreenTheme();

	const helpCenterMenuItems = useMemo(() => [
		{ label: t('SidebarMenu.Index.gettingStarted'), to: '/help/gettingStarted' },
		{ label: t('SidebarMenu.Index.userGuide'), to: '/help/userGuide' },
		{ label: t('SidebarMenu.Index.faqs'), to: '/help/faq' },
		{ label: t('SidebarMenu.Index.troubleshooting'), to: '/help/troubleshooting' },
	], [t]);

	const legalMenuItems = useMemo(() => [
		{ label: t('SidebarMenu.Index.termsAndPrivacy'), to: '/legal/terms' },
		{ label: t('SidebarMenu.Index.cookiesPolicy'), to: '/legal/cookies' },
	], [t]);

	return (
		<Container fluid className="w-100 px-5 mt-5 pt-5 mb-5">
			<div className="divider bg-secondary opacity-25" />
			<Row className="w-100 py-4 mt-4">
				<Col md={3}>
					<div className="nav-logo d-flex align-items-center flex-fill mw-100">
						<Link
							to="/"
							title={t('SidebarHeader.Index.homepage')}
						>
							<BeeyouLogo className="mr-2" height="40px" />
							<div className="d-none d-md-block position-relative">
								<span className="content-darker">
									beeyou.tv
								</span>
								<span className="position-absolute d-block small text-primary right-0 line-height-crop">
									beta
								</span>
							</div>
						</Link>
					</div>
				</Col>
				<Col md={3} className="pt-2">
					<h6 className="mb-4 font-weight-bold">{t('SidebarMenu.Index.helpCenter')}</h6>
					<ul className="list-unstyled">
						{helpCenterMenuItems.map((helpCentarMenuItem) => (
							<li className="mb-2">
								<Link
									to={helpCentarMenuItem.to}
									className="text-secondary"
								>
									{helpCentarMenuItem.label}
								</Link>
							</li>
						))}
					</ul>
				</Col>
				<Col md={3} className="pt-2">
					<h6 className="mb-4 font-weight-bold">{t('SidebarMenu.Index.legalNotice')}</h6>
					<ul className="list-unstyled">
						{legalMenuItems.map((helpCentarMenuItem) => (
							<li className="mb-2">
								<Link
									to={helpCentarMenuItem.to}
									className="text-secondary"
								>
									{helpCentarMenuItem.label}
								</Link>
							</li>
						))}
					</ul>
				</Col>
				<Col md={3} className="pt-2">
					<h6 className="mb-4 font-weight-bold">{t('SidebarMenu.Index.settings')}</h6>
					<ul className="list-unstyled">
						<li
							className="mb-2"
							onClick={() => handleChangeCurrentScreenTheme(
								currentScreenTheme === ScreenThemeLayout.DARK
									? ScreenThemeLayout.LIGHT
									: ScreenThemeLayout.DARK,
							)}
						>
							<span className="cursor-pointer text-secondary">
								{t('SidebarMenu.Index.toggleTheme')} {currentScreenTheme === ScreenThemeLayout.DARK ? t('SidebarMenu.Index.lightTheme') : t('SidebarMenu.Index.darkTheme')}
							</span>
						</li>
					</ul>
				</Col>
			</Row>
		</Container>
	);
};
