import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { Form as SchemaForm, Field, FieldError } from 'react-jsonschema-form-validation';
import {
	Alert,
	Button,
	Container,
	FormGroup,
	Input,
} from 'reactstrap';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { FormLabel } from '../Form/Label';

import FormSubmit from '../Form/Submit';
import { schema } from './confirm.schema';

const initFormData = () => ({
	code: '',
});

export const LoginConfirm = ({
	onBack,
	onResend,
	onSubmit,
}) => {
	const { t } = useTranslation();
	const [formData, setFormData] = useState(initFormData());

	const handleSubmitAsync = useAsyncCallback(async () => {
		ReactGA.event({ category: 'signup', action: 'confirm:next', label: 'User clicked on Next button in Signup Confirm step' });
		return onSubmit(formData);
	});
	const { error: errorSubmit } = handleSubmitAsync;
	if (errorSubmit) console.error(errorSubmit);

	const handleResendAsync = useAsyncCallback(async () => {
		ReactGA.event({ category: 'signup', action: 'confirm:resend', label: 'User clicked on Resend button in Signup Confirm step' });
		await onResend();
		return new Promise((r) => { setTimeout(r, 3000); });
	});
	const { error: errorResend } = handleSubmitAsync;
	if (errorResend) console.error(errorResend);

	const handleClickBack = useCallback(() => {
		ReactGA.event({ category: 'signup', action: 'confirm:back', label: 'User clicked on back button in Signup Confirm step' });
		return onBack();
	}, [onBack]);

	const isSubmitForbiddenError = errorSubmit?.response?.status === 403;
	const isUnknownError = (errorResend || errorSubmit) && !isSubmitForbiddenError;

	const resetSubmitRef = useRef(handleSubmitAsync.reset);
	useEffect(() => { resetSubmitRef.current = handleSubmitAsync.reset; });

	const resetResendRef = useRef(handleSubmitAsync.reset);
	useEffect(() => { resetResendRef.current = handleSubmitAsync.reset; });

	const handleChange = useCallback((data) => {
		resetSubmitRef.current();
		resetResendRef.current();
		setFormData((state) => ({ ...state, ...data }));
	}, []);

	return (
		<Container fluid>
			<SchemaForm
				className="h-100 d-flex flex-column mt-4 mx-0 form-light"
				data={formData}
				onChange={handleChange}
				onSubmit={handleSubmitAsync.execute}
				schema={schema}
			>
				<FormGroup>
					<FormLabel>{t('Onboarding.SetpConfirm.confirmationCode')}</FormLabel>
					<Field
						component={Input}
						placeholder="000000"
						name="code"
						type="string"
						value={formData.code}
					/>
					<FieldError
						name="code"
						errorMessages={{
							pattern: () => 'Onboarding.SetpConfirm.error.confirmationCodePattern',
						}}
					/>
					{isSubmitForbiddenError && (
						<Alert className="mt-2" color="danger">
							{t('Onboarding.SetpConfirm.error.confirmationCode')}
						</Alert>
					)}
				</FormGroup>
				<p>
					{t('Onboarding.SetpConfirm.confirmationCodeNotReceived')}
					<Button
						color="link"
						disabled={handleResendAsync.loading || handleSubmitAsync.loading}
						onClick={handleResendAsync.execute}
					>
						{t('Onboarding.SetpConfirm.confirmationCodeResend')}
					</Button>
				</p>
				<footer className="mt-auto">
					{isUnknownError && (
						<Alert color="danger">
							{t('Global.error')}
						</Alert>
					)}
					<div className="d-flex">
						<Button
							color="link"
							disabled={handleResendAsync.loading || handleSubmitAsync.loading}
							onClick={handleClickBack}
						>
							{t('Onboarding.SetpConfirm.back')}
						</Button>
						<FormSubmit
							className="ml-auto px-5"
							disabled={handleResendAsync.loading}
							loading={handleSubmitAsync.loading}
						>
							{t('Onboarding.SetpConfirm.next')}
						</FormSubmit>
					</div>
				</footer>
			</SchemaForm>
		</Container>
	);
};

LoginConfirm.propTypes = {
	onBack: PropTypes.func.isRequired,
	onResend: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
};
