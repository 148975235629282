import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useVote } from '../../Provider';
import { ReportTable } from './Table';
import { VoteAudienceType } from '../Create';

export const VoteReport = ({ studioId }) => {
	const { t } = useTranslation();
	const { report, fetchReport } = useVote();
	const [activeTab, setActiveTab] = useState(VoteAudienceType.PARTICIPANT);

	const handleChangeAudienceTab = (audienceType) => {
		fetchReport(
			studioId,
			audienceType,
		);
		setActiveTab(audienceType);
	};

	return report ? (
		<>
			<div className="nav-line-alt nav-line-dark w-100 p-4">
				<Nav className="nav-line w-100">
					<NavItem>
						<NavLink
							title={VoteAudienceType.PARTICIPANT}
							onClick={() => handleChangeAudienceTab(VoteAudienceType.PARTICIPANT)}
							active={activeTab === VoteAudienceType.PARTICIPANT}
						>
							<span>{t('Report.Report.participants')}</span>
							<div className="divider" />
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							title={VoteAudienceType.VIEWER}
							onClick={() => handleChangeAudienceTab(VoteAudienceType.VIEWER)}
							active={activeTab === VoteAudienceType.VIEWER}
						>
							<span>{t('Report.Report.viewers')}</span>
							<div className="divider" />
						</NavLink>
					</NavItem>
				</Nav>
			</div>
			<ReportTable
				studioId={studioId}
			/>
		</>
	) : null;
};

VoteReport.propTypes = {
	studioId: PropTypes.string.isRequired,
};
