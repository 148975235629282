import { useCallback } from 'react';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import * as postsApi from '../../api/channel/posts';
import { ChannelPostsMode } from '../../components/Channel/Posts/ChannelPostsMode';
import { axiosMutationWrapper, axiosQueryWrapper } from '../utils/axios-wrapper';

const POST_QUERY_KEYS = {
	fetchPostsByHashtag: (hashtag, mode = ChannelPostsMode.MINE) => ['api', 'channel', 'posts', 'fetch', hashtag, mode],
	fetchPostById: (postId) => ['api', 'channel', 'posts', 'fetch', postId],
	fetchPostLikesById: (postId) => ['api', 'channel', 'posts', 'fetch', 'likes', postId],
	fetchPinnedPostByHashtag: (hashtag) => ['api', 'channel', 'posts', 'fetch', 'pinned', hashtag],
};

const POSTS_PER_PAGE_LIMIT = 20;

export const useFetchPosts = (hashtag, mode) => useInfiniteQuery(
	POST_QUERY_KEYS.fetchPostsByHashtag(hashtag, mode),
	({ pageParam }) => axiosQueryWrapper(
		postsApi.fetchPosts, hashtag, mode, pageParam,
	)(),
	{
		getNextPageParam: (lastPage) => {
			if (lastPage?.length && lastPage[0]?.createdAt && lastPage.length >= POSTS_PER_PAGE_LIMIT) {
				return lastPage[lastPage.length - 1].createdAt;
			}

			return undefined;
		},
		select: (data) => ({
			pages: [...data.pages],
			pageParams: [...data.pageParams],
		}),
		enabled: !!hashtag,
		refetchOnWindowFocus: false,
	},
);

export const useFetchPost = (slug) => useQuery(
	POST_QUERY_KEYS.fetchPostById(slug),
	axiosQueryWrapper(postsApi.fetchPost, slug),
	{ enabled: !!slug },
);

const ITEMS_PER_PAGE = 10;

export const useInfinityFetchPostLikes = (
	postId,
	itemsPerPage = ITEMS_PER_PAGE,
) => useInfiniteQuery(
	POST_QUERY_KEYS.fetchPostLikesById(postId),
	({ pageParam = 1 }) => axiosQueryWrapper(
		postsApi.fetchPostLikes,
		postId,
		pageParam,
		itemsPerPage,
	)(),
	{
		getNextPageParam: (lastPage, allPages) => {
			const nextPage = allPages.length + 1;
			return lastPage.length === itemsPerPage ? nextPage : undefined;
		},
	},
);

export const useFetchPinnedPost = (hashtag, isLoggedIn) => useQuery(
	POST_QUERY_KEYS.fetchPinnedPostByHashtag(hashtag),
	axiosQueryWrapper(postsApi.fetchPinnedPost, hashtag),
	{ enabled: !!isLoggedIn && !!hashtag },
);

const getPostMode = (hashtag, postHashtag) => (hashtag === postHashtag
	? ChannelPostsMode.MINE
	: ChannelPostsMode.MENTIONS);

export const useHandleNewPost = (hashtag) => {
	const queryClient = useQueryClient();

	return useCallback((newPost) => {
		queryClient.invalidateQueries(POST_QUERY_KEYS.fetchPostsByHashtag(newPost.channel.hashtag));

		queryClient.invalidateQueries(
			POST_QUERY_KEYS.fetchPinnedPostByHashtag(hashtag),
		);
	}, [hashtag, queryClient]);
};

export const useHandleUpdatedPost = (hashtag) => {
	const queryClient = useQueryClient();

	return useCallback((updatedPost) => {
		queryClient.invalidateQueries(POST_QUERY_KEYS.fetchPostsByHashtag(updatedPost.channel.hashtag));

		queryClient.setQueryData(
			POST_QUERY_KEYS.fetchPostById(updatedPost._id),
			(prevPost) => ({
				...prevPost,
				...updatedPost,
			}),
		);

		queryClient.setQueryData(
			POST_QUERY_KEYS.fetchPinnedPostByHashtag(hashtag),
			(prevPosts) => prevPosts?.map(
				(prevPost) => (prevPost._id === updatedPost._id
					? {
						...prevPost,
						...updatedPost,
					}
					: prevPost),
			),
		);
	}, [hashtag, queryClient]);
};

export const useHandleDeletedPost = (hashtag) => {
	const queryClient = useQueryClient();

	return useCallback((deletedPost) => {
		queryClient.invalidateQueries(POST_QUERY_KEYS.fetchPostsByHashtag(deletedPost.channel.hashtag));

		queryClient.setQueryData(
			POST_QUERY_KEYS.fetchPinnedPostByHashtag(hashtag),
			(posts) => posts.pages
				?.filter((post) => (post._id !== deletedPost._id)),
		);
	}, [hashtag, queryClient]);
};

export const useHandlePostLikesChange = () => {
	const queryClient = useQueryClient();

	return useCallback((likedPost) => {
		queryClient.invalidateQueries(POST_QUERY_KEYS.fetchPostsByHashtag(likedPost.channel.hashtag));

		queryClient.setQueryData(
			POST_QUERY_KEYS.fetchPostById(likedPost._id),
			(prevPost) => ({
				...prevPost,
				numberOfLikes: likedPost.numberOfLikes,
			}),
		);
	}, [queryClient]);
};

export const useHandlePostPinnedChange = (hashtag) => {
	const queryClient = useQueryClient();
	const handlePostUpdated = useHandleUpdatedPost(hashtag);

	return useCallback((post) => {
		if (post.pinned) {
			queryClient.setQueryData(
				POST_QUERY_KEYS.fetchPinnedPostByHashtag(
					hashtag,
				),
				(prevPosts) => [
					post,
					...prevPosts,
				],
			);
		} else {
			queryClient.setQueryData(
				POST_QUERY_KEYS.fetchPinnedPostByHashtag(
					hashtag,
				),
				(prevPosts) => prevPosts?.filter((prevPost) => prevPost._id !== post._id),
			);
		}
		handlePostUpdated({
			_id: post._id,
			channel: post.channel,
			pinned: post.pinned,
		});
	}, [handlePostUpdated, hashtag, queryClient]);
};

export const useHandlePostLikedChanged = () => {
	const queryClient = useQueryClient();

	return useCallback((hashtag, mode, postId, liked) => {
		queryClient.setQueryData(
			POST_QUERY_KEYS.fetchPostsByHashtag(hashtag, mode),
			(posts) => ({
				...posts,
				pages: (posts.pages?.map((post) => (post._id === postId ? {
					...post,
					liked,
				}
					: post))),
			}),
		);

		queryClient.setQueryData(
			POST_QUERY_KEYS.fetchPostById(postId),
			(prevPost) => ({
				...prevPost,
				liked,
			}),
		);
	}, [queryClient]);
};

export const useCreatePost = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(postsApi.createPost),
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries(POST_QUERY_KEYS.fetchPostsByHashtag(data.hashtag));
			},
		},

	);
};

export const useUpdatePost = () => {
	const queryClient = useQueryClient();

	return useMutation(
		async ({ id, text, images, videos, surveyTemplate }) => axiosMutationWrapper(
			postsApi.updatePost,
		)({ id, text, images, videos, surveyTemplate }), {
			onSuccess: (data) => {
				queryClient.invalidateQueries(POST_QUERY_KEYS.fetchPostsByHashtag(data.hashtag));
			},
		},
	);
};

export const useDeletePost = () => useMutation(
	axiosMutationWrapper(postsApi.deletePost),
);

export const useSharePostInChat = () => useMutation(
	axiosMutationWrapper(postsApi.sharePostInChat),
);

export const useLikePost = () => useMutation(
	axiosMutationWrapper(postsApi.likePost),
);

export const useUnlikePost = () => useMutation(
	axiosMutationWrapper(postsApi.unlikePost),
);

export const usePinPost = () => useMutation(
	axiosMutationWrapper(postsApi.pinPost),
);

export const useUnpinPost = () => useMutation(
	axiosMutationWrapper(postsApi.unpinPost),
);
