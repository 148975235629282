import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { IoMdCheckmark, IoMdClose } from 'react-icons/io';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ResourceAccessRole } from '../../../lib/ResourceAccessRole';
import { NotificationType } from '../../../lib/NotificationType';
import { useLocalizedTimeAgo } from '../../../lib/timeAgo';
import { getLink, Path } from '../../../RoutePath';
import { ADS_LOCAL_STORAGE_KEY } from '../../Studio/Loader';

const getStudioLink = (role, hashtag, code) => {
	switch (role) {
	case ResourceAccessRole.VIEWER:
		return getLink(Path.STUDIO_VIEWER, { hashtag, code });
	case ResourceAccessRole.PARTICIPANT:
		return getLink(Path.STUDIO_PARTICIPANT, { hashtag, code });
	case ResourceAccessRole.OPERATOR:
		return getLink(Path.STUDIO_OPERATOR, { hashtag, code });
	case ResourceAccessRole.OWNER:
		return getLink(Path.STUDIO_HOST, { hashtag, code });
	default:
		throw new TypeError(`Incorrect role ${role}`);
	}
};

const translationKeyRegex = /^[a-zA-Z]+(\.[a-zA-Z]+)*$/;

const NotificationToastInvitation = ({
	notification,
	closeToast,
}) => {
	const { t } = useTranslation();
	const timeAgo = useLocalizedTimeAgo();
	const {
		data: {
			channel,
			code,
			password,
			role,
			startAt,
		},
		sender,
		type,
		message,
	} = notification;

	const getInvitationTextFromRole = () => {
		switch (role) {
		case ResourceAccessRole.VIEWER: {
			return t('Notification.Toast.IInviteYou');
		}
		case ResourceAccessRole.PARTICIPANT: {
			return t('Notification.Toast.IInviteYouToParticipate');
		}
		case ResourceAccessRole.OPERATOR:
		case ResourceAccessRole.OWNER: {
			return t('Notification.Toast.IInviteYouToOperate');
		}
		default:
			throw new TypeError(`Incorrect role ${role}`);
		}
	};

	const getNotificationMessage = () => {
		if (message) {
			if (translationKeyRegex.test(message)) {
				return t(message);
			}
			return message;
		}
		return getInvitationTextFromRole();
	};

	const getNotificationTextAndLink = (notificationType) => {
		switch (notificationType) {
		case NotificationType.STUDIO_HOST_REMINDER:
			return {
				text: t('Notification.Toast.studioStartSoon'),
				redirect: getLink(Path.STUDIO),
			};
		case NotificationType.STUDIO_IDLE:
			return {
				text: t('Notification.Toast.studioIdle'),
				redirect: getLink(Path.STUDIO),
			};
		case NotificationType.PUBLIC_STUDIO_START_SOON: {
			let time = 'soon';
			if (startAt) { time = timeAgo.format(new Date(startAt), { future: true }); }
			return {
				text: (
					<>
						<b className="text-capitalize">{sender.nickname}</b>{' '}
						{t('Notification.Toast.willStartAPublic')} {time}.
					</>
				),
				redirect: getStudioLink(role, channel.hashtag, code),
				useLink: true,
			};
		}
		case NotificationType.STUDIO_INVITE:
		default:
			return {
				redirect: `${getStudioLink(role, channel.hashtag, code)}${password ? `?pwd=${password}` : ''}`,
				text: getNotificationMessage(),
			};
		}
	};
	const { redirect, text, useLink } = getNotificationTextAndLink(type);

	const skipAd = () => {
		// Used when promoting a viewer to participant. We add the studio _id to the already
		// watched ads (if not already there) to not display the ad to the user.
		const listStudioAds = JSON.parse(localStorage.getItem(ADS_LOCAL_STORAGE_KEY)) || [];
		if (!listStudioAds.some((ad) => ad.studioId === notification.data.studioId)) {
			const newListStudioAds = [
				...listStudioAds,
				{ studioId: notification.data.studioId, date: new Date().toISOString() },
			];
			localStorage.setItem(ADS_LOCAL_STORAGE_KEY, JSON.stringify(newListStudioAds.filter(
				(ad) => new Date(ad.date).getTime() > new Date().getTime() - (48 * 60 * 60 * 1000),
			)));
		}
	};

	const handleAccept = () => {
		if (notification.skipAd) skipAd();
		closeToast();
	};

	return (
		<div
			className={clsx(
				'd-flex justify-content-between',
				{
					'align-items-center': !useLink,
					'flex-column': useLink,
				},
			)}
		>
			<div>{text}</div>
			<div className="d-flex align-items-center justify-content-between flex-shrink-0">
				{!useLink ? (
					<>
						<Button
							color="neutral-success"
							className="d-30 p-0 btn-animated-icon btn-animated-icon--success m-1"
							tag={Link}
							to={redirect}
							onClick={handleAccept}
						>
							<span className="btn-wrapper--icon">
								<IoMdCheckmark
									color="var(--success)"
								/>
							</span>
						</Button>
						<Button
							color="neutral-danger"
							className="d-30 p-0 btn-animated-icon btn-animated-icon--danger m-1"
							onClick={closeToast}
						>
							<span className="btn-wrapper--icon">
								<IoMdClose color="var(--danger)" />
							</span>
						</Button>
					</>
				) : (
					<Link to={redirect} onClick={closeToast} className="ml-auto mt-2">
						{t('Notification.Toast.moreInfo')}
					</Link>
				) }
			</div>
		</div>
	);
};

NotificationToastInvitation.propTypes = {
	notification: PropTypes.shape({
		data: PropTypes.shape({
			channel: PropTypes.shape({
				hashtag: PropTypes.string.isRequired,
			}).isRequired,
			code: PropTypes.string.isRequired,
			password: PropTypes.string,
			role: PropTypes.oneOf(Object.values(ResourceAccessRole)),
			startAt: PropTypes.string,
			studioId: PropTypes.string,
		}).isRequired,
		sender: PropTypes.shape({
			avatar: PropTypes.string,
			nickname: PropTypes.string.isRequired,
		}).isRequired,
		skipAd: PropTypes.bool,
		type: PropTypes.oneOf(Object.values(NotificationType)),
		message: PropTypes.string,
	}).isRequired,
	closeToast: PropTypes.func.isRequired,
};

export default NotificationToastInvitation;
