import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { usePlayerMode, PlayerModes } from './PlayerModeProvider';

export const DrawerContext = React.createContext({});
export const useDrawer = () => useContext(DrawerContext);

export const DrawerProvider = ({
	children,
}) => {
	const [config, setConfig] = useState({
		colorPrimary: '#FEC82E',
		colorSecondary: 'rgba(0, 0, 0, 0)',
		penWidth: '2',
	});
	const [isDrawEnabled, setIsDrawEnabled] = useState(false);
	const [drawMode, setDrawMode] = useState('pen');
	const { mode, setMode } = usePlayerMode();

	const setShowCanvas = useCallback((value) => {
		setMode(value ? PlayerModes.DRAW : undefined);
	}, [setMode]);

	const showCanvas = mode === PlayerModes.DRAW;

	const setPrimaryColor = useCallback((colorPrimary) => {
		setConfig((prevState) => ({ ...prevState, colorPrimary }));
	}, []);

	const setSecondaryColor = useCallback((colorSecondary) => {
		setConfig((prevState) => ({ ...prevState, colorSecondary }));
	}, []);

	const setPenWidth = useCallback((penWidth) => {
		setConfig((prevState) => ({ ...prevState, penWidth }));
	}, []);

	useEffect(() => {
		if (
			mode === PlayerModes.DRAW
			&& !isDrawEnabled
		) {
			setShowCanvas(false);
		}
	}, [isDrawEnabled, mode, setShowCanvas]);

	const contextValue = useMemo(() => ({
		config,
		isDrawEnabled,
		setPrimaryColor,
		setSecondaryColor,
		setIsDrawEnabled,
		setPenWidth,
		setShowCanvas,
		showCanvas,
		drawMode,
		setDrawMode,
	}), [
		config,
		isDrawEnabled,
		setPrimaryColor,
		setSecondaryColor,
		setIsDrawEnabled,
		setPenWidth,
		setShowCanvas,
		showCanvas,
		drawMode,
		setDrawMode,
	]);

	return (
		<DrawerContext.Provider value={contextValue}>
			{children}
		</DrawerContext.Provider>
	);
};

DrawerProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
