import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { VideoSeekSlider } from 'react-video-seek-slider';
import 'react-video-seek-slider/styles.css';

import './VideoTimeline.scss';

const displayTime = (time) => {
	const h = Math.floor(time / 3600);
	const m = Math.floor((time - h * 3600) / 60);
	const s = Math.floor(time - h * 3600 - m * 60);

	let timeToDisplay = (h > 0) ? (`${h.toString().padStart(2, '0')}:`) : '';
	timeToDisplay += `${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
	return timeToDisplay;
};

export const VideoTimeline = ({
	bufferedPercent,
	className,
	currentTime,
	duration,
	handleChangeProgressTime,
}) => 	(
	<div className={clsx('VideoTimeline d-flex align-items-center flex-grow-1', className)}>
		<span className="mr-1 p-1 text-secondary">{displayTime(currentTime)}</span>
		<VideoSeekSlider
			max={duration}
			currentTime={currentTime}
			progress={duration * bufferedPercent}
			onChange={(time) => {
				handleChangeProgressTime(time);
			}}
			offset={0}
			secondsPrefix="00:00:"
			minutesPrefix="00:"
		/>
		<span className="ml-1 p-1 text-secondary">{displayTime(duration)}</span>
	</div>

);
VideoTimeline.propTypes = {
	bufferedPercent: PropTypes.number,
	className: PropTypes.string,
	currentTime: PropTypes.number.isRequired,
	duration: PropTypes.number,
	handleChangeProgressTime: PropTypes.func.isRequired,
};

VideoTimeline.defaultProps = {
	bufferedPercent: 0,
	className: '',
	duration: 0,
};
