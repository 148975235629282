import { authenticatedApi } from '../api';

export const API_CHANNEL_GROUPS_PATH = '/channel/groups';

export const fetchGroups = () => authenticatedApi.get(`${API_CHANNEL_GROUPS_PATH}`);

export const fetchGroup = (id) => authenticatedApi.get(`${API_CHANNEL_GROUPS_PATH}/${id}`);

export const createGroup = (group) => authenticatedApi.post(
	`${API_CHANNEL_GROUPS_PATH}/`,
	group,
);

export const updateGroup = (group) => authenticatedApi.put(
	`${API_CHANNEL_GROUPS_PATH}/${group._id}`,
	group,
);

export const deleteGroup = (group) => authenticatedApi.delete(
	`${API_CHANNEL_GROUPS_PATH}/${group._id}`,
);

export const leaveGroup = (groupId) => authenticatedApi.post(
	`${API_CHANNEL_GROUPS_PATH}/${groupId}/leave`,
);
