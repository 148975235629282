import { upload } from '../upload';
import { authenticatedApi } from '../api';

const API_PROFILE_ME_PATH = '/profile/me';

export const fetch = async () => authenticatedApi.get(`${API_PROFILE_ME_PATH}`);

export const update = async (profile) => {
	if (profile.avatar === null) profile.avatar = ''; // remove avatar only if null (not undefined)
	if (profile.cover === null) profile.cover = ''; // remove cover only if null (not undefined)

	let { avatar, cover } = profile;
	if (avatar?.blob) {
		avatar = await upload(avatar.blob);
	}
	if (cover?.blob) {
		cover = await upload(cover.blob);
	}

	const profileUpdate = {
		...profile,
		avatar,
		cover,
	};

	return authenticatedApi.put(
		`${API_PROFILE_ME_PATH}`,
		profileUpdate,
	);
};
