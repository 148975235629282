import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useQueryParams = () => new URLSearchParams(useLocation().search);

export const useAsyncErrorLog = ({ error }) => {
	useEffect(() => {
		if (error) console.error(error);
	}, [error]);
};
