import { NotificationType } from '../../lib/NotificationType';

export const NotificationAction = {
	PROMOTE_TO_PARTICIPANT: 'PROMOTE_TO_PARTICIPANT',
	DEMOTE_TO_VIEWER: 'DEMOTE_TO_VIEWER',
};

const promoteToParticipant = ({ notification }) => {
	notification.skipAd = true;
	notification.message = 'Notification.Toast.IInviteYouToParticipate';
};

const demoteToViewer = ({ notification, history }) => {
	notification.message = 'Notification.Toast.youHaveBeenRemovedAsAParticipant';
	notification.type = NotificationType.INFORMATION;
	history.push(`/c/${notification.data.channel.hashtag}/v/${notification.data.code}`);
};

export const NotificationActionHandler = {
	[NotificationAction.PROMOTE_TO_PARTICIPANT]: (params) => promoteToParticipant(params),
	[NotificationAction.DEMOTE_TO_VIEWER]: (params) => demoteToViewer(params),
};
