import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { UserPreferencesContext } from './Context';

export const LOCAL_STORAGE_KEY = 'beeyou_userPrefences';

const localStorageValue = localStorage.getItem(LOCAL_STORAGE_KEY);
const storedUserPreferences = localStorageValue ? JSON.parse(localStorageValue) : undefined;

export const UserPreferencesProvider = ({ children }) => {
	const [userPreferences, setUserPreferences] = useState(storedUserPreferences || {});

	const contextValue = useMemo(() => ({
		getUserPreference: (key) => userPreferences[key],
		userPreferences,
		setUserPreference: (data) => setUserPreferences((s) => ({ ...s, ...data })),
		setUserPreferences,
	}), [
		userPreferences,
	]);

	useEffect(() => {
		if (!userPreferences) {
			localStorage.removeItem(LOCAL_STORAGE_KEY);
		} else {
			localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(userPreferences));
		}
	}, [userPreferences]);

	return (
		<UserPreferencesContext.Provider value={contextValue}>
			{children}
		</UserPreferencesContext.Provider>
	);
};

UserPreferencesProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
