import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { AnalyticsRange } from '../../../../lib/AnalyticsRange';
import { AnalyticsContext } from './Context';
import { getDayDifference } from '../../../../lib/dates';

export const getAnalyticsDefaultDateRange = () => ({
	startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 5, 1),
	endDate: new Date(),
});

export const AnalyticsProvider = ({ children }) => {
	const [analyticsDates, setAnalyticsDates] = useState(getAnalyticsDefaultDateRange());
	const [analyticsRange, setAnalyticsRange] = useState(AnalyticsRange.MONTHLY);

	const selectSuggestedDateRange = useCallback((daysBetweenDates) => {
		if (daysBetweenDates < 14) {
			setAnalyticsRange(AnalyticsRange.DAILY);
		} else if (daysBetweenDates > 14 && daysBetweenDates < 60) {
			setAnalyticsRange(AnalyticsRange.WEEKLY);
		} else if (daysBetweenDates > 60 && daysBetweenDates < 240) {
			setAnalyticsRange(AnalyticsRange.MONTHLY);
		} else if (daysBetweenDates > 240 && daysBetweenDates < 660) {
			setAnalyticsRange(AnalyticsRange.QUARTERLY);
		} else {
			setAnalyticsRange(AnalyticsRange.ANNUALLY);
		}
	}, []);

	const selectAnalyticDates = useCallback((dates) => {
		const daysBetweenDates = getDayDifference(dates.startDate, dates.endDate);

		selectSuggestedDateRange(daysBetweenDates);

		setAnalyticsDates(dates);
	}, [selectSuggestedDateRange]);

	const value = useMemo(() => ({
		analyticsDates,
		analyticsRange,
		selectAnalyticDates,
	}), [
		analyticsDates,
		analyticsRange,
		selectAnalyticDates,
	]);

	return (
		<AnalyticsContext.Provider value={value}>
			{children}
		</AnalyticsContext.Provider>
	);
};

AnalyticsProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
