import { useCallback, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { axiosQueryWrapper } from '../utils/axios-wrapper';
import * as graphGlobalsApi from '../../api/graph/globals';
import { useAuthentication } from '../../components/Authentication/Authentication';

export const GRAPH_GLOBALS_QUERY_KEYS = {
	fetchGraphGlobals: (userId) => ['api', 'graph', 'globals', 'fetch', userId],
};

const GRAPH_GLOBALS_KEY = {
	PROFILE: 'profile',
	ACTIVE_STUDIO: 'activeStudio',
	PRICES: 'prices',
	BALANCES: 'balances',
	FRIEND_REQUESTS: 'friendRequests',
};

const fetchGraphGlobals = axiosQueryWrapper(graphGlobalsApi.fetchGraphGlobals);

export const useFetchGraphGlobals = (options) => {
	const { user } = useAuthentication();
	const queryClient = useQueryClient();

	useEffect(() => {
		const queryKey = GRAPH_GLOBALS_QUERY_KEYS.fetchGraphGlobals(user?.sub);
		return () => {
			queryClient.setQueryData(queryKey, () => undefined);
			queryClient.removeQueries(queryKey, () => undefined);
		};
	}, [queryClient, user?.sub]);

	return useQuery(
		GRAPH_GLOBALS_QUERY_KEYS.fetchGraphGlobals(user?.sub),
		fetchGraphGlobals,
		{
			...options,
			enabled: !!user && !(options?.enabled === false),
		},
	);
};

const getFetchGraphGlobalsHook = (property) => (options) => {
	const select = (data) => data?.[property];
	return useFetchGraphGlobals({
		...options,
		select,
	});
};

const getSetGraphGlobalsHook = (property) => () => {
	const { user } = useAuthentication();
	const queryClient = useQueryClient();

	return useCallback((data) => {
		const queryKey = GRAPH_GLOBALS_QUERY_KEYS.fetchGraphGlobals(user?.sub);
		return queryClient.setQueryData(
			queryKey,
			(globals) => ({
				...globals,
				[property]: typeof data === 'function' ? data(globals?.[property]) : data,
			}),
		);
	}, [queryClient, user?.sub]);
};

export const useFetchGraphGlobalsProfile = getFetchGraphGlobalsHook(
	GRAPH_GLOBALS_KEY.PROFILE,
);
export const useSetGraphGlobalsProfile = getSetGraphGlobalsHook(
	GRAPH_GLOBALS_KEY.PROFILE,
);

export const useFetchGraphGlobalsActiveStudio = getFetchGraphGlobalsHook(
	GRAPH_GLOBALS_KEY.ACTIVE_STUDIO,
);
export const useSetGraphGlobalsActiveStudio = getSetGraphGlobalsHook(
	GRAPH_GLOBALS_KEY.ACTIVE_STUDIO,
);

export const useFetchGraphGlobalsPrices = getFetchGraphGlobalsHook(
	GRAPH_GLOBALS_KEY.PRICES,
);
export const useSetGraphGlobalsPrices = getSetGraphGlobalsHook(
	GRAPH_GLOBALS_KEY.PRICES,
);

export const useFetchGraphGlobalsBalances = getFetchGraphGlobalsHook(
	GRAPH_GLOBALS_KEY.BALANCES,
);
export const useSetGraphGlobalsBalances = getSetGraphGlobalsHook(
	GRAPH_GLOBALS_KEY.BALANCES,
);

export const useFetchGraphGlobalsFriendRequest = getFetchGraphGlobalsHook(
	GRAPH_GLOBALS_KEY.FRIEND_REQUESTS,
);
export const useSetGraphGlobalsFriendRequest = getSetGraphGlobalsHook(
	GRAPH_GLOBALS_KEY.FRIEND_REQUESTS,
);
