import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFetchSearchSuggestions } from '../../api-hooks/search';
import { TypeaheadInput } from '../../layout-components/TypeaheadInput';
import { getLink, Path } from '../../RoutePath';

export const SearchInput = ({ isSearchOpen, handleSearchOpen }) => {
	const history = useHistory();
	const { tags } = useParams();
	const { t } = useTranslation();

	const [searchValue, setSearchValue] = useState(tags || '');
	const { data: suggestions, isLoading: isSuggesting } = useFetchSearchSuggestions(searchValue);

	useEffect(() => {
		if (tags) {
			setSearchValue(tags);
		}
	}, [tags]);

	const search = useCallback((value) => {
		history.push(getLink(Path.SEARCH, { tags: value.inputValue }));
	}, [history]);

	return (
		<TypeaheadInput
			wrapperClassName={clsx('flex-fill min-w-0', {
				'expand-search-menu': isSearchOpen,
			})}
			onChange={search}
			inputValue={searchValue}
			onInputValueChange={setSearchValue}
			suggestions={suggestions}
			areSuggestionsLoading={isSuggesting}
			areSuggestionsOpened={isSearchOpen}
			onSuggestionsOpenedChange={handleSearchOpen}
			placeholder={t('SearchInput.search')}
		/>
	);
};

SearchInput.propTypes = {
	handleSearchOpen: PropTypes.func.isRequired,
	isSearchOpen: PropTypes.bool,
};

SearchInput.defaultProps = {
	isSearchOpen: false,
};
