import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSpinner } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useMedia } from '@technomiam/react-video';

import { Path, getLink } from '../../RoutePath';

import { MediaManagerWrapper } from './MediaManager';
import { ButtonLoading, ButtonPill, ButtonPillOutline } from '../Button';

const TOO_LONG_MIN_DURATION = 60 * 1000;

export const MediaManagerScreen = ({ pending, onClickConfirm, joinAutomatically }) => {
	const { t } = useTranslation();
	const { setEnabled } = useMedia();
	const [isTakingTooLong, setIsTakingTooLong] = useState(false);

	useEffect(() => {
		setEnabled(true);
		return () => { setEnabled(false); };
	}, [setEnabled]);

	useEffect(() => {
		if (!pending) return undefined;

		const timeout = setTimeout(() => {
			setIsTakingTooLong(true);
		}, TOO_LONG_MIN_DURATION);

		return () => { clearTimeout(timeout); };
	}, [pending]);

	const reload = () => { window.location.reload(); };

	useEffect(() => {
		if (joinAutomatically) {
			onClickConfirm();
		}
	}, [joinAutomatically, onClickConfirm]);

	return (
		<section className="d-flex h-100">
			<div className="d-flex flex-column col-md-6 col-lg-5 col-xl-4 m-auto p-4">
				<div className="mb-4">
					<div className="d-flex align-items-center justify-content-center mb-2">
						<h2 className="m-0">{t('MediaManager.Screen.ready')}</h2>
						<p className="m-0 ml-4 d-flex align-items-center">
							<ButtonLoading
								component={ButtonPillOutline}
								loading={pending}
								onClick={onClickConfirm}
							>
								{t('MediaManager.Screen.join')}
							</ButtonLoading>
							<ButtonPillOutline
								className="ml-2"
								color="secondary"
								tag={Link}
								to={getLink(Path.ROOT)}
							>
								{t('MediaManager.Screen.exit')}
							</ButtonPillOutline>
						</p>
					</div>
					<div className="mb-2">
						{(pending && !isTakingTooLong) && (
							<Alert className="d-flex align-items-center">
								{t('MediaManager.Screen.meetingStarting')}
								<FaSpinner className="ml-auto icon-spin" />
							</Alert>
						)}
						{isTakingTooLong && (
							<Alert color="warning">
								{t('MediaManager.Screen.takingTooLong')}<br />
								{t('MediaManager.Screen.tryTo')} <ButtonPill color="warning" size="sm" onClick={reload}>{t('MediaManager.Screen.reloadThePage')}</ButtonPill>.
							</Alert>
						)}
					</div>
				</div>
				<MediaManagerWrapper />
			</div>
		</section>
	);
};

MediaManagerScreen.propTypes = {
	pending: PropTypes.bool,
	onClickConfirm: PropTypes.func.isRequired,
	joinAutomatically: PropTypes.bool,
};

MediaManagerScreen.defaultProps = {
	pending: false,
	joinAutomatically: false,
};
