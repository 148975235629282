import PropTypes from 'prop-types';
import React, { useCallback, useId, useState, useRef } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FaImage, FaSmile, FaVideo } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import { resizeImageDefault } from '../../lib/file';
import { UserSettingsModal, useUserSettings } from '../UserSettings/Context';
import { useUploadImage } from '../../api-hooks/channel/images';
import { useProfile } from '../Profile/ProfileContext';
import { useUploadVideo } from '../../api-hooks/channel/videos';
import { TooltipTimeout } from '../Tooltip/Timeout';

export const properties = {
	filename: { type: 'string' },
};

export const schema = {
	type: 'object',
	additionalProperties: false,
	properties,
	required: [],
};

export const PostAssetType = {
	IMAGE: 'IMAGE',
	VIDEO: 'VIDEO',
};

export const AssetUploadMode = {
	LIBRARY: 'MediaInput.chooseFromLibrary',
	UPLOAD: 'MediaInput.upload',
};

const isFileAcceptable = (file, accept) => {
	const acceptedTypes = accept.replaceAll(' ', '').split(',');
	return acceptedTypes.includes(file.type);
};

export const MediaInput = ({
	uploadedAssets,
	setUploadedAssets,
	setImageUploadProgress,
	setVideoUploadProgress,
	toggleEmojiPanel,
	isDisabled,
}) => {
	const { t } = useTranslation();
	const [imageDropdownOpen, setImageDropdownOpen] = useState(false);
	const [videoDropdownOpen, setVideoDropdownOpen] = useState(false);

	const [imageIncorrectTypeError, setImageIncorrectTypeError] = useState(false);
	const [videoIncorrectTypeError, setVideoIncorrectTypeError] = useState(false);

	const { mutate: uploadImage } = useUploadImage();
	const { mutate: uploadVideo } = useUploadVideo();

	const { profile } = useProfile();
	const { openModal, closeModal } = useUserSettings();

	const imageInput = useRef(null);
	const videoInput = useRef(null);

	const id = useId();
	const imageId = `MediaInput-image${id.replaceAll(':', '__')}`;
	const videoId = `MediaInput-video${id.replaceAll(':', '__')}`;

	const onImageUploadProgress = useCallback((progress) => {
		setImageUploadProgress(progress);
	}, [setImageUploadProgress]);

	const onVideoUploadProgress = useCallback((progress) => {
		setVideoUploadProgress(progress);
	}, [setVideoUploadProgress]);

	const handleImageUploaded = useCallback((image) => {
		if (image) {
			setUploadedAssets([
				...uploadedAssets,
				{ image: image._id, assetType: PostAssetType.IMAGE, filename: image.filename },
			]);
		}
	}, [setUploadedAssets, uploadedAssets]);

	const handleVideoUploaded = useCallback((video) => {
		if (video) {
			setUploadedAssets((prevUploadedAssets) => [
				...prevUploadedAssets,
				{ video: video._id, assetType: PostAssetType.VIDEO, filename: video.filename },
			]);
		}
	}, [setUploadedAssets]);

	const handleBrowserFiles = useCallback(() => {
		imageInput.current.click();
	}, [imageInput]);

	const handleBrowseVideos = useCallback(() => {
		videoInput.current.click();
	}, [videoInput]);

	const handleUploadImage = useCallback((imageData) => {
		uploadImage({
			channelId: profile._id,
			image: imageData,
			onUploadProgress: onImageUploadProgress,
		}, {
			onSuccess: (data) => {
				handleImageUploaded(data);
				setImageUploadProgress(undefined);
			},
		});
	}, [handleImageUploaded, onImageUploadProgress, profile, setImageUploadProgress, uploadImage]);

	const handleUploadVideo = useCallback((videoData) => {
		uploadVideo({
			channelId: profile._id,
			videoFile: videoData,
			onUploadProgress: onVideoUploadProgress,
		}, {
			onSuccess: (data) => {
				handleVideoUploaded(data);
				setVideoUploadProgress(undefined);
			},
		});
	}, [handleVideoUploaded, onVideoUploadProgress, profile, setVideoUploadProgress, uploadVideo]);

	const handleImageChange = useCallback(async (event) => {
		setImageIncorrectTypeError(false);
		const files = Array.from(event.target.files);

		if (!files?.length) {
			return;
		}

		const file = files[0];

		if (!isFileAcceptable(file, event.target.accept)) {
			setImageIncorrectTypeError(true);
			return;
		}

		const blob = await resizeImageDefault(file);
		const imageData = {
			blob,
			label: file.name.replace(/\.[^/.]+$/, ''),
			filename: URL.createObjectURL(blob),
			isGfx: false,
		};

		handleUploadImage(imageData);
	}, [handleUploadImage]);

	const handleVideoChange = useCallback(async (event) => {
		setVideoIncorrectTypeError(false);
		const files = Array.from(event.target.files);

		if (!files?.length) {
			return;
		}

		const file = files[0];

		if (!isFileAcceptable(file, event.target.accept)) {
			setVideoIncorrectTypeError(true);
			return;
		}

		const label = file.name.replace(/\.[^/.]+$/, '');
		const videoData = {
			blob: file,
			label,
			filename: URL.createObjectURL(file),
		};

		handleUploadVideo(videoData);
	}, [handleUploadVideo]);

	const handleOpenSelectAssetModal = useCallback((callback, modalType) => {
		const call = (asset) => {
			closeModal();
			return callback(asset);
		};
		openModal(modalType, undefined, undefined, call);
	}, [closeModal, openModal]);

	return (
		<>
			<div className="d-flex justify-content-start mt-2 align-items-center">
				<span
					className={classNames(
						'd-none d-sm-block cursor-pointer text-secondary mr-2',
						{ disabled: isDisabled },
					)}
					onClick={toggleEmojiPanel}
				>
					<FaSmile />
				</span>
				<Dropdown
					isOpen={imageDropdownOpen}
					toggle={() => setImageDropdownOpen(!imageDropdownOpen)}
				>
					<DropdownToggle
						className="font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-30 btn-transition-none mr-1 bg-transparent text-secondary"
						title={t('MediaInput.uploadImage')}
						type="button"
						id={imageId}
					>
						<FaImage />
					</DropdownToggle>
					{imageDropdownOpen && (
						<DropdownMenu>
							<DropdownItem onClick={() => handleOpenSelectAssetModal(
								handleImageUploaded,
								UserSettingsModal.SELECT_IMAGES,
							)}
							>
								{t(AssetUploadMode.LIBRARY)}
							</DropdownItem>
							<DropdownItem divider />
							<DropdownItem onClick={handleBrowserFiles}>
								{t(AssetUploadMode.UPLOAD)}
							</DropdownItem>
						</DropdownMenu>
					)}
				</Dropdown>
				<Dropdown
					isOpen={videoDropdownOpen}
					toggle={() => setVideoDropdownOpen(!videoDropdownOpen)}
				>
					<DropdownToggle
						className="font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-30 btn-transition-none bg-transparent text-secondary"
						title={t('MediaInput.uploadVideo')}
						type="button"
						id={videoId}
					>
						<FaVideo />
					</DropdownToggle>
					{videoDropdownOpen && (
						<DropdownMenu>
							<DropdownItem onClick={() => handleOpenSelectAssetModal(
								handleVideoUploaded,
								UserSettingsModal.SELECT_VIDEOS,
							)}
							>
								{t(AssetUploadMode.LIBRARY)}
							</DropdownItem>
							<DropdownItem divider />
							<DropdownItem onClick={handleBrowseVideos}>
								{t(AssetUploadMode.UPLOAD)}
							</DropdownItem>
						</DropdownMenu>
					)}
				</Dropdown>
			</div>
			<input
				className="d-none"
				accept="image/jpeg, .jpeg, image/png, .png"
				type="file"
				onChange={handleImageChange}
				ref={imageInput}
			/>
			<TooltipTimeout
				onTimeout={() => setImageIncorrectTypeError(false)}
				isOpen={imageIncorrectTypeError}
				placement="top"
				popperClassName="tooltip-danger"
				target={imageId}
			>
				{t('MediaInput.imageFormatAllowed')}
			</TooltipTimeout>
			<input
				className="d-none"
				accept="video/mp4, .mp4, video/mpeg, .mpeg, video/quicktime, .mov, video/webm, .webm"
				type="file"
				onChange={handleVideoChange}
				ref={videoInput}
			/>
			<TooltipTimeout
				onTimeout={() => setVideoIncorrectTypeError(false)}
				isOpen={videoIncorrectTypeError}
				placement="top"
				popperClassName="tooltip-danger"
				target={videoId}
			>
				{t('MediaInput.videoFormatAllowed')}
			</TooltipTimeout>
		</>
	);
};

MediaInput.propTypes = {
	uploadedAssets: PropTypes.arrayOf(PropTypes.shape({})),
	setUploadedAssets: PropTypes.func,
	setImageUploadProgress: PropTypes.func,
	setVideoUploadProgress: PropTypes.func,
	toggleEmojiPanel: PropTypes.func,
	isDisabled: PropTypes.bool,
};

MediaInput.defaultProps = {
	uploadedAssets: [],
	setUploadedAssets: undefined,
	setImageUploadProgress: undefined,
	setVideoUploadProgress: undefined,
	toggleEmojiPanel: undefined,
	isDisabled: false,
};
