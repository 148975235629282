import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

import { TabContent, TabPane } from 'reactstrap';
import { resetPasswordBegin, resetPasswordConfirm } from '../../api/profile/password';
import { verificationConfirm, verificationResend } from '../../api/verification';
import { useAuthentication } from '../Authentication/Authentication';
import LoginStepForgottenPassword from './StepForgottenPassword';
import LoginStepLogin from './StepLogin';

import { ModalLazyContent } from '../Modal/LazyContent';
import { ModalScreenHeader } from '../Modal/Screen/Header';
import { ModalScreen } from '../Modal/Screen/Screen';
import { IdentificationField } from '../Signup/signup.schema';
import './Modal.scss';
import { LoginConfirm } from './Confirm';
import { LoginPassword } from './Password';

export const clearAccount = (account) => {
	const preparedAccount = { ...account };
	delete preparedAccount.identificationField;

	if (account.identificationField === IdentificationField.EMAIL) {
		delete preparedAccount.phoneNumber;
	} else if (account.identificationField === IdentificationField.PHONE) {
		delete preparedAccount.email;
	}

	return preparedAccount;
};

const LoginModal = ({
	isOpen,
	toggle,
	showRoutingModalOrContinue,
}) => {
	const [account, setAccount] = useState({});
	const [activeTab, setActiveTab] = useState('stepLogin');
	const [verificationToken, setVerificationToken] = useState();
	const { login } = useAuthentication();

	const handleSubmitStepLogin = useCallback(async (stepLoginData) => {
		setAccount(
			(state) => ({
				...state,
				...stepLoginData,
			}),
		);

		await login(clearAccount({
			...stepLoginData,
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		}));
		showRoutingModalOrContinue();
	}, [login, showRoutingModalOrContinue]);

	const handleSubmitStepForgottenPassword = useCallback(
		async (stepForgottenPasswordData) => {
			setAccount((state) => ({ ...state, ...stepForgottenPasswordData }));
			const result = await resetPasswordBegin(clearAccount(stepForgottenPasswordData));
			setVerificationToken(result.data.verification_token);
			setActiveTab('stepForgottenPasswordConfirm');
		},
		[],
	);

	const handleSubmitStepForgottenPasswordConfirm = useCallback(
		async ({ code }) => {
			await verificationConfirm({ code, verification_token: verificationToken });
			setActiveTab('stepForgottenPasswordReset');
		},
		[verificationToken],
	);

	const handleResendConfirmationCode = useCallback(
		async () => {
			const result = await verificationResend(verificationToken);
			setVerificationToken(result.data.verification_token);
		},
		[verificationToken],
	);

	const handleSubmitStepForgottenPasswordReset = useCallback(
		async (stepForgottenPasswordReset) => {
			await resetPasswordConfirm(
				clearAccount({
					...account,
					...stepForgottenPasswordReset,
				}),
				verificationToken,
			);
			setActiveTab('stepLogin');
		},
		[account, verificationToken],
	);

	const handleBack = useCallback((step, stepData) => {
		if (stepData) {
			setAccount((state) => ({ ...state, ...stepData }));
		}
		setActiveTab(step);
	}, []);

	const handleBackStepConfirm = useCallback(() => {
		handleBack('stepForgottenPassword');
	}, [handleBack]);

	const handleBackStepPassword = useCallback(() => {
		handleBack('stepForgottenPassword');
	}, [handleBack]);

	const handleForgottenPassword = useCallback((stepLoginData) => {
		setAccount((state) => ({ ...state, ...stepLoginData }));
		setActiveTab('stepForgottenPassword');
	}, []);

	const showCloseButton = ['stepLogin', 'stepForgottenPassword'].includes(activeTab);

	const modalTitle = useMemo(() => {
		if (activeTab === 'stepLogin') {
			return {
				title: 'Sign in',
				subTitle: 'Fill in the fields bellow to login',
			};
		} if (activeTab === 'stepForgottenPassword') {
			return {
				title: 'Forgot your password?',
				subTitle: `Enter the ${
					account.identificationField === IdentificationField.EMAIL ? 'email' : 'phone number'
				} related to your account and we will send you a code to reset your password`,
			};
		} if (activeTab === 'stepForgottenPasswordConfirm') {
			return {
				title: `Confirm your ${account.identificationField === IdentificationField.EMAIL ? 'email' : 'phone number'}`,
				subTitle: 'Enter the confirmation code you received',
			};
		} if (activeTab === 'stepForgottenPasswordReset') {
			return {
				title: 'Choose a new password',
				subTitle: 'And you\'ll be able to sign in!',
			};
		}

		return {
			title: '',
			subTitle: '',
		};
	}, [account.identificationField, activeTab]);

	return (
		<ModalScreen
			bodyClassName="py-0 pb-5 bg-light"
			contentClassName="border-0 shadow-lg rounded overflow-hidden m-auto"
			isOpen={isOpen}
			onClose={toggle}
			size="lg"
			header={(
				<ModalScreenHeader
					onClose={showCloseButton ? toggle : undefined}
					title={modalTitle.title}
					subTitle={modalTitle.subTitle}
					emphasisOnTitle
					noBorder
				/>
			)}
		>
			<TabContent activeTab={activeTab}>
				<ModalLazyContent>
					<TabPane tabId="stepLogin" className="h-100 px-5">
						<LoginStepLogin
							initialState={account}
							onForgottenPassword={handleForgottenPassword}
							onSubmit={handleSubmitStepLogin}
						/>
					</TabPane>
					<TabPane tabId="stepForgottenPassword" className="h-100 px-5">
						<LoginStepForgottenPassword
							initialState={account}
							onBack={handleBack}
							onSubmit={handleSubmitStepForgottenPassword}
						/>
					</TabPane>
					<TabPane tabId="stepForgottenPasswordConfirm" className="h-100 px-5">
						<LoginConfirm
							initialState={account}
							onBack={handleBackStepConfirm}
							onResend={handleResendConfirmationCode}
							onSubmit={handleSubmitStepForgottenPasswordConfirm}
						/>
					</TabPane>
					<TabPane tabId="stepForgottenPasswordReset" className="h-100 px-5">
						<LoginPassword
							initialState={account}
							onBack={handleBackStepPassword}
							onSubmit={handleSubmitStepForgottenPasswordReset}
						/>
					</TabPane>
				</ModalLazyContent>
			</TabContent>
		</ModalScreen>
	);
};

LoginModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	showRoutingModalOrContinue: PropTypes.func.isRequired,
	toggle: PropTypes.func.isRequired,
};

export default React.memo(LoginModal);
