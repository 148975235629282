import PropTypes from 'prop-types';
import React from 'react';
import { FaCompress, FaExpand, FaPause, FaPlay } from 'react-icons/fa';
import {
	Button,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { SoundRange } from '../../Sound/SoundRange';
import './ForVideo.scss';

export const ChannelButtonsPanelForVideo = ({
	centerChildren,
	channelDescription,
	handleChangeVolume,
	handleClickFullScreen,
	handleTogglePlaying,
	handleToggleSound,
	isFullscreen,
	isPlaying,
	isSoundMuted,
	leftChildren,
	rightChildren,
	volume,
	shareVideoButton,
	miniPlayer,
}) => {
	const { t } = useTranslation();

	return (
		<>
			<div className={`LeftContent d-flex align-items-center ${miniPlayer && 'miniPlayer'}`}>
				<Button
					className={`p-0 btn-no-focus ${miniPlayer ? 'd-25' : 'mx-1 d-40'}`}
					color="neutral-secondary"
					title={isPlaying ? t('ChannelButtonsPanel.ForVideo.pause') : t('ChannelButtonsPanel.ForVideo.play')}
					onClick={() => handleTogglePlaying()}
				>
					<span className="btn-wrapper--icon">
						{isPlaying ? <FaPause /> : <FaPlay />}
					</span>
				</Button>
				{leftChildren}
			</div>
			{centerChildren && (
				<div className="CenterContent d-flex align-items-center justify-content-center flex-grow-1">
					{centerChildren}
				</div>
			)}
			<div className="RightContent d-flex align-items-center">
				{ shareVideoButton }
				<SoundRange
					handleChangeVolume={handleChangeVolume}
					volume={volume}
					handleToggleSound={handleToggleSound}
					isMute={isSoundMuted}
					miniPlayer={miniPlayer}
				/>
				{rightChildren}
				{!!handleClickFullScreen && (
					<Button
						className={`p-0 btn-no-focus ${miniPlayer ? 'd-25' : 'mx-1 d-40'}`}
						color="neutral-secondary"
						title={t('ChannelButtonsPanel.ChannelButtonsPanel.toggleScreenMode')}
						onClick={(e) => handleClickFullScreen() && e.preventDefault()}
					>
						<span className="btn-wrapper--icon">
							{isFullscreen ? <FaCompress /> : <FaExpand />}
						</span>
					</Button>
				)}
			</div>
		</>
	);
};

ChannelButtonsPanelForVideo.propTypes = {
	centerChildren: PropTypes.node,
	channelDescription: PropTypes.node,
	handleChangeVolume: PropTypes.func,
	handleClickFullScreen: PropTypes.func,
	handleTogglePlaying: PropTypes.func,
	handleToggleSound: PropTypes.func,
	isFullscreen: PropTypes.bool,
	isPlaying: PropTypes.bool,
	isSoundMuted: PropTypes.bool,
	leftChildren: PropTypes.node,
	rightChildren: PropTypes.node,
	volume: PropTypes.number,
	shareVideoButton: PropTypes.node,
	miniPlayer: PropTypes.bool,
};

ChannelButtonsPanelForVideo.defaultProps = {
	centerChildren: null,
	channelDescription: null,
	handleChangeVolume: undefined,
	handleClickFullScreen: undefined,
	handleTogglePlaying: undefined,
	handleToggleSound: undefined,
	isFullscreen: false,
	isPlaying: false,
	isSoundMuted: false,
	leftChildren: null,
	rightChildren: null,
	volume: 50,
	shareVideoButton: null,
	miniPlayer: false,
};
