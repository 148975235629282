import React, { useContext, useState, useMemo, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
	exitFullscreen as exitFullscreenLib,
	requestFullscreen as requestFullscreenLib,
} from '../../../lib/requestFullscreen';

const ScreenModeContext = React.createContext({});

export const ModeScreenLayout = {
	FULLSCREEN: 'FULLSCREEN',
	NORMAL: 'NORMAL',
};
export const useScreenMode = () => useContext(ScreenModeContext);

export const ScreenModeProvider = ({ children }) => {
	const [currentModeScreen, setCurrentModeScreen] = useState(ModeScreenLayout.NORMAL);

	const [showControls, setShowControls] = useState(false);

	const requestFullscreenEventRef = useRef();
	requestFullscreenEventRef.current = () => {
		requestFullscreenLib(children.props?.id
			? document.documentElement.querySelector(`#${children.props.id}`)
			: document.documentElement);
		setCurrentModeScreen(ModeScreenLayout.FULLSCREEN);
	};

	const requestFullscreen = useCallback(() => {
		requestFullscreenEventRef.current();
	}, []);

	const exitFullscreenEventRef = useRef();
	exitFullscreenEventRef.current = () => {
		exitFullscreenLib();
		setCurrentModeScreen(ModeScreenLayout.NORMAL);
	};

	const exitFullscreen = useCallback(() => {
		exitFullscreenEventRef.current();
	}, []);

	const toggleFullscreenEventRef = useRef();
	toggleFullscreenEventRef.current = () => {
		if (currentModeScreen === ModeScreenLayout.NORMAL) requestFullscreenEventRef.current();
		else exitFullscreenEventRef.current();
	};

	const toggleFullscreen = useCallback(() => toggleFullscreenEventRef.current(), []);

	useEffect(() => {
		const handleFullscreenChange = () => {
			if (!document.fullscreenElement) setCurrentModeScreen(ModeScreenLayout.NORMAL);
		};

		const handleFullscreenError = () => console.error('handleFullscreenError');

		document.addEventListener('fullscreenchange', handleFullscreenChange, false);
		document.addEventListener('webkitfullscreenchange', handleFullscreenChange, false);
		document.addEventListener('fullscreenerror', handleFullscreenError, false);
		document.addEventListener('webkitfullscreenerror', handleFullscreenError, false);

		return () => {
			document.removeEventListener('fullscreenchange', handleFullscreenChange, false);
			document.removeEventListener('webkitfullscreenchange', handleFullscreenChange, false);
			document.removeEventListener('fullscreenerror', handleFullscreenError, false);
			document.removeEventListener('webkitfullscreenerror', handleFullscreenError, false);
		};
	});

	useEffect(() => {
		if (currentModeScreen !== ModeScreenLayout.FULLSCREEN) return undefined;

		let timeout = 0;

		const toggleControls = () => {
			clearTimeout(timeout);

			setShowControls(true);

			timeout = setTimeout(() => {
				setShowControls(false);
			}, 5000);
		};

		document.addEventListener('mousemove', toggleControls);
		document.addEventListener('click', toggleControls);
		document.addEventListener('keydown', toggleControls);
		document.addEventListener('touchstart', toggleControls);

		toggleControls();

		return () => {
			clearTimeout(timeout);

			document.removeEventListener('mousemove', toggleControls);
			document.removeEventListener('click', toggleControls);
			document.removeEventListener('keydown', toggleControls);
			document.removeEventListener('touchstart', toggleControls);
		};
	}, [currentModeScreen]);

	const contextValue = useMemo(() => ({
		currentModeScreen,
		exitFullscreen,
		requestFullscreen,
		setCurrentModeScreen,
		toggleFullscreen,
	}), [
		currentModeScreen,
		exitFullscreen,
		requestFullscreen,
		toggleFullscreen,
	]);

	return (
		<ScreenModeContext.Provider value={contextValue}>
			<div className={clsx('layout-wrapper',
				{ showPlayerControls: currentModeScreen === ModeScreenLayout.FULLSCREEN && showControls },
				{ hidePlayerControls: currentModeScreen === ModeScreenLayout.FULLSCREEN && !showControls })}
			>
				{children}
			</div>
		</ScreenModeContext.Provider>
	);
};

ScreenModeProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
