import PropTypes from 'prop-types';
import React, { Suspense, useCallback, useMemo, useState } from 'react';
import { AdminAnalyticsChannelOverviewModal } from '../Admin/Analytics/Channels/OverviewModal';
import { AnalyticsEngagementOverviewVideoModal } from './AnalyticsAndAdvertising/Analytics/Engagement/Overview/VideoModal';
import { AnalyticsAndAdvertisingModal } from './AnalyticsAndAdvertising/Modal';

import { UserSettingsContext, UserSettingsModal } from './Context';
import { InfoCenterModal } from './InfoCenterModal';
import { LibraryModal } from './LibraryModal/LibraryModal';
import { MembershipModal } from './MembershipModal';
import { MyAccountModal } from './MyAccountModal';
import { SurveyVoteTemplateModal } from './SurveyVoteTemplateModal';
import { PaginationProvider } from '../Pagination/Provider';
import { ListSortingProvider } from '../ListSorting/Provider';

export const UserSettingsProvider = ({ children }) => {
	const [modalOpen, setModalOpen] = useState();
	const [activeTab, setActiveTab] = useState();
	const [isSharedBy, setIsSharedBy] = useState();
	const [callback, setCallback] = useState();
	const [analyticsVod, setAnalyticsVod] = useState();
	const [analyticsChannel, setAnalyticsChannel] = useState();
	const [overModalOpen, setOverModalOpen] = useState();
	const [overCallback, setOverCallback] = useState();

	const closeModal = useCallback(() => {
		setModalOpen(undefined);
		setActiveTab(undefined);
		setIsSharedBy(undefined);
	}, []);

	const openModal = useCallback((modalType, tab, sharedBy, cb) => {
		setModalOpen(modalType);
		setActiveTab(tab);
		setIsSharedBy(sharedBy);
		setCallback(() => cb);
	}, []);

	const closeOverModal = useCallback(() => {
		setOverModalOpen(undefined);
		setOverCallback(undefined);
	}, []);

	const openOverModal = useCallback((modalType, tab, sharedBy, cb) => {
		setOverModalOpen(modalType);
		setOverCallback(() => cb);
	}, []);

	const context = useMemo(() => ({
		activeTab,
		closeOverModal,
		closeModal,
		modalOpen,
		openOverModal,
		openModal,
		setActiveTab,
		setModalOpen,
		setAnalyticsVod,
		setAnalyticsChannel,
	}), [
		activeTab,
		closeOverModal,
		closeModal,
		modalOpen,
		openOverModal,
		openModal,
		setAnalyticsVod,
		setAnalyticsChannel,
	]);

	return (
		<UserSettingsContext.Provider value={context}>
			{children}
			<Suspense fallback={null}>
				{modalOpen === UserSettingsModal.ACCOUNT && (
					<MyAccountModal
						isOpen
						activeTab={activeTab}
						setActiveTab={setActiveTab}
					/>
				)}
				{modalOpen === UserSettingsModal.MEMBERSHIP && (
					<MembershipModal defaultTab={activeTab} isOpen />
				)}
				{modalOpen === UserSettingsModal.LIBRARY && (
					<LibraryModal defaultTab={activeTab} isOpen isSharedBy={isSharedBy} />
				)}
				{modalOpen === UserSettingsModal.INFO_CENTER && (
					<InfoCenterModal isOpen />
				)}
				{modalOpen === UserSettingsModal.SELECT_VIDEOS && (
					<LibraryModal
						handleSelectAsset={callback}
						defaultTab="videos"
						isOpen
					/>
				)}
				{modalOpen === UserSettingsModal.SELECT_IMAGES && (
					<LibraryModal
						handleSelectAsset={callback}
						defaultTab="images"
						isOpen
					/>
				)}
				{modalOpen === UserSettingsModal.GFX_LIBRARY && (
					<LibraryModal
						handleSelectAsset={callback}
						defaultTab="gfx"
						isOpen
					/>
				)}
				{modalOpen === UserSettingsModal.VIDEO_IMAGE_LIBRARY && (
					<LibraryModal
						handleSelectAsset={callback}
						defaultTab="images"
						enabledTabs={['videos']}
						isOpen
					/>
				)}
				{modalOpen === UserSettingsModal.SURVEY_POLL && (
					<PaginationProvider initialItemsPerPage={10}>
						<ListSortingProvider>
							<SurveyVoteTemplateModal defaultTab={activeTab} isOpen />
						</ListSortingProvider>
					</PaginationProvider>
				)}
				{modalOpen === UserSettingsModal.ANALYTICS_AND_ADVERTISING && (
					<AnalyticsAndAdvertisingModal defaultTab={activeTab} isOpen />
				)}
				{modalOpen === UserSettingsModal.ANALYTICS_VOD_VIEW_MORE && (
					<AnalyticsEngagementOverviewVideoModal
						video={analyticsVod}
						isOpen
					/>
				)}
				{modalOpen === UserSettingsModal.ANALYTICS_CHANNEL_VIEW_MORE && !!analyticsChannel && (
					<AdminAnalyticsChannelOverviewModal
						channelId={analyticsChannel._id}
						isOpen
					/>
				)}
				{overModalOpen === UserSettingsModal.KEY_LIBRARY && (
					<LibraryModal
						handleSelectAsset={overCallback}
						defaultTab="images"
						isOpen
					/>
				)}
			</Suspense>
		</UserSettingsContext.Provider>
	);
};

UserSettingsProvider.propTypes = {
	children: PropTypes.node,
};

UserSettingsProvider.defaultProps = {
	children: undefined,
};
